<template>
  <v-card width="100%" class="mx-0" flat data-test-name="page-user-preferences">
    <v-card-title
      class="pa-0 pl-0 pl-md-3 py-6 py-md-4 pb-sm-6 text-h5 text-md-h4 font-weight-bold"
      data-test-name="text-title"
    >
      {{ $t('Preferences') }}
    </v-card-title>
    <v-row no-gutters class="my-6 px-md-3">
      <v-col cols="12" lg="6" class="pa-0 pr-lg-3 pb-6 pb-lg-0">
        <hotel-class-selector
          :preferredHotelClassRating="user?.preferences?.preferredHotelClass"
          @update="updateSelectedHotelClass"
        />
      </v-col>
      <v-col cols="12" lg="6" class="pa-0">
        <hotels-selector
          :hotelPreferences="user?.preferences?.hotelMembership"
          @update="updatePreferredHotels"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6 px-md-3">
      <v-col cols="12" lg="6" class="pa-0 pr-lg-3 pb-6 pb-lg-0">
        <flight-class-selector
          :preferredFlightClassRating="user?.preferences?.preferredFlightClass"
          @update="updateSelectedFlightClass"
        />
      </v-col>
      <v-col cols="12" lg="6" class="pa-0">
        <airlines-selector
          :airlinePreferences="user.preferences.airlineMembership"
          @update="updatePreferredAirlines"
        />
      </v-col>
      <v-col cols="12" lg="6" class="pa-0 pr-lg-3 mt-6">
        <restaurant-selector
          :priceLevel="user?.preferences?.preferredRestaurantRating"
          @update="selectedRestaurantRating"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex justify-space-between mt-9">
      <v-col cols="12" lg="4" md="6" class="pa-0 px-md-3 mb-6 mb-md-0">
        <v-btn
          color="primary"
          variant="outlined"
          rounded
          size="large"
          width="100%"
          data-test-name="button-clear-all"
          @click="toggleConfirmationPopup"
        >
          {{ $t('Clear_All') }}
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pa-0 px-md-3">
        <v-btn
          color="primary"
          variant="flat"
          rounded
          size="large"
          width="100%"
          data-test-name="button-save"
          @click="saveChanges"
          :disabled="wasDataChanged"
        >
          {{ $t('Save_Changes') }}
        </v-btn>
      </v-col>
    </v-row>
    <common-popup
      v-if="isConfirmationPopupVisible"
      :body-content="$t('Question_reset_settings')"
      :confirm-label="$t('Yes')"
      :cancel-label="$t('No')"
      @confirm="resetPreferencesToDefault"
      @cancel="toggleConfirmationPopup"
      @close="toggleConfirmationPopup"
    />
  </v-card>
</template>
<script>
import { flightClasses } from '@/helpers/commonConfigs'
import HotelClassSelector from '@/components/selector/HotelClassSelector'
import FlightClassSelector from '@/components/selector/FlightClassSelector'
import RestaurantSelector from '@/components/selector/RestaurantSelector'
import HotelsSelector from '@/components/selector/HotelsSelector'
import AirlinesSelector from '@/components/selector/AirlinesSelector'
import CommonPopup from '@/components/popups/CommonPopup'
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapGetters } from 'vuex'
import checkEquality from  'fast-deep-equal/es6'
import { defaultPreferences } from '@/store/modules/user'
import { logAppError } from '@/logger'

export default {
  name: 'UserPreferences',
  components: {
    HotelClassSelector,
    FlightClassSelector,
    RestaurantSelector,
    HotelsSelector,
    AirlinesSelector,
    CommonPopup
  },
  data() {
    return {
      isConfirmationPopupVisible: false,
      user: null,
    }
  },
  computed: {
    ...mapGetters({
      stateUser: 'USER'
    }),
    flightClassItems() {
      return flightClasses.map(el => ({...el, ...{text: this.$t(el.text)}}))
    },
    wasDataChanged() {
      return checkEquality(this.stateUserWithDefaultPreferences, this.user)
    },
    stateUserWithDefaultPreferences() {
      return {
        ...this.stateUser,
        preferences: {
          ...defaultPreferences,
          ...this.stateUser?.preferences,
        }
      }
    },
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updatePreferredHotels(val){
      this.user.preferences.hotelMembership = val
    },
    updatePreferredAirlines(val) {
      this.user.preferences.airlineMembership = val.map(airline => {
        return {
          iataCode: airline.iataCode
        }
      })
    },
    selectedRestaurantRating(val) {
      this.user.preferences.preferredRestaurantRating = val
    },
    updateSelectedHotelClass(val) {
      this.user.preferences.preferredHotelClass = val
    },
    updateSelectedFlightClass(val) {
      this.user.preferences.preferredFlightClass = val
    },
    async saveChanges() {
      await this.updateUser({ preferences: this.user.preferences })
    },
    async resetPreferencesToDefault() {
      try {
        this.user.preferences = cloneDeep(defaultPreferences)
        await this.saveChanges()
      } catch(error) {
        logAppError('Error during reset preferences to default value: ' + error)
      } finally {
        this.isConfirmationPopupVisible = false
      }
    },
    toggleConfirmationPopup() {
      this.isConfirmationPopupVisible = !this.isConfirmationPopupVisible
    },
  },
  watch: {
    stateUser: {
      handler(newVal) {
        this.user = cloneDeep({
          ...newVal,
          preferences: {
            ...defaultPreferences,
            ...newVal.preferences,
          }
        })
      },
      immediate: true,
    }
  }
}
</script>
