<template>
  <v-card elevation="0" data-test-name="content-transportation-event-details">
    <v-row class="my-0 pt-2">
      <v-col cols="6" lg="4" class="pa-0 pl-3 pr-3 my-0 py-0text-truncate">
        <v-select
          prepend-inner-icon="$customCurrency"
          :model-value="totalPrice?.currency"
          @update:model-value="updateCurrency"
          :label="$t('Currency')"
          :readonly="isReadOnly"
          :items="currencyList"
          class="pa-0 ma-0"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          variant="outlined"
          hide-details
          data-test-name="select-currency"
        />
      </v-col>
      <v-col cols="6" lg="3" class="pa-0 my-0 py-0 pl-3 pr-3">
        <v-currency-field
          :model-value="Number(totalPrice?.amount)"
          @update="updateAmount"
          variant="outlined"
          class="pa-0 ma-0"
          color="primary"
          data-test-name="input-price"
          :label="`${$t('Total_price')}`"
          :readonly="isReadOnly"
        />
      </v-col>
      <v-col cols="6" lg="3" class="pa-0 pl-3 pr-3 my-0 py-0">
        <passengers-selector
          :value="travelers"
          :readonly="isReadOnly"
          @input="updateTravelers"
          name="generate-custom-event"
          class="passengers-selector"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="totalTravelersNumber"
              :label="$t('Travelers') + '*'"
              class="pa-0 ma-0 trigger-change-travelers"
              :class="{ activator: !isReadOnly }"
              bg-color="#fff"
              color="primary"
              base-color="primary"
              variant="outlined"
              readonly
              hide-details
              prepend-inner-icon="$customTravelers"
              v-bind="props"
            />
          </template>
        </passengers-selector>
      </v-col>
      <v-col cols="6" lg="2" class="mb-0 py-0">
        <event-booking
          v-if="isBookable"
          :isBooked="isBooked"
          :readonly="isReadOnly"
          @updateBooked="updateBooked"
          class="d-flex justify-end"
        />
      </v-col>
    </v-row>

    <v-row dense class="mb-12">
      <v-col cols="12" class="mb-8">
        <v-textarea
          :model-value="notes"
          :label="$t('Notes')"
          :auto-grow="false"
          :readonly="readonly"
          @update:model-value="updateNotes"
          class="pa-0 ma-0"
          rows="3"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          variant="outlined"
          no-resize
          hide-details="auto"
          data-test-name="input-notes"
          :rules="notesRules"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCurrencySelectList } from '@/helpers/currency'
import EventBooking from '@/components/popups/event-booking/EventBooking'
import { getItemTypeByType, convertFromStringToNumber } from '@/helpers'
import PassengersSelector from '@/components/popups/PassengersSelector'
import VCurrencyField from '@/components/VCurrencyField'

export default {
  name: 'TransportationEventDetails',
  components: {
    PassengersSelector,
    EventBooking,
    VCurrencyField,
  },
  emits: [
    'updateNotes',
    'updateTravelers',
    'updateBooked',
    'updateAddress',
    'updateTitle',
    'updatePrice'
  ],
  props: {
    imageURL: {
      type: String,
    },
    isBooked: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    totalPrice: {
      type: Object,
    },
    travelers: {
      type: Object,
    },
    eventType: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    isBookable: {
      type: Boolean,
      default: true,
    },
    notes: {
      type: String,
      required: false,
    },
    notesRules: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      currencyList: [],
      imageSrc: '',
    }
  },
  created() {
    this.currencyList = getCurrencySelectList(this.isMobile)
  },
  computed: {
    ...mapGetters({
      isEditor: 'IS_EDITOR',
      isMobile: 'IS_MOBILE',
    }),
    isReadOnly() {
      return !this.isEditor
    },
    totalTravelersNumber() {
      return (this.travelers?.adults || 0) + (this.travelers?.children || 0)
    },
    thumbnailURL() {
      return this.imageSrc || this.imageURL || getItemTypeByType(this.eventType).defaultImageUrl
    }
  },
  methods: {
    updateNotes(value) {
      this.$emit('updateNotes', value)
    },
    updateTravelers(value){
      this.$emit('updateTravelers', value)
    },
    updateBooked(value) {
      this.$emit('updateBooked', value)
    },
    updateAddress(value) {
      this.$emit('updateAddress', value)
    },
    updateTitle(value) {
      this.$emit('updateTitle', value)
    },
    updateCurrency(value) {
      this.$emit('updatePrice', { currency: value, amount: this.totalPrice?.amount })
    },
    updateAmount(value) {
      this.$emit('updatePrice', {currency: this.totalPrice?.currency, amount: convertFromStringToNumber(value)})
    },
    handleImageError() {
      this.imageSrc = getItemTypeByType(this.eventType).defaultImageUrl
    },
  },
}
</script>

<style scoped lang="scss">

.trigger-change-travelers input {
  cursor: pointer !important;
}

.filled-input.v-text-field input{
  color: rgb(var(--v-theme-inactiveColor-lighten5));
}
</style>
