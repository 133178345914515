<template>
  <v-container
    class="pb-10 pb-md-12 text-textPrimaryColor"
    data-test-name="page-about-us"
    :fluid="$vuetify.display.lgAndDown"
  >
    <v-row class="flex-column" align="center" no-gutters>
      <v-col>
        <h2
          class="mt-8 mt-md-16 font-weight-bold text-h3 text-sm-h2 text-center"
          data-test-name="text-title-about-us"
        >
          {{ $t('About_us') }}
        </h2>
        <about-trava-text />
        <v-row class="flex-column" align="center" data-test-name="trava-power">
          <v-col lg="9">
            <h3
              class="mt-8 mt-md-16 font-weight-bold text-h3 text-sm-h2 text-center"
              data-test-name="title"
            >
              {{ $t('Trava_Power') }}
            </h3>
            <v-row class="mt-4 mt-md-10" no-gutter>
              <v-col md="7" cols="12" class="text-body-2 text-sm-body-1">
                <p data-test-name="text-message">{{ $t('Trava_Power_Text_P1') }}</p>
                <h3 class="mb-2 mb-md-4" data-test-name="text-subtitle">{{$t('Trava_Feedback_Title')}}</h3>
                <p class="mb-4 mb-md-10" data-test-name="text-message-feedback">
                    {{ $t('Trava_Feedback_Text_1')}}
                    <strong>{{ $t('Trava_Feedback') }}</strong>
                    {{ $t('Trava_Feedback_Text_2') }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AboutTravaText from '@/components/AboutTravaText.vue'

export default {
  name: 'AboutUs',
  components: {
    AboutTravaText,
  },
}
</script>
