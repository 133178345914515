<template>
  <v-expansion-panel>
    <itinerary-header
      :itemID="itemID"
      :title="title"
      :totalPrice="totalPrice"
      :startDTM="startDTM"
      :endDTM="endDTM"
      :duration="duration"
      :icon-color="itemColor(record)"
      :icon="itemIcon(record)"
      :eventType="getEventType(record)"
      :directionsLink="directionsLink"
      :nightAtHotel="nightAtHotel"
      :checkOut="checkOut"
      :record="record"
    />
  </v-expansion-panel>
</template>

<script>
import ItineraryHeader from '@/components/itinerary-trip-rows/ItineraryHeader'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'ItineraryLodgingRow',
  components: {
    ItineraryHeader,
  },
  props: {
    record: Object,
    directionsLink: String,
    readonly: { type:Boolean, required: true },
  },
  computed: {
    itemDetails() {
      return this.record?.lodging
    },
    nightAtHotel() {
      return this.record?.header?.nightAtHotel
    },
    checkOut() {
      return this.record?.header?.checkOut
    },
    itemID() {
      return this.record?.header?.itemID
    },
    title() {
      return this.itemDetails?.title || this.itemDetails?.roData?.name //TODO remove once BE is done
    },
    totalPrice() {
      //only show price for check in event
      return !(this.record?.header?.nightAtHotel ||  this.record?.header?.checkOut)  ? this.itemDetails?.totalPrice : {}
    },
    duration() {
      if (this.nightAtHotel) return 'Night at'

      let start = this.$moment.parseZone(this.startDTM)
      let end = this.$moment.parseZone(this.endDTM)

      if (this.checkOut) return `${Math.ceil(this.$moment.duration(end.diff(start)).asHours())}h`

      let duration = Math.ceil(this.$moment.duration(end.diff(start)).asDays())
      return duration + ' night' + (duration > 1 ? 's':'') + ' stay'
    },
    startDTM() {
      return this.record?.header?.startDateTime
    },
    endDTM() {
      return this.record?.header?.endDateTime
    },
  },
  methods: {
    itemColor: itineraryService.itemColor,
    getEventType: itineraryService.getEventType,
    itemIcon: itineraryService.itemIcon,
  }
}
</script>
