const URITemplate = require('urijs/src/URITemplate')

/**
 * Used as core params, extend this here only if it's reusable everywhere,
 * otherwise extend it in given context.
 *
 * @param {Object} rootState
 * @returns {userID, itineraryID}
 */
export const getDefaultRequestParams = (rootState) => {
  return {
    userID: rootState.user.user.id,
    itineraryID: rootState.itinerary.currentItinerary.header.itineraryID
  }
}
/**
 * Build and return URITemplate based URL for request
 *
 * @param {String} templateUrl - Endpoint URL
 * @param {Object} params - Request params obj
 * @returns url
 */
export const getRequestURL = (templateUrl, params) => {
  const template = URITemplate(templateUrl)
  const url = template.expand(params).replace(/\/$/, '')
  return url
}
