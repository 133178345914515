<template>
  <div class="pt-6 px-3 mt-6" data-test-name="content-more-about-trava">
    <h2
      data-test-name="text-title"
      class="mt-4 mt-md-16 font-weight-bold text-h4 text-md-h2 text-center">
      {{ $t('More_About_Trava') }}
    </h2>
    <about-trava-text />
    <v-row class="flex-column" align="center">
      <v-col lg="9">
        <v-row class="mt-0 mt-md-10" no-gutter>
          <v-col md="7" cols="12" class="text-body-2 text-sm-body-1" data-test-name="first-point">
            <h3 class="mb-3 mt-1 mt-md-5" data-test-name="text-title">{{ $t('Trava_Power') }}</h3>
            <p data-test-name="text">{{ $t('Trava_Power_Text_P1') }}</p>
          </v-col>
        </v-row>
        <v-row justify="end" no-gutters>
          <v-col
            md="6"
            cols="12"
            class="mt-1 mt-md-10 mb-2 mb-md-6 text-body-2 text-sm-body-1"
            data-test-name="second-point"
          >
            <h3 class="mb-2 mb-md-4" data-test-name="text-title">{{ $t('Trava_Feedback_Title') }}</h3>
            <p class="mb-10 mb-md-16" data-test-name="text">{{ $t('Trava_Feedback_Text_1') }}
              <strong>{{ $t('Trava_Feedback') }}</strong>
              {{ $t('Trava_Feedback_Text_2') }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AboutTravaText from '@/components/AboutTravaText.vue'

export default {
  name: 'MoreAboutTrava',
  components: { AboutTravaText },
}
</script>

<style lang="scss" scoped>
  .cardBorderRadius {
    border-radius: 8px;
  }
</style>
