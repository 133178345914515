<template>
  <div class="text-textPrimaryColor" data-test-name="content-about-trava">
    <v-row class="flex-column" align="center">
      <v-col lg="9">
        <v-row no-gutter class="mt-2 mt-md-12">
          <v-col md="7" cols="12" class="text-body-2 text-sm-body-1" data-test-name="first-point">
            <h3 class="mb-2 mb-md-5" data-test-name="text-title">{{ $t('Trava_Is_Built_To_Save') }}</h3>
            <p data-test-name="text-message">{{ $t('Trava_Is_Built_To_Save_Text') }}</p>
          </v-col>
        </v-row>
        <v-row justify="end" no-gutters>
          <v-col md="6" cols="12" class="mt-md-10 mt-1 text-body-2 text-sm-body-1" data-test-name="second-point">
            <h3 class="mb-2 mb-md-5" data-test-name="text-title">{{ $t('Trava_Is_A_Great') }}</h3>
            <p class="mb-3 mb-sm-5 mb-md-10" data-test-name="text-message">{{ $t('Trava_Is_A_Great_Text') }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="11" xl="10">
        <v-row v-if="!$vuetify.display.xs" no-gutters>
          <v-col cols="4" class="pa-0">
            <v-img
              data-test-name="image-1"
              class="cardBorderRadius"
              src="/assets/images/more-about-trava-1.png"
              >
            </v-img>
          </v-col>
          <v-col cols="5" class="px-4 px-md-8">
            <v-img
              data-test-name="image-2"
              class="cardBorderRadius"
              src="/assets/images/more-about-trava-2.png"
            >
            </v-img>
          </v-col>
          <v-col cols="3" class="pa-0">
            <v-img
              data-test-name="image-3"
              class="cardBorderRadius"
              src="/assets/images/more-about-trava-3.png"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="py-0">
            <v-img
              data-test-name="image"
              class="cardBorderRadius"
              src="/assets/images/more-about-trava-2.png"
              >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AboutTravaText',
}
</script>

<style lang="scss" scoped>
  .cardBorderRadius {
    border-radius: 8px;
  }
</style>
