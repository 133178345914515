import { logAppError } from '@/logger'

export const PLACE_IMAGE_PATH = '/dias/images/destinations'

export const generateUrlByPlaceID = (placeID) => {
  if (!placeID) return ''

  let path = ''
  // path will be something like this (based on the file name)
  // 1-234567.jpeg -> "1-/23/45/1-234567.jpeg"
  for (let i = 0; i <= 4; i += 2) {
    const sub = placeID.substr(i, 2)
    path += sub ? ('/' + placeID.substr(i, 2)) : ''
  }
  return `${PLACE_IMAGE_PATH}${path}/${placeID}.jpeg`
}

export const defaultImageUrl = `${PLACE_IMAGE_PATH}/default.png`
export const ROLLBAR_NO_IMAGE_ERROR_MESSAGE = 'Image for IATA code does not exist. Code: '

export const getDefaultImageUrl = (code) => {
  if(code) {
    logAppError(ROLLBAR_NO_IMAGE_ERROR_MESSAGE + ' ' + code)
  }
  return `${PLACE_IMAGE_PATH}/default.png`
}