<template>
  <dialog-wrapper
    ref="dw"
    :min-width="minWidth"
    :name="name"
    :content-class="contentClass"
    offset-y="5"
    @close="onDialogClose"
    @open="onDialogOpen"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator">
        <v-text-field
          :variant="variant"
          :class="{ 'text-red-lighten-4': !isValid, activator: !readonly }"
          :label="label"
          @click="!readonly && on($event)"
          :placeholder="placeholder"
          readonly
          :prepend-inner-icon="icon"
          :model-value="value ? activatorLabel : placeholder"
          @keydown.enter="on"
          :color="color"
          :base-color="baseColor"
          :bg-color="bgColor"
          data-test-name="input-open-dialog"
        >
          <div
            @click="on"
            class="text-truncate"
            data-test-name="chip"
          >
            <v-chip
              v-if="chipValue"
              class="mb-1 px-1 text-caption"
              label
              density="compact"
            >
              {{ chipValue }}
            </v-chip>
            <slot name="append" />
          </div>
        </v-text-field>
      </slot>
    </template>

    <template v-slot:default="{ off }">
      <v-card
        :max-width="fullscreen ? '' : '450'"
        :max-height="fullscreen ? '' : '450'"
        data-test-name="card-place"
      >
        <v-card-title>
          <v-icon @click="off" icon="mdi-arrow-left" />
          <v-text-field
            v-model="searchQuery"
            ref="searchInput"
            variant="underlined"
            color="primary"
            base-color="primary"
            autofocus
            hide-details
            density="compact"
            data-test-name="input-search"
            :placeholder="placeholder"
            :readonly="readonly"
            @update:modelValue="lookUp"
            @focus="selectInputText"
            @keydown.enter="focusOnList()"
          >
          </v-text-field>
        </v-card-title>
        <v-card-text
          class="pa-0 overflow-y-auto"
          ref="scrollableContainer"
          v-show="entries"
        >
          <v-list class="pa-0 ma-0" density="compact">
            <v-list-item
              v-for="(item, index) in entries"
              :key="index"
              link
              :class="selectedID && item.id === selectedID ? 'v-list-item--highlighted ml-7' : 'ml-7'"
              :prepend-icon="placeIcon(item)"
              @click="selectItem(item)"
              data-test-name="list-item"
            >
              {{ placeTitle(item) }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
  </dialog-wrapper>
</template>

<script>
import DialogWrapper from './DialogWrapper'
import { mapGetters } from 'vuex'
import { logAppError } from '@/logger'
import placeSearch from '@/helpers/placeSearch'
import { placeName } from '@/helpers/itineraryServices/nameFormatter'

export default {
  name: 'PlaceInput',
  components: { DialogWrapper },
  // update - placeID
  // updateLocation - { placeID, iataCode, latLon }
  emits: ['update', 'updateLocation'],
  props: {
    placeholder: String,
    icon: {
      type: String,
      default: '$customLocation',
    },
    variant: {
      type: String,
      validator(value) {
        return [
          'flat',
          'text',
          'elevated',
          'tonal',
          'outlined',
          'plain',
        ].includes(value)
      },
      default: 'plain',
    },
    //value is placeID
    value: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * returnPlaceType defines what place will be returned:
     *  - 'airport':
     *    - if user selects an airport, it gets returned
     *    - if user selects a city/location other than an airport - an airport in range is searched and returned
     *  - 'city':
     *    - user can select either airport or city from the list, and it will be returned
     *
     * returned above means 'update' event will be emited to the caller
    */
    returnPlaceType: {
      type: String,
      validator(value) {
        return [
          'city',
          'airport',
        ].includes(value)
      },
      default: 'city',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'primary'
    },
    bgColor: {
      type: String,
      default: '#fff'
    },
    baseColor: {
      type: String,
      default: 'primary'
    },
    contentClass: {
      type: String,
      default: 'place-input-dialog',
    },
    minWidth: String,
    borderColor: String,
    name: String,
    readonly: Boolean,
    isAllowAirportsInSearch: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      searchQuery: '',
      city: '',
      country: '',
      fullscreen: false,
      selectedItemIndex: -1,
      selectedID: null,
      entries: [],
      chipValue: '',
      place: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      currentItinerary: 'CURRENT_ITINERARY',
    }),
    minHeight() {
      //const minVisibleEntries = 3
      //const entryHeight = 64
      return '100' //String((this.entries?.length <= 3 ? entryHeight * this.entries?.length : 64 * minVisibleEntries))
    },
    activatorLabel() {
      return placeName(this.place)
    },
  },
  async mounted() {
    this.searchQuery = this.city

    if (this.value) {
      await this.placeLookup(this.value)
    }
  },
  watch: {
    // HACK!! this watcher is needed as the value can be updated by the parent component,
    // and we need to make sure it's propagated inside
    value: {
      async handler(val) {
        if (val) {
          await this.placeLookup(this.value)
        }

      },
      immediate: true
    }
  },
  methods: {
    async placeLookup(value) {
      if (value) {
        const data = await placeSearch.lookupPlacesByIds(value)
        if (data && data.length > 0) {
          this.place = data[0]
          this.city = data[0]?.city
          this.country = data[0]?.isoCountry
          this.chipValue = data[0].type === 'airport' ? data[0]?.airportPlace?.iataCode : ''
        }
      }
    },
    placeTitle(place) {
      if (!place) return ''

      switch (place.type) {
      case 'airport':
        return '(' + place?.airportPlace?.iataCode + ') ' + placeName(place)
      case 'city':
        return placeName(place)
      default:
        return 'no name'
      }
    },
    placeIcon(place) {
      if (!place) return ''

      switch (place.type) {
      case 'airport':
        return 'mdi-airplane'
      case 'city':
        return 'mdi-city'
      default:
        return 'mdi-star'
      }
    },
    onResize(isFullScreen) {
      if (1 === 1 || !this.$el) return
      const el = this.$el.getBoundingClientRect()
      const scrollableContainer = this.$refs?.scrollableContainer?.$el
      if (scrollableContainer) {
        scrollableContainer.style.maxHeight =
          window.innerHeight -
          (isFullScreen ? 4 : el.top + 32) -
          this.$refs.scrollableContainer.offsetTop +
          'px'
        scrollableContainer.style.minHeight = this.minHeight
        //(this.entries.length <= 3 ? 64 * this.entries.length : 64 * 3) + 'px'
      }
    },
    focusOnList() {
      try {
        this.$refs?.searchList[0]?.$el?.focus()
      } catch (ignore) {
        //ignore
      }
    },
    onDialogOpen() {
      this.searchQuery = this.city
      const input = this.$refs.searchInput?.$el.querySelector('input')
      input?.classList.add('text-capitalize')
      this.lookUp(this.searchQuery)
    },
    onDialogClose() {
      this.entries = []
    },
    async selectItem(place) {
      if (this.readonly || !place?.city) return false

      this.city = place.city
      this.country = place.isoCountry
      this.searchQuery = place.city
      if (this.returnPlaceType === 'airport') {
        if (place.type === 'airport') {
          this.$emit('update', place.placeID)
          this.$emit('updateLocation', {
            placeID: place?.placeID,
            iataCode: place?.airportPlace?.iataCode,
            latLon: place?.latLon,
            tzName: place?.tzName
          })
        } else if (place.type === 'city') {
          //need to search for the airport in range first
          try {
            const airports = await placeSearch.lookupPlacesInRange(place.latLon, 'airport', '100mi', 1)
            if (airports && airports.length > 0) {
              this.city = airports[0]?.city
              this.country = airports[0]?.isoCountry
              this.searchQuery = airports[0]?.city
              this.chipValue = airports[0]?.placeID
              this.$emit('update', airports[0]?.placeID)
              this.$emit('updateLocation', {
                placeID: airports[0]?.placeID,
                iataCode: airports[0]?.airportPlace?.iataCode,
                latLon: airports[0]?.latLon,
                tzName: place?.tzName
              })
            }
          } catch (err) {
            logAppError(err)
          }
        }
      } else if (this.returnPlaceType === 'city') {
        //return place id regardless what user selected
        this.$emit('update', place.placeID)
        this.$emit('updateLocation', {
          placeID: place?.placeID,
          iataCode: place?.airportPlace?.iataCode || undefined,
          latLon: place?.latLon,
          tzName: place?.tzName
        })
      }
      this.$refs?.dw && this.$refs.dw.dialogClose()
    },

    async lookUp(query) {
      //loading is in progress already -- return
      if (this.isLoading) return

      if (!query || query.length < 2) {
        //search starts with at least 2 chars
        return
      }

      this.isLoading = true
      this.entries = []
      const searchType = this.isAllowAirportsInSearch ? '' : 'city'
      try {
        this.entries = await placeSearch.searchPlaces(query, searchType)
      } catch(err) {
        logAppError(err)
      } finally {
        this.isLoading = false
      }
    },
    selectInputText() {
      this.$refs.searchInput?.$el.querySelector('input')?.select()
    },
  },
}
</script>
