<template>
  <v-card class="card-common-form" elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 pt-4 tabs-container">
      <div class="text-primary font-weight-medium text-uppercase my-4 ml-4">
        {{ $t('Tab_Details') }}
      </div>
    </v-row>
    <v-card-text class="event-content">
      <v-expand-transition>
        <section v-if="isBaseView" class="my-sm-12 mt-12">
          <v-row density="compact" class="mx-0 mx-sm-2 pt-1 pt-sm-4 mt-12">
            <v-col cols="12" sm="6" class="py-0 px-0 px-sm-3">
              <place-input
                :value="departureLocation?.placeID"
                @update="updateDepartureLocation"
                min-width="450"
                :placeholder="$t('Origin')"
                :label="$t('Origin') + '*'"
                :isValid="isLocationsDifferent"
                variant="outlined"
                base-color="primary"
                color="primary"
                readonly
                data-test-name="input-place-from"
              />
            </v-col>

            <v-col cols="12" sm="6" class="py-0 px-0 px-sm-3">
              <place-input
                :value="arrivalLocation?.placeID"
                @update="updateArrivalLocation"
                min-width="450"
                :placeholder="$t('Destination')"
                :label="$t('Destination') + '*'"
                :isValid="isLocationsDifferent"
                variant="outlined"
                base-color="primary"
                color="primary"
                readonly
                data-test-name="input-place-to"
              />
            </v-col>
          </v-row>

          <v-row class="mx-0 mx-sm-2 mt-0" density="compact">
            <v-col cols="6" sm="3" class="pl-0 pl-sm-3 pb-1">
              <date-picker
                :model-value="startDate"
                :label="$t('Departure_date') + '*'"
                min-width="320"
                @update:model-value="updateStartDate"
                name="StartDate"
                variant="outlined"
                readonly
              />
            </v-col>
            <v-col cols="6" sm="3" class="pr-0 pr-sm-3 pb-1">
              <time-picker
                :model-value="startTime"
                :label="$t('Departure_time') + '*'"
                @update:model-value="updateStartTime"
                variant="outlined"
                readonly
              />
            </v-col>
            <v-col cols="6" sm="3" class="pl-0 pl-sm-3 pb-6 pb-sm-1">
              <date-picker
                :model-value="endDate"
                :min-date="minEndDate"
                :label="$t('Arrival_date') + '*'"
                :rules="[rules.afterStartTime]"
                min-width="320"
                @update:model-value="updateEndDate"
                name="EndDate"
                variant="outlined"
                readonly
              />
            </v-col>
            <v-col cols="6" sm="3" class="pr-0 pr-sm-3 pb-6 pb-sm-1">
              <time-picker
                :model-value="endTime"
                :label="$t('Arrival_time') + '*'"
                :rules="[rules.afterStartTime]"
                @update:model-value="updateEndTime"
                variant="outlined"
                readonly
              />
            </v-col>
          </v-row>
        </section>
      </v-expand-transition>

      <section
        class="d-flex flex-column mx-0 px-3 mt-12 mb-7 pb-4 segment-wrapper"
      >
        <v-btn
          v-if="segments.length > 1"
          class="align-self-center btn--showmore"
          color="primary"
          height="48"
          variant="text"
          data-test-name="button-toggle-segments-view"
          @click="isShowMore = !isShowMore"
        >
          {{ isShowMore ? $t('Hide_info') : $t('Edit_info') }}
        </v-btn>
        <div v-if="segments.length">
          <time-line :event-type="eventType" :segments="segments" :duration="durations" />
        </div>
        <v-expand-transition>
          <section v-if="isSegmentsView" class="mt-6">
            <div v-if="segments.length > 0">
              <event-segment
                v-for="(segment, index) in segments"
                :key="`segment-${index}`"
                :event-type="eventType"
                :model-value="segment"
                :index="index"
                :segments-count="segments.length"
                :min-date-time="previousSegmentArrivalDateTime(index)"
                :event-duration="durations"
                :isReadOnly="isReadOnly"
                @delete-segment="deleteSegment"
                @update:model-value="updateSegment($event, index)"
                @update:departure-location="updateSegmentDepartureLocation"
                @update:arrival-location="updateSegmentArrivalLocation"
                @validate-segment="updateSegmentValidation"
                @validate-all-segments="validateAllSegments"
                ref="segmentsList"
              />
            </div>
            <div class="pb-4 d-flex justify-center">
              <v-btn
                color="primary"
                rounded
                data-test-name="button-add-segment"
                :width="160"
                :disabled="isDisabledAddSegment"
                @click="addSegment"
              >
                {{ $t('Add_segment') }}
              </v-btn>
            </div>
          </section>
        </v-expand-transition>
      </section>
      <v-row class="mx-0 mx-sm-6">
        <v-col cols="12" class="pa-0">
          <transportation-event-details
            :title="title"
            :is-bookable="!isDriveEvent"
            :isBooked="isBooked"
            :notes="notes"
            :totalPrice="totalPrice"
            :travelers="travelers"
            :eventType="eventType"
            :readonly="isReadOnly"
            :notesRules="[rules.validNotes]"
            @updateTravelers="travelers = $event"
            @updateBooked="isBooked = $event"
            @updatePrice="totalPrice = $event"
            @updateNotes="notes = $event"
            @updateTitle="title = $event"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <event-popup-buttons
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />

    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
  </v-card>
</template>
<script>
import { transportationFormsMixin } from '@/mixins/TransportationFormsMixin'
import TransportationEventDetails from '@/components/popups/events/flight/TransportationEventDetails.vue'
import { EventTypes } from '@/helpers'

export default {
  name: 'AddTransportationForm',
  components: { TransportationEventDetails },
  mixins: [transportationFormsMixin],
  methods: {
    validateAllSegments() {
      if (this.$refs.segmentsList?.length) {
        this.$refs.segmentsList.forEach(segment => {
          segment.validate()
        })
      }
    }
  },
  computed: {
    isDriveEvent() {
      return this.eventType === EventTypes.DRIVE
    }
  }
}
</script>
