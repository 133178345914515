<template>
  <v-card class="card--hotel overflow-visible" elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 pt-2 mb-2 tabs-container">
      <v-tabs v-model="tab" class="card__tabs">
        <v-tab v-for="item in tabItems" :key="item" color="primary">
          {{ $t(item) }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-card-text class="event-content">
      <v-row class="mx-0 mt-6 pt-1 pt-sm-4 my-2">
        <v-col cols="12" class="mt-2 mt-sm-0 mb-0 pb-0 px-0">
          <v-text-field
            :readonly="isReadOnly"
            :model-value="title"
            @update:model-value="updateTitle($event)"
            :label="$t('Title')"
            :class="{ 'no-pointer-events': isReadOnly }"
            variant="outlined"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            hide-details
            maxLength="200"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" sm="6" class="mb-0 pb-0 px-0">
          <place-input
            :value="location?.placeID"
            :readonly="isReadOnly"
            :placeholder="$t('Location') + '*'"
            :label="$t('Location') + '*'"
            :show-suggestions="true"
            @updateLocation="updateLocation"
            min-width="320"
            name="placeInputLocation"
            variant="outlined"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="!$vuetify.display.xs || tab == 1" class="px-0 pl-sm-4 mb-0">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                variant="outlined"
                color="primary"
                base-color="primary"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
      </v-row>

      <v-row class="ma-0 mt-0 pt-0">
        <v-col cols="6" sm="3" class="pa-0 pb-1 pr-2">
          <date-picker
            :model-value="startDate"
            :disabled="!location"
            :readonly="isReadOnly"
            variant="outlined"
            :label="`${$t('Start_date')}*`"
            @update:model-value="updateStartDate"
            min-width="320"
            name="StartDate"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="pa-0 pb-1 pl-sm-2">
          <time-picker
            :model-value="startTime"
            :label="`${$t('Start_time')}*`"
            :disabled="!location"
            :readonly="isReadOnly"
            @update:model-value="updateStartTime"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="pa-0 pb-1 pr-2 pl-sm-4">
          <date-picker
            :model-value="endDate"
            :min-date="minEndDate"
            :disabled="!location"
            :readonly="isReadOnly"
            :label="`${$t('End_date')}*`"
            @update:model-value="updateEndDate"
            min-width="320"
            name="EndDate"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="pa-0 pb-1 pl-sm-2">
          <time-picker
            :model-value="endTime"
            :label="`${$t('End_time')}*`"
            :disabled="!location"
            :readonly="isReadOnly"
            :rules="[rules.afterStartTime]"
            @update:model-value="updateEndTime"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
      </v-row>

      <div v-if="tab === 0">
        <section class="mx-0 mt-6 mt-sm-10">
          <attraction-details-tool
            :imageURL="imageURL"
            :title="title"
            :isBooked="isBooked"
            :description="description"
            :notes="notes"
            :totalPrice="totalPrice"
            :address="simpleAddress"
            :travelers="travelers"
            :userRating="userRating"
            :eventType="eventType"
            :readonly="isReadOnly"
            :productURL="productURL"
            :addressRules="[rules.validateAddress]"
            :notesRules="[rules.validNotes]"
            @updateTravelers="travelers = $event"
            @updateBooked="isBooked = $event"
            @updatePrice="totalPrice = $event"
            @updateAddress="simpleAddress = $event"
            @updateNotes="notes = $event"
            @handleImageError="handleImageError"
            data-test-id="details-selector-tool"
          />
        </section>
      </div>

      <alternative-selector-tool
        v-if="tab === 1 && !isReadOnly"
        :record="newRecord"
        :isSearchDisabled="!isDatesLocationValid"
        :dataSource="dataSource"
        @updateDataSource="updateDataSource"
        @select="onSelectEvent"
        ref="alternatives"
        data-test-id="alternative-selector-tool"
      />
    </v-card-text>

    <event-popup-buttons
      v-if="tab === 0"
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />

  </v-card>
</template>

<script>
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import AlternativeSelectorTool from '@/components/itinerary-edit/AlternativeSelectorTool'
import AttractionDetailsTool from '@/components/popups/events/attraction/AttractionDetailsTool'
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import {
  convertAmountToUIFormat,
  convertAmountToServerFormat,
  getDatesAndTimeAsISOString,
} from '@/helpers'

export default {
  name: 'AddAttractionForm',
  components: {
    AttractionDetailsTool,
    AlternativeSelectorTool,
  },
  emits: ['updateTitle', 'cancel', 'save', 'setIsSearch'],
  mixins: [eventFormMainStructure],
  data() {
    return {
      tab: '',
      imageURL: '',
      description: '',
      userRating: { aggregateRating:0, votes:0 },
      selectedEvent: null,
      productURL: '',
    }
  },
  computed: {
    newRecord() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      const {startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let eventData
      if (this.selectedEvent) {
        eventData = this.selectedEvent[this.eventType]
      } else if (this.record) {
        eventData = this.record[this.eventType]
      }

      let rv = {
        startDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(startDateTime)),
        endDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(endDateTime)),
        location: this.location,
        travelers: this.travelers,
        [this.eventType]: {
          ...(this.title) && { title: this.title },
          ...(this.simpleAddress) && { simpleAddress: this.simpleAddress },
          ...(this.notes) && { notes: this.notes },
          ...(this.isBooked) && { isBooked: this.isBooked },
          ...(convertAmountToServerFormat(this.totalPrice?.amount))
            && { totalPrice: {
              currency: this.totalPrice?.currency,
              amount: convertAmountToServerFormat(this.totalPrice?.amount)
            },
            },
          //roData is never updated by the user -- just pass it on to the BE when present
          ...(eventData?.roData) && { roData: eventData.roData },
        }
      }

      return rv
    },
  },
  methods: {
    mapDataToFormFields(eventData) {
      this.$emit('updateTitle', eventData?.title)
      this.isBooked = Boolean(eventData?.isBooked)
      this.notes = eventData?.notes || this.notes //preserve notes
      this.simpleAddress = eventData?.simpleAddress
      this.totalPrice.amount = convertAmountToUIFormat(eventData?.totalPrice?.amount)
      this.totalPrice.currency = eventData?.totalPrice?.currency || this.userCurrency || 'USD'

      this.productURL = eventData?.roData?.productURL
      this.imageURL = eventData?.roData?.thumbnailURL
      this.userRating = eventData?.roData?.userRating
      this.description = eventData?.roData?.description
    },
    onSelectEvent(event) {
      if (event) {
        this.selectedEvent = event
        this.mapDataToFormFields(event[this.eventType])
        this.detailsTabCachedRecord = null
        this.tab = 0
      }
    },
  },
  watch: {
    isDialogVisible: {
      handler(value) {
        if(!value) {
          this.tab = 0
        } else if (this.record) {
          this.mapDataToFormFields(this.record[this.eventType])
        }
      },
      immediate: true
    },
    tab: {
      handler(value) {
        this.$emit('setIsSearch', value === 1)
      },
      immediate: true
    },
  }
}
</script>

<style scoped lang="scss">
.passengers-selector {
  width: 100%;
}

.card__tabs {
  border-bottom: 1px solid rgb(var(--v-theme-secondary-lighten4));
}
</style>
