<template>
  <div class="d-flex flex-column itinerary" data-test-name="page-itinerary">
    <v-row no-gutters class="mt-n12 mt-sm-0">
      <v-col cols="12" class="pb-6 pb-sm-2 pt-0 px-0 px-sm-6 d-flex justify-center align-center itinerary-header">
        <v-row no-gutters align="center" justify="center" class="mb-6 mb-sm-0">
          <v-col cols="12" sm="6" md="6" lg="4" xl="5">
            <v-row class="d-flex flex-column justify-center">
              <v-col v-if="!isSharedItineraryPage" cols="10" class="mx-auto ml-sm-6 mt-sm-4 d-flex justify-center justify-sm-start">
                <v-btn
                  @click="proceedToMyItinerariesRoute"
                  color="primary"
                  variant="plain"
                  rounded
                  size="large"
                  prepend-icon="mdi-arrow-left"
                  data-test-name="button-open-my-itineraries-page"
                  :width="$vuetify.display.xs ? '100%' : '240'"
                >
                  {{ $t('Back_to_my_itineraries') }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="8" v-if="ownerName && isSharedItineraryPage" class="py-0 d-flex justify-center justify-sm-start">
                <div
                  class="px-2 py-2 text-body-2 itinerary__shared-info"
                  data-test-name="text-shared-by"
                >
                  {{$t('Shared_by')}} {{ ownerName }}
                </div>
              </v-col>
              <v-col cols="12" sm="11" lg="10" class="pt-0 pt-sm-2 pb-0 d-flex justify-center justify-sm-start">
                <div v-if="isRoleLoaded && !isOwner" class="py-2 my-4 my-sm-2 itinerary__shared">
                  <v-tooltip location="right">
                    <template v-slot:activator="{ props }">
                      <span
                        v-bind="props"
                        class="px-4 py-3 cursor-pointer font-weight-medium text-body-2 itinerary__shared__info"
                        data-test-name="text-about-you"
                      >
                        {{ $t('You_are_p1') }}
                        <span class="text-primary" data-test-name="text-your-role">
                          {{ userRoleTooltipInfo.role }}
                        </span>
                        {{ $t('You_are_p2') }}
                      </span>
                    </template>
                    <span data-test-name="text-tooltip-info">{{ userRoleTooltipInfo.tooltip }}</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12" sm="11" lg="10" class="pr-0 pt-0 pt-sm-2 pb-0 d-flex justify-space-between align-center">
                <v-col cols="8" xl="10">
                  <h1
                    class="text-h5 text-md-h4 text-lg-h3 font-weight-bold itinerary-title"
                    data-test-name="text-title"
                  >
                    {{ itineraryTitle }}
                  </h1>
                </v-col>
                <v-col v-if="records.length" class="px-0 mr-sm-4 d-flex justify-end align-center" cols="2" xl="1">
                  <v-btn
                    @click.stop="openTripLink"
                    icon="$customColorMap"
                    data-test-name="button-view-on-map"
                    :title="$t('View_on_map')"
                    size="x-small"
                  ></v-btn>
                </v-col>
                <v-col v-if="isOwner" cols="2" xl="1" class="pl-0">
                  <share-menu
                    class="d-flex justify-end align-center text-no-wrap"
                    data-test-id="itinerary__item--menu"
                    :itineraryID="itineraryID"
                  />
                </v-col>
                <v-col v-else cols="2" xl="1" class="pl-0 d-flex justify-end align-center">
                  <v-btn
                    class="ma-2 copyLink"
                    :data-clipboard-text="linkToShareValue"
                    @click="copyLink"
                    icon="$customCopyLink"
                    data-test-name="button-copy-link"
                    :title="$t('Copy_Link')"
                    size="x-small"
                  ></v-btn>
                </v-col>
              </v-col>
              <v-col cols="12" sm="11" lg="11" class="pl-4 pr-lg-14">
                <v-divider class="text-primary" />
              </v-col>
              <v-col cols="12" class="py-0 d-flex justify-center justify-sm-start">
                <div class="px-2 my-2">
                  <span class="text-body-1 font-weight-medium itinerary__dates--label" data-test-name="text-label-modified">
                    {{ $t('Last_modified') }}:
                  </span>
                  <span data-test-name="text-modified">{{ lastUpdatedItinerary }}</span>
                </div>
              </v-col>
              <v-col cols="12" class="py-0 px-2 mb-4 d-flex justify-center justify-sm-start">
                <div class="px-3 text-body-2" data-test-name="text-notes">
                  * {{ $t('Times_are_local') }}
                </div>
              </v-col>
              <v-col v-if="isEditor && isMobile" cols="12" class="itinerary-content">
                <add-row-dialog
                  class="itinerary-mobile__add"
                  :clickedDateTime="clickedDateTime"
                  :showEventsMenu="showEventsMenu"
                  @update:showEventsMenu="showEventsMenu = $event"
                  @update:dialog-opened="eventDialogOpened = $event"
                />
              </v-col>
              <v-col v-if="isEditor && !isMobile && !showFloatingAddEventBtn">
                <section class="py-3 d-flex justify-center justify-sm-start itinerary-section">
                  <add-row-dialog
                    class="itinerary-section-actions__add"
                    :clickedDateTime="clickedDateTime"
                    :showEventsMenu="showEventsMenu"
                    @update:showEventsMenu="showEventsMenu = $event"
                    @update:dialog-opened="eventDialogOpened = $event"
                  />
                </section>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="11" sm="6" md="5" lg="5" xl="4" class="pb-0 pb-lg-16 pb-xl-6 pb-xl-16 pt-0 pt-sm-4 d-flex justify-center align-center">
            <v-img
              :src="bannerImgUrl"
              class="rounded-xl"
              :aspect-ratio="16/9"
              @error="setDefaultBanner"
              cover
              data-test-name="image-banner"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-col class="itinerary-sticky d-flex justify-center">
      <div
        v-if="!isMobile && showFloatingAddEventBtn"
        style="position: absolute"
      >
        <add-row-dialog
          v-if="isEditor"
          :clickedDateTime="clickedDateTime"
          :showEventsMenu="showEventsMenu"
          @update:showEventsMenu="showEventsMenu = $event"
          @update:dialog-opened="eventDialogOpened = $event"
        />
      </div>
    </v-col>
    <v-row no-gutters class="mb-n16">
      <v-col cols="12" md="11" class="pt-0 pt-md-0 pt-lg-12 pt-xl-6 px-0 px-sm-3 mx-auto mt-8 mt-sm-10 itinerary-form">
        <v-container class="py-0">
          <notification-snackbar
            v-if="isSignInNotificationShown"
            class="sign-in-snackbar-wrapper"
            name="sign-in-information"
            data-test-id="sign-in-information"
            color="info"
            :minHeight="20"
            :gap="$vuetify.display.xsOnly? 4 : 60"
            @close="closeSignInNotification"
          >
            <sign-in-notification-content />
          </notification-snackbar>
          <div class="text-center mb-2 mb-md-10">
            <div class="itinerary-header-row rounded-xl">
              <v-col cols="12" md="11" lg="9"
                class="bg-white py-0 py-sm-2 px-2 px-sm-6 itinerary-header-container"
                :style="{'max-height': $vuetify.display.smAndDown ? '100%': ''}"
              >
                <v-row no-gutters align="center" :class="{'flex-column': $vuetify.display.mdAndDown}">
                  <v-col class="d-flex align-sm-center flex-sm-row flex-column">
                    <v-col sm="4" md="5" lg="6" class="pt-2 pt-sm-0 pb-2">
                      <text-edit-combo
                        v-if="isOwner"
                        class="pr-3 text-edit-combo"
                        data-test-name="input-itinerary-name"
                        :class="{'itinerary-header__edit': $vuetify.display.mdAndDown}"
                        :value="itineraryTitle"
                        :rules="[rules.validName]"
                        :maxlength="210"
                        @changed="changeItineraryName"
                        @update="updateItineraryName"
                        color="primary"
                      />
                      <v-col v-else class="pa-0 d-flex">
                        <div class="pr-2 mb-2 mt-4 mt-sm-2 d-flex justify-center align-center text-h6" data-test-name="text-itinerary-title">
                          {{ itineraryTitle }}
                        </div>
                      </v-col>
                    </v-col>
                    <v-divider class="py-1 text-primary" :vertical="$vuetify.display.smAndUp" />
                    <v-col
                      sm="4"
                      md="4"
                      lg="3"
                      class="pb-4 pb-sm-2 d-flex justify-start align-center text-body-2 text-md-body-1"
                      data-test-name="text-itinerary-start-end-dates"
                    >
                      <v-icon class="pr-2 icon text-primary" data-test-name="icon-calendar">
                        $customCalendar
                      </v-icon>
                      {{ itineraryStartDate }} - {{ itineraryEndDate }}
                    </v-col>
                    <v-divider class="py-1 text-primarys" :vertical="$vuetify.display.smAndUp" />
                    <v-col
                      sm="4"
                      md="3"
                      class="pb-5 pb-sm-2 d-flex justify-start align-center text-body-2 text-md-body-1"
                      data-test-name="text-adults-children"
                    >
                      <v-icon class="pl-0 pl-sm-1 pr-4 pr-sm-2 icon text-primary" data-test-name="icon-users">
                        $customUsers
                      </v-icon>
                      {{ travelersAdults }}
                      {{ travelersAdults > 1 ? $t('Adults') : $t('Adult') }}
                      <div v-if="travelersChildren" class="pl-1">
                        - {{ travelersChildren }}
                        {{ travelersChildren > 1 ? $t('Children') : $t('Child') }}
                      </div>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>

              <v-row v-if="isRoleLoaded" class="px-1 px-sm-6 pt-7 pb-0 mt-n8 d-flex rounded-b-xl bg-semi-transparent" no-gutters>
                <v-col
                  v-if="$vuetify.display.lgAndUp"
                  cols="12"
                  class="d-flex justify-start align-center pt-3"
                  :class="{'flex-column': $vuetify.display.mdAndDown}"
                >
                  <v-col cols="12" lg="7"
                    class="d-flex px-0 align-center itinerary-header__actions"
                    :class="[
                      {'itinerary-header__actions--tablet': $vuetify.display.mdOnly || $vuetify.display.smOnly},
                      {'itinerary-header__actions--mobile': $vuetify.display.xsOnly}]"
                  >
                    <v-btn
                      v-if="!isOwner"
                      variant="text"
                      class="d-flex justify-start align-center text-subtitle-1 pa-0 mr-3 text-capitalize itinerary__item"
                      @click="openCreateItineraryPopup"
                      prepend-icon="$customCopy"
                      data-test-name="button-open-create-itinerary-popup"
                      color="textSecondaryColor"
                    >
                      {{ $t('Clone_itinerary') }}
                    </v-btn>
                    <v-btn
                      @click.stop="toggleComments"
                      variant="text"
                      class="mb-0 ml-0 mr-3 d-flex justify-start align-center text-subtitle-1 text-no-wrap itinerary__item"
                      data-test-id="itinerary__item--toggle"
                      prepend-icon="$customComment"
                      data-test-name="button-open-comments-popup"
                      color="textSecondaryColor"
                    >
                    <template #prepend>
                      <v-icon color="textSecondaryColor" />
                    </template>
                      <comment-window
                        v-if="isCommentsVisible"
                        data-test-id="comment-window"
                        :itineraryID="itineraryID"
                        @close="toggleComments"
                      />
                      <span class="text-subtitle-1 font-weight-light" data-test-id="comment-counter">{{ $t('Comments') }}</span>
                      <unread-comment-counter
                        v-if="unreadCommentsCount > 0"
                        class="bg-textSecondaryColor text-textPrimaryColor itinerary__item--unread"
                        :unread-comments-count="unreadCommentsCount"
                      />
                    </v-btn>
                    <v-btn
                      variant="text"
                      @click.stop="isThingsForTrip = true"
                      class="d-flex justify-start align-center mb-2 mb-sm-0 mb-md-0 mr-0 text-subtitle-1 pr-sm-3 pr-md-3 pr-md-0 text-no-wrap itinerary__item"
                      prepend-icon="$customBag"
                      data-test-name="button-open-what-to-bring-popup"
                      color="textSecondaryColor"
                    >
                      <template #prepend>
                        <v-icon color="textSecondaryColor" />
                      </template>
                      {{ $t('What_to_bring') }}
                    </v-btn>
                  </v-col>
                  <v-col sm="auto" lg="3" xl="2" class="mb-4 ml-md-4 ml-xl-auto py-0">
                    <v-select
                      :class="{'itinerary-select__activity--responsive': $vuetify.display.mdAndDown}"
                      multiple
                      hide-details
                      class="mt-0 py-1 px-8 text-textSecondaryColor itinerary-select__activity"
                      prepend-inner-icon="$customActivities"
                      color="textSecondaryColor"
                      :items="activityTypes"
                      :model-value="activityTypes.type"
                      :placeholder="$t('Activity_filter')"
                      :menu-props="{bottom: true, offsetY: true}"
                      variant="plain"
                      item-title="label"
                      item-value="type"
                      data-test-name="select-activities"
                    >
                      <template #item="{ item }">
                        <v-list class="ma-0 pa-0">
                          <v-list-item data-test-name="list-item">
                            <v-checkbox
                              :label="item.raw.label"
                              true-icon="mdi-checkbox-marked-outline"
                              false-icon="mdi-checkbox-blank-outline"
                              :model-value="eventFilterValue[item.raw.type]"
                              @click.stop="toggleActivityType(item.raw.type)"
                              :color="item.raw.color"
                              hide-details
                              class="py-n2 my-n2"
                              data-test-name="checkbox-set-activity"
                            >
                              <template v-slot:label>
                                <v-icon
                                  :color="item.raw.color"
                                  class="mx-2"
                                  size="18"
                                  :icon="item.raw.icon"
                                />
                                {{ item.raw.label }}
                              </template>
                            </v-checkbox>
                          </v-list-item>
                          <v-col v-if="item.raw.groupDivider" cols="12" class="px-2 py-2">
                            <v-divider></v-divider>
                          </v-col>
                        </v-list>
                      </template>
                    </v-select>
                  </v-col>
                  <v-divider class="mb-4 text-textSecondaryColor" vertical/>
                  <v-col sm="auto" lg="2" class="mb-4 ml-md-0 ml-xl-auto py-0">
                    <v-select
                      :model-value="selectedItem.type"
                      :class="[
                        'mt-0 mr-4 itinerary-select__viewmode',
                        {'itinerary-select__viewmode--responsive': $vuetify.display.mdAndDown}
                      ]"
                      :items="views"
                      item-title="text"
                      item-value="type"
                      :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true}"
                      class="text-textSecondaryColor itinerary-select__activity"
                      color="textSecondaryColor"
                      variant="plain"
                      prepend-inner-icon="$customAgenda"
                      hide-details
                      data-test-name="select-view-mode"
                      @update:model-value="selectViewMode"
                    >
                      <template #item="{ item, props }">
                        <v-list density="compact" class="ma-0 pa-0">
                          <v-list-item
                            v-bind="props"
                            class="pa-0 ma-0 pl-4"
                            :prepend-icon="item.raw.icon"
                          />
                        </v-list>
                      </template>
                    </v-select>
                  </v-col>
                </v-col>

                <v-row
                  v-else
                  no-gutters
                  class="py-2 d-flex justify-space-between align-center itinerary-actions-row flex-nowrap"
                >
                  <v-col cols="2" v-if="!isOwner">
                    <v-icon
                      variant="plain"
                      class="pr-2"
                      color="textSecondaryColor"
                      @click="openCreateItineraryPopup"
                      icon="$customCopy"
                      data-test-name="button-open-itinerary-popup"
                      size="large"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-icon
                      color="textSecondaryColor"
                      variant="plain"
                      data-test-id="itinerary__item--toggle"
                      @click="toggleComments"
                      icon="$customComment"
                      size="small"
                      data-test-name="button-open-comments-popup"
                    />
                    <comment-window
                      v-if="isCommentsVisible"
                      data-test-id="comment-window"
                      :itineraryID="itineraryID"
                      @close="toggleComments"
                    />
                    <unread-comment-counter
                      v-if="unreadCommentsCount > 0"
                      class="bg-textSecondaryColor text-textPrimaryColor itinerary__item--unread"
                      :unread-comments-count="unreadCommentsCount"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-icon
                      color="textSecondaryColor"
                      variant="plain"
                      @click="isThingsForTrip = true"
                      icon="$customBag"
                      size="small"
                      data-test-name="button-open-what-to-bring-popup"
                  />
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      data-test-name="menu-activities"
                      location="bottom"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn
                          variant="text"
                          v-bind="props"
                          prepend-icon="$customActivities"
                          data-test-name="button-open-activities-menu"
                          color="textSecondaryColor"
                        />
                      </template>
                      <v-list
                        max-height="320"
                        class="pt-4 overflow-y-auto"
                        data-test-name="list-activities"
                      >
                        <v-list-item
                          class="py-0 my-n5 itinerary-select__activity--mobile"
                          :class="{ 'groupEventTypes mb-2': item.groupDivider }"
                          v-for="item in activityTypes"
                          :key="item.id"
                          data-test-name="list-item"
                        >
                          <v-checkbox
                            :label="item.label"
                            true-icon="mdi-checkbox-marked-outline"
                            false-icon="mdi-checkbox-blank-outline"
                            :model-value="eventFilterValue[item.type]"
                            @update:model-value="toggleActivityType(item.type)"
                            :color="item.color"
                            data-test-name="checkbox-set-activity"
                            hide-details
                          >
                            <template v-slot:label>
                              <v-icon
                                :color="item.color"
                                class="mx-2"
                                size="18"
                                :icon="item.icon"
                              />
                              {{item.label}}
                            </template>
                          </v-checkbox>
                          <v-col v-if="item.groupDivider" cols="12" class="px-2 py-0 mb-6">
                            <v-divider></v-divider>
                          </v-col>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      location="bottom left"
                      :min-width="150"
                      data-test-name="menu-agenda"
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn
                          v-bind="props"
                          variant="text"
                          prepend-icon="$customAgenda"
                          data-test-name="button-open-agenda-menu"
                          color="textSecondaryColor"
                        />
                      </template>
                      <v-list data-test-name="list-agenda">
                        <v-list-item
                          v-for="item in views"
                          :key="item.type"
                          @click="selectView(item.type)"
                          :prepend-icon="item.icon"
                          data-test-name="list-item"
                        >
                          {{ item.text }}
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="11" class="mt-n16 mt-sm-0 mt-md-n8 px-0 px-sm-3 py-6 py-sm-0 mx-auto itinerary-content">
        <div class="px-0 px-sm-3 mt-n12 mb-4 mx-auto d-flex flex-column">
          <v-alert
            v-if="isItineraryChanged"
            :value="isItineraryChanged"
            elevation="2"
            @click="updateItinerary"
            class="pa-1 pl-4 mt-4 update-notification"
            color="info"
            density="compact"
            data-test-name="alert"
          >
            {{ $t('Itinerary_update_avaliable') }}
            <v-btn variant="text" color="accent" size="small" data-test-name="button-close-alert">
              <v-icon icon="mdi-reload" />
            </v-btn>
          </v-alert>

          <v-col
            v-if="viewMode === 'agenda'"
            class="px-0 px-sm-3"
          >
            <v-expansion-panels readonly>
              <v-container>
                <v-col
                  v-for="(record,index) in records"
                  class="pa-0 my-4"
                  :key="key(record, index)">
                  <component
                    transition="fade"
                    transition-mode="out-in"
                    :is="getEventType(record)"
                    :key="key(record, index)"
                    :record="record"
                    :directionsLink="directionsLink(index)"
                    :readonly="!isEditor"
                    :totalTravelersNumber="calculateTotalTravelers(record.header)"
                  />
                </v-col>
                <v-col class="pa-0" v-if="showTripProtection && tripProtectionRecord">
                  <insurance
                    transition="fade"
                    transition-mode="out-in"
                    focusable
                    :record="tripProtectionRecord"
                  />
                  <v-divider class="text-secondary"></v-divider>
                </v-col>
              </v-container>
            </v-expansion-panels>
          </v-col>
          <v-col v-else class="pa-0 px-sm-3">
            <v-container class="my-sm-3">
              <calendar-eventer
                :activeCalendarViewType="viewMode"
                :readonly="!isEditor"
                :records="records"
                @clicked-date-time="handleClickedDateTime"
                :selectedDateTime="clickedDateTime"
              />
            </v-container>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-row class="itinerary-details" no-gutters>
      <v-container>
        <v-col cols="12" class="pb-4 pb-sm-0 d-flex flex-column text-center text-sm-left flex-sm-row">
          <v-col cols="12" sm="6" lg="8">
            <div class="mb-2">
              <span class="pr-1 text-body-1 itinerary__dates--label" data-test-name="text-label-created">
                {{ $t('Created') }}:
              </span>
              <span data-test-name="text-created">{{ createdItinerary }}</span>
            </div>
            <div class="mb-4">
              <span class="pr-1 text-body-1 itinerary__dates--label" data-test-name="text-label-modified">
                {{ $t('Last_modified') }}:
              </span>
              <span data-test-name="text-modified">{{ lastUpdatedItinerary }}</span>
            </div>
            <div class="text-subtitle-2" data-test-name="text-notes">
              *{{ $t('Times_are_local') }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="d-flex flex-column text-center text-sm-right">
            <div data-test-name="text-total-price">
              {{ $t('Total_price') }}*
            </div>
            <div
              data-test-id="total-price"
              class="text-h5 text-lg-h3 font-weight-medium mb-4"
              data-test-name="text-formatted-total-price"
            >
              {{ formattedTotalPrice }}
            </div>
            <div
              class="ml-0 ml-sm-auto text-caption font-weight-regular itinerary-details-information__legend"
              data-test-name="text-price-legend"
            >
              * {{ $t('Price_legend') }}
            </div>
          </v-col>
        </v-col>
        <v-col v-if="itineraryUserRole" cols="12" class="pb-6 d-flex flex-column text-center text-sm-left mb-0 py-0">
          <v-col cols="12" class="pa-0">
            <v-btn
              @click="openDeleteItineraryConfirmation"
              variant="outlined"
              rounded
              class="mr-2"
              color="secondary"
              data-test-id="itinerary-delete__button"
              prepend-icon="$customDelete"
              data-test-name="button-delete"
            >
              {{ $t('Delete_itinerary') }}
            </v-btn>
          </v-col>
        </v-col>
      </v-container>
      <notification-snackbar
        v-if="isOpenMyItineraryInformation && !isSharedItineraryPage && !isAnonymous"
        color="primary"
        class="shared-notification"
        name="itineraries-information"
        position="bottom"
        data-test-name="notification-itinerary"
        :minHeight="50"
        :gap="0"
        :fullWidth="true"
        @close="closeNotification"
      >
        <div class="text-center text-subtitle-1" data-test-name="text-notification">
          {{ $t('Itinerary_banner_text') }}
          <span @click="proceedToMyItinerariesRoute" class="px-1 text-decoration-underline v-snack__link">
              "{{ $t('My_itineraries') }}"
            </span>
          {{ $t('Itinerary_banner_text2') }}
        </div>
      </notification-snackbar>
    </v-row>
    <create-itinerary-popup
      v-if="!isOwner && isCreateItineraryPopupVisible && currentItinerary"
      :travelers="travelers"
      :title="$t('Clone_itinerary_as_my_own')"
      :placeIDLocations="currentItinerary.header.placeIDLocations"
      :confirm-label="$t('Create')"
      :cancel-label="$t('Cancel')"
      @confirm="cloneItinerary"
      @close="closeCreateItineraryPopup"
    />

    <common-popup
      v-if="isDeleteItineraryConfirmationVisible"
      :body-content="$t('Delete_this_itinerary')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="doDelete"
      @cancel="isDeleteItineraryConfirmationVisible= false"
      @close="isDeleteItineraryConfirmationVisible = false"
    />

    <what-to-bring
      v-if="isThingsForTrip"
      :itineraryID="itineraryID"
      @close="isThingsForTrip = false"
    />
  </div>
</template>

<script>
import itineraryService from '@/helpers/itineraryService'
import { itineraryView } from '@/helpers/views'
import lodging from '@/helpers/itineraryServices/lodging'
import cruise from '@/helpers/itineraryServices/cruise'
import ShareMenu from '@/components/popups/ShareMenu'
import MenuPopup from '@/components/popups/MenuPopup'
import OptionsSelector from '@/components/popups/OptionsSelector'
import PassengersSelector from '@/components/popups/PassengersSelector'
import UnreadCommentCounter from '@/components/popups/comment/UnreadCommentCounter'
import ItineraryTransportationRow from '@/components/itinerary-trip-rows/ItineraryTransportationRow'
import ItineraryFlightRow from '@/components/itinerary-trip-rows/ItineraryFlightRow'
import ItineraryLodgingRow from '@/components/itinerary-trip-rows/ItineraryLodgingRow'
import ItineraryRestaurantRow from '@/components/itinerary-trip-rows/ItineraryRestaurantRow'
import ItineraryAttractionRow from '@/components/itinerary-trip-rows/ItineraryAttractionRow'
import ItineraryShowRow from '@/components/itinerary-trip-rows/ItineraryShowRow'
import ItineraryTourRow from '@/components/itinerary-trip-rows/ItineraryTourRow'
import ItineraryCustomEventRow from '@/components/itinerary-trip-rows/ItineraryCustomEventRow'
import ItineraryCruiseRow from '@/components/itinerary-trip-rows/ItineraryCruiseRow'
import ItineraryInsuranceRow from '@/components/itinerary-trip-rows/ItineraryInsuranceRow'
import CalendarEventer from '@/components/calendar/CalendarEventer'
import ButtonCheckbox from '@/components/buttons/ButtonCheckbox'
import AddRowDialog from '@/components/popups/AddRowDialog'
import EventFilter from '@/components/EventFilter'
import TextEditCombo from '@/components/TextEditCombo'
import CommentWindow from '@/components/popups/comment/CommentWindow.vue'
import WhatToBring from '@/components/popups/WhatToBring.vue'
import DatePicker from '@/components/DatePicker'
import addressUtil from '@/helpers/addressUtil'
import CommonPopup from '@/components/popups/CommonPopup'
import NotificationSnackbar from '@/components/NotificationSnackbar'
import CreateItineraryPopup from '@/components/popups/itinerary/CreateItineraryPopup.vue'
import { convertToUserCurrency } from '@/helpers/currency'
import { mapActions, mapGetters } from 'vuex'
import equal from  'fast-deep-equal/es6'
import cloneDeep from 'lodash.clonedeep'
import { RouteNames } from '@/helpers/routerUtils'
import { EventTypes, itemTypes, proceedToItinerary, formatPrice } from '@/helpers'
import { generateUrlByPlaceID } from '@/helpers/staticImagesUtil'
import SignInNotificationContent from '@/components/popups/SignInNotificationContent'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { useRoute } from 'vue-router'
import { logAppError } from '@/logger'
import datetime from '@/helpers/datetime'
import ClipboardJS from 'clipboard'

const DAYS = '4days'
const SHARED_ITINERARY = 'shared-itinerary'
const defaultBannerImgUrl = '/assets/images/plan-myself-bg.jpg'

export default {
  name: 'ItineraryPage',
  components: {
    SignInNotificationContent,
    EventFilter,
    MenuPopup,
    AddRowDialog,
    ButtonCheckbox,
    CalendarEventer,
    UnreadCommentCounter,
    TextEditCombo,
    ShareMenu,
    CommentWindow,
    WhatToBring,
    OptionsSelector,
    DatePicker,
    PassengersSelector,
    CommonPopup,
    NotificationSnackbar,
    CreateItineraryPopup,
    [EventTypes.ATTRACTION]:       ItineraryAttractionRow,
    [EventTypes.CAR_RENTAL]:       ItineraryCustomEventRow,
    [EventTypes.CUSTOM_EVENT]:     ItineraryCustomEventRow,
    [EventTypes.EXERCISE]:         ItineraryCustomEventRow,
    [EventTypes.EXPLORE]:          ItineraryCustomEventRow,
    [EventTypes.FLIGHT]:           ItineraryFlightRow,
    [EventTypes.INSURANCE]:        ItineraryInsuranceRow,
    [EventTypes.LODGING]:          ItineraryLodgingRow,
    [EventTypes.RELAX]:            ItineraryCustomEventRow,
    [EventTypes.RESTAURANT]:       ItineraryRestaurantRow,
    [EventTypes.SHOPPING]:         ItineraryCustomEventRow,
    [EventTypes.SHOW]:             ItineraryShowRow,
    [EventTypes.TOUR]:             ItineraryTourRow,
    [EventTypes.CRUISE]:           ItineraryCruiseRow,
    [EventTypes.BUS]:              ItineraryTransportationRow,
    [EventTypes.DRIVE]:            ItineraryTransportationRow,
    [EventTypes.FERRY]:            ItineraryTransportationRow,
    [EventTypes.TRAIN]:            ItineraryTransportationRow,
    [EventTypes.TRANSFER]:         ItineraryTransportationRow,
  },
  data() {
    return {
      clipboardObj: '',
      itinerary: null,
      isItineraryUpdating: false,
      isItineraryChanged: false,
      panel: [],
      calendarMode: false,
      isEventFilterEnabled: false,
      viewMode: 'day',
      formattedTotalPrice: '',
      containsForeignCurrency: false,
      isCommentsVisible: false,
      isThingsForTrip: false,
      loadCalendar: false,
      isDeleteItineraryConfirmationVisible: false,
      isOpenMyItineraryInformation: false,
      isCreateItineraryPopupVisible: false,
      bannerImgUrl: '',
      itineraryTitle: '',
      prevRouteName: null,
      menuActivities: false,
      menuAgenda: false,
      route: useRoute(),
      eventFilterValue: {},
      showEventsMenu: false,
      eventDialogOpened: false,
      clickedDateTime: '',
      showFloatingAddEventBtn: false,
      rules: {
        validName: (v) => {
          return this.validateItineraryName(v) || this.$t('Input_length_error', [3, 200])
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      eventFilter: 'EVENT_FILTER',
      currentItinerary: 'CURRENT_ITINERARY',
      loadingDialog: 'LOADING_DIALOG',
      user: 'USER',
      itineraryID: 'GET_CURRENT_ITINERARY_ID',
      isMobile: 'IS_MOBILE',
      isSharedItinerary: 'IS_SHARED_ITINERARY',
      isEditor: 'IS_EDITOR',
      isOwner: 'IS_OWNER',
      isCommenter: 'IS_COMMENTER',
      isViewer: 'IS_VIEWER',
      view: 'VIEW',
      userCurrency: 'CURRENCY',
      tripRequestState: 'TRIP_REQUEST',
      itineraryComments: 'COMMENTS',
      itineraryUserRole: 'CURRENT_ITINERARY_ROLE',
      isAnonymous: 'IS_ANONYMOUS',
      isUserNew: 'IS_NEW',
    }),
    linkToShareValue () {
      let link = window.location.origin
        + '/' + SHARED_ITINERARY
        + '/' + this.itineraryID
          + '/' + process.env.VUE_APP_UI_DEFAULT_ITINERARY_VIEW
      return link
    },
    activityTypes() {
      let rv = []
      itemTypes.forEach(item => {
        rv.push({ title: item.label, value: item.type, ...item })
      })
      return rv
    },
    isRoleLoaded() {
      return this.itineraryUserRole !== null
    },
    isSignInNotificationShown() {
      return this.isAnonymous && this.isOwner
    },
    ownerName() {
      return this.currentItinerary?.ownerName
    },
    isSharedItineraryPage() {
      return this.route.name === SHARED_ITINERARY
    },
    selectedItem() {
      return this.views.find((item) => item.type === this.viewMode)
    },
    travelers() {
      return this.itinerary?.header?.travelers
    },
    travelersAdults() {
      return this.itinerary?.header?.travelers.adults
    },
    travelersChildren() {
      return this.itinerary?.header?.travelers?.children
    },
    createdItinerary() {
      const createdDate = this.itinerary?.header?.createdDate
      const dateFormat = this.user.settings.dateFormat
      return `${itineraryService.formatTime(createdDate)}, ${this.$moment(createdDate).format(dateFormat)}`
    },
    lastUpdatedItinerary() {
      const updatedDate = this.itinerary?.header?.updatedDate
      const dateFormat = this.user.settings.dateFormat
      return `${itineraryService.formatTime(updatedDate)}, ${this.$moment(updatedDate).format(dateFormat)}`
    },
    options() {
      return this.tripRequestState.options
    },
    tripRequest() {
      const cloneTripRequest = cloneDeep(this.tripRequestState)
      const clonedRoutes = cloneTripRequest.routes
      const transformedRoutes = []
      const today = new Date()
      for (let i = 0; i < clonedRoutes.length; i++) {
        let route = clonedRoutes[i]
        if(!('date' in route) && ('departureDate' in route)) {
          const date = this.$moment({
            year:   route.departureDate.year,
            month:  route.departureDate.month - 1,
            day:    route.departureDate.day
          }).format('YYYY-MM-DD')

          const dateBack = cloneTripRequest.isRoundTrip && clonedRoutes.length === 2
            ? this.$moment({
              year:   clonedRoutes[1].departureDate.year,
              month:  clonedRoutes[1].departureDate.month - 1,
              day:    clonedRoutes[1].departureDate.day
            }).format('YYYY-MM-DD')
            : ''

          transformedRoutes.push({
            fromPlaceID: route.fromPlaceID,
            toPlaceID:   route.toPlaceID,
            date: cloneTripRequest.isRoundTrip? [date, dateBack]: date,
            minDate:  clonedRoutes.indexOf(route) === 0 ? today.toISOString().substring(0, 10) : transformedRoutes[transformedRoutes.length - 1].date
          })
        } else {
          route.minDate = clonedRoutes.indexOf(route) === 0 ? today.toISOString().substring(0, 10) : clonedRoutes[i - 1].date
          if(!cloneTripRequest.isRoundTrip && Array.isArray(route.date)) {
            route.date = route.date[0]
          } else if (cloneTripRequest.isRoundTrip && !Array.isArray(route.date)){
            route.date = [route.date]
          }
          transformedRoutes.push(route)
        }
        if (clonedRoutes.length === 2 && cloneTripRequest.isRoundTrip)
          break
      }
      cloneTripRequest.routes = transformedRoutes
      if(!cloneTripRequest.travelers.children) {
        cloneTripRequest.travelers.children = 0
      }
      if(!cloneTripRequest.travelers.adults) {
        cloneTripRequest.travelers.adults = 0
      }
      return cloneTripRequest
    },
    views() {
      return itineraryView
    },
    records() {
      const itineraryList = this.itineraryItems
      const types = {}

      for (const v in EventTypes) {
        types[EventTypes[v]] = this.eventFilterValue[EventTypes[v]]
      }

      let records = cloneDeep(itineraryList.filter(function (record) {
        return types[itineraryService.getEventType(record)]
      }))

      if (records.length > 0) {
        records[0].modes = {
          isDisplayDate: true,
          isDisplayMonth: true
        }

        for (let i = 1; i < records.length; i++) {
          const datePrev = this.$moment.parseZone(records[i - 1].header.startDateTime, 'YYYY-MM-DDTHH:mm:ssZ')
          const dateNext = this.$moment.parseZone(records[i].header.startDateTime, 'YYYY-MM-DDTHH:mm:ssZ')
          records[i].modes = {
            isDisplayDate: datePrev.format('DD') !== dateNext.format('DD'),
            isDisplayMonth: datePrev.format('MM') !== dateNext.format('MM')
          }
        }
      }

      //adding night at hotel (FE gimmick)
      records = lodging.addNightAtHotel(records)
      //adding days at ... for cruises (FE gimmick)
      records = cruise.addCruiseDays(records)
      return records
    },
    itineraryID() {
      return this.route.params.id
    },
    itineraryItems() {
      let rv = this.itinerary ? this.itinerary.items : []
      if (!Array.isArray(rv)) {
        rv = []
      }
      return rv
    },
    showTripProtection() {
      return this.itinerary && this.eventFilterValue[EventTypes.INSURANCE]
    },
    tripProtectionRecord() {
      return this.itinerary?.tripProtection
    },
    commentsCount() {
      const comments = this.itineraryComments[this.itineraryID]?.itineraryComments?.read
      return comments?.length
    },
    unreadCommentsCount() {
      const comments = this.itineraryComments[this.itineraryID]?.itineraryComments?.unread
      return comments?.length || 0
    },
    itineraryName() {
      return this.itinerary?.header?.itineraryName
    },
    totalTravelersNumber() {
      return this.itineraryAdults + this.itineraryChildren
    },
    itineraryAdults() {
      return this.itinerary?.header?.travelers?.adults || 0
    },
    itineraryChildren() {
      return this.itinerary?.header?.travelers?.children || 0
    },
    itineraryStartDate() {
      const dateFormat = this.user.settings.dateFormat
      return this.$moment.parseZone(this.itinerary?.header?.startDate).format(dateFormat)
    },
    itineraryEndDate() {
      const dateFormat = this.user.settings.dateFormat
      return this.$moment.parseZone(this.itinerary?.header?.endDate).format(dateFormat)
    },
    userRoleTooltipInfo() {
      if (this.isEditor) {
        return {role: this.$t('Editor'), tooltip: this.$t('Editor_tooltip')}
      }
      if (this.isCommenter) {
        return {role: this.$t('Commenter'), tooltip: this.$t('Commenter_tooltip')}
      }
      if (this.isViewer) {
        return {role: this.$t('Viewer'), tooltip: this.$t('Viewer_tooltip')}
      }
      return {role: this.$t('Viewer'), tooltip: this.$t('Viewer_anonymous_tooltip')}
    }
  },
  created() {
    if (this.isAnonymous && this.isUserNew) {
      this.addNewUser({ user: this.user })
    }
    this.itineraryTitle = this.itinerary?.header?.itineraryName
    const isOpenMyItineraryInformation = localStorage.getItem('acceptedMyItinerariesInformation')
    const viewMode = this.getViewMode()
    this.changeViewMode(viewMode)
    if (!isOpenMyItineraryInformation) {
      this.isOpenMyItineraryInformation = true
    }

    this.isCreateItineraryPopupVisible = this.route.query?.isCreateItineraryPopupVisible
  },
  mounted() {
    this.eventFilterValue = cloneDeep(this.eventFilter)
    this.loadItinerary()
    this.calculateTotalItineraryPrice()
    this.clipboardObj = new ClipboardJS('.copyLink')
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    this.clearCurrentItinerary()
    this.clipboardObj.destroy()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    totalTravelersNumber: {
      handler() {
        this.calculateTotalItineraryPrice()
      }
    },
    itineraryName: {
      handler(newVal){
        if(newVal){
          this.itineraryTitle = newVal
        }
      },
      immediate: true
    },
    currentItinerary: {
      handler(newVal) {
        if(newVal && !this.isItineraryUpdating && !this.isItineraryChanged) {
          this.bannerImgUrl = newVal.header?.placeIDLocations?.[0] ? generateUrlByPlaceID(newVal.header.placeIDLocations?.[0]) : defaultBannerImgUrl
          this.itinerary = {...newVal}
          this.loadAllCommentInfo()
        }
      },
      immediate: true
    },
    itinerary() {
      this.isItineraryChanged = false
      this.calculateTotalItineraryPrice()
    },
    'user.id'() {
      this.loadItinerary()
    },
    route(to, from) {
      if(to !== from) {
        this.loadItinerary()
        this.calculateTotalItineraryPrice()
        this.itineraryTitle = this.itinerary?.header?.itineraryName
      }
    }
  },
  methods: {
    ...mapActions({
      showLoader: 'LOADING_DIALOG',
      showNotification: 'PUSH_APP_NOTIFICATION',
      removeItinerary: 'REMOVE_ITINERARY',
      removeSharedItinerary: 'REMOVE_SHARED_ITINERARY',
      clearCurrentItinerary: 'CLEAR_CURRENT_ITINERARY',
      addNewUser: 'ADD_NEW_USER',
      loadComments: 'LOAD_COMMENTS',
      setTripRequest: 'SET_TRIP_REQUEST',
      getSharedItineraryById: 'GET_SHARED_ITINERARY_BY_ID',
      getItineraryById: 'GET_ITINERARY_BY_ID',
      updateItineraryHeader: 'UPDATE_ITINERARY_HEADER',
      executeCloneItinerary: 'CLONE_ITINERARY',
      pushAppNotification: 'PUSH_APP_NOTIFICATION',
    }),
    handleScroll() {
      const scrollY = window.scrollY || window.pageYOffset
      this.showFloatingAddEventBtn = this.eventDialogOpened || scrollY > 290
    },
    updateItineraryName(value){
      if(value) {
        this.itineraryTitle = value
      }
    },
    async loadAllCommentInfo() {
      await this.loadComments()
    },
    proceedToMyItinerariesRoute() {
      this.$router.push({name: RouteNames.ITINERARIES, params: { page: 1 }})
    },
    closeNotification() {
      localStorage.setItem('acceptedMyItinerariesInformation', 'true')
      this.isOpenMyItineraryInformation = false
    },
    closeSignInNotification() {
      this.isSignInNotificationShown = false
    },
    selectView(type) {
      this.viewMode = type
      this.$router.push({
        name: this.route.name,
        params: { id: this.itineraryID, view: this.viewMode }
      })
    },
    openDeleteItineraryConfirmation() {
      this.isDeleteItineraryConfirmationVisible = true
    },
    openCreateItineraryPopup() {
      if (this.isAnonymous){
        this.$router.push({name: RouteNames.SIGN_IN, query: {redirect: this.route.path, isCreateItineraryPopupVisible: true}})
      } else {
        this.isCreateItineraryPopupVisible = true
      }
    },
    closeCreateItineraryPopup() {
      this.isCreateItineraryPopupVisible = false
    },
    async doDelete() {
      try {
        const payload = {
          itineraryID: this.itinerary.header.itineraryID,
          userID: this.user.id
        }
        this.isOwner ? await this.removeItinerary(payload) : await this.removeSharedItinerary(payload)
        this.showNotification({
          name: 'removeItinerary',
          content: 'Itinerary_removed',
          color: 'primary',
          timeout: 3000,
        })
        this.$router.push({name: RouteNames.ITINERARIES, params: { page: 1 }})
      } catch (error) {
        this.showNotification({
          name: 'errItinerary',
          content: 'Itinerary_not_removed',
          color: 'error',
          timeout: 3000,
        })
        logAppError(`${error}: ${JSON.stringify({
          itineraryID: this.itinerary.header.itineraryID,
          userID: this.user.id
        })}`)
      }
    },
    /**
    Total price of the itinerary -- each item has its own representation
    TODO: make sure all the item types are included
     */
    async calculateTotalItineraryPrice() {
      let total = 0
      //trip protection handling
      if (this.tripProtectionRecord?.totalPrice?.amount) {
        total += await convertToUserCurrency(
          +this.tripProtectionRecord?.totalPrice?.amount,
          this.tripProtectionRecord?.totalPrice?.currency,
          this.userCurrency)
      }
      for ( const item of this.itineraryItems) {
        const price = itineraryService.itemPrice(item)
        if (price?.amount && price?.currency) {
          if (price.currency === this.userCurrency) {
            total += price.amount
          } else {
            total += await convertToUserCurrency(price.amount, price.currency, this.userCurrency)
            this.containsForeignCurrency = true
          }
        }
      }
      this.formattedTotalPrice = formatPrice({ amount: total, currency: this.userCurrency })
    },
    updateOptions(newVal){
      this.updateTripRequest({
        options: newVal
      })
    },
    toggleActivityType(type) {
      this.eventFilterValue[type] = !this.eventFilterValue[type]
    },
    selectViewMode(mode) {
      let data = mode.toLowerCase().split(/\s+/).join('')
      if (data === DAYS) {
        data = data.substring(0, data.length - 1)
      }

      this.viewMode = data
      this.$router.push({
        name: this.route.name,
        params: { id: this.itineraryID, view: this.viewMode }
      })
    },
    updateTripRequest(updatedParams) {
      this.setTripRequest({ ...this.tripRequest, ...updatedParams })
    },
    key(record, index) {
      return record.header.itemID + '.' + index
    },
    getViewMode(){
      if (itineraryView.find(item => item.type === this.route.params.view)) {
        return {
          type: this.route.params.view,
        }
      }
      return { type: process.env.VUE_APP_UI_DEFAULT_ITINERARY_VIEW }
    },
    async loadItinerary() {
      if (this.isSharedItinerary || (!this.isSharedItinerary && this.user.id)) {
        try {
          if (this.isSharedItinerary) {
            await this.getSharedItineraryById({
              'userID': this.user.id,
              'itineraryID': this.route.params.id
            })
          } else {
            await this.getItineraryById({
              'userID': this.user.id,
              'itineraryID': this.route.params.id
            })
          }
          this.checkItineraryUpdated()
        } catch {
          this.$router.push({name: RouteNames.GENERATE_ITINERARY})
        } finally {
          this.isItineraryUpdating = false
        }
      } else {
        this.$router.push({name: RouteNames.GENERATE_ITINERARY})
      }
      this.loadCalendar = true
    },
    checkItineraryUpdated() {
      if(this.itinerary?.header?.updatedDate && !equal(this.itinerary.header.updatedDate, this.currentItinerary.header.updatedDate)) {
        this.isItineraryChanged = true
      }
    },
    startTime(item) {
      return this.$moment.parseZone(item.header.startDateTime, 'YYYY-MM-DDTHH:mm:ssZ')
    },
    toggleCollapse() {
      this.panel = []
      window.scrollTo(0, 0)
    },
    changeItineraryName(value) {
      if (this.itineraryName === value) return
      const origHeader = { ...this.itinerary.header }
      origHeader.itineraryName = value
      if(value !== '' && this.validateItineraryName(value)){
        this.updateItineraryHeader({ header: origHeader })
          .then(() => {
            this.itinerary.header.itineraryName = value
          })
          .catch(() => {})
      }
    },
    directionsLink(index) {
      return addressUtil.mapPoint(index, this.records)
    },
    openTripLink() {
      const transferRecords = this.records.filter(record => record.header.locations.length === 2)
      const googleMapLink = transferRecords.length ? addressUtil.buildTransferDirections(this.records, this.isMobile) : addressUtil.buildMultipleDirections(this.records)
      !!googleMapLink && window.open(googleMapLink, '_blank')
    },
    changeViewMode(value){
      this.viewMode = value.type
      this.$router.push({
        name: this.route.name,
        params: {id: this.itineraryID, view: this.viewMode}
      })
    },
    toggleComments() {
      this.isCommentsVisible = !this.isCommentsVisible
    },
    setDefaultBanner() {
      this.bannerImgUrl = defaultBannerImgUrl
    },
    updateItinerary() {
      this.itinerary = {...this.currentItinerary}
    },
    calculateTotalTravelers(eventHeader) {
      if(eventHeader.travelers) {
        return (eventHeader.travelers.adults || 0) + (eventHeader.travelers.children || 0)
      } else {
        return this.totalTravelersNumber
      }
    },
    async cloneItinerary(data) {
      try {
        const payload = {
          userId: this.user?.id,
          itineraryID: this.itineraryID,
          travelers: data?.travelers,
          fromPlaceID: data?.location?.placeID,
          startDate: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(data.startDate))
        }
        this.showLoader({
          visible: true,
        })
        const result = await this.executeCloneItinerary(payload)
        if (result) {
          this.closeCreateItineraryPopup()
          const { header: { itineraryID: itineraryID } } = result
          this.showNotification({
            name: 'clone_itinerary',
            content: 'Itinerary_created',
            color: 'primary',
            timeout: 3000,
          })
          proceedToItinerary(this.$router, itineraryID, this.user.settings.view)
        }
      } catch (err) {
        this.showNotification({
          name: 'clone_itinerary_error',
          content: 'Itinerary_error',
          color: 'error',
          timeout: 3000,
        })
      } finally {
        this.showLoader()
      }
    },
    getEventType: itineraryService.getEventType,
    formatDateTime: itineraryService.formatDateTime,
    validateItineraryName(v) {
      return (v.length >= 3 && v.length <= 200)
    },
    handleClickedDateTime(dateTime) {
      if (!datetime.validateDateInRange(28, dateTime, this.itinerary?.header?.startDate, this.itinerary?.header?.endDate)) {
        this.showNotification({
          name: 'date-range-error',
          content: 'Date_Range_Error',
          color: 'error',
          timeout: 3000,
        })
        return
      }
      this.showEventsMenu = true
      this.clickedDateTime = dateTime
    },
    copyLink() {
      //the actual work is done by clipboardJS
      this.showPushAppNotification({
        name: 'copyLink',
        content: 'Itinerary_link_copied',
        color: 'primary',
      })
    },
    showPushAppNotification(notification){
      this.pushAppNotification(notification)
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.viewMode = to.params.view
    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRouteName = from.name
    })
  },
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

.fade-transition {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave {
  opacity: 0;
}

.comment-counter {
  position: absolute;
  padding: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}

.update-notification {
  position: fixed;
  z-index: 1;
  top: 20px;
  right: 20px;
}


.itinerary {
  height: 100%;
  padding-bottom: 0;
  position: relative;

  &-sticky {
    position: fixed;
    z-index: 5;
  }

  &-header {
    background-color: rgba(1, 68, 199, 0.05);
    height: 600px;

    @media screen and (max-width: 600px) {
      height: 700px;
      }
  }

  &-form {
    position: relative;
    top: -50%;
  }

  &-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-content {
    flex: 1 0 auto;
  }

  &__shared {
    &__info {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 50px;
    }
  }

  &-select {
    &__viewmode {
      width: 100%;

      &--responsive {
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
      }
    }

    &__activity {
      width: 100%;

      :deep(.v-field__input) input::placeholder,
      :deep(.v-field__prepend-inner > .v-icon),
      :deep(.v-field__input) {
        opacity: 1!important;
      }

      &--responsive {
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
      }
    }
  }

  &__dates {

    &--label {
      color: rgb(var(--v-theme-secondary));
    }
  }

  &__item {
    cursor: pointer;
    transition: opacity .15s ease-in;

    &--comment {
      position: relative;
      left: 19px;
      top: -1px;
      color: rgb(var(--v-theme-primary));
    }

    &--unread {
      position: relative;
      bottom: 10px;
    }

    &--menu {
      position: relative;
      left: -9px;
    }

    &--label {
      position: relative;
      left: -8px;

      span {
        margin-left: 0!important;
      }
    }
    &:before {
      background-color: initial;
    }
    &:hover {
      opacity: .75;
    }
  }

  .input-header__field {
    height: 48px;
  }

  &-mobile__add {
      z-index: 4;
      position: fixed;
      bottom: 10px;
      left: 12px;
      right: 12px;
      display: flex;
      justify-content: center;
    }

  &-section {

    &-actions {

      &__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        max-height: 40px;
        align-items: center;
      }

      @media screen and (max-width: 767px) {

        .v-input {
          width: 100%;
        }
      }

      &__add {

        .v-speed-dial--fixed {
          position: relative;
        }
      }
    }
  }

  &-header {
    position: relative;

    &-banner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      &:deep(.v-img__img--cover) {
        opacity: 0.4;
      }
    }

    &__actions {
      max-height: 50px;

      &--mobile {
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &--tablet {
        display: flex;
        justify-content: center!important;
      }
    }

    &__edit {
      max-width: 100% !important;
    }

    &__text--underline {
      text-decoration: underline;
      cursor: pointer;
      transition: opacity .15s ease-in;

      &:hover {
        opacity: .75;
      }
    }

    &-row {
      max-width: 100%;
      height: auto;
      background-color: rgb(var(--v-theme-inactiveColor-lighten-5));

      .text-edit-combo {
        width: 100%;
      }
      .icon {
        width: 32px;
      }
    }

    &__input {
      width: 100%;

      div {
        color: rgb(var(--v-theme-textPrimaryColor-darken-4));
      }
    }

    &-container {
      position: relative;
      border-radius: 12px;
      border: 3px solid rgb(var(--v-theme-primary)) !important;
      background: #fff !important;
      max-width: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &-details {
    background: rgb(var(--v-theme-inactiveColor));

    .v-snack:not(.v-snack--absolute) {
      height: auto;
      position: static;
    }

    &__delete {
      transition: opacity .15s ease-in;
      cursor: pointer;

      &:hover {
        opacity: .75;
      }
    }

    &__passengers span:first-child {
      margin-right: 5px;
    }

    &__column, &__row {
      flex-direction: column;
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__wrapper {
      height: 130px;
    }

    &-information {

      &__legend {
        color: rgb(var(--v-theme-secondary));
      }
    }
  }
}

.sign-in-snackbar-wrapper {
  position: absolute;

  .v-snack:not(.v-snack--absolute) {
    height: auto;
    position: static;
  }
}
.itinerary {
  .v-application--wrap {
    min-height: 600px!important;
  }

  .container {
    padding-bottom: 0;
  }
}
.shared-notification {
  z-index: 1;
}

.v-tooltip{

  &__content {
    max-width: 280px;
    background-color: rgb(var(--v-theme-inactiveColor-lighten-1));
    color: rgb(var(--v-theme-secondary));
    border: 1px solid rgb(var(--v-theme-secondary-lighten-3))
  }
}
.groupEventTypes {
    border-bottom: 1px solid rgb(var(--v-theme-secondary-lighten-4));
}
.bg-semi-transparent {
  background-color: rgb(var(--v-theme-primary));
  border-radius: 15px;
}
.v-btn--variant-elevated {
    box-shadow: none;
  }
</style>
