<template>
  <div class="calendar-eventer" data-test-name="content-event-calendar" v-if="haveLoadedEvents">
    <v-row class="mx-auto calendar-eventer-row px-0">
      <v-col class="pa-0">
        <v-sheet class="calendar-eventer__sheet mt-10">
          <calendar-view
            :events="events"
            :selected-date="new Date(currentItinerary?.header?.startDate)"
            @event-was-resized="(event) => dragEvent(event)"
            @event-was-dragged="(event) => dragEvent(event)"
            @datetime-was-clicked="(event) => handleDateTimeClick(event)"
            @edit-event="(event) => showDialogToEditEvent(event)"
            @delete-event="(event) => showDialogToDeleteEvent(event)"
            @show-comments="(event) => showCommentsWindow(event)"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <comment-window
      v-if="isCommentsWindowVisible && selectedEvent.id"
      :itemID="selectedEvent.id"
      :itineraryID="itineraryID"
      data-test-id="comment-window"
      @close="closeCommentsWindow"
    />
    <v-dialog
      :model-value="isEventDialogVisible"
      @update:model-value="updateEventDialog"
      :fullscreen="isMobile"
      max-width="75rem"
      @click:outside="$refs.eventDialog.closeDialog(true)"
    >
      <v-card>
        <event-dialog
          :isOpened="isEventDialogVisible"
          :isCreateMode="isCreateMode"
          :event="selectedEvent"
          :eventStartDateTime="selectedDateTime"
          :record="selectedEvent.record"
          @checkFormState="isDirty = $event.dirty"
          @cancel="closeEventDialog"
          @close="closeEventDialog"
          ref="eventDialog"
        />
      </v-card>
    </v-dialog>
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
    <common-popup
      v-if="confirmationPopupVisible"
      :body-content="$t('Question_to_discard_changes')"
      :confirm-label="$t('Discard')"
      :cancel-label="$t('Keep_editing')"
      @confirm="onCloseUnsavedChangesDialog(true)"
      @cancel="onCloseUnsavedChangesDialog(false)"
      @close="onCloseUnsavedChangesDialog(false)"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapGetters } from 'vuex'
import CommentWindow from '@/components/popups/comment/CommentWindow'
import commonPopup from '@/components/popups/CommonPopup'
import { findEventByType } from '@/helpers'
import addressUtil from '@/helpers/addressUtil'
import itineraryService from '@/helpers/itineraryService'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { logAppError } from '@/logger'
import CalendarView from './CalendarView'
import EventDialog from './EventDialog'
import datetime from '@/helpers/datetime'

export default {
  name: 'CalendarEventer',
  emits: ['change', 'event-update', 'clicked-date-time'],
  components: {
    CalendarView,
    EventDialog,
    commonPopup,
    CommentWindow
  },
  props: {
    readonly: {
      type: Boolean,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
    selectedDateTime: {
      type: String,
    },
  },
  data() {
    return {
      selectedEvent: {},
      events: [],
      isEventDialogVisible: false,
      isDialogToDeleteEventVisible: false,
      isDirty: false,
      confirmationPopupVisible: false,
      isCommentsWindowVisible: false,
      haveLoadedEvents: false,
      dragEventInProgress: false,
    }
  },
  async created() {
    await this.transformItineraryEventsToCalendarItems()
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
      user: 'USER',
      currentItinerary: 'CURRENT_ITINERARY',
      isMobile: 'IS_MOBILE',
    }),
    itineraryID() {
      return this.$route.params.id
    },
    isCreateMode() {
      return !!this.selectedDateTime
    }
  },
  methods: {
    ...mapActions({
      showNotification: 'PUSH_APP_NOTIFICATION',
    }),
    updateEventDialog(val) {
      if (!val) {
        this.closeEventDialog()
      }
    },
    onCloseUnsavedChangesDialog(closeEditDialog) {
      if (closeEditDialog) {
        this.confirmationPopupVisible = false
        this.isEventDialogVisible = false
      } else {
        this.confirmationPopupVisible = false
      }
      this.isDirty = false
    },
    closeEventDialog() {
      if (this.isDirty) {
        this.confirmationPopupVisible = true
      } else {
        this.isEventDialogVisible = false
      }
    },
    showDialogToEditEvent(eventID) {
      const event = this.events.find((event) => event.id === eventID)
      const staticEvent = findEventByType(event.eventType)
      if (Object.keys(event).length === 0) return
      if (!this.isEventDialogVisible) {
        this.selectedEvent = { ...staticEvent, ...event }
        this.isEventDialogVisible = true
      }
    },
    showDialogToDeleteEvent(eventID) {
      this.isDialogToDeleteEventVisible = true
      this.selectedEvent = { id: eventID }
    },
    showCommentsWindow(eventID) {
      this.selectedEvent = { id: eventID }
      this.isCommentsWindowVisible = true
    },
    closeCommentsWindow() {
      this.isCommentsWindowVisible = false
      this.selectedEvent = {}
    },
    async transformItineraryEventsToCalendarItems() {
      const records = this.records.map((record, index) => {
        return {
          ...record,
          header: {
            ...record.header,
            directionLink: addressUtil.mapPoint(index, this.records)
          }
        }
      })
      const data = await itineraryService.getFormatCalendarEvents(records)
      if (data && data.length > 0) {
        this.events = data
        this.haveLoadedEvents = true
      }
    },
    async dragEvent(event) {
      if (this.dragEventInProgress) return
      if (!datetime.validateDateInRange(28, event.time.start, this.currentItinerary.header.startDate, this.currentItinerary.header.endDate)
      || !datetime.validateDateInRange(28, event.time.end, this.currentItinerary.header.startDate, this.currentItinerary.header.endDate)) {
        this.showNotification({
          name: 'date-range-error',
          content: 'Date_Range_Error',
          color: 'error',
          timeout: 3000,
        })
        return
      }

      try {
        this.dragEventInProgress = true
        const itineraryItem = cloneDeep(this.currentItinerary.items.find(item => item.header.itemID === event.id))
        if (itineraryItem) {
          itineraryItem.header = {
            ...itineraryItem.header,
            startDateTime: this.$moment(event.time.start).format(),
            endDateTime: this.$moment(event.time.end).format(),
          }

          await commonAPIs.replaceItem(
            this.currentItinerary.header.itineraryID,
            this.user.id,
            itineraryItem,
            itineraryItem.header.itemID
          )
        }
      } catch (err) {
        logAppError(err)
      } finally {
        this.dragEventInProgress = false
      }
    },
    handleDateTimeClick(dateTime) {
      if (!this.readonly) {
        this.$emit('clicked-date-time', dateTime)
      }
    },
    async onDeleteEvent() {
      try {
        await commonAPIs.removeItem(
          this.currentItinerary.header.itineraryID,
          this.user.id,
          this.selectedEvent.id,
        )
        this.isDialogToDeleteEventVisible = false
        this.selectedEvent = {}
      } catch (err) {
        logAppError(err)
        this.selectedEvent = {}
      }
    },
  },
  watch: {
    records: {
      async handler() {
        await this.transformItineraryEventsToCalendarItems()
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
.calendar-eventer-row {
  width: 100% !important;
}
.calendar-eventer-row .v-calendar-daily__scroll-area {
  overflow-y: hidden !important;
}
.event-title {
  display: flex;
  align-items: center;
  height: 100%;
}
.white-space-nowrap {
  white-space: nowrap !important;
}
.v-calendar-weekly__head-weekday {
  padding-top: 10px;
}
.calendar-eventer {
  border-radius: 8px;
  margin-bottom: 20px;
  height: auto !important;

  &__sheet {
    .v-application--wrap {
      min-height: 600px !important;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
  }

  .ds-week-header {
    background: rgb(var(--v-theme-warning));
  }
}
</style>
