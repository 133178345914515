<template>
  <div data-test-name="popup-share-with-community">
    <common-popup
      :title-content="$t('Share_as_Community_Template')"
      :full-screen="isMobile"
      :confirm-label="$t('Share')"
      :cancel-label="$t('Cancel')"
      @confirm="onSubmitForm"
      @cancel="closeModal"
      @close="closeModal"
    >
      <v-row no-gutters class="pt-1">
        <v-col cols="12" sm="5" class="mr-0 mr-sm-8 mb-8 mb-sm-0">
          <v-img
            :src="url"
            data-test-name="image"
            class="rounded-lg"
            aspect-ratio="1.45"
            @error="setDefaultImg"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-form ref="form">
            <v-text-field
              :model-value="name"
              @update:model-value="name = $event"
              variant="outlined"
              color="primary"
              bg-color="#fff"
              base-color="primary"
              data-test-name="input-itinerary-name"
              :placeholder="$t('Itinerary_name')"
              :rules="validateNameInput"
            >
              <template v-slot:prepend-inner>
                <v-icon
                  class="pr-2"
                  data-test-name="icon-flag"
                  color="primary"
                  size="large"
                  icon="$customFlag"
                />
              </template>
            </v-text-field>
            <v-textarea
              :model-value="description"
              autofocus
              variant="outlined"
              color="primary"
              bg-color="#fff"
              base-color="primary"
              auto-grow
              rows="4"
              :placeholder="$t('Description')"
              :rules="validateDescriptionInput"
              @update:model-value="description = $event"
              data-test-name="input-description"
            >
              <template v-slot:prepend-inner>
                <v-icon
                  class="pr-2"
                  data-test-name="icon-book-open"
                  color="primary"
                  size="large"
                  icon="$customBookOpen"
                />
              </template>
            </v-textarea>
          </v-form>
        </v-col>
      </v-row>
      <div class="mt-6">
        <h3 class="text-black font-weight-medium">{{ $t('Add_tags') }}</h3>
        <v-chip-group
          :model-value="selectedTags"
          selected-class="text-primary"
          multiple
          mandatory="force"
          :max="3"
          @update:model-value="selectedTags = $event"
          data-test-name="chip-group"
        >
          <v-chip
            v-for="tag in tagsOptionsLocalized"
            :key="tag.value"
            color="rgba(1, 68, 199, 0.1)"
            :value="tag.value"
            data-test-name="chip"
          >
            {{ tag.text }}
          </v-chip>
        </v-chip-group>
        <span class="text-error text-caption" data-test-name="text-error-message">{{ tagsValidationError }}</span>
      </div>
    </common-popup>
  </div>
</template>

<script>
import CommonPopup from '@/components/popups/CommonPopup.vue'
import { generateUrlByPlaceID, defaultImageUrl } from '@/helpers/staticImagesUtil'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ShareWithCommunity',
  components: { CommonPopup },
  emits: ['close', 'notConvertible'],
  data() {
    return {
      name: '',
      url: '',
      description: '',
      selectedTags: [],
      tags: [],
      validateNameInput: [
        v => (v.length >= 3 && v.length < 50) || this.$t('Input_length_error', [3, 50])
      ],
      validateDescriptionInput: [
        v => (v.length < 120) || this.$t('Input_length_error', [0, 120])
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentItinerary: 'CURRENT_ITINERARY',
      isMobile: 'IS_MOBILE',
    }),
    tagsValidationError() {
      return this.selectedTags.length < 1 ? this.$t('Share_with_tags_valid_text') : ''
    },
    tagsOptionsLocalized() {
      return this.tags.map(el => ({text: this.$t(el), value: el}))
    },
  },
  methods: {
    ...mapActions({
      getTags: 'GET_TAGS',
      convertItineraryToTemplate: 'CONVERT_ITINERARY_TO_TEMPLATE',
      isItineraryConvertibleToTemplate: 'CHECK_ITINERARY_CONVERTIBLE_TO_TEMPLATE',
    }),
    setDefaultImg() {
      this.url = defaultImageUrl
    },
    onSubmitForm() {
      if (this.selectedTags.length < 1 && this.$refs.form.validate()) {
        return
      }
      const payload = {
        name: this.name,
        description: this.description.trim(),
        tags: this.selectedTags
      }
      this.convertItineraryToTemplate(payload)
      this.closeModal()
    },
    closeModal() {
      this.$emit('close')
    },
    async isConvertibleCheck() {
      const payload = {
        name: this.name,
        description: this.description.trim(),
        tags: this.selectedTags
      }
      return await this.isItineraryConvertibleToTemplate(payload)
    },
  },
  async created() {
    const { itineraryName, placeIDLocations } = this.currentItinerary.header
    this.name = itineraryName
    this.url = placeIDLocations?.[0] ? generateUrlByPlaceID(placeIDLocations?.[0]) : defaultImageUrl
    this.tags = await this.getTags()

    if (! await this.isConvertibleCheck()) {
      this.$emit('notConvertible')
      return
    }
  },
}
</script>

<style lang="scss" scoped>
  .v-chip {
    &:not(.v-chip--active):focus::before {
      opacity: 0;
    }
  }
</style>
