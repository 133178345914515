import commonAPIs from './commonAPIs'
import { EventTypes } from '../itemTypes'
import moment from 'moment'
import datetime from '@/helpers/datetime'
import itineraryService from '../itineraryService'

export default {
  async autoFill(params) {
    await commonAPIs.createTransportationEvents({...params, eventType: EventTypes.CRUISE})
  },

  createCruiseDay(startDateTime, endDateTime, point, notes) {
    let rv = {
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      notes: notes,
    }
    rv.cruiseDayAtPort = { port: point }

    return rv
  },

  /**
   * returns an array of cruise days CruiseDay[] based on startDataTime/endDateTime:
   * cruiseDayAtSea, startDateTime, endDateTime, notes
   *
   */
  async synchronizeCruiseDays(startDateTime, endDateTime, embarkPoint, disembarkPoint, cruiseDays) {
    const rv = []
    startDateTime = await datetime.convertDateTimeISOStringToLocalizedString(startDateTime, embarkPoint?.location?.placeID)
    endDateTime = await datetime.convertDateTimeISOStringToLocalizedString(endDateTime, disembarkPoint?.location?.placeID)
    const days = moment(endDateTime).diff(moment(startDateTime), 'days')

    rv.push(this.createCruiseDay(
      await datetime.convertDateTimeISOStringToLocalizedString(startDateTime, embarkPoint?.location?.placeID),
      await datetime.convertDateTimeISOStringToLocalizedString(moment.parseZone(startDateTime).add(4, 'hour').format('YYYY-MM-DDTHH:mmZ'), embarkPoint?.location?.placeID),
      embarkPoint?.location,
      'Notes for day'
    ))
    for (let i = 1; i <= days; i++) {
      const date = moment.parseZone(startDateTime).add(i, 'day').format('YYYY-MM-DD')
      rv.push(this.createCruiseDay(
        await datetime.convertDateTimeISOStringToLocalizedString(date + 'T07:00:00', embarkPoint?.location?.placeID),
        await datetime.convertDateTimeISOStringToLocalizedString(date + 'T20:00:00', embarkPoint?.location?.placeID),
        embarkPoint?.location,
        'Notes for day'
      ))
    }
    rv.push(this.createCruiseDay(
      await datetime.convertDateTimeISOStringToLocalizedString(endDateTime, disembarkPoint?.location?.placeID),
      await datetime.convertDateTimeISOStringToLocalizedString(moment.parseZone(endDateTime).add(4, 'hour').format('YYYY-MM-DDTHH:mmZ'), disembarkPoint?.location?.placeID),
      disembarkPoint?.location,
      'Notes for day'
    ))

    // trying to preserve the notes for cruise days when the length of the cruise changes
    for (let i = 1; i < rv.length; i++) {
      rv[i].notes = cruiseDays?.at(i)?.notes || rv[i].notes
    }

    return rv
  },

  /**
    this method will insert "day at ..." records into existing itinerary
    single cruise record will expand into embark, days of cruise, and disembark events
  */
  addCruiseDays(records) {
    let rv = []
    let currentCruise = null

    for (let i=0; i<records.length; i++) {
      let record = {...records[i]}
      if (itineraryService.getEventType(record) === EventTypes.CRUISE) {
        currentCruise = {...record}
        this.addDayAtCruiseEvents(currentCruise, rv)
      } else {
        rv.push(record)
      }
    }

    return rv.sort((a,b) => {
      return new moment(a.header.startDateTime).diff(new moment(b.header.startDateTime))
    })
  },

  /** embark event is the last in cruiseDays array */
  getDayAtCruiseByIndex(currentCruise, cruiseDay, index) {
    let newEvent = {}
    newEvent.header = {...currentCruise.header}
    newEvent[EventTypes.CRUISE] = {...currentCruise[EventTypes.CRUISE]}
    newEvent.header.startDateTime = cruiseDay.startDateTime
    newEvent.header.dayIndex = index
    newEvent.header.embark = (index === 0)
    newEvent.header.disembark = (index === currentCruise?.cruise?.cruiseDays.length - 1)
    if (index === 0) {
      //embark event shows the duration of the entire cruise
      newEvent.header.endDateTime = currentCruise?.header?.endDateTime
    } else if (index === currentCruise?.cruise?.cruiseDays.length - 1) {
      // disembark
      newEvent.header.endDateTime = moment.parseZone(cruiseDay.startDateTime).add(4, 'hour').format('YYYY-MM-DDTHH:mm:ssZ')
    } else {
      newEvent.header.endDateTime = cruiseDay.endDateTime
    }

    return newEvent
  },

  addDayAtCruiseEvents(currentCruise, records) {
    const cruiseDays = currentCruise?.cruise?.cruiseDays || []
    for (let i = 0; i < cruiseDays.length; i++) {
      records.push(this.getDayAtCruiseByIndex(currentCruise, cruiseDays[i], i))
    }
  },

  /** will update dayIndex element of the array cruiseDays with cruiseDayUpdated */
  updateCruiseDays(cruiseDays, cruiseDayUpdated, dayIndex) {
    if (!Array.isArray(cruiseDays)) {
      throw new Error('cruiseDays is expected to be an array, got ' + typeof cruiseDays)
    }
    return cruiseDays.map((item, index) => (index === dayIndex) ? { ...item, ...cruiseDayUpdated } : item)
  }
}
