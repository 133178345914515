<template>
  <v-expansion-panel
    :value="index"
    elevation="0"
    class="expansionPanel rounded-lg"
    data-test-name="content-event-card"
  >
    <v-expansion-panel-title class="d-flex align-center">
      <v-row v-if="$vuetify.display.mdAndUp">
        <v-col v-if="displayPriceLevel && displayRating">
          <v-row no-gutters>
            <v-col class="container">
              <v-icon
                :color="iconColor"
                :icon="icon"
                size="large"
                class="mr-2"
                data-test-name="icon-event-type"
              />
              <p class="font-weight-medium panelText" data-test-name="text-title">{{ title }}</p>
            </v-col>
            <v-divider vertical class="mx-2" data-test-name="divider" />
            <v-col md="2" class="justify-center container">
              <v-rating
                :model-value="priceLevel"
                readonly
                size="small"
                density="compact"
                full-icon="$customDollar"
                empty-icon=""
                color="primary"
                data-test-name="rating-price-level"
              />
            </v-col>
            <v-divider vertical class="mx-2" data-test-name="divider" />
            <v-col md="3" class="justify-center container">
              <user-rating
                :userRating="rating"
                class="rating"
              />
            </v-col>
          </v-row>
          <v-row v-if="titleLabel" no-gutters>
            <v-col>
              <p class="text-caption ml-13" data-test-name="text-title-label">{{ titleLabel }}</p>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-else-if="displayPriceLevel">
          <v-row no-gutters>
            <v-col class="container">
              <v-icon
                :color="iconColor"
                :icon="icon"
                size="large"
                class="mr-2"
                data-test-name="icon-event-type"
              />
              <p class="font-weight-medium panelText" data-test-name="text-title">{{ title }}</p>
            </v-col>
            <v-divider v-if="displayPriceLevel" vertical class="mx-2" data-test-name="divider" />
            <v-col v-if="displayPriceLevel" md="2" class="justify-center container">
              <v-rating
                :model-value="priceLevel"
                readonly
                size="small"
                density="compact"
                full-icon="$customDollar"
                empty-icon=""
                color="primary"
                data-test-name="rating-price-level"
              />
            </v-col>
          </v-row>
          <v-row v-if="titleLabel" no-gutters>
            <v-col>
              <p class="text-caption ml-13" data-test-name="text-title-label">{{ titleLabel }}</p>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-else-if="displayRating">
          <v-row no-gutters>
            <v-col class="container">
              <v-icon
                :color="iconColor"
                :icon="icon"
                size="large"
                class="mr-2"
                data-test-name="icon-event-type"
              />
              <p class="font-weight-medium panelText" data-test-name="text-title">{{ title }}</p>
            </v-col>
            <v-divider vertical class="mx-2" data-test-name="divider" />
            <v-col md="3" class="justify-center container">
              <user-rating
                :userRating="rating"
                class="rating"
              />
            </v-col>
          </v-row>
          <v-row v-if="titleLabel" no-gutters>
            <v-col>
              <p class="text-caption ml-13" data-test-name="text-title-label">{{ titleLabel }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <v-row no-gutters>
            <v-col class="container">
              <v-icon
                :color="iconColor"
                :icon="icon"
                size="large"
                class="mr-2"
                data-test-name="icon-event-type"
              />
              <p class="font-weight-medium panelText" data-test-name="text-title">{{ title }}</p>
            </v-col>
          </v-row>
          <v-row v-if="titleLabel" no-gutters>
            <v-col>
              <p class="text-caption ml-13" data-test-name="text-title-label">{{ titleLabel }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-else no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col class="container">
              <v-icon
                :color="iconColor"
                :icon="icon"
                size="large"
                class="mr-2"
                data-test-name="icon-event-type"
              />
              <p class="font-weight-medium d-inline panelText" data-test-name="text-title">{{ title }}</p>
            </v-col>
          </v-row>
          <v-row v-if="displayRating || displayPriceLevel" no-gutters>
            <v-col v-if="$vuetify.display.xs">
              <v-row v-if="displayPriceLevel" no-gutters class="row">
                <v-rating
                  :model-value="priceLevel"
                  readonly
                  size="small"
                  density="compact"
                  full-icon="$customDollar"
                  empty-icon=""
                  color="primary"
                  data-test-name="rating-price-level"
                />
              </v-row>
              <v-row v-if="displayRating" no-gutters class="row">
                <user-rating
                  :userRating="rating"
                  class="rating"
                />
              </v-row>
            </v-col>

            <v-col v-else>
              <v-row no-gutters>
                <v-col v-if="displayPriceLevel" class="justify-center container">
                  <v-rating
                    :model-value="priceLevel"
                    readonly
                    size="small"
                    density="compact"
                    full-icon="$customDollar"
                    empty-icon=""
                    color="primary"
                    data-test-name="rating-price-level"
                  />
                </v-col>
                <v-divider v-if="displayPriceLevel" vertical class="mx-2" data-test-name="divider" />
                <v-col v-if="displayRating" class="justify-center container">
                  <user-rating
                    :userRating="rating"
                    class="rating"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="titleLabel" no-gutters>
            <v-col>
              <p class="text-caption ml-13" data-test-name="text-title-label">{{ titleLabel }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="bg-white rounded-lg">
      <div class="py-6 px-6">
        <v-row v-if="$vuetify.display.smAndUp" no-gutters justify="space-between">
          <v-col md="7">
            <v-row v-if="location" no-gutters class="row">
              <v-col class="container">
                <v-icon color="primary" size="large" icon="mdi-map-marker-outline" data-test-name="icon-location" />
                <p data-test-name="text-location">{{ location }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="row">
              <v-col class="container">
                <v-icon color="primary" size="large" icon="mdi-clock-outline" data-test-name="icon-time" />
                <p data-test-name="text-time">{{ time }}</p>
              </v-col>
            </v-row>
            <v-row v-if="address" no-gutters class="row">
              <v-col class="container">
                <v-icon color="primary" size="large" icon="$customHouse" data-test-name="icon-address" />
                <p data-test-name="text-address">{{ address }}</p>
                <v-tooltip :text="$t('View_on_map')">
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon="$customColorMap" size="x-small" class="mapButton" data-test-name="button-open-google-map" @click="handleGoogleMap" />
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="description" no-gutters class="row">
              <v-col class="container">
                <p data-test-name="text-description">{{ description }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" xl="3" class="ml-8">
            <v-img
              :src="imageSrc"
              :aspect-ratio="16/9"
              cover
              class="rounded-lg"
              data-test-name="image-hotel"
            />
          </v-col>
        </v-row>
        <v-row v-else no-gutters>
          <v-col>
            <v-row no-gutters class="row">
              <v-col class="container">
                <v-icon color="primary" size="large" icon="mdi-map-marker-outline" data-test-name="icon-location" />
                <p data-test-name="text-location">{{ location }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="row">
              <v-col class="container">
                <v-icon color="primary" size="large" icon="mdi-clock-outline" data-test-name="icon-time" />
                <p data-test-name="text-time">{{ time }}</p>
              </v-col>
            </v-row>
            <v-row v-if="address" no-gutters class="row">
              <v-col class="container">
                <v-icon color="primary" size="large" icon="$customHouse" data-test-name="icon-address" />
                <p data-test-name="text-address">{{ address }}</p>
                <v-tooltip :text="$t('View_on_map')">
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon="$customColorMap" size="x-small" class="mapButton" data-test-name="button-open-google-map" @click="handleGoogleMap" />
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="description" no-gutters class="row">
              <v-col class="container">
                <p data-test-name="text-description">{{ description }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-img
                :src="imageSrc"
                :aspect-ratio="16/9"
                cover
                class="rounded-lg"
                data-test-name="image-hotel"
              />
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import UserRating from '@/components/UserRating'

export default {
  name: 'EventCard',
  components: { UserRating },
  emits: ['open-google-map'],
  props: {
    index: {
      type: Number,
      required: true
    },
    icon: String,
    iconColor: {
      type: String,
      default: 'black'
    },
    title: String,
    titleLabel: String,
    location: String,
    time: String,
    address: String,
    description: String,
    imageSrc: String,
    displayRating: Boolean,
    rating: {
      aggregateRating: Number,
      votes: Number
    },
    priceLevel: Number,
    displayPriceLevel: Boolean,
  },
  methods: {
    handleGoogleMap() {
      this.$emit('open-google-map')
    },
  },
}
</script>

<style lang="scss" scoped>
.expansionPanel {
  background: #EAF2FF;
  border: 1px solid rgba(0, 0, 0, 0.01) ;

  & :deep(.v-expansion-panel-title__overlay) {
    opacity: 0 !important;
  }

  & :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }

  & :deep(.v-expansion-panel-text) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    position: relative;
  }

  & :deep(.v-expansion-panel-text::before) {
    content: "";
    position: absolute;
    left: 0;
    top: -1rem;
    height: 1rem;
    width: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    box-shadow: 0 0.5rem 0 0 white;
  }

  & :deep(.v-expansion-panel-text::after) {
    content: "";
    position: absolute;
    right: 0;
    top: -1rem;
    height: 1rem;
    width: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0 0.5rem 0 0 white;
  }
}

.panelText {
  font-size: 1.125rem;
}

.row {
  justify-content: center;
  align-items: center;
}

.row:not(:last-child) {
  margin-bottom: 1rem;
}

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mapButton {
  width: 32px;
  height: 32px;
  background-color: #F2F6FC;

  & :deep(circle) {
    fill: #F2F6FC;
  }
}

.mapButton.v-btn--variant-elevated {
  box-shadow: none;
}

.rating {
  align-items: center;

  & :deep(div) {
    padding: 0 !important;
  }

  & :deep(span) {
    font-weight: 500;
  }
}

</style>

