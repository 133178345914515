<template>
  <svg viewBox="0 0 491 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2_43)" fill="currentColor">
      <path d="M87.3679 82.4851L96.0666 73.7925L75.4945 31.4078L92.8284 14.0859C95.8126 11.1038 95.8126 6.21811 92.8284 3.1725C89.8442 0.19035 84.9551 0.19035 81.9074 3.1725L64.5735 20.4944L22.2229 0L13.5242 8.69266L49.4619 35.659L26.1596 58.9451L4.95254 54.5671L0 59.5162L22.6674 73.2849L36.4456 95.9365L41.3982 90.9874L37.0806 69.7951L60.3829 46.5089L87.3679 82.4851Z"/>
      <path d="M141.909 64.9729V66.4323C141.909 77.2188 146.735 75.6325 165.974 74.6173V94.2234C165.974 94.2234 162.037 95.6193 141.909 95.6193C121.782 95.6193 121.845 79.1223 121.845 68.3358V38.6411H111.94V21.7634H121.845V0.380699H141.973V21.7634H166.037V38.6411H141.973V64.9729H141.909Z"/>
      <path d="M230.547 23.4131L227.372 42.3847C225.531 41.8771 220.452 40.735 214.102 40.735C199.181 40.735 194.736 52.3463 194.736 62.3715V95.5559H174.609V21.573H194.736V32.423H196.006C200.133 25.3166 204.705 21.573 217.404 21.573C222.737 21.573 227.055 22.6517 230.547 23.4131Z"/>
      <path d="M295.375 32.3595V21.5096H315.502V95.5558H295.375V85.2135H294.74C289.724 91.6219 281.279 95.5558 267.374 95.5558C236.262 95.5558 232.769 68.5261 232.769 58.501C232.769 47.7145 236.071 21.4461 267.437 21.4461C281.152 21.4461 289.533 25.7607 294.549 32.2961H295.375V32.3595ZM294.168 58.501C294.168 48.4759 290.613 38.768 273.279 38.768C254.04 38.768 252.008 52.7905 252.008 58.501C252.008 63.8308 254.167 78.234 273.215 78.234C290.613 78.2974 294.168 69.7317 294.168 58.501Z"/>
      <path d="M408.458 22.0806L379.949 95.5558H349.345L320.772 22.0806H341.979L363.567 77.6629H365.599L387.187 22.0806H408.458Z"/>
      <path d="M470.936 32.3595V21.5096H491.064V95.5558H470.936V85.2135H470.301C465.285 91.6219 456.84 95.5558 442.935 95.5558C411.823 95.5558 408.331 68.5261 408.331 58.501C408.331 47.7145 411.632 21.4461 442.998 21.4461C456.713 21.4461 465.094 25.7607 470.11 32.2961H470.936V32.3595ZM469.666 58.501C469.666 48.4759 466.11 38.768 448.776 38.768C429.538 38.768 427.506 52.7905 427.506 58.501C427.506 63.8308 429.665 78.234 448.713 78.234C466.11 78.2974 469.666 69.7317 469.666 58.501Z"/>
    </g>
    <defs>
      <clipPath id="clip0_2_43">
        <rect width="491" height="96" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
