<template>
  <v-card flat class="ma-4" @click="goToItinerary" data-test-name="card-itinerary">
    <v-img
      :src="imageURL"
      :aspect-ratio="20/13"
      cover
      class="rounded-lg"
      data-test-name="image"
      @error="setDefaultImg"
    />
    <v-card-title class="px-0 pt-5 pb-2 text-h6 font-weight-bold" data-test-name="text-title-itinerary-name">
      {{ itinerary.header.itineraryName }}
    </v-card-title>
    <v-divider color="bg-primary" />
    <v-row align="center" no-gutters>
      <v-icon size="small">$customCalendar</v-icon>
      <v-card-subtitle
        class="px-0 py-2 ml-2 text-body-2 text-md-body-1 text-textPrimaryColor"
        data-test-name="text-subtitle-itinerary-date"
      >
        {{ itineraryStartEndDate }}
      </v-card-subtitle>
    </v-row>
    <v-row no-gutters class="d-flex align-center justify-space-between">
      <v-col cols="8">
        <v-card-subtitle class="pa-0 d-flex flex-wrap" data-test-name="text-subtitle-itinerary-data">
          <span
            class="mr-2 text-textPrimaryColor d-flex align-center"
            data-test-name="text-subtitle-itinerary-created"
          >
            {{ $t('Itinerary_created') }}
          </span>
          <span v-if="isShared" data-test-name="text-subtitle-itinerary-shared">({{$t('Shared')}})</span>
          <span class="d-flex align-center" data-test-name="text-subtitle-itinerary-creation-date">{{ itineraryCreatedDate }}</span>
        </v-card-subtitle>
      </v-col>
      <v-col :cols="isOwner? 1 : 12" class="d-flex justify-space-between">
        <div v-if="!isOwner" class="d-flex align-center text-truncate">
          <v-icon size="x-small" data-test-name="icon">$customShare</v-icon>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <span
                v-bind="props"
                class="ml-1 text-textPrimaryColor text-body-2 text-md-body-1 text-truncate"
                data-test-name="text-itinerary-owner-name"
              >
                {{ itinerary.ownerName }}
              </span>
            </template>
            <span data-test-name="text-subtitle-itinerary-owner-name">{{ itinerary.ownerName }}</span>
          </v-tooltip>
        </div>
        <v-btn
          icon
          variant="plain"
          size="small"
          data-test-name="button-delete"
          :title="$t('Delete')"
          @click.stop="isDeleteItineraryConfirmationVisible = true"
        >
          <v-icon size="small">$customDelete</v-icon>
        </v-btn>
        <common-popup
          v-if="isDeleteItineraryConfirmationVisible"
          :body-content="$t('Delete_this_itinerary')"
          :confirm-label="$t('Delete')"
          :cancel-label="$t('Cancel')"
          @confirm="doDelete"
          @cancel="isDeleteItineraryConfirmationVisible= false"
          @close="isDeleteItineraryConfirmationVisible = false"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {generateUrlByPlaceID, getDefaultImageUrl} from '@/helpers/staticImagesUtil'
import CommonPopup from '@/components/popups/CommonPopup'
import { logAppError } from '@/logger'

export default {
  name: 'ItineraryCard',
  emits: ['deleted', 'goToItinerary'],
  components: {
    CommonPopup,
  },
  props: {
    itinerary: { type: Object, required: true },
    role: { type: String },
    isShared: { type: Boolean, default: false },
  },
  data() {
    return {
      overlay: false,
      imageURL: '',
      isDeleteItineraryConfirmationVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isMobile: 'IS_MOBILE'
    }),
    itineraryStartEndDate() {
      return `${this.$moment.parseZone(this.itinerary.header.startDate).format(this.user.settings.dateFormat)} - ${this.$moment.parseZone(this.itinerary.header.endDate).format(this.user.settings.dateFormat)}`
    },
    isOwner() {
      return this.role === 'OWNER' || this.itinerary.header.userID === this.user.id
    },
    itineraryCreatedDate() {
      return this.$moment.parseZone(this.itinerary.header.createdDate).format(`${this.user.settings.dateFormat}  ${this.user.settings.timeFormat}`)
    },
  },
  created() {
    this.imageURL = this.itinerary?.header?.placeIDLocations?.[0] ? generateUrlByPlaceID(this.itinerary.header.placeIDLocations?.[0]) : getDefaultImageUrl()
  },
  methods: {
    ...mapActions({
      showNotification: 'PUSH_APP_NOTIFICATION',
      removeItinerary: 'REMOVE_ITINERARY',
      removeSharedItinerary: 'REMOVE_SHARED_ITINERARY',
    }),
    setDefaultImg() {
      this.imageURL = getDefaultImageUrl(this.itinerary?.header?.placeIDLocations?.[0])
    },
    async doDelete() {
      try {
        const payload = {
          itineraryID: this.itinerary.header.itineraryID,
          userID: this.user.id
        }
        this.isOwner ? await this.removeItinerary(payload) : await this.removeSharedItinerary(payload)
        this.notifyParent()
        this.showNotification({
          name: 'removeItinerary',
          content: 'Itinerary_removed',
          color: 'primary'
        })
      } catch (error) {
        this.showNotification({
          name: 'errItinerary',
          content: 'Itinerary_not_removed',
          color: 'error'
        })
        logAppError({
          message: error,
          itineraryID: this.itinerary.header.itineraryID,
          userID: this.user.id
        })
      }
    },
    notifyParent() {
      this.$emit('deleted', this.itinerary.header.itineraryID)
    },
    goToItinerary() {
      this.$emit('goToItinerary', this.itinerary.header.id)
    },
  }
}
</script>
