export default {
  /** make sure to sort entries alphabetically in each section */

  // Common
  Add_event:                   'Add Event',
  Add:                         'Add',
  Attraction_name:             'Attraction name',
  Back:                        'Back',
  Book:                        'Book',
  Booked:                      'Booked',
  Bus_at:                      'Bus',
  Cancel:                      'Cancel',
  CarRental_at:                'Car rental',
  Clear_All:                   'Clear All',
  Complete:                    'Complete',
  Create:                      'Create',
  Created:                     'Created',
  Cruise_name:                 'Cruise name',
  CustomEvent_at:              'Event',
  Date:                        'Date',
  Dates:                       'Dates',
  Delete_Event:                'Delete event',
  Delete_Segment:              'Delete Segment',
  Delete:                      'Delete',
  Discard:                     'Discard',
  Done:                        'Done',
  Drive_to:                    'Drive',
  Edit:                        'Edit',
  Event:                       'Event',
  Exercise_at:                 'Exercise',
  Explore_at:                  'Explore',
  Ferry_at:                    'Ferry',
  Flight_at:                   'Flight',
  Hotel_lodging_name:          'Hotel/Lodging name',
  Insurance_name:              'Insuring company name',
  Keep_editing:                'Keep editing',
  Keywords:                    'Keywords',
  Last_modified:               'Last modified',
  Logout:                      'Logout',
  Modify:                      'Modify',
  Next:                        'Next',
  No:                          'No',
  NotBooked:                   'Not booked',
  Ok:                          'Ok',
  Others:                      'others',
  Passengers:                  'Passengers',
  Please_rate:                 'Please, leave your rating',
  Protect:                     'Protect',
  Protected:                   'Protected',
  Relax_at:                    'Relax',
  Remove:                      'Remove',
  Reset_Default:               'Reset to Default',
  Restaurant_at:               'Restaurant name',
  Save_Changes:                'Save changes',
  Save:                        'Save',
  Send:                        'Send',
  Share:                       'Share',
  Shopping_name:               'Shopping name',
  Show_at:                     'Show/Performance',
  Skip_All:                    'Skip All',
  Skip:                        'Skip',
  Sorting:                     'Sorting',
  Start:                       'Start',
  Submit:                      'Submit',
  Tour_name:                   'Tour name',
  Train_at:                    'Train',
  Transfer_to:                 'Transfer',
  Trip_preferences:            'Trip preferences',
  Update:                      'Update',
  Visit:                       'Visit',
  Yes:                         'Yes',

  // Home & Itineraries Pages
  Activities:                  'Activities',
  Activity_filter:             'Activity filter',
  Add_comment:                 'Add comment',
  Add_segment:                 'Add segment',
  Add_tags:                    'Add tags',
  Address:                     'Address',
  Adult:                       'Adult',
  Adults:                      'Adults',
  Age:                         'Age',
  Airline_name:                'Airline name',
  Amenities:                   'Amenities',
  Arrival_date:                'Arrival date',
  Arrival_time:                'Arrival time',
  Arrival:                     'Arrival',
  Back_to_my_itineraries:      'Back to my itineraries',
  Booking_link:                'Booking link',
  Bus_company:                 'Bus company',
  Bus_number:                  'Bus number',
  By:                          'by',
  Cabin_class:                 'Cabin Class',
  Cabin_number:                'Cabin number',
  Change_search_criteria:      'Please, change your search criteria and try again.',
  CheckIn_date:                'Check in date',
  CheckIn_time:                'Check in time',
  CheckIn:                     'Check in',
  CheckOut_date:               'Check out date',
  CheckOut_time:               'Check out time',
  CheckOut:                    'Check out',
  Child:                       'Child',
  Children:                    'Children',
  Clone_itinerary_as_my_own:   'Clone itinerary as my own',
  Clone_itinerary:             'Clone itinerary',
  Close:                       'Close',
  Comment:                     'Comment',
  Commenter_tooltip:           'You can add, delete your comments and clone itinerary.',
  Commenter:                   'a Commenter',
  Comments:                    'Comments',
  Community_banner_title_p1:   'Use itineraries shared',
  Community_banner_title_p2:   'by our community',
  Copy_Link:                   'Copy link',
  Countries_of_travel:         'Countries of travel',
  Create_itinerary:            'Create Itinerary',
  Create_plan:                 'create your itinerary',
  Cruise_company:              'Cruise Company',
  Cruise_embark:               'Embarkation',
  Cruise_day:                  'Cruise day',
  Cruise_disembark:            'Disembarkation',
  Cruise_port:                 'Port of call',
  Cruise_ship_name:            'Cruise Ship Name',
  Currency:                    'Currency',
  Delete_itinerary:            'Delete itinerary',
  Delete_this_comment:         'Delete this comment?',
  Delete_this_event:           'Delete this event?',
  Delete_this_itinerary:       'Delete this itinerary?',
  Departure_date:              'Departure date',
  Departure_time:              'Departure time',
  Departure:                   'Departure',
  Description:                 'Description',
  Destination:                 'Destination',
  Directions:                  'Directions',
  Disembark_date:              'Disembark date',
  Disembark_port:              'Disembark port',
  Disembark_time:              'Disembark time',
  Drop_off_location:           'Drop off location',
  Drop_off:                    'Drop off place',
  Duration_segment:            'Segment duration',
  Duration:                    'Duration',
  Edit_Event:                  'Edit event',
  Edit_info:                   'Edit info',
  Editor_tooltip:              'You can create, edit, delete events as well as clone itinerary.',
  Editor:                      'an Editor',
  Email_duplicated:            'Duplicate email',
  Email_of_current_user:       'You can\'t share the itinerary with yourself within the same account',
  Embark_date:                 'Embark date',
  Embark_port:                 'Embark port',
  Embark_time:                 'Embark time',
  End_date:                    'End date',
  End_time:                    'End time',
  Event_actions:               'Event actions',
  Event_beginning:             'beginning of the event',
  Event_ending:                'ending of the event',
  Ferry_company:               'Ferry company',
  Ferry_number:                'Ferry number',
  Filter_list:                 'Filter list',
  Flight_number:               'Flight number',
  Found:                       'Found',
  Generate_my_own_itinerary:   'Generate my own itinerary',
  Here:                        'Here is',
  Hide_info:                   'Hide info',
  Insurance_Text_p1:           'Travel insurance is dramatically growing in popularity as more and more people realize that it provides peace of mind while they travel, and assistance when unforeseen situations arise.',
  Insurance_Text_p2:           'The mission of TravelInsurance.com is to make it easy for travelers to compare and buy travel insurance for their trips, every time they travel. Plans and prices are constantly changing and they offer a wide array of plans from top rated insurers and a simple process for quoting, comparing and buying.',
  Itinerary_banner_text:       'This itinerary is saved under',
  Itinerary_banner_text2:      'in your profile',
  Itinerary_created:           'Itinerary created',
  Itinerary_error:             'Itinerary is not created',
  Itinerary_name:              'Itinerary name',
  Itinerary_not_removed:       'Cannot remove itinerary. Something went wrong.',
  Itinerary_removed:           'Itinerary removed',
  Itinerary_update_avaliable:  'Itinerary update is available',
  Itinerary_updated:           'Itinerary is updated',
  Itinerary:                   'Itinerary',
  Layover_duration:            'Layover duration',
  Layover:                     'Without Layover | 1 Layover | {count} Layovers',
  Let_us:                      'Let us',
  Link:                        'Link',
  Location:                    'Location',
  Main_Country:                'Main Country of Travel',
  No_comments_message:         'No comments yet.',
  No_itineraries:              'No planned itineraries',
  No_of_nights:                'No. of nights',
  No_results_found:            'Sorry, no result found.',
  No_results_message:          'Looks like you did’t search yet.',
  No_results_message4:         'Sorry we couldn\'t find anything matching the criteria',
  No_shared_itineraries:       'No shared itineraries',
  Notes:                       'My notes',
  On_the_way:                  'On the way',
  Open_daily:                  'Open daily',
  Origin:                      'Origin',
  Per_person:                  'per person',
  Pick_arrival:                'Pick arrival first',
  Pick_departure:              'Pick departure first',
  Pick_location:               'Pick location first',
  Pick_up_location:            'Pick up location',
  Pick_up:                     'Pick up place',
  Place_from:                  'Place from',
  Place_to:                    'Place to',
  Plan_myself_banner_title_p2: 'your own itinerary',
  Price_legend:                'the price total is calculated based on the currency conversion rate provided by a third party',
  Price_per_person:            'Price per person',
  Price_range:                 'Price range',
  Remember_me:                 'Remember me',
  Round_trip_title:            'Place To is the same as Place From',
  Round_trip:                  'Round trip',
  Route_number:                'Route number',
  Same_Location:               'Drop Off at the same location?',
  Search_shared_plans:         'Search',
  Seat_class:                  'Seat class',
  Seats:                       'Seats',
  Share_as_Community_Template: 'Share with community',
  Share_Dialog_Community:      'With community',
  Share_Dialog:                'Privately',
  Share_Itinerary:             'Share itinerary',
  Shared_by:                   'Shared by',
  Shared_itinerary:            'Itinerary shared with me',
  Shared:                      'Shared',
  Sign_in_notification:        'Note: your itinerary won’t be saved until you sign in. ',
  Star_Rating:                 'Star Rating',
  Start_date:                  'Start date',
  Start_time:                  'Start time',
  Station:                     'Station',
  Tab_Details:                 'Details',
  Tab_Search:                  'Search',
  Times_are_local:             'Times are local',
  Title:                       'Title',
  To_center:                   'To center',
  Total_Duration:              'Total duration',
  Total_price:                 'Total price',
  Train_company:               'Train company',
  Train_number:                'Train number',
  Transfer_company:            'Transfer company',
  Transport_company:           'Transport company',
  Transportation:              'Transportation',
  Travel_description:          'Travel description',
  Travelers:                   'Travelers',
  Trip:                        'Trip',
  Type_of_car:                 'Type of the car',
  Type_text_here:              'Type text here',
  Update_available:            'Update available',
  View_Mode:                   'View mode',
  View_on_map:                 'View on map',
  Viewer_anonymous_tooltip:    'You can view events and clone itinerary. Please, sign in.',
  Viewer_tooltip:              'You can view events and clone itinerary.',
  Viewer:                      'a Viewer',
  What_to_bring:               'What to bring',
  Where_from:                  'From',
  Where_to:                    'To',
  Write_comment:               'Write a comment',
  You_are_p1:                  'You are',
  You_are_p2:                  'of this itinerary',
  Your_itinerary:              'your itinerary',
  Your:                        'Your',

  // Edit Itinerary page
  Share_with_people:          'Share with people',

  //Home page errors
  Equal_Airport_Error:         'Arrival and departure airports should be different for one flight',
  Fill_Field_Error:            'Please fill out all the fields',
  Min_Date_Range_Error:        'The itinerary duration less than 2 days is not currently supported',
  Date_Range_Error:            'The itinerary duration over 4 weeks is not currently supported',
  Max_Date_Range_Error:        'The automatic itinerary generation for trips over 35 days is currently not supported. To extend your trip, click "Add Event" after the itinerary is generated',

  // Profile Page
  Add_Airline:                 'Add Airline',
  Add_Hotel:                   'Add Hotel Chain',
  Airline_Account_Number:      'Airline Account Number',
  Airline_already_added:       'This airline has already been added to the list.',
  Change_Password:             'Change password',
  Current_Password:            'Current password',
  Date_Format:                 'Date format',
  Day:                         'Day',
  Edit_Airline:                'Edit Airline',
  Edit_Hotel:                  'Edit Hotel Chain',
  Email:                       'Email',
  Enter_Phone:                 'Enter Phone Number',
  First_Name:                  'First name',
  Flight_Class:                'Flight class',
  Home:                        'Home',
  Home_Airport:                'Home airport',
  Hotel_Account_Number:        'Hotel Account Number',
  Hotel_Class:                 'Preferred hotel rating',
  Hotel_already_added:         'This hotel has already been added to the list.',
  I_dont_know_password:        'I don\'t know my password',
  Language:                    'Language',
  Last_Name:                   'Last name',
  New_Password:                'New password',
  New_Password_Saved:          'The new password is successfully saved!',
  No_airlines_available:       'No airlines available',
  No_hotels_available:         'No hotels available',
  Password_Hint:               'At least 8 characters',
  Passwords_Must_Match:        'Passwords must match',
  Passwords_Must_Not_Match:    'Passwords must not match',
  Phone:                       'Phone',
  Preferences:                 'Preferences',
  Preferred_Airlines:          'Preferred airlines',
  Preferred_Currency:          'Preferred currency',
  Preferred_Hotel_Chain:       'Preferred hotel chains',
  Profile:                     'Profile',
  Profile_Settings_Title:      'My profile',
  Question_reset_settings:     'Reset your settings to default?',
  Question_to_discard_changes: 'Do you want to discard the changes?',
  Repeat_New_Password:         'Repeat new password',
  Restaurant_Price_Range:      'Restaurant price range',
  Select:                      'Select',
  Select_Flight_Class:         'Select flight class',
  Select_Hotel_Class:          'Select hotel rating',
  Select_Restaurant_Price:     'Select restaurant price range',
  Sensitive_Operation_Warning: 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
  Settings:                    'Settings',
  Start_Typing_To_See_Resuls:  'Start typing to see suggestions...',
  Time_Format:                 'Time format',
  View:                        'View',

  // Profile Page errors
  Could_Not_Change_Password:   'Could not update a password. Please, try again later.',
  Current_Password_Incorrect:  'The current password is incorrect.',
  Email_Error:                 'Check if email is correct.',
  Empty_Field_Error:           'This field is required.',
  First_Name_Length_Error:     'First name must be between 2 and 50 characters.',
  Incorrect_Email_OR_Password: 'Incorrect email or password',
  Last_Name_Length_Error:      'Last name must be between 2 and 50 characters.',
  Phone_Error:                 'Only digits, spaces, -, +, (, ) are allowed.',
  Phone_Length_Error:          'Phone number must be between 8 and 20 characters.',
  Short_Password_Error:        'Password must contain at least 8 characters.',
  Space_Error:                 'Space symbol is not allowed',

  // Validation errors
  Before_Start_Error:          'Event must end after it starts',
  Segment_Before_Start_Error:  'The starting time of the segment can’t be earlier than the ending time of the previous one',
  Input_length_error:          'Input should be between {0} and {1} in length',
  Share_with_tags_valid_text:  'At least one tag should be selected',
  Price_error:                 'Cannot be negative',
  Input_max_length_error:      'Maximum char limit reached: {0} chars',
  No_special_chars_error:      'No special characters allowed',
  Allowed_chars_error:         'Only letters, spaces, -, \', . are allowed.',

  // User Menu
  My_itineraries:              'My itineraries',
  Shared_itineraries:          'Itineraries shared with me',

  // Sign-in Sign-up pages
  Do_not_have_account_question:'Don\'t have an account?',
  Have_account_question:       'Already have an account?',
  Incorrect_login_message:     'Incorrect email/password combination provided.',
  Non_unique_email_message:    'Looks like this email is already in use. Sign In here',
  Non_verified_email_message:  'If this is your first time singing in, please make sure you have validated your email address by clicking on the link we provided. Didn\'t receive a verification email?',
  Password:                    'Password',
  Resend:                      'Resend',
  Resend_email_message:        'A confirmation email has been sent to {email}. Click on the confirmation link in the email to activate your account',
  Sign_in:                     'Sign in',
  Sign_in_with_Apple:          'Continue with Apple',
  Sign_in_with_Facebook:       'Continue with Facebook',
  Sign_in_with_Google:         'Continue with Google',
  Sign_up:                     'Sign up',
  Sign_up_error_message:       'Smth. went wrong. Please make sure you provided valid data and try again',

  // Forgot password page
  Reset_password:              'Reset password',
  Reset_password_error_message:'Smth. went wrong. Make sure you enter an existing email address and try again',
  Reset_password_message:      'Enter your email address and we will send you a link to restore access to your account',
  Reset_password_sent_message: 'Email to reset password has been sent to the address you provided',

  // Header & Footer
  About_us:                    'About us',
  Contact_Us:                  'Contact us',
  Copyright:                   `© ${ new Date()?.getFullYear() } — Trava.ai. All rights reserved.`,
  Leave_Feedback:              'Leave feedback',
  Social:                      'Social',
  nav_itineraries:             'itineraries',
  nav_itinerary:               'itinerary',
  nav_plan_your_trip:          'plan your trip',
  nav_planner_assistant:       'Planner Assistant',
  nav_profile_settings:        'Profile and Settings',
  nav_shared_itineraries:      'shared itineraries',
  nav_shared_itinerary:        'shared itinerary',
  template_itinerary:          'template itinerary',

  // Itinerary Errors
  itinerary_init_error:        'This destination is not currently supported or we couldn\'t find flights for your request, please try another destination.',

  // Planner assistant
  Airlines_Caption:            'We will suggest these airlines first',
  Airlines_Preferences:        'Airlines preferences',
  Flight_Caption:              'We will suggest this flight class first',
  Hotels_Caption:              'We will suggest the best suitable place to stay for your trip',
  Hotels_Preferences:          'Hotels preferences',
  Join_Trava:                  'Join Trava',
  Login_Message_Planner:       'Login into your account and your preferences will be saved into your profile section. Then you can modify your profile at any time',
  Preferred_Flight_Class:      'Flight class preferences',
  Preferred_Hotels_Rating:     'Hotel\'s rating preferences',
  Restaurant_Caption:          'We will suggest the best places to eat according to your answer',
  Restaurants_Preferences:     'Restaurants preferences',
  Step:                        'Step',

  // Flight classes
  Business_Class:              'Business',
  Economy_Class:               'Economy',
  First_Class:                 'First',
  Premium_Economy_Class:       'Premium Economy',

  // Time format
  Hour_12:                     '12-hour',
  Hour_24:                     '24-hour',
  Min:                         'min',

  // Date format
  DD_MM_YYYY:                  'DD-MM-YYYY',
  MM_DD_YYYY:                  'MM-DD-YYYY',

  // User permissions
  Commenter_permission:        'Commenter',
  Editor_permission:           'Editor',
  Owner_permission:            'Owner',
  Viewer_permission:           'Viewer',

  // Page not found
  Go_home_caption:             'Go home',
  Page_not_found_message:      'Uh no! Page not found',

  //Button primary outlined
  Optional_keywords:           'Optional keywords',
  Search:                      'Search',

  //Welcome
  All_Articles:                'All articles',
  Choose_One:                  'Choose one of the 3 options',
  Create_first_itinerary:      'Create Your First Itinerary',
  Generate_For_Me:             'Generate for me',
  Generate_For_Me_Description: 'Use this option if you’d like our patent-pending technology to generate your itinerary automatically based on your preferences and what we know about you and your trip. Don’t worry, you can always modify this itinerary in any way you like.',
  Go_to_community:             'Go to the community',
  Incorrect_date_message:      'Check if the date is correct',
  More_About_Trava:            'More about Trava',
  More_Trips:                  'More Trips',
  Or_If_You_New:               'Or if you are new to Trava, we explain each of the options in detail below.',
  Plan_Myself:                 'Plan myself',
  Plan_Myself_Description:     'Use this option if you want to use our intelligent technology capabilities to set up your travel itinerary yourself. No worries though, you’ll still be guided along the way.',
  Popular_Trips:               'Popular trips',
  Simplified:                  'simplified',
  Trava_Feedback:              ' feedback ',
  Trava_Feedback_Text_1:       'We really value any of your',
  Trava_Feedback_Text_2:       'on how we can improve further. Just use the "Leave feedback" button below.',
  Trava_Feedback_Title:        'Feedback?',
  Trava_Is_A_Great:            'Trava is a great travel organizer.',
  Trava_Is_A_Great_Text:       'With Trava all your past, current, and planned itineraries are stored in one place. You can edit, comment on, and share them with your friends and even if you choose, the whole of Trava community with a click of a button.',
  Trava_Is_Built_To_Save:      'Trava is built to save travelers a ton of time as they plan and prepare for their next adventure.',
  Trava_Is_Built_To_Save_Text: 'It is and will stay absolutely free for all travelers. Brought to life by your fellow explorers, its goal is to make your travel planning as easy and seamless as possible.',
  Trava_Power:                 'Trava\'s power is in its community!',
  Trava_Power_Text_P1:         'Our network of travelers is rapidly growing. We’d actually really appreciate if you share your favorite itineraries with us. To do so, just click on the itinerary you\'d like to share and use “Share with Community” as your itinerary sharing option.',
  Use_Community:               'Use community',
  Use_Community_Description:   'Use this option if you’d like to take advantage of one of the hundreds of itineraries shared by our amazing network of your fellow travelers. No worries, you can modify it to fit your needs at any time.',
  Your_Travel:                 'Your travel, ',

  // User privacy
  Clear_data:                  'Permanently erase all my data',
  Delete_private_data:         'Clear Private Data',
  Erase_all_my_data:           'Erase all my data',
  Privacy_Policy:              'Privacy Policy',
  Question_to_clear_data:      'Do you really want to clear your own personal data ?',
  Terms_of_Service:            'Terms of Service',

  //Contact us
  If_you_have_questions:       ' if you have any questions',
  Please_contact_us:           'Please contact us',

  // Notifications
  Already_shared:              'You have already shared this itinerary',
  Itinerary_cannot_be_shared:  'This itinerary cannot be shared - make sure there are events besides transportation',
  Itinerary_link_copied:       'A read-only link to your Itinerary has been copied to clipboard!',
  Itinerary_shared:            'Your Itinerary has been shared with our community as a template for others to adopt!',
  Itinerary_shared_message:    'Your itinerary has been shared!',
  No_flights_found_message:    'No flights satisfying the search criteria were found',
  Removed_access_message:      'Access to your itinerary has been removed for',
  Smth_went_wrong_message:     'Something went wrong. Please, try again',
  Template_removed:            'This template is successfully deleted',

  // Blog
  Blog:                        'Blog',
  More:                        'More',
  Our:                         'Our',

  // Community
  Back_to_community:           'Back to community',
  Delete_this_template:        'Delete this template?',

  // Sorting options
  longest:                     'Longest',
  newest:                      'Newest',
  oldest:                      'Oldest',
  ranked:                      'Ranked',
  shortest:                    'Shortest',

  // Tags options
  Culture:                     'Culture',
  History:                     'History',
  Museums:                     'Museums',
  Nature:                      'Nature',
  Romantic:                    'Romantic',
  Shopping:                    'Shopping',
}
