<template>
  <v-card width="100%" class="mx-0" flat data-test-name="page-user-settings">
    <v-card-title class="pa-0 pl-0 pl-md-3 pt-6 pb-2 py-md-4 text-h5 text-md-h4 font-weight-bold">
      {{ $t('Settings') }}
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12" class="px-md-3 mb-md-7">
        <place-input
          id="homeAirport"
          :label="$t(homePlaceID.label)"
          :placeholder="$t(homePlaceID.placeholder)"
          :value="user.settings.homePlaceID"
          icon="$customAirplaneOutline"
          min-width="450"
          name="placeInputHomeAirport"
          hide-details
          @update="updateHomeAirport"
          variant="outlined"
          data-test-name="input-home-airport"
        />
      </v-col>
      <v-col cols="12" md="6" class="pa-md-3 pb-md-0 mt-9 mt-md-0">
        <v-select
          prepend-inner-icon="$customCalendar"
          variant="outlined"
          :label="$t(dateFormat.label)"
          :items="dateFormatItems"
          :model-value="user.settings.dateFormat"
          @update:modelValue="updateDateFormat"
          :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true}"
          class="mb-9"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          data-test-name="select-date-format"
        />
        <v-select
          prepend-inner-icon="$customCurrency"
          variant="outlined"
          :label="$t(currency.label)"
          :items="currencyList"
          :model-value="user.settings.currency"
          @update:model-value="updateCurrency"
          :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true}"
          class="mb-9"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          data-test-name="select-preferred-currency"
        />
      </v-col>
      <v-col cols="12" md="6" class="pa-md-3 pb-md-0 my-9 my-md-0">
        <v-select
          prepend-inner-icon="$customClockOutline"
          variant="outlined"
          :label="$t(timeFormat.label)"
          :items="timeFormatItems"
          :model-value="user.settings.timeFormat"
          @update:modelValue="updateTimeFormat"
          :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true}"
          class="mb-9"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          data-test-name="select-time-format"
        >
        </v-select>
        <v-select
          prepend-inner-icon="$customGlobal"
          variant="outlined"
          :label="$t(language.label)"
          :items="languageItems"
          :model-value="user.settings.language"
          @update:model-value="updateLanguage"
          :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true}"
          class="mb-9"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          data-test-name="select-language"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="6" class="px-md-3 pt-md-9 pb-md-0">
        <v-select
          prepend-inner-icon="$customAgenda"
          variant="outlined"
          :label="$t(view.label)"
          :items="itineraryViews"
          :model-value="user.settings.view"
          @update:model-value="updateItineraryView"
          :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true}"
          class="mb-9"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          data-test-name="select-view"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex justify-space-between mt-9">
      <v-col cols="12" lg="4" md="6" class="pa-0 px-md-4 mb-6 mb-md-0">
        <v-btn
          width="100%"
          color="primary"
          class="reset"
          rounded
          variant="outlined"
          size="large"
          @click="toggleConfirmationPopup"
          data-test-name="button-clear-all"
        >
          {{ $t('Clear_All') }}
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pa-0 px-md-3">
        <v-btn
          color="primary"
          width="100%"
          variant="flat"
          rounded
          size="large"
          data-test-name="button-save"
          :disabled="wasDataChanged"
          @click="saveChanges"
        >
          {{ $t('Save_Changes') }}
        </v-btn>
      </v-col>
    </v-row>
    <common-popup
      v-if="isConfirmationPopupVisible"
      :body-content="$t('Question_reset_settings')"
      :confirm-label="$t('Yes')"
      :discard-label="$t('No')"
      @confirm="resetSettingsToDefault"
      @cancel="toggleConfirmationPopup"
      @close="toggleConfirmationPopup"
      class="common-popup"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import PlaceInput from '@/components/PlaceInput'
import CommonPopup from '@/components/popups/CommonPopup'
import {
  itineraryMobileView,
  allLanguages,
  getCurrencySelectList,
  loadLanguageAsync,
} from '@/helpers'
import cloneDeep from 'lodash.clonedeep'
import checkEquality from  'fast-deep-equal/es6'
import { homePlaceID, dateFormat, currency, timeFormat, language, view } from '@/helpers/userProfileFields'
import { defaultSettings } from '@/store/modules/user'
import { logAppError } from '@/logger'

export default {
  name: 'UserSettings',
  components: { PlaceInput, CommonPopup },
  data() {
    return {
      isConfirmationPopupVisible: false,
      user: null,
      homePlaceID: null,
      dateFormat: null,
      currency: null,
      timeFormat: null,
      language: null,
      view: null,
      currencyList: [],
    }
  },
  computed: {
    ...mapGetters({
      userView: 'VIEW',
      stateUser: 'USER'
    }),
    viewMode() {
      return itineraryMobileView.find(view => view.type === this.userView) || itineraryMobileView[2]
    },
    wasDataChanged() {
      return checkEquality(this.stateUser, this.user)
    },
    dateFormatItems() {
      const rv = []
      this.dateFormat.items.forEach(item => {
        rv.push({ title: this.$t(item.text), value: item.value })
      })
      return rv
    },
    timeFormatItems() {
      const rv = []
      this.timeFormat.items.forEach(item => {
        rv.push({ title: this.$t(item.text), value: item.value })
      })
      return rv
    },
    languageItems() {
      const rv = []
      allLanguages.forEach(item => {
        rv.push({ title: item.text, value: item.value })
      })
      return rv
    },
    itineraryViews() {
      const rv = []
      itineraryMobileView.forEach(item => {
        rv.push({ title: item.text, value: item.type })
      })
      return rv
    }
  },
  created() {
    this.homePlaceID = cloneDeep(homePlaceID)
    this.dateFormat = cloneDeep(dateFormat)
    this.currency = cloneDeep(currency)
    this.timeFormat = cloneDeep(timeFormat)
    this.language = cloneDeep(language)
    this.view = cloneDeep(view)
    this.currencyList = getCurrencySelectList()
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updateDateFormat(val) {
      this.user.settings.dateFormat = val
    },
    updateHomeAirport(val){
      this.user.settings.homePlaceID = val
    },
    updateCurrency(val){
      this.user.settings.currency = val
    },
    updateTimeFormat(val) {
      this.user.settings.timeFormat = val
    },
    updateLanguage(val) {
      this.user.settings.language = val
    },
    updateItineraryView(val) {
      this.user.settings.view = val
    },
    async saveChanges() {
      loadLanguageAsync(this.user.settings.language)
        .then(() => this.$i18n.locale = this.user.settings.language)
        .catch((error) => {
          logAppError(`${error}: ${JSON.stringify({
            id: this.user.id,
            extID: this.user.extID,
            settings: this.user.settings
          })}`)
        })
      await this.updateUser(this.user)
    },
    async resetSettingsToDefault() {
      try {
        this.user.settings = cloneDeep(defaultSettings)
        await this.updateUser({ settings: this.user.settings })
      } catch(error) {
        logAppError(`Error during reset preferences to default value ${error}`)
      } finally {
        this.isConfirmationPopupVisible = false
      }
    },
    toggleConfirmationPopup() {
      this.isConfirmationPopupVisible = !this.isConfirmationPopupVisible
    },
  },
  watch: {
    stateUser: {
      handler(newVal) {
        this.user = cloneDeep(newVal)
      },
      immediate: true,
    }
  }
}
</script>
