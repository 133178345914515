<template>
  <div class="itineraries-page" data-test-name="page-itineraries-shared-with-me">
    <v-row no-gutters
      class="itineraries-page-banner d-flex justify-center align-center flex-column"
      :style="{'background-image': `url('${viewBackgroundImage}')`}"
    >
      <v-col cols="12" class="bg py-15 text-center">
        <h1
          class="text-h3 text-sm-h2 font-weight-bold text-primary text-center"
          data-test-name="text-title"
        >
          {{ viewText }}
        </h1>
        <v-row no-gutters justify="center">
        <v-col
          cols="12"
          md="10"
          lg="9"
          class="bg-white mt-5 mb-2 pa-3 py-sm-1 rounded-lg"
        >
          <v-row
            no-gutters
            align="center"
            class="mt-2 mb-n3 py-0"
            :class="$vuetify.display.xsOnly ? 'flex-column' : ''"
            justify="center"
          >
            <v-col
              :cols="12"
              class="d-flex align-start"
            >
              <v-text-field
                v-model="searchQuery"
                :clearable="true"
                class="search-field pa-0"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                data-test-name="input-search"
                variant="outlined"
                :label="$t('Search')"
                @keydown.enter="doSearch"
              >
              </v-text-field>
              <v-btn
                color="primary"
                rounded
                elevation="0"
                data-test-id="filter-templates-btn"
                @click="doSearch"
                icon="$customSearch"
                data-test-name="button-do-search"
                class="search-button"
                width="3.5rem"
                height="3.5rem"
              >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
    <v-container :fluid="$vuetify.display.lgAndDown" class="pa-0">
      <v-col cols="12" md="10" lg="9" xl="8" class="py-0 px-4 px-sm-0 mx-auto">
        <v-row>
          <v-col cols="12">
            <div class="mt-4 d-flex justify-end ml-3">
              <v-select
                prepend-inner-icon="$customShuffle"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                hide-details
                class="sort-select mr-4"
                data-test-name="select-sorting-order"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="sortOptionsList"
                :model-value="sorting"
                @update:model-value="onSorting"
              />
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="haveItineraries"
          no-gutters
        >
          <v-col
            v-for="itinerary in itineraries"
            :key="itinerary.header.itineraryID"
            cols="12"
            sm="6"
            md="4"
          >
            <itinerary-card
              :role="roles[itinerary.header.itineraryID]"
              :isShared="sharedItineraryIds.includes(itinerary.header.itineraryID)"
              :itinerary="itinerary"
              @goToItinerary="goToItinerary(itinerary.header.itineraryID)"
              @deleted="reloadItineraries"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="!isPageLoading && !haveItineraries" class="py-4 px-sm-3 mx-auto" elevation="0">
          <v-col
            cols="12"
            class="text-h4 text-sm-h3 text-break d-flex justify-center text-center"
            data-test-name="text-message"
          >
            {{ isShared? $t('No_shared_itineraries'): $t('No_itineraries') }}
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              v-if="isShared"
              color="primary"
              rounded
              size="large"
              data-test-name="button-open-community-page"
              @click="goToCommunity"
            >
              {{ $t('Go_to_community') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              rounded
              size="large"
              data-test-name="button-open-generate-itinerary-page"
              @click="goToGenerateItinerary"
            >
              {{ $t('Create_first_itinerary') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="pageCount > 1" cols="12" md="9" class="mx-auto mb-10 mt-3">
          <extended-pagination
            class="my-2"
            @startButtonClicked="startButtonClickedHandler"
            @endButtonClicked="endButtonClickedHandler"
          >
            <v-pagination
              rounded
              next-icon="mdi-chevron-right"
              prev-icon="mdi-chevron-left"
              v-model="page"
              data-test-name="pagination"
              :length="pageCount"
              :total-visible="$vuetify.display.xsOnly? 3 : 6"
              @update:model-value="changePage"
            />
          </extended-pagination>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import ItineraryCard from '../components/ItineraryCard'
import ExtendedPagination from '@/components/ExtendedPagination'
import {mapGetters, mapActions} from 'vuex'
import {RouteNames} from '@/helpers/routerUtils'
import { itineraryMobileView } from '@/helpers'
import { pageScroll } from '@/mixins/PageScrollMixin'
import { useRoute } from 'vue-router'
import { logAppError } from '@/logger'

const sharedItinerariesBannerBg = '/assets/images/itineraries-shared-banner-bg.jpg'
const itinerariesBannerBg = '/assets/images/itineraries-banner-bg.jpg'

export default {
  name: 'ItinerariesPage',
  mixins: [pageScroll],
  components: {
    ItineraryCard,
    ExtendedPagination,
  },
  data() {
    return {
      itineraries: [],
      roles: {},
      sharedItineraryIds: [],
      sorting: 'newest',
      searchQuery: null,
      pageCount: 0,
      page: 0,
      size: 12,
      isPageLoading: false,
      route: useRoute(),
      sortOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
    }),
    sortOptionsList() {
      return this.sortOptions.map((item) => ({
        title: this.$t(item).charAt(0).toUpperCase() + this.$t(item).slice(1),
        value: item,
      }))
    },
    haveItineraries() {
      return this.itineraries.length > 0
    },
    viewText() {
      return this.isShared ? this.$t('Shared_itineraries') : this.$t('My_itineraries')
    },
    viewBackgroundImage() {
      return this.isShared ? sharedItinerariesBannerBg : itinerariesBannerBg
    },
    currentPage() {
      return this.route.params.page
    },
    payloadToLoadItineraries() {
      const currentPage = this.currentPage || 1

      return {
        userID: this.user.id,
        from: (currentPage - 1) * this.size,
        size: this.size,
        isShared: this.isShared,
        sort: this.sorting,
        destination: this.searchQuery
      }
    },
    isShared() {
      return this.route.name === RouteNames.SHARED_ITINERARIES
    },
  },
  watch: {
    'route.params.page': {
      handler() {
        if (this.route.name === RouteNames.ITINERARIES || this.route.name === RouteNames.SHARED_ITINERARIES) {
          this.loadItinerariesData()
        }
      },
      immediate: true
    },
    'route.name': {
      handler() {
        if (this.route.name === RouteNames.ITINERARIES || this.route.name === RouteNames.SHARED_ITINERARIES) {
          this.loadItinerariesData()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      showLoader: 'LOADING_DIALOG',
      getItineraries: 'GET_ITINERARIES',
      getSharedItinerariesRoles: 'GET_SHARED_ITINERARIES_ROLES',
      getSharedStatusesForItineraries: 'GET_SHARED_STATUSES_FOR_ITINERARIES',
      getSortOptions: 'GET_SORT_OPTIONS',
    }),
    onSorting(val) {
      this.sorting = val
      this.changePage(1)
      if (this.route.name === RouteNames.ITINERARIES || this.route.name === RouteNames.SHARED_ITINERARIES) {
        this.loadItinerariesData()
      }
    },
    doSearch() {
      this.changePage(1)
      if (this.route.name === RouteNames.ITINERARIES || this.route.name === RouteNames.SHARED_ITINERARIES) {
        this.loadItinerariesData()
      }
    },
    startButtonClickedHandler(){
      this.changePage(1)
    },
    endButtonClickedHandler(){
      this.changePage(this.pageCount)
    },
    async getItinerariesData(payload) {
      try {
        this.isPageLoading = true
        this.showLoader({
          visible: true,
          opacity: 0.5,
          overlayColor: 'rgb(var(--v-textSecondaryColor))'
        })
        const data = await this.getItineraries(payload)
        if (data) {
          this.itineraries = Array.isArray(data.itineraries) ? data.itineraries : []
          this.pageCount = Math.ceil(data.hits / this.size)
          this.page = Math.ceil(++data.from / this.size)
        }
        this.scrollToTheTop()
      } catch (error) {
        this.itineraries = []
        this.page = 1
        this.pageCount = 1
        logAppError(`${error}: ${payload}`)
      } finally {
        this.isPageLoading = false
        this.showLoader(null)
      }
    },
    async getRolesForSharedItineraries(payload) {
      try {
        const data = await this.getSharedItinerariesRoles(payload)
        if (data && payload.from === this.payloadToLoadItineraries.from) {
          this.roles = data.roles
        }
      } catch (error) {
        this.roles = {}
        logAppError(`${error}: ${payload}`)
      }
    },
    async getSharedStatuses(payload) {
      try {
        const data = await this.getSharedStatusesForItineraries(payload)
        if (data && payload.from === this.payloadToLoadItineraries.from) {
          this.sharedItineraryIds = data
        }
      } catch (error) {
        this.sharedItineraryIds = []
        logAppError(`${error}: ${payload}`)
      }
    },
    loadItinerariesData() {
      if(this.isPageLoading) {
        // it will help to avoid extra API call with equal parameters.
        // It happens when user switches from Itinerarys page (2+ page) to Shared Itineraies page (to the first page by default) and vice vera.
        // Multiple API call happens because two watchers executes at the same time 'route.params.page' and 'route.name'
        return
      }
      const payload = { ...this.payloadToLoadItineraries }
      this.getItinerariesData(payload)
      if (this.isShared) {
        this.getRolesForSharedItineraries(payload)
      } else {
        this.getSharedStatuses(payload)
      }
    },
    changePage(page) {
      this.$router.push({name: this.route.name, params: {page: page}})
    },
    async goToItinerary(id) {
      await this.$router.push({
        name: this.isShared ? RouteNames.SHARED_ITINERARY : RouteNames.ITINERARY,
        params: {
          id: id,
          view: this.user?.settings?.view || itineraryMobileView[0].type
        },
      })
    },
    goToGenerateItinerary() {
      this.$router.push({name: RouteNames.GENERATE_ITINERARY})
    },
    goToCommunity() {
      this.$router.push({name: RouteNames.COMMUNITY})
    },
    reloadItineraries(id) {
      if (this.itineraries.length === this.size) {
        this.$nextTick(() => {
          this.loadItinerariesData()
        })
      } else {
        const existingItineraries = this.itineraries.filter(itinerary => id !== itinerary.header.itineraryID)
        if (existingItineraries.length === 0 && this.page > 1) {
          this.changePage(this.page - 1)
        } else {
          this.itineraries = existingItineraries
        }
      }
    },
  },
  async created() {
    this.sortOptions = await this.getSortOptions()
  }
}
</script>

<style lang="scss" scoped>
  .itineraries-page {
    &-banner {
      min-height: 130px;
      background-size: cover;
      background-position: bottom;
    }
  }

  .search-button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :deep(.search-field .v-field) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sort-select {
    max-width: 300px;
    .v-input__prepend-inner {
      .v-icon {
        width: 24px;
      }
    }
  }
</style>
