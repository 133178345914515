import axios from 'axios'
import moment from 'moment'

import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { logAppError } from '@/logger'

/**
 * take a date/time struct, location and converts to a string according to the timezone of the location passed
 *
 */
const convertDateTimeStructToString = async (dateTimeStruct, placeID) => {
  let rv = ''
  let payload

  if (typeof placeID === 'object') {
    throw new Error('PlaceID is not a String!')
  }

  if (!!dateTimeStruct
    && !!dateTimeStruct.year
    && !!dateTimeStruct.month
    && !!dateTimeStruct.day
    && !!placeID) {
    payload = {
      location: {
        placeID: placeID
      },
      dateTime: dateTimeStruct
    }

    if (payload) {
      try {
        let response = await axios.post(process.env.VUE_APP_STD_DATE_TIME, payload)
        if (response.status === 200) {
          rv = response.data
        }
      } catch (err) {
        logAppError(err)
      }
    }
  }

  return rv
}

/**
 * calculates time based on the dateTimeString provided in the placeID, and hourOffset (if provided)
 * @param {*} dateTimeString -- timestamp ('2023-10-07T10:00:00-07:00')
 * @param {*} placeID -- ex '2-PHNL'
 * @param {*} hourOffset -- number ex 2
 * @returns an array of timestamps
 */
const calculateDateTimeStringOffset = async (dateTimeString, placeID, hourOffset = 0) => {
  let arr = ''
  let payload

  if (typeof placeID === 'object') {
    throw new Error('PlaceID is not a String!')
  }

  if (typeof hourOffset !== 'number') {
    throw new Error('hourOffset is not a Number!')
  }

  if (!!dateTimeString
    && !!placeID) {
    payload = {
      timestamp: dateTimeString,
      offsets: [
        {
          location: {
            placeID: placeID
          },
          hourOffset: hourOffset
        }
      ]
    }

    if (payload) {
      try {
        let response = await axios.post(process.env.VUE_APP_DATE_TIME_OFFSET, payload)
        if (response.status === 200) {
          arr = response.data
        }
      } catch (err) {
        logAppError(err)
      }
    }
  }

  return arr
}

/**
 * takes dateTime ISO string, location
 * returns a string according to the timezone of the location passed
 *
 */
const convertDateTimeISOStringToLocalizedString = async (dateTimeString, placeID) => {
  let rv

  if (typeof dateTimeString !== 'string') {
    throw new Error('dateTimeString expected a string, got ' + typeof dateTimeString)
  }

  if (dateTimeString && placeID) {
    const dateTime = commonAPIs.momentDateTimeToStruct(moment.parseZone(dateTimeString), true)

    rv = convertDateTimeStructToString(dateTime, placeID)
  }

  return rv
}

/**
 *
 * @param {*} startDate string '2023-08-11'
 * @param {*} startTime string '15:43'
 * @param {*} startPlaceID string placeID
 * @param {*} endDate string '2023-08-11'
 * @param {*} endTime string '23:12'
 * @param {*} endPlaceID string placeID
 * @returns Promise<Boolean>
 */
const validateEndDateAfterStart = async (
  startDate, startTime, startPlaceID, endDate, endTime, endPlaceID = startPlaceID
) => {
  let rv = true

  if (!!startPlaceID && !!endPlaceID
    && !!startDate && !!startTime
    && !!endDate && !!endTime) {

    //validates that end time is after start
    const startDateTime = moment(startDate + 'T' + startTime).format('YYYY-MM-DDTHH:mm:ssZ')
    const endDateTime = moment(endDate + 'T' + endTime).format('YYYY-MM-DDTHH:mm:ssZ')
    const calculatedStartDateTime = await convertDateTimeISOStringToLocalizedString(startDateTime, startPlaceID)
    const calculatedEndDateTime = await convertDateTimeISOStringToLocalizedString(endDateTime, endPlaceID)

    if (calculatedStartDateTime && calculatedEndDateTime) {
      rv = moment.parseZone(calculatedEndDateTime).isAfter(moment.parseZone(calculatedStartDateTime))
    }
  }
  return rv
}

/**
 *
 * @param {*} range number of days
 * @param {*} date string | Date
 * @param {*} startDate string | Date
 * @param {*} endDate string | Date
 * @returns Boolean
 *
 * If itinerary start date is 10.12. and end date is 20.12., 01.12 would be also in the 28 days range counting from the end date backwards.
 */
const validateDateInRange = (range, date, startDate, endDate) => {
  const rangeStart = moment.parseZone(endDate).subtract(range, 'days')
  const rangeEnd = moment.parseZone(startDate).add(range, 'days')
  return moment(date).isBetween(rangeStart, rangeEnd, 'day', '[]')
}

/**
 * Converts a date-time input to a date string with (YYYY-MM-DD) format
 * @param {string|Date} dateTime input date-time string or Date object
 * @returns String
  */
const toDate = dateTime => {
  return moment.parseZone(dateTime).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD')
}

/**
 * Calculates an array of dates in (YYYY-MM-DD) format within dates range
 * @param {string|Date} startDateTime start date-time string or date object
 * @param {string|Date} endDateTime end date-time string or date object
 * @returns string[]
 */
const getDatesList = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) {
    return []
  }

  const startDate = toDate(startDateTime)
  const endDate = toDate(endDateTime)
  const diff = moment.parseZone(endDate).diff(moment.parseZone(startDate), 'days')
  if (diff === 0) {
    return [startDate]
  }

  if (diff === 1) {
    return [startDate, endDate]
  }

  const result = new Array(diff + 1)
    .fill(startDate)
    .map((value, i) => moment.parseZone(value).add(i, 'days').format('YYYY-MM-DD'))

  return result
}

const ONE_DAY = 1000 * 60 * 60 * 24

/**
 * Calculates the difference in days between two date-time strings or dates
 * @param {string|Date} start start date-time string or date object
 * @param {string|Date} end end date-time string or date object
 * @returns number
 */
const diffInDays = (start, end) => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const diffInTime = endDate.getTime() - startDate.getTime()
  const diffInDays = Math.round(diffInTime / ONE_DAY)
  return diffInDays
}

export default {
  convertDateTimeStructToString,
  calculateDateTimeStringOffset,
  convertDateTimeISOStringToLocalizedString,
  validateEndDateAfterStart,
  validateDateInRange,
  toDate,
  getDatesList,
  diffInDays
}
