<template>
  <div class="pt-6 pb-sm-2" data-test-name="content-event-segment">
  <v-form ref="form">
    <v-row>
      <v-col cols="12" sm="6">
        <place-input
          :value="departurePlaceID"
          :placeholder="$t('Origin')"
          :label="$t('Origin') + '*'"
          :show-suggestions="true"
          :name="`origin-${index}`"
          :content-class="`origin-${index}`"
          :readonly="isReadOnly"
          :returnPlaceType="getReturnPlaceType"
          @updateLocation="updateDepartureLocation"
          variant="outlined"
          min-width="450"
          data-test-name="input-place-from"
        >
          <template v-slot:icon>
            <v-icon color="primary" class="mr-2">room</v-icon>
          </template>
        </place-input>
      </v-col>

      <v-col cols="12" sm="6">
        <place-input
          :value="arrivalPlaceID"
          :placeholder="$t('Destination')"
          :label="$t('Destination') + '*'"
          :show-suggestions="true"
          :name="`destination-${index}`"
          :content-class="`destination-${index}`"
          :readonly="isReadOnly"
          :returnPlaceType="getReturnPlaceType"
          @updateLocation="updateArrivalLocation"
          variant="outlined"
          min-width="450"
          data-test-name="input-place-to"
        >
          <template v-slot:icon>
            <v-icon color="primary" class="mr-2">room</v-icon>
          </template>
        </place-input>
      </v-col>
    </v-row>

    <place-addresses-row
      v-if="isDriveEvent"
      :value="placeAddresses"
      @update:model-value="updatePlaceAddresses($event)"
      :isReadOnly="isReadOnly" />

    <v-row class="mb-4">
      <v-col cols="6" sm="3" class="py-0 py-sm-3">
        <date-picker
          :model-value="departureDate"
          :label="$t('Departure_date') + '*'"
          min-width="320"
          :disabled="!departurePlaceID"
          :readonly="isReadOnly"
          :name="`segmentDepartureDate-${index}`"
          :rules="departureDateTimeRules"
          @update:model-value="updateDepartureDateTime($event, departureTime)"
          hide-details="auto"
        />
        <span v-if="!departurePlaceID">{{ $t('Pick_departure') }}</span>
      </v-col>
      <v-col cols="6" sm="3" class="py-0 py-sm-3">
        <time-picker
          :model-value="departureTime"
          :label="$t('Departure_time') + '*'"
          :disabled="!departurePlaceID || !departureDate"
          :readonly="isReadOnly"
          :rules="departureDateTimeRules"
          @update:model-value="updateDepartureDateTime(departureDate, $event)"
          hide-details="auto"
        />
        <span v-if="!departurePlaceID">{{ $t('Pick_departure') }}</span>
      </v-col>
      <v-col cols="6" sm="3">
        <date-picker
          :model-value="arrivalDate"
          :min-date="minArrivalDate"
          :disabled="!arrivalPlaceID || !departureDate"
          :readonly="isReadOnly"
          :label="$t('Arrival_date') + '*'"
          min-width="320"
          :name="`segmentArrivalDate-${index}`"
          :rules="arrivalDateRules"
          @update:model-value="updateArrivalDateTime($event, arrivalTime)"
          hide-details="auto"
        />
        <span v-if="!arrivalPlaceID">{{ $t('Pick_arrival') }}</span>
      </v-col>
      <v-col cols="6" sm="3">
        <time-picker
          :model-value="arrivalTime"
          :disabled="!arrivalPlaceID || !arrivalDate"
          :readonly="isReadOnly"
          :label="$t('Arrival_time') + '*'"
          :rules="arrivalTimeRules"
          @update:model-value="updateArrivalDateTime(arrivalDate, $event)"
          hide-details="auto"
        />
        <span v-if="!arrivalPlaceID">{{ $t('Pick_arrival') }}</span>
      </v-col>
    </v-row>

    <v-row class="mb-4" v-if="!isDriveEvent">
      <v-col cols="6" sm="4" class="py-0 pt-sm-3">
        <component
          :is="eventType"
          :value="carrier?.carrier"
          :isReadOnly="isReadOnly"
          :label="carrierTitle"
          variant="outlined"
          min-width="450"
          @update:model-value="updateCarrier"
          class="pa-0 ma-0"
        >
        </component>
      </v-col>
      <v-col cols="6" sm="2" class="py-0 pt-sm-3">
        <v-text-field
          :model-value="carrier?.number"
          :label="routeNumberTitle"
          :readonly="isReadOnly"
          variant="outlined"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          maxlength="20"
          data-test-name="input-route-number"
          @update:model-value="updateRouteNumber"
        />
      </v-col>
      <v-col cols="6" sm="4" class="py-0 pt-sm-3">
        <v-select
          :model-value="seatClass"
          :items="seatClassList"
          :label="$t('Seat_class')"
          :readonly="isReadOnly"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          variant="outlined"
          hide-details
          data-test-name="select-seat-class"
          @update:model-value="updateSeatClass"
        />
      </v-col>
      <v-col cols="6" sm="2" class="py-0 pt-sm-3">
        <v-text-field
          :model-value="seats"
          :label="$t('Seats')"
          :readonly="isReadOnly"
          class="pa-0 ma-0"
          variant="outlined"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          hide-details
          maxlength="80"
          data-test-name="input-seats"
          @update:model-value="updateSeats"
        />
      </v-col>
      <v-col cols="12" class="mt-4 mt-sm-0 d-flex justify-center">
        <span
          v-if="segmentDuration"
          class="font-weight-medium text-caption text-sm-subtitle-1"
          data-test-name="text-segment-duration"
        >
          {{ $t('Duration_segment') + ': ' + segmentDuration }}
        </span>
      </v-col>
    </v-row>
    <v-row class="mb-4"
           v-if="!isReadOnly && segmentsCount > 1"
    >
      <v-col
        cols="12"
        class="pt-2 pt-sm-0 pb-2 d-flex justify-center justify-sm-end"
      >
        <v-btn
          color="secondary"
          variant="text"
          rounded
          size="small"
          prepend-icon="$customDelete"
          data-test-name="button-delete-segment"
          @click="$emit('delete-segment', index)"
        >
          {{ $t('Delete_Segment') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isLastSegment" class="mx-0 mb-4 bg-indigo-lighten-5 rounded-lg">
      <v-col
        v-if="layoverDuration"
        cols="12"
        class="py-1 d-flex justify-center font-weight-medium text-caption text-sm-subtitle-1"
        data-test-name="text-layover-duration"
      >
        {{ $t('Layover_duration') + ': ' + layoverDuration }}
      </v-col>

      </v-row>
    </v-form>
  </div>
</template>

<script>
import AirlineInputSelector from '@/components/selector/AirlineInputSelector'
import DatePicker from '@/components/DatePicker'
import TimePicker from '@/components/TimePicker'
import PlaceInput from '@/components/PlaceInput'
import { EventTypes, flightClasses, getItemTypeByType } from '@/helpers'
import datetime from '@/helpers/datetime'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import TransportationCompanySelector from '@/components/selector/TransportationCompanySelector.vue'
import PlaceAddressesRow from '@/components/PlaceAddressesRow.vue'

export default {
  name: 'EventSegment',
  emits: [
    'update:model-value',
    'update:departure-location',
    'update:arrival-location',
    'delete-segment',
    'validate-segment',
    'validate-all-segments'
  ],
  components: {
    DatePicker,
    PlaceInput,
    TimePicker,
    [EventTypes.FLIGHT]:    AirlineInputSelector,
    [EventTypes.BUS]:       TransportationCompanySelector,
    [EventTypes.FERRY]:     TransportationCompanySelector,
    [EventTypes.TRAIN]:     TransportationCompanySelector,
    [EventTypes.TRANSFER]:  TransportationCompanySelector,
    PlaceAddressesRow,
  },
  data() {
    return {
      minArrivalDateTime: '',
      eventItem: null,
      dateTimeValidationResult: true,
      crossSegmentValidationResult: true,
      rules: {
        dateTime: () => this.dateTimeValidationResult || this.$t('Before_Start_Error'),
        crossSegmentDateTime: () => this.crossSegmentValidationResult || this.$t('Segment_Before_Start_Error')
      }
    }
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0
    },
    segmentsCount: {
      type: Number,
      default: 0,
    },
    //will contain event durations as an array with element for each segment
    eventDuration: {
      type: Object,
    },
    isReadOnly: {
      type: Boolean
    },
    eventType: {
      type: String,
      required: true,
    },
    minDateTime: String
  },
  mounted() {
    this.eventItem = getItemTypeByType(this.eventType)
    this.minArrivalDateTime = this.segment?.departure?.at ? this.$moment.parseZone(this.segment?.departure?.at).format('YYYY-MM-DD HH:mm') : ''
  },
  computed: {
    segment() {
      return this.modelValue
    },
    departurePlaceID() {
      return this.segment?.departure?.location?.placeID
    },
    arrivalPlaceID() {
      return this.segment?.arrival?.location?.placeID
    },
    departureDate() {
      return this.segment?.departure?.at
        ? this.$moment.parseZone(this.segment?.departure?.at).format('YYYY-MM-DD')
        : ''
    },
    departureTime() {
      return this.segment?.departure?.at ? this.$moment.parseZone(this.segment?.departure?.at).format('HH:mm') : ''
    },
    arrivalDate() {
      return this.segment?.arrival?.at
        ? this.$moment.parseZone(this.segment?.arrival?.at).format('YYYY-MM-DD')
        : ''
    },
    arrivalTime() {
      return this.segment?.arrival?.at ? this.$moment.parseZone(this.segment?.arrival?.at).format('HH:mm') : ''
    },
    placeAddresses() {
      return {
        fromPlace: this.segment?.departure?.placeName,
        fromAddress: this.segment?.departure?.address,
        toPlace: this.segment?.arrival?.placeName,
        toAddress: this.segment?.arrival?.address,
      }
    },
    carrier() {
      return this.segment?.carrier || {}
    },
    carrierTitle() {
      let title = ''
      switch (this.eventType) {
      case EventTypes.FLIGHT:
        title = this.$t('Airline_name')
        break
      case EventTypes.BUS:
        title = this.$t('Bus_company')
        break
      case EventTypes.FERRY:
        title = this.$t('Ferry_company')
        break
      case EventTypes.TRAIN:
        title = this.$t('Train_company')
        break
      case EventTypes.TRANSFER:
        title = this.$t('Transfer_company')
        break
      default:
        title = this.$t('Transport_company')
      }
      return title
    },
    routeNumberTitle() {
      let title = ''
      switch (this.eventType) {
      case EventTypes.FLIGHT:
        title = this.$t('Flight_number')
        break
      case EventTypes.BUS:
        title = this.$t('Bus_number')
        break
      case EventTypes.FERRY:
        title = this.$t('Ferry_number')
        break
      case EventTypes.TRAIN:
        title = this.$t('Train_number')
        break
      default:
        title = this.$t('Route_number')
      }
      return title
    },
    seatClass() {
      return this.segment?.seatClass
    },
    segmentDuration() {
      return this.eventDuration?.segments[this.index]
    },
    layoverDuration() {
      return this.eventDuration?.layovers[this.index]
    },
    seatClassList() {
      return flightClasses.map(el => ({...el, ...{title: this.$t(el.text)}}))
    },
    seats() {
      return this.segment.seats
    },
    currentDate() {
      return this.$moment(Date.now()).format('YYYY-MM-DD')
    },
    minDepartureDate() {
      return this.$moment.parseZone(this.currentDate).format('YYYY-MM-DD HH:mm')
    },
    minArrivalDate() {
      return this.minArrivalDateTime
    },
    getReturnPlaceType() {
      return this.eventType === EventTypes.FLIGHT ? 'airport' : 'city'
    },
    isSingleSegment() {
      return this.segmentsCount === 1
    },
    isFirstSegment() {
      return this.index === 0
    },
    isLastSegment() {
      return this.index === this.segmentsCount - 1
    },
    isDriveEvent() {
      return this.eventType === EventTypes.DRIVE
    },
    departureDateTimeRules() {
      return this.departurePlaceID && !this.isSingleSegment ? [this.rules.crossSegmentDateTime] : []
    },
    arrivalDateRules() {
      if (this.arrivalPlaceID && this.arrivalDate !== this.departureDate) {
        return [this.rules.dateTime]
      }
      return []
    },
    arrivalTimeRules() {
      return this.arrivalPlaceID ? [this.rules.dateTime] : []
    },
    hasAllRequiredValues() {
      return (
        !!this.departurePlaceID &&
        !!this.arrivalPlaceID &&
        !!this.departureDate &&
        !!this.departureTime &&
        !!this.arrivalDate &&
        !!this.arrivalTime
      )
    },
  },
  methods: {
    updateDepartureLocation(location) {
      this.segment.departure = {
        ...this.segment.departure,
        location: location
      }

      this.updateModelValue()

      if (!this.isSingleSegment && !this.isFirstSegment) {
        this.$emit('update:arrival-location', this.index - 1, location)
      }
    },
    updateArrivalLocation(location) {
      this.segment.arrival = {
        ...this.segment.arrival,
        location: location
      }

      this.updateModelValue()

      if (!this.isSingleSegment && !this.isLastSegment) {
        this.$emit('update:departure-location', this.index + 1, location)
      }
    },
    async updatePlaceAddresses(addresses) {
      this.segment.departure = {
        ...this.segment.departure,
        placeName: addresses.fromPlace,
        address: addresses.fromAddress,
      }
      this.segment.arrival = {
        ...this.segment.arrival,
        placeName: addresses.toPlace,
        address: addresses.toAddress,
      }

      this.updateModelValue()
    },
    async updateDepartureDateTime(date, time) {
      //date input in format 'YYYY-MM-DD'
      //time input in format 'HH:mm'
      time = time || '10:00'
      this.segment.departure.at = await datetime.convertDateTimeStructToString(
        commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(date + 'T' + time)),
        this.departurePlaceID
      )
      this.minArrivalDateTime = await datetime.convertDateTimeISOStringToLocalizedString(this.segment.departure.at, this.departurePlaceID)

      this.updateModelValue()
    },
    async updateArrivalDateTime(date, time) {
      //date input in format 'YYYY-MM-DD'
      //time input in format 'HH:mm'
      const defaultDuration = this.eventItem?.defaultDuration || 120
      if (!time) {
        time = this.$moment(this.departureTime, 'HH:mm').add(defaultDuration, 'minutes').format('HH:mm')
      }

      this.segment.arrival.at = await datetime.convertDateTimeStructToString(
        commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(date + 'T' + time)),
        this.arrivalPlaceID
      )

      this.updateModelValue()
    },
    updateModelValue() {
      this.$emit('update:model-value', this.segment)
    },
    updateRouteNumber(value) {
      this.segment.carrier = {
        carrier: this.segment?.carrier?.carrier,
        number: value
      }
      this.$emit('update:model-value', this.segment)
    },
    updateCarrier(value) {
      this.segment.carrier = {
        carrier: value,
        number: this.segment?.carrier?.number
      }
      this.$emit('update:model-value', this.segment)
    },
    updateSeatClass(value) {
      this.segment.seatClass = value
      this.$emit('update:model-value', this.segment)
    },
    updateSeats(value) {
      this.segment.seats = value
      this.$emit('update:model-value', this.segment)
    },
    async validateSegmentAsync() {
      const isValid = await datetime.validateEndDateAfterStart(
        this.departureDate,
        this.departureTime,
        this.departurePlaceID,
        this.arrivalDate,
        this.arrivalTime,
        this.arrivalPlaceID,
      )

      this.dateTimeValidationResult = isValid
    },
    async validateCrossSegmentAsync() {
      const isValid = await datetime.validateEndDateAfterStart(
        this.$moment.parseZone(this.minDateTime).format('YYYY-MM-DD'),
        this.$moment.parseZone(this.minDateTime).format('HH:mm'),
        this.departurePlaceID,
        this.departureDate,
        this.departureTime,
        this.departurePlaceID,
      )

      this.crossSegmentValidationResult = isValid
    },
    async validate() {
      if (!this.hasAllRequiredValues) {
        this.$emit('validate-segment', false, this.index)
        return
      }

      await this.validateSegmentAsync()
      if (!this.isSingleSegment && !this.isFirstSegment) {
        await this.validateCrossSegmentAsync()
      }

      const { valid } = await this.$refs.form.validate()
      this.$emit('validate-segment', valid, this.index)
    },
  },
  watch: {
    segment: {
      async handler() {
        if (!this.isSingleSegment) {
          this.$emit('validate-all-segments')
          return
        }

        await this.validate()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
