<template>
  <vue-date-picker
    :model-value="dateValue"
    :range="rangeValue"
    :month-change-on-scroll="false"
    :auto-apply="true"
    :clearable="clearable"
    :enable-time-picker="false"
    :format="pickerFormat"
    :disabled="disabled"
    :readonly="readonly"
    :teleport="teleport"
    @update:model-value="updateDate"
    data-test-name="date-picker"
  >
    <template #dp-input="{ value, onInput }">
      <v-text-field
        :model-value="value"
        :variant="variant"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        :disabled="disabled"
        :class="{ activator: !readonly }"
        readonly
        :density="density"
        @update:model-value="onInput"
        class="mt-3"
        :prepend-inner-icon="prependInnerIcon"
        color="primary"
        base-color="primary"
        bg-color="#fff"
        hide-details="auto"
        data-test-name="input"
      />
    </template>
  </vue-date-picker>
</template>

<script>
import { mapGetters } from 'vuex'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'DatePicker',
  emits: ['update:model-value'],
  components: { VueDatePicker },
  date() {
    return {
      dateValue: '',
      rangeValue: false,
    }
  },
  props: {
    modelValue: {
      type: [String, Array, Date],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'outlined',
    },
    disabled: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    teleport: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: 'default',
    },
    range: {
      type: Boolean,
      default: false
    },
    prependInnerIcon: {
      type: String,
      default: '$customCalendar'
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  created() {
    this.setDateFromModel(this.modelValue)
    this.rangeValue = this.range
  },
  methods: {
    setDateFromModel(val) {
      this.dateValue = ''
      if (val !== 'Invalid date') {
        if (val && !Array.isArray(val)) {
          this.dateValue = this.$moment(val).set('hour', 0).set('minute', 0).format('YYYY-MM-DD HH:mm')
        } else if (val && Array.isArray(val) && val.length > 1) {
          let rv = []
          rv.push(this.$moment(val[0]).set('hour', 0).set('minute', 0).format('YYYY-MM-DD HH:mm'))
          rv.push(this.$moment(val[1]).set('hour', 0).set('minute', 0).format('YYYY-MM-DD HH:mm'))
          this.dateValue = rv
        }
      }
    },
    updateDate(val) {
      this.$emit('update:model-value', this.range
        ? [
          this.$moment.parseZone(val[0]).set('hour', 0).set('minute', 0).format('YYYY-MM-DD'),
          this.$moment.parseZone(val[1]).set('hour', 0).set('minute', 0).format('YYYY-MM-DD')
        ]
        : this.$moment.parseZone(val).set('hour', 0).set('minute', 0).format('YYYY-MM-DD'))
    }
  },
  computed: {
    ...mapGetters({
      dateFormat: 'DATE_FORMAT',
    }),
    pickerFormat() {
      //this conversion is needed as picker takes format in a different way:
      //lower case dd and yyyy, upper case MM
      let rv = 'MM-dd-yyyy'
      if (this.dateFormat === 'DD-MM-YYYY') {
        rv = 'dd-MM-yyyy'
      }

      return rv
    }
  },
  watch: {
    modelValue: {
      handler(val) {
        this.setDateFromModel(val)
      },
      immediate: true
    },
    range: {
      handler(newVal) {
        this.rangeValue = newVal
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.dp__input_wrap) {
  box-sizing: border-box;
}
:deep(.v-text-field input) {
  --v-disabled-opacity: 1;
}
</style>
