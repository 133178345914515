import { EventTypes, itemTypeLookup, CATEGORY_TRANSPORTATION } from '@/helpers/itemTypes'
import placeSearch from '../placeSearch'

export const getFullName = async (record) =>{
  let fullName = ''
  const event = itemTypeLookup(record)

  switch (event.category) {
  case CATEGORY_TRANSPORTATION: {
    let segments = record[event?.type]?.segments || []
    let departureSegment = segments[0]
    let arrivalSegment = segments[segments.length - 1]
    const places = await placeSearch.lookupPlacesByIds([departureSegment.departure.location.placeID, arrivalSegment.arrival.location.placeID])
    let departureLocation = places[0]
    let arrivalLocation = places[1]

    fullName = placeName(departureLocation)
      + (event.type === EventTypes.FLIGHT
      && departureSegment?.departure?.location?.iataCode
        ? '(' + departureSegment?.departure?.location?.iataCode + ')' : '')
        + ' - '
        + placeName(arrivalLocation)
      + (event.type === EventTypes.FLIGHT
      && arrivalSegment?.arrival?.location?.iataCode
        ? '(' + arrivalSegment?.arrival?.location?.iataCode + ')' : '')

    break
  }
  default:
    fullName = record[event.type].title
    break
  }
  return fullName
}

export const placeName = (place, includeCountry = true) => {
  let rv
  if (place) {
    const state = place.isoCountry === 'US' ? place.isoRegion?.substring(3) : ''
    const country = (includeCountry ? place.isoCountry : '')
    rv = place.city + ', ' + (place.isoCountry === 'US' ? state + '-' + country : country)
  }

  return rv
}