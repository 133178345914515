<template>
  <div>
    <v-dialog
      :model-value="isEventDialogOpened"
      @update:model-value="handleEventDialogChange"
      :fullscreen="isMobile"
      max-width="75rem"
      @click:outside="$refs.eventDialog.cancelChanges()"
      scrollable
    >
      <event-dialog
        :isOpened="isEventDialogOpened"
        :event="getSelectedEvent"
        :eventStartDateTime="clickedDateTime"
        @checkFormState="isDirty = $event.dirty"
        @cancel="cancelEventDialogChanges"
        @close="closeEventDialog"
        ref="eventDialog"
      />
    </v-dialog>
    <v-menu
      :model-value="showEventsMenu"
      @update:model-value="$emit('update:showEventsMenu', $event)"
      :scrim="true"
      scroll-strategy="close"
    >
      <template #activator="{ props }">
        <v-btn
          :title="$t('Add_event')"
          :width="isMobile? 'calc(100vw - 1rem)' : 245"
          data-test-name="button-add-event"
          v-bind="props"
          color="primary"
          height="48"
          rounded
        >
          {{ $t('Add_event') }}
        </v-btn>
      </template>
      <v-list>
        <div v-for="(item, index) in eventsMenuItems" :key="index">
          <v-list-item
            :value="index"
            :style="'color:' + item.color"
            :disabled="item.disabled"
            :prepend-icon="item.icon"
            :text="item.title"
            @click="handleEventChange(item)"
            class="pl-4 px-2 font-weight-bold"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-divider v-if="item.groupDivider" />
        </div>
      </v-list>
    </v-menu>
    <common-popup
      v-if="confirmationPopupVisible"
      :body-content="$t('Question_to_discard_changes')"
      :confirm-label="$t('Discard')"
      :cancel-label="$t('Keep_editing')"
      data-test-name="popup-confirmation"
      @confirm="closeConfirmationPopup(true)"
      @cancel="closeConfirmationPopup"
      @close="closeConfirmationPopup"
    />
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { mapGetters } from 'vuex'
import EventDialog from '@/components/calendar/EventDialog'
import { itemTypes, EventTypes } from '@/helpers/itemTypes'
import commonPopup from '@/components/popups/CommonPopup.vue'

export default {
  name: 'AddRowDialog',
  emits: ['update:showEventsMenu', 'update:dialog-opened'],
  components: {
    commonPopup,
    EventDialog,
  },
  data() {
    return {
      isEventDialogOpened: false,
      selectedEvent: {},
      isDirty: false,
      confirmationPopupVisible: false,
    }
  },
  props: {
    showEventsMenu: {
      type: Boolean,
      default: false,
    },
    clickedDateTime: {
      type: String,
    }
  },
  computed: {
    ...mapGetters({
      currentItinerary: 'CURRENT_ITINERARY',
      user: 'USER',
      isEditor: 'IS_EDITOR',
      isMobile: 'IS_MOBILE',
    }),
    isInsuranceEventExists() {
      return this.currentItinerary?.tripProtection !== undefined
    },
    eventsMenuItems() {
      return itemTypes
        .filter((type) => type.addManually)
        .map((event) => ({
          ...event,
          title: event.label,
          value: event.type,
          disabled:
            event.type === EventTypes.INSURANCE && this.isInsuranceEventExists,
        }))
    },
    getSelectedEvent() {
      return markRaw(this.selectedEvent)
    },
  },
  methods: {
    handleEventDialogChange() {
      this.cancelEventDialogChanges()
    },
    openEventDialog() {
      this.isEventDialogOpened = true
      this.$emit('update:dialog-opened', true)
    },
    closeEventDialog() {
      this.isEventDialogOpened = false
      this.$emit('update:dialog-opened', false)
    },
    cancelEventDialogChanges() {
      if (this.isDirty) {
        this.openConfirmationPopup()
      } else {
        this.closeEventDialog()
      }
    },
    openConfirmationPopup() {
      this.confirmationPopupVisible = true
    },
    closeConfirmationPopup(forceClose = false) {
      if (forceClose) {
        this.closeEventDialog()
      }

      this.confirmationPopupVisible = false
      this.isDirty = false
    },
    handleEventChange(event) {
      this.selectedEvent = event
      this.openEventDialog()
    },
  },
}
</script>
