import uiUtils from '../uiUtils'
import commonAPIs from './commonAPIs'
import { EventTypes } from '../itemTypes'
import { store } from '../../store'

export const ATTRACTION_TIME = {startTime: {hour: 9, minute: 0}, duration: {hours:2, minutes: 0}}
export const EVENT_TIMES = [ATTRACTION_TIME]

export default {
  /**
    payload = {
      location: {
        placeID: String
      },
      startDateTime: {year: Number, month: Number, day: Number, hour: Number, minute: Number}
      endDateTime: {year: Number, month: Number, day: Number, hour: Number, minute: Number}
      size: Number of results expected
      keywords: String
      userID: String
    }
   */
  async search(payload) {
    let rv = []
    try {
      rv = await store.dispatch('SEARCH_ATTRACTIONS', payload)
    } catch (error) {
      rv = []
    }
    return rv
  },

  /**
    autoFill will fetch and add restaurants to the ititnerary provided:
      -locationsAndDates is an array:
      [
        { placeID, arrivalDateTime, departureDateTime },
        { placeID, arrivalDateTime, departureDateTime }
        ...
      ]
      -placeID: String
      -arrivalDateTime:   { year: Number, month: Number, day: Number, hour: Number, minute: Number }
      -departureDateTime: { year: Number, month: Number, day: Number, hour: Number, minute: Number }
   */
  async autoFill({ locationsAndDates, itineraryID, userID, travelers, routes }) {
    uiUtils.loadingDialog(EventTypes.ATTRACTION)

    let events = await commonAPIs.autoFillEvents(
      locationsAndDates,
      EVENT_TIMES,
      async (placeID, startDateTime, endDateTime, size) => {
        return await this.search({
          location: {
            placeID
          },
          //this time doen't matter as it will be changed at add
          startDateTime,
          endDateTime,
          size,
          userID,
          travelers,
        })
      },
      routes
    )

    if (events && events.length > 0) {
      await commonAPIs.addItems(itineraryID, userID, events)
    }

    uiUtils.loadingDialog()
  },
}