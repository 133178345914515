<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="0"
      :color="hover ? 'rgba(1, 68, 199, 0.1)': 'transparent'"
      style="cursor: pointer"
      class="pa-4 mb-2 rounded-lg"
      @click="onCardClick"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          class="mb-3"
          >
          <v-img
            class="rounded-lg"
            :src="imgUrl"
            :aspect-ratio="16/9"
            data-test-name="image"
            @error="handleImageError"
            cover
          >
          </v-img>
        </v-col>
        <v-col cols="12">
          <slot name="credentials"></slot>
        </v-col>
        <v-col cols="10">
          <h3 class="mb-3 text-left font-weight-bold card-title" data-test-name="text-title">
            {{ title }}
          </h3>
        </v-col>
        <v-col cols="2" class="mr-auto d-flex justify-end">
          <thumb-up-votes
            @toggleLike="onLike"
            :count="likes"
            :is-user-voted="isLiked"
            :isReadOnly="isAnonymous"
            class="pr-2 rounded"
            variant="text"
          />
        </v-col>
        <v-col
          v-if="isDividerVisible"
          cols="12"
          class="mb-2"
        >
        </v-col>
        <v-divider class="text-primary" />
        <v-row v-if="$vuetify.display.smAndUp" no-gutters class="mt-3 mb-1">
          <v-col class="d-flex flex-wrap">
            <span
              v-for="(tag, index) in tags"
              :key="index"
              data-test-name="text-tag"
              class="decorator text-primary text-overline"
            >
              {{ tag }}
            </span>
          </v-col>
        </v-row>
        <v-col cols="12" class="d-flex justify-space-between">
          <article
            data-test-name="article"
            v-html="description"
            class="pt-2 pt-sm-0 text-left text-body-2 font-weight-medium card-description text-textPrimaryColor"
          />
          <span class="pl-6 mt-2">
            <slot name="actions" />
          </span>
        </v-col>

      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import ThumbUpVotes from '@/components/ThumbUpVotes.vue'
import { toggleLike } from '@/helpers/templates'
import { mapGetters } from 'vuex'

export default {
  name: 'CommonCard',
  components: { ThumbUpVotes },
  emits: ['selectTag', 'select', 'imageError'],
  props: {
    imgUrl: String,
    templateID: String,
    likes: Number,
    isLiked: Boolean,
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
    },
    tags: Array,
    tagsDisabled: {
      type: Boolean,
      default: false,
    },
    isDividerVisible: {
      type: Boolean,
      default: false
    },
    selectedLabel: String,
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isAnonymous: 'IS_ANONYMOUS',
    }),
    selectedLabelId() {
      return this.tags?.findIndex(el => el === this.selectedLabel)
    },
    isVotesSlotFilled() {
      return !!this.$slots['votes']
    }
  },
  methods: {
    onSelectTag(tagId) {
      const tag = this.tags[tagId]
      this.$emit('selectTag', tag)
    },
    onCardClick() {
      this.$emit('select')
    },
    handleImageError() {
      this.$emit('imageError')
    },
    async onLike() {
      await toggleLike(this.templateID, this.user?.id)
    }
  }
}
</script>

<style lang="scss">
.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 44px;

  * {
    // https://stackoverflow.com/a/72170897/12369029
    display: inline;
    background-color: transparent !important;
    font-size: inherit !important;
    font-family: inherit !important;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    max-height: 50px;
    -webkit-line-clamp: 2;
  }
}

.v-chip--disabled {
  opacity: 1;
}

.likes-wrapper {
  height: 100%;
  & > div {
    background: rgba(255,255,255,0.9)
  }
}

.decorator::after {
  content: "\2022";
  margin: 0 5px;
}

.decorator:last-child::after {
  content: none;
}

</style>
