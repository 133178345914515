<template>
  <div>
    <button-selector
      :text="label"
      :icon="icon"
      :isChecked="!isMenuOn"
      id="options-selector-radio-activator"
      :variant="variant"
    />
    <v-menu
      activator="#options-selector-radio-activator"
      :close-on-content-click="true"
      :model-value="isMenuOn"
      @update:model-value="isMenuOn = $event"
      data-test-name="menu"
    >
      <v-card
        class="pa-4 pt-6 bg-white"
        style="min-height: 250px"
        width="280"
      >
      <v-row>
        <v-col cols="12" class="py-0">
          <v-row>
            <v-col
              v-for="item in options"
              :key="item.type"
              cols="12"
              class="pa-0">
              <v-radio-group
                :model-value="selectedOptions"
                @update:model-value="onChange"
                true-icon="mdi-radiobox-marked"
                false-icon="mdi-radiobox-blank"
                hide-details
                class="pt-0"
                color="primary"
              >
                <v-radio
                  :value="item.type"
                  :label="item.label"
                  :disabled="disabled"
                  data-test-name="input-option"
                  class="mt-0"
                  color="primary"
                  hide-details
                >
                  <template v-slot:label>
                    <v-icon
                      :color="item.color"
                      class="mr-2"
                      size="18"
                      :icon="item.icon"
                      data-test-name="icon"
                    />
                    {{ item.label }}
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import ButtonSelector from '@/components/buttons/ButtonSelector'

export default {
  name: 'OptionsSelectorRadio',
  components: {
    ButtonSelector,
  },
  emits: ['input'],
  data() {
    return {
      isMenuOn: false,
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    selectedOptions() {
      return Object.keys(this.value).find(el => this.value[el])
    },
  },
  methods: {
    onChange(selectedOption) {
      const optionsCopy = {...this.value}
      Object.keys(optionsCopy).forEach(el => {
        optionsCopy[el] = el === selectedOption
      })
      this.$emit('input', optionsCopy)
    },
  },
}
</script>
