<template>
  <div data-test-name="page-privacy">
    <v-col cols="12" lg="6" xl="8" class="mx-auto">
      <v-sheet class="py-4 py-sm-14 text-body-2 text-textPrimaryColor text-wrap">
        <h2
          class="pb-4 text-h4 text-sm-h3 font-weight-medium mt-4 text-center"
          data-test-name="title"
        >
          Privacy Policy
        </h2>
        <p
          class="mb-6 mb-sm-10 text-center text-date"
          data-test-name="text-last-modified"
        >
          Last modified: March 18, 2022
        </p>
        <p data-test-name="text-about-policy">At Intelligent Travel Systems we strive to provide the best possible travel planning experience to our rapidly growing community. This policy describes Intelligent Travel Systems practices regarding personally identifiable information and data (“Personal Information"). This policy applies to our website, email and text messages, and social media accounts (the “Platforms"). When you use the Platforms, you agree to the terms in this policy.</p>
        <h4 data-test-name="text-subtitle-policy-outlines" class="mb-2 font-weight-medium">Our Privacy Policy outlines:</h4>
        <ul>
          <li>what information we collect and why we collect it</li>
          <li>how we use that information</li>
          <li class="mb-3">the choices we offer, including how to access and update information</li>
        </ul>
        <p></p>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-categories"
        >
          Categories of Personal Information
        </h3>
        <div data-test-name="text-categories">
          <p>When you visit the Platforms, we may collect information from you. Some of it may be personal information. This information can include search history, IP address, screen resolution, browser used, operating system and settings, access times, and referring URL. If you are using a mobile device, we may also collect data that identifies your device, your settings, and your location.</p>
          <p>If you plan a trip on Intelligent Travel Systems, we will collect your Travel Information to create and maintain your itineraries. If you comment on an itinerary created by another Intelligent Travel Systems user, we will collect and display your comment with that itinerary.</p>
          <p>If you choose to register and create an account, we will collect your email address, password, address, and preference information you provide us.</p>
          <p>When you give us your personal information, you are telling us that the information is true, accurate, complete, and current. You are also telling us that you have the authorization to provide it to us.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-how-we-collect"
        >
          How We Collect Your Personal Information
        </h3>
        <div data-test-name="text-how-we-collect">
          <p>We collect your personal information directly from you, e.g., when you register for an account or when you enter travel information when you plan or book travel.</p>
          <p>We may collect your personal information from third parties, e.g., when you log in using a third-party platform such as Facebook.</p>
          <p>Platform features may make use of your device attributes and settings that will allow us to determine your physical location. Such technologies may include IP address mapping, WIFI, GPS signals, cell tower positioning, or other technologies. In the future, we may use this information to enhance and personalize your experience and provide you with offers and services that may be of interest to you. For example, we may help you find your closest hotel or send you messages that may be of interest to you based on your current location.</p>
          <p>We may use automated Google Analytics Advertising Features that anonymously help identify users most interested in our offering. We do not use first-party cookies (such as the Google Analytics cookies) or other identifiers ourselves. You can opt-out of the Google Analytics Advertising Features through Ads Settings, Ad Settings for mobile apps, or any other available means. </p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-why-we-use"
        >
          Why We Use Your Personal Information
        </h3>
        <div data-test-name="text-why-we-use">
          <h4 class="mb-2 font-weight-medium">We use your Personal Information:</h4>
          <ul>
            <li>to provide you our services</li>
            <li>to display more relevant and personalized recommendations or to suppress content that you might find irrelevant</li>
            <li>to process your bookings and store related information where applicable</li>
            <li>to connect you with travel providers per your direction</li>
            <li>to better understand the users of our Platforms and Services and to improve our Platforms and Services</li>
            <li class="mb-3">to protect our property and to prevent damage to our Platforms and Services</li>
          </ul>
          <h4 class="mb-2 font-weight-medium">We also use your Personal Information to contact you, including as follows:</h4>
          <ul>
            <li>when you send or save booking confirmations in our Services</li>
            <li>to get in touch if you contact Intelligent Travel Systems customer support</li>
            <li>to send you administrative and other non-marketing emails</li>
            <li>to solicit feedback</li>
          </ul>
        </div>
        <p></p>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-how-we-share"
        >
          How We Share Your Personal Information
        </h3>
        <div data-test-name="text-how-we-share">
          <p>We may share your personal information with our corporate affiliates and with third parties for our legitimate business purposes as permitted by law. For example, we may share your personal information with suppliers who perform services on our behalf and have agreed in writing to protect and not further disclose your information.</p>
          <p>If you book a trip through one of our booking partners, we may share relevant personal information with those partners, as directed by you, so that they can process your booking without you needing to enter all your personal and travel information again. This can include travel agencies, hotels, airlines, car rental companies, and travel insurance providers. These third parties will process your personal information as data controllers in accordance with their own privacy policies.</p>
          <p>If you create itineraries as part of our service, you can grant access to your itinerary to anyone you choose. Your itinerary may contain enough details (for example, booking reference codes) to allow the recipient to cancel or modify your booking, perform a check-in, etc. You should only share your itinerary with people you trust. If you choose to display your itinerary through social networks like Facebook, that information may be collected and used by others.</p>
          <p>If you make your trip public on Intelligent Travel Systems, you authorize us to publish it on all our Platforms under the screen name you provided.</p>
          <p>We may disclose your personal information if required by law, for example to law enforcement or other authorities. This includes court orders, subpoenas and orders arising from legal processes, and administrative or criminal investigations. We may also disclose your personal information if the disclosure is necessary for the prevention, detection, or prosecution of criminal acts or to prevent other damage, or in response to a legal action or to enforce our rights and claims.</p>
          <p>We may also share anonymous aggregated usage information with others.</p>
          <p>If Intelligent Travel Systems itself (or part of its business) is sold or otherwise changes control, owners would have access to your personal information for the uses set out herein.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-how-we-store"
        >
          How We Store and Protect Your Personal Information
        </h3>
        <div data-test-name="text-subtitle-how-we-store">
          <p>Our servers and data centers are located in the U.S., and our service providers may be located there and in other countries. By providing us personal information, you agree that your personal information may be transferred to and stored in these countries. These countries may have different and/or less stringent privacy/data protection and data security rules than those of your own country. As a result, your personal information may be subject to access requests from governments, courts, or law enforcement in those countries according to laws in those countries.</p>
          <p>Intelligent Travel Systems has a security program intended to keep the personal information stored in our systems protected from unauthorized access and misuse. Our systems are configured with data encryption, or scrambling technologies and firewalls constructed to industry standards. We also use Secure Socket Layer (SSL) technology that protects the data you send over the Internet. Personal information may only be accessed by persons within our organizations or our partners to carry out the uses indicated in this privacy policy. We do not store any payment information (such as credit card information).</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-accessing"
        >
          Accessing and rectifying your Personal Information
        </h3>
        <div data-test-name="text-accessing">
          <p>You can manage your account settings on the Setting and Preferences page on Intelligent Travel Systems. You can request to delete your account at any time.</p>
          <p>You are entitled at any time to obtain information about all your Personal Information that we store, in accordance with applicable law and without any fee. However, in exceptional cases we ask you to pay a reasonable fee before we provide the information, if permitted by applicable law. We may request proof of your identity before providing this information. We may not be able to allow you to access certain personal information in some cases, e.g., if your Personal Information is connected with personal information of other persons, or for legal reasons. In such cases we will provide you with an explanation why you cannot obtain this information.</p>
          <p>You can also withdraw your consent to the use of your Personal Information at any time by contacting us at the address below. You can demand deletion or rectification of your Personal Information. However, statutory provisions, especially those affecting Intelligent Travel Systems’ accounting, processing of claims, and mandatory data retention, may prohibit deletion or anonymization.</p>
          <p>To exercise your rights, or if you have questions about our privacy practices or need assistance with exercising or understanding your privacy choices, please <router-link :to="{name: CONTACT_US}">contact us</router-link>.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-cookies"
        >
          Cookies and Other Tracking Tools
        </h3>
        <div data-test-name="text-cookies">
        <p>We (or our suppliers) use several common tracking tools to optimize and personalize our Platforms and Services. We use these tools to collect usage and demographic information about you over time and across different websites when you use our Platforms. We may also have third parties (e.g., advertisers) that collect personal information this way. Tools we use may include browser cookies. Your browser will store these cookies on your computer in a small file.</p>
        <p>We and our advertising partners may also use web beacons (single pixel GIF images). These web beacons are placed in the code of a Web page or an email newsletter.</p>
        <p>By using the Platforms, you give us your free, unambiguous, and informed consent to the use of any of the cookies and tracking tools mentioned in this policy.</p>
        <h4 class="mb-2 font-weight-medium">We may use tracking tools for the following purposes:</h4>
        <ul>
          <li>to track new visitors to the Platforms</li>
          <li>to help us recognize your browser as a previous visitor (including saving and remembering any preferences that may have been set while your browser was visiting the Platforms, such as language)</li>
          <li>to store your email and password if you are registered with us</li>
          <li>to work with online advertising companies to display targeted advertising on our Platforms and third-party platforms that you visit</li>
          <li>to track when you book on partner websites</li>
          <li class="mb-3">to help improve our website offering and for capacity planning purposes</li>
        </ul>
        <p>We may use Google Analytics to collect demographic and interest data about you (such as age, gender, and interests), including through Google Analytics Demographics and Interest Reporting. Choices you make are browser and device specific. Some aspects of our site use cookies to function. You may not be able to use these features if you set your device to block cookies. If you block or delete cookies or opt out of online behavioral advertising, not all the tracking that we have described in this policy will stop.</p>
        <p>Some browsers have a “Do Not Track" feature that lets you tell websites that you do not want to have your online activities tracked. These features are not yet uniform, so we are not currently set up to respond to those signals.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-other-websites"
        >
          Other Websites
        </h3>
        <p data-test-name="text-other-websites">If you click through to third party websites or other platforms such as our booking partners, Intelligent Travel Systems’ privacy policy does not apply.</p>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-children"
        >
          Children
        </h3>
        <p data-test-name="text-children">Intelligent Travel Systems does not knowingly collect personal information from minors. If we become aware that a minor is attempting to submit personal information, we will remove this information from our records. If you are the parent/legal guardian of a minor who has given us Personal Information, please <router-link :to="{name: CONTACT_US}">contact us</router-link> so we can delete it.</p>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-about-policy"
        >
          About this Privacy Policy
        </h3>
        <div data-test-name="text-about-policy">
          <p>We may modify this Privacy Policy at any time and without prior notice. We will notify you of material changes to how we use your personal information prior to implementing them. We may notify you about such a material change by email or prominent notice on our website. By using our platforms and services after we post these changes, you indicate that you accept them.</p>
          <p>For questions about this privacy policy, please <router-link :to="{name: CONTACT_US}" data-test-name="link-to-contact-us-page">contact us</router-link>.</p>
          <p class="font-weight-medium">The Intelligent Travel Systems Team</p>
        </div>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { RouteNames } from '@/helpers/routerUtils'

export default {
  name: 'PrivacyPage',
  created() {
    this.CONTACT_US = RouteNames.CONTACT_US
  }
}
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  ul > li:before {
    display: inline-block;
    content: "–";
    width: 1em;
    margin-left: -1em;
  }
  .text-wrap {
    line-height: 140%;
  }
  .text-date {
    color: rgb(var(--v-theme-secondary));
  }
</style>
