<template>
  <event-card
    :index="index"
    :icon="icon"
    :icon-color="iconColor"
    :title="title"
    :title-label="titleLabel"
    :time="time"
    :image-src="imageSrc"
  />
</template>

<script>
import EventCard from '@/components/itinerary-template/EventCard'
import { itemTypeLookup } from '@/helpers/itemTypes'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'TransportationEventCard',
  components: { EventCard },
  props: {
    record: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    eventType() {
      return itemTypeLookup(this.record)
    },
    eventDetails() {
      return { ...this.record[this.eventType.type], ...this.record.header }
    },
    iconColor() {
      return this.eventType.color
    },
    icon() {
      return this.eventType.icon
    },
    title() {
      const { locations: [origin, destination] } = this.eventDetails
      if (!origin && !destination) {
        return ''
      }

      if (origin && !destination) {
        return this.formatLocation(origin)
      }

      const originString = this.formatLocation(origin)
      const destinationString = this.formatLocation(destination)
      const separator = destinationString ? ' — ' : ''

      return `${originString}${separator}${destinationString}`
    },
    titleLabel() {
      const { isFirstDate, isLastDate } = this.eventDetails
      if (isFirstDate && !isLastDate) {
        return this.$t('Event_beginning')
      }

      if (!isFirstDate && isLastDate) {
        return this.$t('Event_ending')
      }

      return ''
    },
    time() {
      const { todayStartDateTime, todayEndDateTime } = this.eventDetails
      const startTime = itineraryService.formatTime(todayStartDateTime)
      const endTime = itineraryService.formatTime(todayEndDateTime)

      return `${startTime} - ${endTime}`
    },
    imageSrc() {
      return this.eventType.defaultImageUrl
    },
  },
  methods: {
    formatLocation(location) {
      const { city, placeID } = location
      if (!city && !placeID) {
        return ''
      }

      const cityString = city ?? ''
      const placeIdString = placeID ? `(${placeID})` : ''

      return `${cityString}${placeIdString}`
    },
  }
}
</script>
