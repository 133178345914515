import axios from 'axios'
import { store } from '../../store'

export const  getters = {
  APP_VERSION: state => state.appVersion,
  APP_NOTIFICATIONS: state => state.appNotifications
}

export const  mutations = {
  SET_APP_VERSION: (state, version) => {
    state.appVersion = version
  },
  SET_APP_NOTIFICATIONS: (state, notifications) => {
    state.appNotifications = notifications
  }
}

export const actions = {
  SET_APP_VERSION: async ({ commit }, version) => {
    commit('SET_APP_VERSION', version)
  },

  INIT_APP_VERSION: async ({ commit, dispatch }) => {
    let version = await dispatch('CHECK_APP_VERSION')
    commit('SET_APP_VERSION', version)
  },

  CHECK_APP_VERSION: async () => {
    return axios.get('/version.json?'+new Date().getTime(),{headers: {'Accept': 'application/json'}})
      .then(({status, data}) => status === 200 ? data['VUE_APP_VERSION'] : '')
  },

  PUSH_APP_NOTIFICATION: ({ commit, getters }, notification) => {
    const notifications = getters.APP_NOTIFICATIONS.concat()

    if(!notifications.find(item => item.name === notification.name)){
      notifications.push(notification)
    }
    commit('SET_APP_NOTIFICATIONS', notifications)

    if(notification.timeout !== -1){
      setTimeout(() => {
        store.dispatch('CLOSE_NOTIFICATION_BY_NAME', notification.name)
      }, notification.timeout || 5000)
    }
  },

  CLOSE_NOTIFICATION_BY_NAME: ({ commit, getters }, notificationName) => {
    const notifications = getters.APP_NOTIFICATIONS
    const notificationsToSave = notifications.filter(item => item.name !== notificationName)
    commit('SET_APP_NOTIFICATIONS', notificationsToSave)
  }

}

export default {
  state: {
    appVersion: '',
    appNotifications: []
  },
  getters,
  mutations,
  actions
}
