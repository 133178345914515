import axios from 'axios'
import { EventTypes } from '@/helpers/itemTypes'
import { logAppError } from '@/logger'

var URITemplate = require('urijs/src/URITemplate')

export const initialTripRequest = {
  isRoundTrip: true,
  travelers: {
    adults: 2,
    children: 0
  },
  options: {
    [EventTypes.ATTRACTION]:   true,
    [EventTypes.FERRY]:    false,
    [EventTypes.BUS]:     false,
    [EventTypes.CAR_RENTAL]:   false,
    [EventTypes.CUSTOM_EVENT]: false,
    [EventTypes.EXERCISE]:     false,
    [EventTypes.EXPLORE]:      false,
    [EventTypes.FLIGHT]:       true,
    [EventTypes.INSURANCE]:    true,
    [EventTypes.LODGING]:      true,
    [EventTypes.RELAX]:        false,
    [EventTypes.RESTAURANT]:   true,
    [EventTypes.SHOPPING]:     false,
    [EventTypes.SHOW]:         true,
    [EventTypes.TOUR]:         true,
    [EventTypes.TRAIN]:   false,
    [EventTypes.TRANSFER]: false,
  },
  routes: []
}

export const getters = {
  TRIP_REQUEST: state => state.tripRequest
}

export const mutations = {
  SET_TRIP_REQUEST: (state, payload) => {
    state.tripRequest = payload
  },
  RESET_TRIP_REQUEST: (state) => {
    state.tripRequest = initialTripRequest
  }
}

export const actions = {
  SET_TRIP_REQUEST: ({ commit }, tripRequest) => {
    commit('SET_TRIP_REQUEST', tripRequest)
  },

  RESET_TRIP_REQUEST: async ({ commit }) => {
    commit('RESET_TRIP_REQUEST')
  },

  CREATE_TRIP_REQUEST: async ({ dispatch, getters }, payload) => {
    const copyPayload = {...payload}
    copyPayload.userID = getters.USER.id
    const template = URITemplate(process.env.VUE_APP_USER_TRIP_REQUEST_URL)
    const url = template.expand({
      userID: copyPayload.userID
    }).replace(/\/$/, '')
    try {
      return await axios.post(url, {tripRequest: copyPayload})
    } catch (error) {
      dispatch('PUSH_APP_NOTIFICATION', {
        name: 'initErr',
        content: 'itinerary_init_error',
        color: 'error'
      })
      logAppError(`${error}: ${JSON.stringify(copyPayload)}`)
      return null
    }
  },

  UPDATE_TRIP_REQUEST: async (_, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_TRIP_REQUEST_URL)
    const url = template.expand({
      userID: payload.userID,
      tripRequestID: payload.tripRequestID
    }).replace(/\/$/, '')
    return await axios.put(url, {tripRequest: payload})
  },

  LOAD_TRIP_REQUEST: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_TRIP_REQUEST_URL)
    const url = template.expand({
      userID: payload.userID,
      tripRequestID: payload.tripRequestID
    }).replace(/\/$/, '')
    return await axios.get(url)
      .then((response) => {
        commit('SET_TRIP_REQUEST', response.data)
        return response
      })
      .catch((error) => {
        logAppError(`${error}: ${JSON.stringify(payload)}`)
      })
  },
}

export default {
  state: {
    tripRequest: initialTripRequest
  },
  getters,
  mutations,
  actions
}
