<template>
  <question-wrapper
    :step="step"
    :footerText="$t('Hotels_Caption')"
  >
    <template v-slot:body>
      <v-col class="pa-0">
        <hotel-class-selector
          :preferredHotelClassRating="hotelRating"
          @update="updateHotelRating"
        />
      </v-col>
    </template>
  </question-wrapper>
</template>

<script>
import QuestionWrapper from '../QuestionWrapper.vue'
import { mapActions, mapState } from 'vuex'
import HotelClassSelector from '@/components/selector/HotelClassSelector'
import { defaultPreferences } from '@/store/modules/user'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'HotelQuestion',
  components: {
    QuestionWrapper,
    HotelClassSelector
  },
  props: {
    step: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      hotelRating: null,
    }
  },
  created() {
    this.hotelRating = this.user.preferences?.preferredHotelClass || 0
  },
  computed:{
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updateHotelRating(rating) {
      this.hotelRating = rating
    },
    updateProfile() {
      if(this.hotelRating && this.hotelRating !== this.user.preferences?.preferredHotelClass){
        const preferences = cloneDeep(this.user.preferences || defaultPreferences)
        preferences.preferredHotelClass = this.hotelRating
        this.updateUser({ preferences })
      }
    }
  },
  beforeUnmount() {
    this.updateProfile()
  },
}
</script>
