<template>
  <v-sheet
    height="18"
    width="18"
    class="text-caption d-flex justify-center align-center rounded-circle"
    data-test-id="unread-comment-counter"
    data-test-name="text-unread-comments-counter"
  >
    {{ counterValue }}
  </v-sheet>
</template>

<script>
export default {
  name: 'UnreadCommentCounter',
  props: {
    unreadCommentsCount: {
      type: Number,
      required: true
    },
  },
  computed: {
    counterValue() {
      return this.unreadCommentsCount > 99 ? '+99' : this.unreadCommentsCount
    }
  }
}
</script>
