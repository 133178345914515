<template>
  <common-popup
    :title-content="$t('What_to_bring')"
    :full-screen="isMobile"
    :confirm-button-disabled="!isDescriptionValid || isReadOnly || !isWhatToBringChanged"
    :max-width="870"
    :confirm-label="$t('Save')"
    :cancel-label="$t('Cancel')"
    @confirm="onSubmitForm"
    @cancel="closeModal"
    @close="closeModal"
  >
    <v-row no-gutters class="pt-1">
      <v-col>
        <v-textarea
          v-model="whatToBring"
          variant="outlined"
          color="primary"
          bg-color="#fff"
          base-color="primary"
          autofocus
          auto-grow
          rows="4"
          :disabled="isReadOnly"
          :placeholder="$t('Type_text_here')"
          :rules="[validateDescriptionInput, isSubmitButtonEnabled]"
          data-test-id="what-to-bring__textarea"
          data-test-name="input"
        />
      </v-col>
    </v-row>
  </common-popup>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import CommonPopup from '@/components/popups/CommonPopup.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WhatToBring',
  components: { CommonPopup },
  emits: ['close'],
  data() {
    return {
      whatToBring: '',
      isDescriptionValid: false,
      originalWhatToBring: false
    }
  },
  computed: {
    ...mapGetters({
      currentItinerary: 'CURRENT_ITINERARY',
      isOwner: 'IS_OWNER',
      isEditor: 'IS_EDITOR',
      isCommenter: 'IS_COMMENTER',
      isMobile: 'IS_MOBILE',
      user: 'USER',
    }),
    isReadOnly() {
      return !(this.isEditor || this.isOwner)
    },
    isSubmitButtonEnabled() {
      return !this.isReadOnly
    },
    isWhatToBringChanged() {
      return this.whatToBring !== this.originalWhatToBring
    },
  },
  methods: {
    ...mapActions({
      updateWhatToBringForSharedItinerary: 'UPDATE_WHAT_TO_BRING_FOR_SHARED_ITINERARY',
      updateItineraryHeader: 'UPDATE_ITINERARY_HEADER',
    }),
    onSubmitForm() {
      if (!this.isOwner) {
        this.updateWhatToBringForSharedItinerary({
          userData: { whatToBring: this.whatToBring },
          userID: this.user.id,
          itineraryID: this.currentItinerary.header.itineraryID,
        })
          .then(() => this.closeModal())
      } else {
        const origHeader  = cloneDeep(this.currentItinerary.header)
        origHeader.userData.whatToBring = this.whatToBring
        this.updateItineraryHeader({ header: origHeader})
          .then(() => this.closeModal())
      }
    },
    closeModal() {
      this.$emit('close')
    },
    validateDescriptionInput(val) {
      const maxLength = 2000
      this.isDescriptionValid = false
      if (val && val.length > maxLength) {
        return this.$t('Input_max_length_error', [maxLength] )
      }
      this.isDescriptionValid = true
      return true
    },
  },
  mounted() {
    this.originalWhatToBring = this.currentItinerary.header.userData.whatToBring || ''
  },
  watch: {
    'currentItinerary.header.userData.whatToBring': {
      handler(newVal) {
        this.whatToBring = newVal
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
