<template>
  <common-popup
    :confirm-label="confirmLabel"
    :cancel-label="cancelLabel"
    :title-content="title"
    :large-title="!isMobile"
    :full-screen="isMobile"
    :max-width="870"
    :confirm-button-disabled="!isValidCreateItineraryFields"
    @cancel="closeDialogWindow"
    @confirm="confirm"
    @close="closeDialogWindow"
  >
    <v-row no-gutters class="d-flex justify-space-between pt-3">
      <v-col cols="12" sm="6" class="pr-sm-8">
        <v-img
          :src="imageUrl"
          class="rounded-lg"
          :height="$vuetify.display.mdAndDown ? '220' : '260'"
          cover
          aspect-ratio="1.6"
          data-test-name="image"
          @error="setDefaultImg"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-row no-gutters>
          <v-col cols="12" class="">
            <date-picker
              :model-value="startDate"
              :min-date="minStartDate"
              variant="outlined"
              class="mb-5 mb-sm-8"
              :placeholder="`${$t('Start_date')}`"
              @update:model-value="startDate = $event"
              min-width="320"
              name="StartDate"
              data-test-name="date-picker-start-date"
            />
          </v-col>
          <v-col cols="12" class="">
            <place-input
              id="homeAirport"
              :placeholder="$t('Where_from')"
              :value="location?.placeID"
              min-width="450"
              name="placeInputHomeAirport"
              class="mb-sm-3"
              hide-details
              :small="false"
              @updateLocation="changedHomeAirport"
              variant="outlined"
              icon="$customLocation"
              data-test-name="input-place-from"
            />
          </v-col>
          <v-col cols="12" class="">
            <passengers-selector
              :value="localTravelers"
              @input="localTravelers = $event"
              name="generate-custom-event"
              class="passengers-selector"
            >
              <template #activator="{ props }">
                <v-text-field
                  :model-value="totalTravelersNumber"
                  :label="$t('Travelers') + '*'"
                  class="pa-0 ma-0 trigger-change-travelers"
                  variant="outlined"
                  color="primary"
                  bg-color="#fff"
                  base-color="primary"
                  readonly
                  hide-details
                  prepend-inner-icon="$customTravelers"
                  v-bind="props"
                  data-test-name="input-total-travelers-counter"
                />
              </template>
            </passengers-selector>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </common-popup>
</template>

<script>
import CommonPopup from '@/components/popups/CommonPopup'
import DatePicker from '@/components/DatePicker.vue'
import PlaceInput from '@/components/PlaceInput.vue'
import PassengersSelector from '../PassengersSelector.vue'
import { initialTripRequest } from '@/store/modules/tripRequest'
import { generateUrlByPlaceID, defaultImageUrl } from '@/helpers/staticImagesUtil'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateItineraryPopup',
  emits: ['confirm', 'close'],
  components: {
    CommonPopup,
    DatePicker,
    PlaceInput,
    PassengersSelector,
  },
  data() {
    return {
      isCloneWindowVisible: false,
      localTravelers: null,
      location: '',
      startDate: null,
      imageUrl: '',
    }
  },
  props: {
    travelers: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true
    },
    confirmLabel: {
      type: String,
      required: true
    },
    cancelLabel: {
      type: String,
      required: true
    },
    placeIDLocations: Array,
  },
  created() {
    this.localTravelers = {
      ...this.travelers,
      children: initialTripRequest.travelers.children
    }
    this.imageUrl = this.placeIDLocations?.[0] ? generateUrlByPlaceID(this.placeIDLocations?.[0]) : defaultImageUrl
  },
  computed: {
    ...mapGetters({
      isMobile: 'IS_MOBILE',
      user: 'USER'
    }),
    currentDate() {
      return this.$moment(Date.now()).format('YYYY-MM-DD')
    },
    totalTravelersNumber() {
      return (this.localTravelers.adults || 0) + (this.localTravelers.children || 0)
    },
    isValidCreateItineraryFields() {
      return !!this.startDate && !!this.location
    },
    minStartDate() {
      let date = this.$moment.parseZone(this.currentDate).format('YYYY-MM-DD HH:mm')
      return date
    },
  },
  methods: {
    setDefaultImg() {
      this.imageUrl = defaultImageUrl
    },
    changedHomeAirport(airport) {
      this.location = airport
    },
    resetValues() {
      this.startDate = ''
      this.location = ''
      this.localTravelers = initialTripRequest.travelers
    },
    confirm() {
      const date = this.$moment.parseZone(this.startDate)
      const itineraryData = {
        startDate: date,
        location: this.location,
        travelers: this.localTravelers
      }
      this.$emit('confirm', itineraryData)
    },
    closeDialogWindow() {
      this.$emit('close')
      this.resetValues()
    },
  },
  watch: {
    user: {
      handler(value) {
        if (value?.settings?.homePlaceID) {
          this.location = value.settings.homePlaceID
        }
      },
      immediate: true,
    }
  }
}
</script>
