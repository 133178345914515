<template>
  <div class="d-flex align-center event-modal-type-preview" data-test-name="content-event-preview">
    <v-icon :color="color" class="mr-4" size="24" data-test-name="icon">{{ icon }}</v-icon>
    <div class="d-flex flex-column">
      <div class="font-weight-medium text-body-1" data-test-name="text-title">
        {{ label }}
      </div>
      <div
        v-if="additionalInformation"
        class="font-weight-regular"
        data-test-name="text-additional-info"
      >
        {{ additionalInformation }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventModalTypePreview',
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    additionalInformation: {
      type: String,
      required: false,
    },
  }
}
</script>

