<template>
  <div data-test-name="menu-user">
    <div id="user-menu-activator" data-test-name="menu-activator">
      <v-btn icon data-test-name="menu-button">
        <v-avatar class="aft" color="primary" size="32">
          <v-avatar color="black" size="30">
            <v-img v-if="userAvatar" :src="userAvatar" />
            <span v-else class="text-white text-h5">{{userFirstLetter}}</span>
          </v-avatar>
        </v-avatar>
      </v-btn>
      <label
        v-if="$vuetify.display.smAndUp"
        class="text-body-1 font-weight-medium user-menu-trigger v-label--clickable"
        v-on:click="on"
        data-test-name="text-user-name"
      >
        <span
          class="user-name mr-1 ml-2"
        >
          {{ userName }}
        </span>
        <v-icon
          class="ml-n1"
          color="primary"
          size="small"
          icon="mdi-chevron-down"
          data-tets-name="icon-user"
        />
      </label>
    </div>
    <v-menu
      activator="#user-menu-activator"
      data-test-name="menu"
      :close-on-content-click="true"
    >
      <v-card width="290">
        <v-list nav>
          <v-list-item-action class="hidden-sm-and-up">
            <v-btn
              variant="plain"
              append-icon="mdi-close"
              class="mr-auto d-block"
              data-test-name="button-close"
            />
          </v-list-item-action>
          <v-list-item link class="mt-1">
            <div @click="goToProfile" data-test-name="button-open-user-profile-page">
              <v-list-item-title
                class="text-body-1 font-weight-medium"
              >
              {{ $t('Profile_Settings_Title') }}
            </v-list-item-title>
            </div>
          </v-list-item>

          <v-divider class="divider"></v-divider>

          <v-list-item class="mt-4" @click="goToMyItineraries" data-test-name="button-open-itineraries-page">
            <div >
              <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('My_itineraries') }}</v-list-item-title>
            </div>
          </v-list-item>

          <v-list-item class="mt-1" @click="goToSharedItineraries" data-test-name="button-open-shared-itineraries-page">
            <div >
              <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('Shared_itineraries') }}</v-list-item-title>
            </div>
          </v-list-item>

          <v-divider class="divider"></v-divider>

          <v-list-item class="mt-3 mb-2" @click="goToBlog" data-test-name="button-open-blog-page">
            <div >
              <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('Blog') }}</v-list-item-title>
            </div>
          </v-list-item>

          <v-divider class="divider"></v-divider>

          <v-list-item class="mt-4 mb-2" @click="logoutClick" data-test-name="button-sign-out">
            <div>
              <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('Logout') }}</v-list-item-title>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import { getDisplayUserName, getUserFirstLetter } from '@/helpers/userUtils'

export default {
  name: 'UserMenu',
  props: {
    color: String,
    name: {
      type: String,
      default: 'user-menu',
    },
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isMobile: 'IS_MOBILE',
    }),
    userName() {
      return getDisplayUserName(this.user)
    },
    userFirstLetter() {
      return getUserFirstLetter(this.user)
    },
    userAvatar() {
      return this.user?.userAvatar
    },
  },
  methods: {
    ...mapActions({
      logout: 'LOGOUT',
    }),
    logoutClick() {
      this.logout()
      if (this.$router.currentRoute.name !== RouteNames.HOME) {
        this.$router.push({ name: RouteNames.HOME })
      }
    },
    goToMyItineraries() {
      this.$refs?.dw && this.$refs.dw.dialogClose()
      this.$router.push({name: RouteNames.ITINERARIES, params: { page: 1 }})
    },
    goToSharedItineraries() {
      this.$refs?.dw && this.$refs.dw.dialogClose()
      this.$router.push({name: RouteNames.SHARED_ITINERARIES, params: { page: 1 }})
    },
    goToBlog() {
      this.$refs?.dw && this.$refs.dw.dialogClose()
      this.$router.push({name: RouteNames.BLOG})
    },
    goToProfile() {
      this.$refs?.dw && this.$refs.dw.dialogClose()
      this.$router.push({name: RouteNames.USER_PROFILE})
    },
  }
}
</script>
