<template>
  <event-card
    :index="index"
    :icon="icon"
    :icon-color="iconColor"
    :title="title"
    :location="location"
    :time="time"
    :address="address"
    :description="description"
    :image-src="imageSrc"
    :display-rating="displayRating"
    :rating="rating"
    @open-google-map="openGoogleMap"
  />
</template>

<script>
import EventCard from '@/components/itinerary-template/EventCard'
import google from '@/helpers/google'
import { getGoogleImageUrl } from '@/helpers/imageUtil'
import { itemTypeLookup } from '@/helpers/itemTypes'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'LodgingEventCard',
  components: { EventCard },
  props: {
    record: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    eventType() {
      return itemTypeLookup(this.record)
    },
    eventDetails() {
      return { ...this.record[this.eventType.type], ...this.record.header }
    },
    iconColor() {
      return this.eventType.color
    },
    icon() {
      return this.eventType.icon
    },
    title() {
      return this.eventDetails?.title
    },
    location() {
      const location = this.eventDetails.locations?.at(0)
      const city = location?.city
      const country = location?.country
      if (!city || !country) {
        return ''
      }

      return `${city}, ${country}`
    },
    time() {
      const { startDateTime, endDateTime, isFirstDate, isLastDate } = this.eventDetails
      if (!isFirstDate && !isLastDate) {
        return ''
      }

      const time = itineraryService.formatTime(isFirstDate ? startDateTime : endDateTime)
      const prefix = isFirstDate ? this.$t('CheckIn') : this.$t('CheckOut')

      return `${prefix}: ${time}`
    },
    roData() {
      const { roData } = this.eventDetails

      return roData ?? {}
    },
    address() {
      const { simpleAddress } = this.roData

      return simpleAddress ?? ''
    },
    description() {
      const { shortDescription } = this.eventDetails
      const { description } = this.roData

      return shortDescription ?? description ?? ''
    },
    imageSrc() {
      const { thumbnailURL, googleImages } = this.roData

      return thumbnailURL ?? getGoogleImageUrl(googleImages) ?? this.eventType.defaultImageUrl
    },
    rating() {
      const { userRating } = this.roData

      return userRating
    },
    displayRating() {
      if (!this.rating) {
        return false
      }

      const { aggregateRating, votes } = this.rating

      return aggregateRating !== undefined && votes !== undefined
    },
  },
  methods: {
    openGoogleMap() {
      const { id, latLon: { lat, lon } } = this.eventDetails.roData
      const link = google.searchLink({ lat, lon, id })
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
}
</script>
