<template>
  <v-col class="d-flex flex-column align-center justify-center" style="height: 100%" data-test-name="page-not-found">
    <div class="text-h6 d-flex flex-row align-center text-red-lighten-5 my-2" data-test-name="message">
      {{ $t('Page_not_found_message') }}
      <v-icon color="error" class="ml-2">mdi-emoticon-sad-outline</v-icon>
    </div>
    <v-btn variant="outlined" color="primary" @click="goHome" data-test-name="button-go-home">
      {{ $t('Go_home_caption') }}
    </v-btn>
  </v-col>
</template>

<script>
import { RouteNames } from '@/helpers/routerUtils'

export default {
  name: 'PageNotFound',
  methods: {
    goHome() {
      this.$router.push({name: RouteNames.HOME})
    }
  }
}
</script>
