<template>
  <v-select
    prepend-inner-icon="$customRestaurantOutline"
    data-test-name="select-restaurants-class"
    :label="$t(preferredRestaurantRating.label)"
    :placeholder="$t(preferredRestaurantRating.placeholder)"
    :items="priceLevelsItems"
    :model-value="ratingValue"
    @update:model-value="selectedRestaurantRating"
    color="primary"
    base-color="primary"
    bg-color="#fff"
    variant="outlined"
    hide-details
  >
    <template v-slot:item="{ props }">
      <v-list-item v-bind="props" class="text-caption text-secondary font-weight-normal" data-test-name="list-item">
        <v-rating v-bind="props"
          size="16"
          full-icon="$customDollar"
          empty-icon=""
          disabled
          data-test-name="rating"
          :model-value="props.value"
        />
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <div class="d-flex text-caption text-secondary" v-for="i in ratingValue" :key="i">
        <v-icon size="16" icon="$customDollar" :title="item.value"/>
      </div>
    </template>
  </v-select>
</template>
<script>
import { preferredRestaurantRating, priceLevels } from '@/helpers'

export default {
  name: 'RestaurantSelector',
  emits: ['update'],
  data() {
    return {
      preferredRestaurantRating: null,
      restaurantPriceLevels: [],
      ratingValue: 0,
    }
  },
  props: {
    priceLevel: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    priceLevelsItems() {
      const rv = []
      this.restaurantPriceLevels.forEach(item => {
        rv.push({title: '', value: item.value})
      })
      return rv
    }
  },
  methods: {
    selectedRestaurantRating(val) {
      this.ratingValue = val
      this.$emit('update', val)
    }
  },
  created() {
    this.ratingValue = this.priceLevel
    this.preferredRestaurantRating = preferredRestaurantRating
    this.restaurantPriceLevels = priceLevels
  },
  watch: {
    priceLevel: {
      handler(newVal) {
        this.ratingValue = newVal
      },
      immediate: true,
    }
  }
}
</script>
