<template>
  <v-expansion-panels v-model="panelsIndexesList" multiple variant="accordion" class="d-flex flex-column">
    <v-col
      v-for="(record, index) in recordsList"
      :key="getKey(day, index, record)"
      class="pa-0 mb-4 text-black"
    >
      <component
        :is="getEventType(record)"
        :record="record"
        :index="index"
        :readonly="true"
        :is-template-view="true"
        transition="fade"
        transition-mode="out-in"
      />
    </v-col>
  </v-expansion-panels>
</template>

<script>
import ActivityEventCard from '@/components/itinerary-template/ActivityEventCard'
import EntertainmentEventCard from '@/components/itinerary-template/EntertainmentEventCard'
import LodgingEventCard from '@/components/itinerary-template/LodgingEventCard'
import TransportationEventCard from '@/components/itinerary-template/TransportationEventCard'
import { EventTypes } from '@/helpers/itemTypes'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'ItineraryEventsList',
  components: {
    [EventTypes.ATTRACTION]:       EntertainmentEventCard,
    [EventTypes.BUS]:              TransportationEventCard,
    [EventTypes.CAR_RENTAL]:       ActivityEventCard,
    [EventTypes.CRUISE]:           ActivityEventCard,
    [EventTypes.CUSTOM_EVENT]:     ActivityEventCard,
    [EventTypes.DRIVE]:            TransportationEventCard,
    [EventTypes.EXERCISE]:         ActivityEventCard,
    [EventTypes.EXPLORE]:          ActivityEventCard,
    [EventTypes.FERRY]:            TransportationEventCard,
    [EventTypes.FLIGHT]:           TransportationEventCard,
    [EventTypes.LODGING]:          LodgingEventCard,
    [EventTypes.RELAX]:            ActivityEventCard,
    [EventTypes.RESTAURANT]:       EntertainmentEventCard,
    [EventTypes.SHOPPING]:         ActivityEventCard,
    [EventTypes.SHOW]:             EntertainmentEventCard,
    [EventTypes.TOUR]:             EntertainmentEventCard,
    [EventTypes.TRAIN]:            TransportationEventCard,
    [EventTypes.TRANSFER]:         TransportationEventCard,
  },
  props: {
    day: {
      type: String,
      required: true
    },
    recordsList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      panelsIndexesList: new Array(this.recordsList.length).fill(0).map((_, index) => index)
    }
  },
  methods: {
    getKey(day, index, record) {
      return `${day}-${record.header.itemID}-${index}`
    },
    getEventType: itineraryService.getEventType,
  },
}
</script>
