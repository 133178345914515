
<template>
  <v-row
    class="no-gutters d-flex flex-column align-center flex-md-row align-md-start justify-center pt-6 pb-16 px-4 px-md-0"
    data-test-name="page-user-profile-navigation"
  >
    <v-col cols="12" md="3" class="mr-0 mr-md-5">
      <v-card
        class="mx-auto profile-card"
        elevation="0"
      >
        <v-navigation-drawer
          v-if="$vuetify.display.mdAndUp"
          floating
          permanent
          width="100%"
          color="rgba(1, 68, 199, 0.05)"
          class="py-6 px-3"
          data-test-name="navigation-user"
        >
          <v-list class="d-flex justify-center flex-lg-row flex-column pa-0 mb-6">
            <v-list-item class="pa-0 justify-center mx-lg-4 mx-0" data-test-name="list-item">
              <v-avatar color="primary" size="82" data-test-name="image-user-avatar">
                <v-avatar color="black" size="80">
                  <v-img v-if="userAvatar" :src="userAvatar" />
                  <span v-else class="first-letter text-white text-h5">{{ userFirstLetter }}</span>
                </v-avatar>
              </v-avatar>
            </v-list-item>

            <v-list-item class="pa-0 mt-4 ma-lg-0 text-md-center text-lg-left" data-test-name="list-item">
              <v-list-item-title class="user-name text-body-1 font-weight-bold" data-test-name="text-user-name">
                {{ userName }}
              </v-list-item-title>
              <v-list-item-subtitle class="user-email text-body-1" data-test-name="text-user-email">{{ userEmail }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <navigation-list />
        </v-navigation-drawer>
      </v-card>
      <v-expansion-panels
        v-if="$vuetify.display.smAndDown"
        width="100%"
        class="d-flex justify-start pa-0"
      >
        <v-expansion-panel class="panel" data-test-name="navigation-user">
          <v-expansion-panel-title>
            <v-row>
              <v-col cols="4" class="pa-0 mb-0 mx-3 mx-lg-4 justify-center">
                <v-avatar color="primary" size="42" data-test-name="image-user-avatar">
                  <v-avatar color="black" size="40">
                    <v-img v-if="userAvatar" :src="userAvatar" />
                    <span v-else class="first-letter text-white text-h5">{{ userFirstLetter }}</span>
                  </v-avatar>
                </v-avatar>
              </v-col>
              <v-col cols="8" class="pa-0 mr-lg-6">
                <div class="user-name text-body-1 font-weight-bold" data-test-name="text-user-name">{{ userName }}</div>
                <div class="user-email text-body-1" data-test-name="text-user-email">>{{ userEmail }}</div>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <navigation-list v-if="$vuetify.display.smAndDown" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" md="6">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import NavigationList from '../components/profile/NavigationList'
import { mapGetters } from 'vuex'
import { getDisplayUserName, getUserFirstLetter } from '@/helpers/userUtils'

export default {
  name: 'UserProfileNavigation',
  components: { NavigationList },
  computed: {
    ...mapGetters({
      user: 'USER',
    }),
    userName() {
      return getDisplayUserName(this.user)
    },
    userFirstLetter() {
      return getUserFirstLetter(this.user)
    },
    userAvatar() {
      return this.user.userAvatar
    },
    userEmail() {
      return this.user.email
    },
  },
}
</script>
<style lang="scss" scoped>
  .first-letter {
    position: absolute;
  }
  .panel {
    background: rgba(1, 68, 199, 0.05)!important;
  }
  .profile-card {
    border-radius: 1rem;
  }
  .user-email, .user-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(var(--v-theme-textPrimaryColor));
  }
</style>
