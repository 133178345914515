<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <v-list color="rgba(1, 68, 199, 0)" data-test-name="list-user-navigation">
    <v-list-item
      @click="goToProfile"
      prepend-icon="$customUser"
      data-test-name="list-item-open-profile"
      :append-icon="(this.route.name === USER_PROFILE && $vuetify.display.mdAndUp) ? '$customNavigationArrow' : ''"
    >
      <v-list-item-title
        class="profile font-weight-medium text-secondary"
        :class="{'active': this.route.name === USER_PROFILE}"
      >
        {{ $t("Profile") }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      data-test-name="list-item-open-settings"
      @click="goToSettings"
      prepend-icon="$customSettings"
      :append-icon="(this.route.name === USER_SETTINGS && $vuetify.display.mdAndUp) ? '$customNavigationArrow' : ''"
    >
      <v-list-item-title class="settings font-weight-medium text-secondary" :class="{'active': this.route.name === USER_SETTINGS}">
        {{ $t("Settings") }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      data-test-name="list-item-open-preferences"
      @click="goToPreferences"
      prepend-icon="$customClipboard"
      :append-icon="(this.route.name === USER_PREFERENCES && $vuetify.display.mdAndUp) ? '$customNavigationArrow' : ''"
    >
      <v-list-item-title class="preferences font-weight-medium text-secondary" :class="{'active': this.route.name === USER_PREFERENCES}">
        {{ $t("Preferences") }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      data-test-name="list-item-logout"
      @click="logoutClick"
      prepend-icon="$customLogout"
    >
      <v-list-item-title class="font-weight-medium text-secondary">
        {{ $t("Logout") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
import { RouteNames } from '@/helpers/routerUtils'
import { mapActions } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'navigation-list',
  data() {
    return {
      route: useRoute(),
    }
  },
  created() {
    this.USER_PROFILE = RouteNames.USER_PROFILE
    this.USER_SETTINGS = RouteNames.USER_SETTINGS
    this.USER_PREFERENCES = RouteNames.USER_PREFERENCES
  },
  methods: {
    ...mapActions({
      logout: 'LOGOUT',
    }),
    goToProfile(){
      this.$router.push({ name: RouteNames.USER_PROFILE })
    },
    goToSettings(){
      this.$router.push({ name: RouteNames.USER_SETTINGS })
    },
    goToPreferences(){
      this.$router.push({ name: RouteNames.USER_PREFERENCES })
    },
    logoutClick() {
      this.logout()
      if (this.$router.currentRoute.name !== RouteNames.HOME) {
        this.$router.push({ name: RouteNames.HOME })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .v-list-item__title.active {
    color: rgb(var(--v-theme-primary))
  }
  .v-list-item__icon {
    align-self: center!important;
  }
</style>
