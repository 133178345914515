<template>
  <v-select
    :items="flightClassItems"
    :model-value="preferredFlightClassRating"
    @update:model-value="selectedFlightClass"
    :placeholder="$t(preferredFlightClass.placeholder)"
    :label="$t(preferredFlightClass.label)"
    data-test-name="select-flights-class"
    prepend-inner-icon="$customAirplaneOutline"
    variant="outlined"
    color="primary"
    base-color="primary"
    bg-color="#fff"
    hide-details
  />
</template>
<script>
import { flightClasses } from '@/helpers/commonConfigs'
import { preferredFlightClass } from '@/helpers'

export default {
  name: 'FlightClassSelector',
  emits: ['update'],
  props: {
    preferredFlightClassRating: String,
  },
  methods: {
    selectedFlightClass(selectedClass) {
      this.$emit('update', selectedClass)
    }
  },
  created() {
    this.preferredFlightClass = preferredFlightClass
  },
  computed: {
    flightClassItems() {
      return flightClasses.map(el => ({...el, ...{title: this.$t(el.text)}}))
    }
  }
}
</script>
