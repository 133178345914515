<template>
  <v-btn
    class="mx-1 pa-1"
    color="primary"
    variant="tonal"
    elevation="0"
    :disabled="disabled"
    min-width="36"
    @click="$emit('click', $event)"
  >
    <v-icon color="primary" :icon="icon" />
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonIterator',
  emits: ['click'],
  props: {
    icon: String,
    disabled: Boolean,
  }
}
</script>
