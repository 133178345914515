<template>
  <v-card class="card-carrental" elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 pt-4 tabs-container">
      <div class="text-primary font-weight-medium text-uppercase my-4 ml-4">
        {{ $t('Tab_Details') }}
      </div>
    </v-row>
    <v-card-text class="event-content">
      <v-row class="mx-0 mt-10 pt-1 pt-sm-4 my-2">
        <v-col cols="12" class="mb-0 pb-0 px-0">
          <place-input
            :value="location?.placeID"
            :label="`${$t('Pick_up_location')}*`"
            :show-suggestions="true"
            :readonly="isReadOnly"
            min-width="320"
            name="placeInputLocation"
            variant="outlined"
            @updateLocation="updateLocation"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="12" sm="6" class="mb-0 py-0 py-sm-2 px-0 pr-sm-2">
          <v-text-field
            v-model="fromPlace"
            :label="`${$t('Pick_up')}`"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="mt-2 mb-0 mx-sm-0 py-2 py-sm-0 px-0 pl-sm-2">
          <v-text-field
            v-model="fromAddress"
            :label="`${$t('Address')}`"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          >
            <template v-slot:prepend-inner>
              <v-icon size="large" class="pr-2">$customHouse</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="12" class="px-0">
          <v-checkbox
            v-model="isRoundTrip"
            :true-value="true"
            :readonly="isReadOnly"
            :label="$t('Same_Location')"
            @update:model-value="onRoundTripToggle"
            true-icon="mdi-checkbox-intermediate"
            class="text-body-2 ml-n2"
            color="primary"
            hide-details
          >
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="!isRoundTrip" class="ma-0 px-sm-0" data-test-id="card-carrental-row__dropoff">
        <v-col cols="12" sm="12" class="mb-0 pb-0 px-0">
          <place-input
            :value="arrivalLocation?.placeID"
            :label="`${$t('Drop_off_location')}*`"
            :show-suggestions="true"
            :readonly="isReadOnly"
            min-width="320"
            name="arrivalLocation"
            variant="outlined"
            @updateLocation="updateArrivalLocation"
          />
        </v-col>
        <v-col cols="12" sm="6" class="mb-0 py-0 py-sm-2 px-0 pr-sm-2">
          <v-text-field
            v-model="toPlace"
            :label="`${$t('Drop_off')}`"
            :readonly="isReadOnly"
            :min="0"
            class="ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="mt-2 mb-0 mx-sm-0 py-2 py-sm-0 px-0 pl-sm-2">
          <v-text-field
            v-model="toAddress"
            :label="`${$t('Address')}`"
            :readonly="isReadOnly"
            :min="0"
            class="ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          >
            <template v-slot:prepend-inner>
              <v-icon class="pr-2">$customHouse</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pr-sm-2">
          <date-picker
            :model-value="startDate"
            :readonly="isReadOnly"
            variant="outlined"
            :label="`${$t('Start_date')}*`"
            @update:model-value="updateStartDate"
            min-width="320"
            name="StartDate"
          />
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 pl-2 pr-0 pl-sm-2 pr-sm-2">
          <time-picker
            :model-value="startTime"
            :label="`${$t('Start_time')}*`"
            :readonly="isReadOnly"
            @update:model-value="updateStartTime"
          />
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pl-sm-2 pr-sm-2">
          <date-picker
            :model-value="endDate"
            :min-date="minEndDate"
            :readonly="isReadOnly"
            :label="`${$t('End_date')}*`"
            @update:model-value="updateEndDate"
            min-width="320"
            name="EndDate"
          />
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pl-2 pl-sm-2">
          <time-picker
            :model-value="endTime"
            :label="`${$t('End_time')}*`"
            :readonly="isReadOnly"
            :rules="[rules.afterStartTime]"
            @update:model-value="updateEndTime"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="6" v-if="$vuetify.display.xs" class="px-0 mt-2">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 mr-2 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                bg-color="#fff"
                color="primary"
                base-color="primary"
                variant="outlined"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
        <v-col cols="6" sm="12" class="pr-2">
          <event-booking
            :isBooked="isBooked"
            :readonly="isReadOnly"
            @updateBooked="isBooked = !isBooked"
            class="d-flex justify-end"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="12" md="3" class="mb-0 pb-0 px-0 pr-sm-2">
          <v-select
            :items="carTypesList"
            :label="$t('Type_of_car')"
            :model-value="carType"
            :readonly="isReadOnly"
            @update:model-value="carType = $event"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0 px-0 pl-sm-2 pr-sm-2">
          <v-select
            prepend-inner-icon="$customCurrency"
            :model-value="totalPrice?.currency"
            @update:model-value="updateCurrency"
            :label="$t('Currency')"
            :readonly="isReadOnly"
            :items="currencyList"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0 px-0 pl-2 pl-sm-2 pr-sm-2">
          <v-currency-field
            :model-value="Number(totalPrice?.amount)"
            @update="updateAmount"
            variant="outlined"
            class="pa-0 ma-0"
            color="primary"
            :label="`${$t('Total_price')}`"
            :readonly="isReadOnly"
          />
        </v-col>
        <v-col cols="3" v-if="!$vuetify.display.xs" class="pl-sm-2 pr-sm-0">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="`${$t('Travelers')}*`"
                class="ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
      </v-row>

      <v-row class="ma-0 mb-14">
        <v-col cols="12" class="pt-0 px-0 px-sm-0">
          <v-textarea
            v-model="notes"
            :label="$t('Notes')"
            :readonly="isReadOnly"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            rows="3"
            variant="outlined"
            no-resize
            hide-details="auto"
            :rules="[rules.validNotes]"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <event-popup-buttons
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />

    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
  </v-card>
</template>

<script>
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import { carTypes } from '@/helpers/carTypes'
import { convertAmountToServerFormat, convertAmountToUIFormat } from '@/helpers/currency'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { getDatesAndTimeAsISOString } from '@/helpers'
import VCurrencyField from '@/components/VCurrencyField'
import { checkAddressInput } from '@/helpers/inputValidators'

export default {
  name: 'AddCarRentalForm',
  mixins: [eventFormMainStructure],
  components: {
    VCurrencyField,
  },
  emits: ['updateTitle', 'cancel', 'save'],
  data() {
    return {
      fromAddress: '',
      toAddress: '',
      fromPlace: '',
      toPlace: '',
      isRoundTrip: true,
      carTypesList: [],
      carType: '',
      arrivalLocation: '',
    }
  },
  created() {
    this.carTypesList = carTypes
  },
  computed: {
    computedTitle() {
      const title = this.$t('CarRental_at') + ' ' + this.fromPlace
      this.$emit('updateTitle', title)
      return title
    },
    newRecord() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      const { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let departure = {
        location: this.location,
        placeName: this.fromPlace,
        address: this.fromAddress
      }
      let arrival = {
        location: this.arrivalLocation,
        placeName: this.toPlace,
        address: this.toAddress
      }

      if (this.isRoundTrip) {
        arrival = departure
      }

      let rv = {
        startDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(startDateTime)),
        endDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(endDateTime)),
        location: this.location,
        travelers: this.travelers,
        [this.eventType]: {
          title: this.computedTitle,
          ...(this.notes) && { notes: this.notes },
          ...(this.isBooked) && { isBooked: this.isBooked },
          ...(convertAmountToServerFormat(this.totalPrice?.amount))
            && { totalPrice: {
              currency: this.totalPrice?.currency,
              amount: convertAmountToServerFormat(this.totalPrice?.amount)
            },
            },
          ...(this.carType) && { carType: this.carType },
          segments: [{departure, arrival}],
        },
      }
      return rv
    },
    isEventValid() {
      return (
        this.isValidDropOffValues
        && checkAddressInput(this.fromAddress)
        && checkAddressInput(this.toAddress)
        && checkAddressInput(this.fromPlace)
        && checkAddressInput(this.toPlace)
      )
    },
    isValidDropOffValues() {
      return this.isRoundTrip || !!this.arrivalLocation
    }
  },
  methods: {
    onRoundTripToggle() {
      this.toPlace = ''
      this.toAddress = ''
      this.arrivalLocation = ''
    },
    mapDataToFormFields(eventData) {
      this.$emit('updateTitle', eventData?.title)
      this.isBooked = Boolean(eventData?.isBooked)
      this.notes = eventData?.notes
      this.totalPrice.amount = convertAmountToUIFormat(eventData?.totalPrice?.amount)
      this.totalPrice.currency = eventData?.totalPrice?.currency || this.userCurrency || 'USD'
      this.fromAddress = eventData?.segments[0].departure.address
      this.fromPlace = eventData?.segments[0].departure.placeName
      this.arrivalLocation = eventData?.segments[0].arrival.location
      this.toPlace = eventData?.segments[0].arrival.placeName
      this.toAddress = eventData?.segments[0].arrival.address
      this.isRoundTrip = (this.fromAddress === this.toAddress && this.fromPlace === this.toPlace)
      this.carType = eventData?.carType
    },
    updateArrivalLocation(location) {
      this.updateAlternativeVisibility(location, this.arrivalLocation)
      this.arrivalLocation = location
    },
  },
  watch: {
    isDialogVisible: {
      handler(visible) {
        if(visible && this.record) {
          this.mapDataToFormFields(this.record[this.eventType])
        }
      },
      immediate: true
    },
  }
}
</script>

<style scoped lang="scss">
.passengers-selector {
  width: 100%;
}
</style>
