<template>
  <v-card class="calendar-event-dialog">
    <v-card-title class="d-flex pa-4 pb-0">
      <v-spacer></v-spacer>
      <v-icon @click="close" size="x-small">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="px-5 pb-0 text-body-2 text-sm-body-1">
      <v-row class="align-center mb-3">
        <v-col class="pa-0 flex-grow-0"><v-icon :color="itemColor(record)" :icon="itemIcon(record)" /></v-col>
        <v-col class="text-subtitle-2 text-sm-subtitle-1 font-weight-bold pa-0 ml-4"><span>{{eventData.title}}</span></v-col>
      </v-row>
      <v-row class="mb-3">
        <span>{{ datetimeRange }}</span>
      </v-row>
      <v-row class="mb-3">
         <span class="mr-2 font-weight-medium">Duration:</span>
         <span>{{ duration }}</span>
      </v-row>
      <v-row v-if="formattedPrice">
        <span class="mr-2 font-weight-medium">Price:</span>
        <span v-if="convertedPrice" class="mr-1">{{ convertedPrice }} /</span>
        <span>{{ formattedPrice }} </span>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="align-center justify-start">
        <v-col
          v-if="isEditable && (isOwner || isEditor)"
          cols="1"
          class="mr-4"
        >
          <v-icon
          size="large"
          color="primary"
          @click.stop="$emit('edit-event', eventData.id)"
          >
          mdi-square-edit-outline
          </v-icon>
        </v-col>
        <v-col cols="1" class="mr-2" data-test-id="comment-button">
          <v-icon
            color="primary"
            size="small"
            class="mr-1"
            icon="$customComment"
            @click.stop="$emit('show-comments', eventData.id)"
          />
          <unread-comment-counter
            v-if="unreadCommentsCount > 0"
            class="bg-primary text-textSecondaryColor itinerary__item--unread"
            :unread-comments-count="unreadCommentsCount"
          />
        </v-col>
        <v-col v-if="directionLink" cols="1" class="mr-2">
          <v-icon
            color="primary"
            size="small"
            class="mr-4"
            data-test-id="map-button"
            icon="$customMap"
            @click.stop="proceedToMap"
          />
        </v-col>
        <v-col
          v-if="isEditable && (isOwner || isEditor)"
          cols="1"
          class="mr-2"
        >
          <v-icon
            size="small"
            class="mr-4"
            icon="$customDelete"
            @click.stop="$emit('delete-event', eventData.id)"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import itineraryService from '@/helpers/itineraryService'
import { formatPrice } from '@/helpers'
import { convertToUserCurrency } from '@/helpers/currency'
import UnreadCommentCounter from '@/components/popups/comment/UnreadCommentCounter'

export default {
  name: 'CalendarEventDialog',
  emits: ['close', 'edit-event', 'delete-event', 'show-comments'],
  components: {
    UnreadCommentCounter
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    eventData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dateFormat: 'ddd, MMM DD, YYYY',
      convertedPrice: '',
    }
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
      userCurrency: 'CURRENCY',
      isOwner: 'IS_OWNER',
      isEditor: 'IS_EDITOR',
      itineraryComments: 'COMMENTS',
    }),
    itemType() {
      return itineraryService.getEventType(this.record)
    },
    itemDetails() {
      return this.record[this.itemType]
    },
    event() {
      return this.eventData.originalEvent || this.eventData
    },
    isEditable() {
      return this.event.isEditable
    },
    totalPrice() {
      return this.itemDetails.totalPrice
    },
    formattedPrice() {
      return formatPrice(this.totalPrice)
    },
    duration() {
      return itineraryService.formatDuration(this.event.duration)
    },
    startDate() {
      return this.formatDateTime(this.event.time.start, this.dateFormat)
    },
    endDate() {
      return this.formatDateTime(this.event.time.end, this.dateFormat)
    },
    datetimeRange() {
      let datetimeRange = ''
      if(!this.isValidDateTime(this.event.time.start) || !this.isValidDateTime(this.event.time.end)) {
        datetimeRange = `${this.startDate} - ${this.endDate}`
        return datetimeRange
      }
      const startTime = this.formatDateTime(this.event.time.start, this.timeFormat)
      const endTime = this.formatDateTime(this.event.time.end, this.timeFormat)
      datetimeRange = `${this.startDate} · ${startTime} - `
      if(this.startDate !== this.endDate) {
        datetimeRange += `${this.endDate} · ${endTime}`
      } else {
        datetimeRange += `${endTime}`
      }
      return datetimeRange
    },
    unreadCommentsCount() {
      const unreadComments = this.itineraryComments[this.itineraryID]?.itemsComments[this.itemDetails.id]?.unread
      return unreadComments?.length || 0
    },
    directionLink() {
      return this.eventData.directionLink
    },
    itineraryID() {
      return this.$route.params?.id
    },
  },
  methods: {
    close(){
      this.$emit('close')
    },
    proceedToMap() {
      window.open(this.directionLink, '_blank')
    },
    isValidDateTime(dateTime) {
      return this.$moment(dateTime, 'YYYY-MM-DD HH:mm', true).isValid()
    },
    async convertTotalPrice() {
      const { amount, currency } = this.totalPrice
      if(!amount || !currency) return
      const convertedAmount = await convertToUserCurrency(amount, currency, this.userCurrency)
      this.convertedPrice = (currency !== this.userCurrency)
        ? formatPrice({ amount: convertedAmount, currency: this.userCurrency }) : ''
    },
    getEventType: itineraryService.getEventType,
    itemColor: itineraryService.itemColor,
    itemIcon: itineraryService.itemIcon,
    formatDateTime: itineraryService.formatDateTime,
  },
  watch: {
    totalPrice: {
      async handler() {
        this.convertTotalPrice()
      },
      immediate: true
    },
  }
}
</script>

<style scoped>
  .v-row {
    margin: 0;
  }
  .calendar-event-dialog {
    z-index: 3;
  }
</style>
