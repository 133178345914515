import axios from 'axios'
const URITemplate = require('urijs/src/URITemplate')

export const lookupAirlinesByIataCodes = async (iataCodes) => {
  let rv = []
  if (!Array.isArray(iataCodes)) {
    throw Error('Wrong parameter type: expected Array got String')
  }
  if (iataCodes && iataCodes.length > 0) {
    const template = URITemplate(process.env.VUE_APP_AIRLINE_LOOKUP_BY_IATA_CODE_URL)
    const url = template.expand({
      codes: iataCodes
    }).replace(/\/$/, '')
    let { data } = await axios.get(url)
    if (data?.length && data?.length > 0) {
      rv = data
    }
  }

  return rv
}

export const searchAirlines = async (query) => {
  let rv = []

  const response = await axios.get(process.env.VUE_APP_AIRLINES_SEARCH_URL, {
    params: {
      query:query,
      from: 0,
      size: 35
    }
  })
  if (response.status === 200) {
    rv = response?.data?.airlines
  }

  return rv
}


export default {
  lookupAirlinesByIataCodes,
  searchAirlines
}
