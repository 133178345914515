<template>
  <v-container data-test-name="content-features">
    <options-item
      v-for="item in optionsData"
      :key="item.key"
      :optionItem="item"
    />
  </v-container>
</template>

<script>
import OptionsItem from '@/components/welcomePage/OptionsItem'
import { RouteNames } from '@/helpers/routerUtils'

export default {
  name: 'WelcomePageNavigationOptions',
  components: {
    OptionsItem
  },
  data() {
    return {
      optionsData: [{
        id: 1,
        image: {
          src: {
            rawUrl: '/assets/images/illustartion_generateforme.png',
          },
        },
        mobileImage: {
          src: {
            rawUrl: '/assets/images/illustartion_generateforme_mobile.png',
          },
        },
        title: 'Generate_For_Me',
        description: 'Generate_For_Me_Description',
        buttonTitle: 'Generate_For_Me',
        route: RouteNames.GENERATE_ITINERARY,
      },
      {
        id: 2,
        image: {
          src: {
            rawUrl: '/assets/images/illustration_planmyself.png',
          },
        },
        mobileImage: {
          src: {
            rawUrl: '/assets/images/illustration_planmyself_mobile.png',
          },
        },
        title: 'Plan_Myself',
        description: 'Plan_Myself_Description',
        buttonTitle: 'Plan_Myself',
        route: RouteNames.CUSTOM,
      },
      {
        id: 3,
        image: {
          src: {
            rawUrl: '/assets/images/illustration_usesharedplan.png',
          },
        },
        mobileImage: {
          src: {
            rawUrl: '/assets/images/illustration_usesharedplan_mobile.png',
          },
        },
        title: 'Use_Community',
        description: 'Use_Community_Description',
        buttonTitle: 'Use_Community',
        route: RouteNames.COMMUNITY,
      }],
    }
  },
}
</script>
