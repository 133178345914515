<template>
  <v-row
    class="pb-11 flex-column mx-0"
    align="center"
    justify="center"
    data-test-name="page-delete-personal-data"
  >
    <v-col
      cols="12"
      sm="8"
      class="d-flex mx-0 overflow-x-hidden"
    >
      <h1 class="text-h3 text-sm-h2 font-weight-bold text-center text-break" data-test-name="title">
        {{ $t("Clear_data") }}
      </h1>
    </v-col>
    <v-col
      cols="11"
      sm="8"
      class="pt-16 pb-sm-6 mx-0 px-0"
      data-test-name="text"
    >
      <p>
        The California Consumer Privacy Act (CCPA) is the first big
        state-wide privacy legislation in the US. It entered into force on
        January 1, 2020 in the US after the European GDPR reshaped how
        data privacy law looks in May 2018.
      </p>
      <p>
        The General Data Protection Regulation (GDPR) is an EU law that
        came into effect in May 2018 and is uniformly binding in all 27
        member states. GDPR controls how websites, companies and
        organizations are allowed to handle personal data, which is
        anything from names, e-mail addresses, location data, browser
        history and many other things
      </p>
    </v-col>
    <v-col
      cols="11"
      sm="8"
      class="py-0 d-flex justify-center justify-sm-start mx-0 px-0"
    >
      <button-primary
        data-test-name="button-delete-personal-data"
        :text="$t('Delete_private_data')"
        @click="askToDeleteData"
        width="auto"
      />
      <common-popup
        v-if="isConfirmationWindowVisible"
        :body-content="$t('Question_to_clear_data')"
        @confirm="deleteData"
        @cancel="rejectDeletion"
        @close="closeConfirmationPopup"
      />
    </v-col>
  </v-row>
</template>

<script>
import CommonPopup from '@/components/popups/CommonPopup'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'

export default {
  name: 'DeletionPersonalData',
  components: {
    CommonPopup,
    ButtonPrimary,
  },
  data() {
    return {
      isConfirmationWindowVisible: false,
    }
  },
  methods: {
    askToDeleteData() {
      this.openModal()
    },
    deleteData() {
      this.closeModal()
    },
    rejectDeletion() {
      this.closeModal()
    },
    closeConfirmationPopup() {
      this.closeModal()
    },
    openModal() {
      this.isConfirmationWindowVisible = true
    },
    closeModal() {
      this.isConfirmationWindowVisible = false
    },
  },
}
</script>
