const PASSWORD_CHECK_PATTERN = /^\S{8,}$/

export const firstName = {
  name: 'firstName',
  value: '',
  required: true,
  pattern: /^[a-zA-Z ,.'-]+$/,
  requiredLength: {min: 2, max: 50},
  label: 'First_Name',
  placeholder: 'First_Name',
  blurred: false,
  errors: [],
  errorIncorrect: 'Allowed_chars_error',
  errorLength: 'First_Name_Length_Error',
  errorEmpty: 'Empty_Field_Error',
}

export const lastName = {
  name: 'lastName',
  value: '',
  required: true,
  pattern: /^[a-zA-Z ,.'-]+$/,
  requiredLength: {min: 2, max: 50},
  label: 'Last_Name',
  placeholder: 'Last_Name',
  blurred: false,
  errors: [],
  errorIncorrect: 'Allowed_chars_error',
  errorLength: 'Last_Name_Length_Error',
  errorEmpty: 'Empty_Field_Error',
}

export const email = {
  name: 'email',
  value: '',
  required: true,
  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  label: 'Email',
  placeholder: 'Email',
  blurred: false,
  errors: [],
  errorIncorrect: 'Email_Error',
  errorEmpty: 'Empty_Field_Error',
}

export const phone = {
  name: 'phone',
  value: '',
  required: false,
  pattern: /^[0-9\s\-+()]*$/,
  requiredLength: {min: 8, max: 20},
  label: 'Phone',
  placeholder: 'Phone',
  errorIncorrect: 'Phone_Error',
  errorLength: 'Phone_Length_Error',
  errorEmpty: 'Empty_Field_Error',
}

export const homePlaceID = {
  name: 'homePlaceID',
  label: 'Home_Airport',
  placeholder: 'Home_Airport',
  required: false,
}

export const dateFormat = {
  name: 'dateFormat',
  label: 'Date_Format',
  placeholder: 'Date_Format',
  items: [
    { text: 'MM_DD_YYYY', value: 'MM-DD-YYYY'},
    { text: 'DD_MM_YYYY', value: 'DD-MM-YYYY'}
  ],
  required: false,
}

export const timeFormat = {
  name: 'timeFormat',
  label: 'Time_Format',
  placeholder: 'Time_Format',
  items: [
    { text: 'Hour_12', value: 'h:mm A'},
    { text: 'Hour_24', value: 'HH:mm'}
  ],
  required: false,
}

export const currency = {
  name: 'currency',
  label: 'Preferred_Currency',
  placeholder: 'Preferred_Currency',
  required: false,
}

export const language = {
  name: 'language',
  label: 'Language',
  placeholder: 'Language',
  required: false,
}

export const preferredHotelClass = {
  name: 'preferredHotelClass',
  label: 'Hotel_Class',
  placeholder: 'Select_Hotel_Class',
  required: false,
}

export const preferredFlightClass = {
  name: 'preferredFlightClass',
  label: 'Flight_Class',
  placeholder: 'Select_Flight_Class',
  required: false,
}

export const preferredRestaurantRating = {
  name: 'preferredRestaurantRating',
  label: 'Restaurant_Price_Range',
  placeholder: 'Select_Restaurant_Price',
  required: false,
}

export const hotelMembership = {
  name: 'hotelMembership',
  label: 'Preferred_Hotel_Chain',
  placeholder: 'Select_Preferred_Hotels',
  required: false,
}

export const airlineMembership = {
  name: 'airlineMembership',
  label: 'Preferred_Airlines',
  placeholder: 'Select_Preferred_Airlines',
  required: false,
}

export const currentPassword = {
  name: 'currentPassword',
  value: '',
  required: false,
  pattern: PASSWORD_CHECK_PATTERN,
  label: 'Current_Password',
  placeholder: 'Current_Password',
  hint: 'Password_Hint',
  isValueVisible: false,
  blurred: false,
  errors: [],
  errorIncorrect: 'Short_Password_Error',
  errorEmpty: 'Empty_Field_Error',
  errorMatch: 'Passwords_Must_Match',
  errorMismatch: 'Passwords_Must_Not_Match',
  errorSpace: 'Space_Error'
}

export const newPassword = {
  name: 'newPassword',
  value: '',
  required: false,
  pattern: PASSWORD_CHECK_PATTERN,
  label: 'New_Password',
  placeholder: 'New_Password',
  hint: 'Password_Hint',
  isValueVisible: false,
  blurred: false,
  errors: [],
  errorIncorrect: 'Short_Password_Error',
  errorEmpty: 'Empty_Field_Error',
  errorMatch: 'Passwords_Must_Match',
  errorMismatch: 'Passwords_Must_Not_Match',
  errorSpace: 'Space_Error'
}

export const repeatNewPassword = {
  name: 'password',
  value: '',
  required: false,
  pattern: PASSWORD_CHECK_PATTERN,
  label: 'Repeat_New_Password',
  placeholder: 'Repeat_New_Password',
  hint: 'Password_Hint',
  isValueVisible: false,
  blurred: false,
  errors: [],
  errorIncorrect: 'Short_Password_Error',
  errorEmpty: 'Empty_Field_Error',
  errorMatch: 'Passwords_Must_Match',
  errorMismatch: 'Passwords_Must_Not_Match',
  errorSpace: 'Space_Error'
}

export const view = {
  name: 'view',
  label: 'View',
  placeholder: 'View',
  required: false,
}

export const userProfileFields = {
  firstName,
  lastName,
  email,
  phone,
  homePlaceID,
  dateFormat,
  timeFormat,
  currency,
  language,
  preferredHotelClass,
  preferredFlightClass,
  preferredRestaurantRating,
  hotelMembership,
  airlineMembership,
  view
}

export default {
  firstName,
  lastName,
  email,
  phone,
  homePlaceID,
  dateFormat,
  timeFormat,
  currency,
  language,
  preferredHotelClass,
  preferredFlightClass,
  preferredRestaurantRating,
  hotelMembership,
  airlineMembership,
  currentPassword,
  newPassword,
  repeatNewPassword
}
