<template v-slot:default>
  <v-select
    :items="starsItems"
    :model-value="currentRating"
    :placeholder="$t(preferredHotelClass?.placeholder)"
    :label="$t(preferredHotelClass?.label)"
    data-test-name="select-hotels-class"
    prepend-inner-icon="$customHotelOutline"
    variant="outlined"
    color="primary"
    base-color="primary"
    bg-color="#fff"
    hide-details
    @update:model-value="selectedHotelClass"
  >
    <template v-slot:item="{ props }">
      <v-list-item v-bind="props" class="text-caption text-secondary font-weight-normal" data-test-name="list-item">
        <v-rating
          v-bind="props"
          size="24"
          full-icon="mdi-star"
          empty-icon=""
          disabled
          data-test-name="rating"
          :model-value="props.value"
        />
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <div class="d-flex text-caption text-secondary" v-for="i in currentRating" :key="i">
        <v-icon size="24" icon="mdi-star" :title="item.value"/>
      </div>
    </template>
  </v-select>
</template>
<script>
import { preferredHotelClass } from '@/helpers'
import { eventRatings } from '@/helpers/commonConfigs'

export default {
  name: 'HotelClassSelector',
  emits: ['update'],
  data() {
    return {
      preferredHotelClass: '',
      currentRating: ''
    }
  },
  props: {
    preferredHotelClassRating: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    starsItems() {
      const rv = []
      eventRatings.forEach(item => {
        rv.push({ title: '', value: item.value })
      })
      return rv
    }
  },
  methods: {
    selectedHotelClass(rating) {
      this.currentRating = rating
      this.$emit('update', rating)
    }
  },
  created() {
    this.preferredHotelClass = preferredHotelClass
    this.currentRating = this.preferredHotelClassRating
  },
  watch: {
    preferredHotelClassRating: {
      handler(newVal) {
        this.currentRating = newVal
      },
      immediate: true,
    }
  }
}
</script>
