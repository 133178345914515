import commonAPIs from './commonAPIs'
import moment from 'moment'
import datetime from '@/helpers/datetime'
import placeSearch from '@/helpers/placeSearch'

export default {
  /**
   * this will create a flight event struct
   * @param departLocation
   * @param departDateTime
   * @param arriveLocation
   * @param arriveDateTime
   * @param totalPrice
   * @param carrierCode
   * @param tripNumber
   * @param duration
   */
  async createEventStruct(departurePlaceID, departureDateTime, arrivalPlaceID, arrivalDateTime, totalPrice, carrier, number, seats, seatsClass) {
    let departureLocation = { placeID: '', iataCode: '' }
    let arrivalLocation = { placeID: '', iataCode: '' }

    if (departurePlaceID || arrivalPlaceID) {
      const locations = await placeSearch.lookupPlacesByIds([departurePlaceID, arrivalPlaceID])
      locations.forEach(location => {
        departureLocation = (location?.placeID === departurePlaceID) ? { placeID: location?.placeID, iataCode: location?.airportPlace?.iataCode } : departureLocation
        arrivalLocation = (location?.placeID === arrivalPlaceID) ? { placeID: location?.placeID, iataCode: location?.airportPlace?.iataCode } : arrivalLocation
      })
    }

    return {
      title: departurePlaceID + ' ' + arrivalPlaceID,
      totalPrice: totalPrice,
      segments: [
        await this.createSegmentStruct(departureDateTime, departureLocation, arrivalDateTime, arrivalLocation, carrier, number, seats, seatsClass)
      ],
    }
  },

  /**
   * this will create a flight segment struct
   * @param departDateTime
   * @param departLocation
   * @param arriveDateTime
   * @param arriveLocation
   * @param carrier
   * @param number
   * @param seats
   * @param seatsClass
   */
  async createSegmentStruct(departureDateTime, departureLocation, arrivalDateTime, arrivalLocation, carrier, number, seats, seatsClass) {
    let locations = [departureLocation, arrivalLocation]
    if (!departureLocation?.airportPlace && !departureLocation?.cityPlace) {
      locations = await placeSearch.lookupPlacesByIds([departureLocation?.placeID, arrivalLocation?.placeID])
    }
    return {
      departure: {
        at: await datetime.convertDateTimeISOStringToLocalizedString( moment.parseZone(departureDateTime).format('YYYY-MM-DDTHH:mm:ssZ'), departureLocation?.placeID ),
        location: (locations && locations?.[0]
          ? {
            placeID:  locations[0].placeID,
            iataCode: locations[0].airportPlace?.iataCode || locations[0].city,
            latLon:   locations[0].latLon,
          }
          : { placeID: '', iataCode: '' }),
      },
      arrival: {
        at: await datetime.convertDateTimeISOStringToLocalizedString(moment.parseZone(arrivalDateTime).format('YYYY-MM-DDTHH:mm:ssZ'), arrivalLocation?.placeID ),
        location: (locations && locations?.[1]
          ? {
            placeID:  locations[1].placeID,
            iataCode: locations[1].airportPlace?.iataCode || locations[1].city,
            latLon:   locations[1].latLon,
          }
          : { placeID: '', iataCode: '' }),
      },
      carrier: {
        carrier: carrier,
        number: number,
      },
      seats: seats,
      seatsClass: seatsClass,
    }
  },

  async autoFill(params) {
    await commonAPIs.createTransportationEvents({...params})
  },

  async parseEventData(event) {
    let fullName = '',
      duration = 0,
      departureLocation,
      arrivalLocation

    let segments = event?.segments
    let totalPrice = event?.totalPrice
    if (Array.isArray(segments) && segments.length > 0) {
      let departureSegment = segments[0]
      let arrivalSegment = segments[segments.length - 1]
      const places = await placeSearch.lookupPlacesByIds([departureSegment.departure.location.placeID, arrivalSegment.arrival.location.placeID])
      departureLocation = places[0]
      arrivalLocation = places[1]

      fullName =
        '(' +
        departureSegment.departure.location.placeID +
        ')' +
        ' - ' +
        '(' +
        arrivalSegment.arrival.location.placeID +
        ')'

      let departureDateTime = moment.parseZone(departureSegment.at)
      let arrivalDateTime = moment.parseZone(arrivalSegment.at)
      duration = moment.duration(departureDateTime.diff(arrivalDateTime)).as('minutes')
    }
    return {
      departureLocation: departureLocation,
      arrivalLocation: arrivalLocation,
      totalPrice: totalPrice,
      segments: segments,
      fullName: fullName,
      duration: duration,
    }
  },
}
