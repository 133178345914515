<template>
  <dialog-wrapper
    ref="dw"
    min-width="280"
    min-height="100"
    :contentClass="contentClass"
    :hide-overlay="hideOverlay"
    @open="$emit('open')"
    :name="name"
  >
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
      <v-btn
        v-if="currentIcon"
        color="primary"
        rounded
        variant="text"
        size="small"
        v-on:click="activatorProps.on"
      >
        <v-icon color="primary">{{ currentIcon }}</v-icon>
      </v-btn>
    </template>

    <template v-slot:default="{ off }">
      <v-card>
        <v-list>
          <v-list-item v-if="headerText">
            <div>
              <strong>{{headerText}}</strong>
            </div>
            <v-list-item-action class="hidden-md-and-up">
              <v-btn icon @click="off">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="headerText"></v-divider>
          <v-list-item
            v-for="item in items"
            :key="item.type"
            @click="processClick(item)"
          >
            <v-icon :color="item.type === value ? 'primary' : 'secondary'">{{item.icon}}</v-icon>
            <div>
              <div class="d-flex justify-space-between">
                <div
                  :class="item.type === value ? 'text-primary' : 'text-secondary'"
                  class="d-flex align-center"
                >
                  {{item.text}}
                </div>
                <v-icon color="primary" v-if="item.type === value">done</v-icon>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </template>
  </dialog-wrapper>
</template>

<script>
import DialogWrapper from '../DialogWrapper'

export default {
  name: 'MenuPopup',
  components: { DialogWrapper },
  emits: ['open', 'clickItem'],
  props: {
    value: String,
    items: {
      type: Array,
      required: true,
    },
    name: String,
    activatorIcon: String,
    headerText: String,
    contentClass: String,
    hideOverlay: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    currentIcon() {
      let icon
      if(this.value) {
        icon = this.items.find(item => item.type === this.value)?.icon
      }
      return icon || this.activatorIcon
    },
  },
  methods: {
    processClick(item) {
      this.$emit('clickItem', item)
      this.$refs?.dw && this.$refs.dw.dialogClose()
    }
  }
}
</script>
