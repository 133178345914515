export const carTypes = [
  {title: 'SUV',            value: 'SUV'},
  {title: 'Hatchback',      value: 'Hatchback'},
  {title: 'Crossover',      value: 'Crossover'},
  {title: 'Convertible',    value: 'Convertible'},
  {title: 'Sport Car',      value: 'SportCar'},
  {title: 'Coupe',          value: 'Coupe'},
  {title: 'Minivan',        value: 'Minivan'},
  {title: 'Station Wagon',  value: 'StationWagon'},
  {title: 'Truck',          value: 'Truck'},
]
