<template>
  <v-container data-test-name="page-welcome" class="pa-0" :fluid="$vuetify.display.lgAndDown">
    <v-row class="flex-column" align="center" no-gutters>
      <v-col>
        <welcome-page-top-component />
      </v-col>
      <v-col lg="9" xl="8">
        <welcome-page-navigation-options />
      </v-col>
      <v-col lg="10" xl="9">
        <popular-trips />
      </v-col>
      <v-col lg="10" xl="9">
        <blog-section />
      </v-col>
      <v-col>
        <more-about-trava />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PopularTrips from '@/components/welcomePage/PopularTrips'
import MoreAboutTrava from '@/components/welcomePage/MoreAboutTrava'
import WelcomePageTopComponent from '@/components/welcomePage/WelcomePageTopComponent'
import WelcomePageNavigationOptions from '@/components/welcomePage/WelcomePageNavigationOptions'
import BlogSection from '@/components/welcomePage/BlogSection'

export default {
  name: 'WelcomePage',
  components: {
    WelcomePageTopComponent,
    BlogSection,
    WelcomePageNavigationOptions,
    PopularTrips,
    MoreAboutTrava
  },
}
</script>
