<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.63964 10.9412C4.04162 10.1395 5.04419 8.92442 6.67643 8.15815C7.58464 7.73178 8.6969 10 10 10C11.255 10 12.3154 7.71094 13.2039 8.11149C14.8961 8.87432 15.9319 10.123 16.3421 10.9412C17.0391 12.1477 17.4076 13.7283 17.4076 15.3372V18.1943C17.4076 18.6086 17.0718 18.9443 16.6576 18.9443H3.32422C2.91001 18.9443 2.57422 18.6086 2.57422 18.1943V15.3372C2.57422 13.7283 2.94269 12.1477 3.63964 10.9412ZM9.99089 10C9.24178 10 8.57906 9.06037 8 9.24729C6.26212 9.80831 5.27785 11.0081 4.97372 11.6273C4.96583 11.6433 4.95737 11.6591 4.94836 11.6746C4.40352 12.6086 4.07422 13.9252 4.07422 15.3372V17.4443H15.9076V15.3372C15.9076 13.9252 15.5783 12.6086 15.0334 11.6746C15.0244 11.6591 15.0159 11.6433 15.0081 11.6273C14.7135 11.0276 13.7809 9.88324 12.1436 9.30212C11.5271 9.08331 10.8107 10 9.99089 10Z"
      fill="#0144C7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99089 2.27734C8.10391 2.27734 6.57422 3.80704 6.57422 5.69401C6.57422 7.58098 8.10391 9.11068 9.99089 9.11068C11.8779 9.11068 13.4076 7.58098 13.4076 5.69401C13.4076 3.80704 11.8779 2.27734 9.99089 2.27734ZM5.07422 5.69401C5.07422 2.97861 7.27549 0.777344 9.99089 0.777344C12.7063 0.777344 14.9076 2.97861 14.9076 5.69401C14.9076 8.40941 12.7063 10.6107 9.99089 10.6107C7.27549 10.6107 5.07422 8.40941 5.07422 5.69401Z"
      fill="#0144C7"
    />
  </svg>
</template>
