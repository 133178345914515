<template>
  <div class="px-2 px-sm-12 pb-6 pb-sm-12 blogContainer" data-test-name="page-blog">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2 my-sm-8 d-flex justify-center text-h2" data-test-name="text-title">
        <span class="font-weight-regular">{{ $t('Our') + " " }}</span>
        <span class="header text-primary font-weight-bold text-lowercase"> {{ $t(' Blog')}}</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-chip-group
          v-model="selectedLabelId"
          mandatory
        >
          <v-chip
            v-for="label in labels"
            :key="label"
            data-test-name="chip"
            color="rgba(1, 68, 199, 0.6)"
          >
            {{ label }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col>
        <v-row v-if="!isPageLoading && currentPageChunk.length > 0" no-gutters>
          <v-col cols="12" sm="6" md="4" v-for="post in currentPageChunk" :key="post.id">
            <blog-card
              class="blog-card"
              :post="post"
              :selectedLabel="selectedLabel"
              @select="() => onSelectPost(post.id)"
              @selectLabel="onSelectLabel"
            />
          </v-col>
        </v-row>
        <v-row
          v-else-if="!isPageLoading"
          class="d-flex justify-center"
          no-gutters
        >
          <div
            class="mb-4 d-flex text-h6 text-sm-h3 text-primary font-weight-medium"
            data-test-name="text-no-results"
          >
            {{ $t('No_results_found')}}
          </div>
        </v-row>
      </v-col>
      <extended-pagination
        v-if="currentPageChunk.length > 0"
        class="my-2"
        :isStartButtonDisabled="currentPageNumber === 1"
        :isEndButtonDisabled="currentPageNumber === allBlogPosts.length"
        :isEndButtonVisible="lastPageReached && currentPageNumber < pagesCount - 3"
        @startButtonClicked="currentPageNumber = 1"
        @endButtonClicked="currentPageNumber = allBlogPosts.length"
      >
        <v-pagination
          v-model="currentPageNumber"
          :length="pagesCount"
          :total-visible="$vuetify.display.xsOnly? 3 : 6"
          data-test-name="pagination"
          rounded
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
        />
      </extended-pagination>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BlogCard from '@/components/blog/BlogCard.vue'
import ExtendedPagination from '@/components/ExtendedPagination.vue'
import { RouteNames } from '@/helpers/routerUtils'
import { pageScroll } from '@/mixins/PageScrollMixin'

export default {
  name: 'BlogPage',
  components: {
    BlogCard,
    ExtendedPagination,
  },
  mixins: [pageScroll],
  data() {
    return {
      selectedLabelId: 0,
      currentPageNumber: 1,
      isPageLoading: false,
      lastPageReached: false,
    }
  },
  mounted() {
    this.loadBlogPostsPage(true)
  },
  computed: {
    ...mapGetters({
      allBlogPosts: 'ALL_BLOG_POSTS',
    }),
    pagesCount() {
      return this.allBlogPosts.length + (this.allBlogPosts[this.allBlogPosts.length - 1]?.nextPageToken ? 1: 0)
    },
    blogPostsInfo() {
      return this.allBlogPosts[this.currentPageNumber - 1].items || []
    },
    labels() {
      return ['All', 'Culture', 'History', 'Museums', 'Shopping', 'Nature', 'Romantic']
    },
    currentPageChunk() {
      return this.allBlogPosts[this.currentPageNumber - 1]?.items || []
    },
    isLastPage() {
      return !this.isPageLoading && !this.allBlogPosts[this.currentPageNumber - 1]?.nextPageToken
    },
    selectedLabel() {
      return this.labels[this.selectedLabelId]
    },
  },
  methods: {
    ...mapActions({
      showLoader: 'LOADING_DIALOG',
      loadBlogPosts: 'LOAD_BLOG_POSTS',
    }),
    async loadBlogPostsPage(clearCache) {
      this.isPageLoading = true
      this.showLoader({
        visible: true,
        opacity: 0.5,
        overlayColor: 'var(--v-textSecondaryColor-base)'
      })
      try {
        await this.loadBlogPosts({
          nextPageToken: clearCache? '': this.allBlogPosts[this.currentPageNumber - 2]?.nextPageToken,
          // selectedLabelId === 0 when label 'All' is selected. In this case 'label' should be excluded from query params
          labels: this.selectedLabelId === 0 ? '': this.selectedLabel,
        })
        this.scrollToTheTop()
      } finally {
        this.isPageLoading = false
        this.showLoader(null)
      }
    },
    onSelectPost(id) {
      this.$router.push({ name: RouteNames.BLOG_POST, params: { id: id } })
    },
    onSelectLabel(label) {
      const selectedLabelId = this.labels.findIndex(el => el === label)
      this.selectedLabelId = selectedLabelId
    },
  },
  watch: {
    selectedLabelId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.currentPageNumber = 1
        }
      },
      immediate: true
    },
    currentPageNumber: {
      handler(newVal, oldVal) {
        const isPageBlogPostsLoaded = this.allBlogPosts[newVal - 1]
        if (newVal > oldVal && !isPageBlogPostsLoaded) {
          this.loadBlogPostsPage()
        } else {
          this.scrollToTheTop()
        }
      },
      immediate: true
    },
    selectedLabel: {
      handler() {
        this.loadBlogPostsPage(true)
      },
      immediate: true
    },
    isLastPage: {
      handler(isLast) {
        if (isLast) {
          this.lastPageReached = true
        }
      },
      immediate: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.blogContainer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  .header {
    white-space: pre;
  }
}
</style>
