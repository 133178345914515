<template>
  <v-text-field
    :model-value="text"
    :variant="isEditModeEnabled ? 'outlined' : 'plain'"
    ref="inputRef"
    :rules="rules"
    hide-details="auto"
    :maxlength="maxlength"
    @click="isEditModeEnabled = true"
    @update:model-value="updateText"
    @keydown="keyPress"
    @blur="cancelEdit"
  >
    <template #prepend>
      <v-icon color="primary" data-test-name="icon">$customFlag</v-icon>
      <slot name="prepend" />
    </template>
    <template #append-inner>
      <v-icon
        :key="`icon-${isEditModeEnabled}`"
        @click="isEditModeEnabled = true"
        color="primary"
        data-test-name="button-toggle-mode"
      >
        {{ isEditModeEnabled ? '' : 'mdi-pencil' }}
      </v-icon>
    </template>
    <slot name="append-outer"></slot>
  </v-text-field>
</template>
<script>

import debounce from 'lodash.debounce'

export default {
  name: 'TextEditCombo',
  emits: ['changed', 'update'],
  props: {
    value: String,
    maxlength: {
      type: Number,
      required: false,
      default: 200,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isEditModeEnabled: false,
      text: this.value,
    }
  },
  created(){
    this.debounceInput = debounce(() => {
      this.$emit('changed', this.text)
    }, 1000)
  },
  methods: {
    updateText(value) {
      this.text = value.trim()
      this.$emit('update', this.text)
      this.debounceInput()
    },
    cancelEdit() {
      this.text = this.value
      this.isEditModeEnabled = false
      this.$refs.inputRef.blur()
    },
    edit() {
      this.$emit('changed', this.text)
      this.debounceInput.cancel()
      this.isEditModeEnabled = false
      this.$refs.inputRef.blur()
    },
    keyPress(event) {
      switch (event.key) {
      case 'Enter':
        this.edit()
        break
      case 'Escape':
        this.cancelEdit()
        break
      }
    },
  },
  beforeUnmount() {
    this.debounceInput.cancel()
  },
}
</script>
