<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.665 11.2802V13.2802C14.6657 13.4659 14.6277 13.6497 14.5533 13.8198C14.479 13.9899 14.3699 14.1426 14.233 14.2681C14.0962 14.3937 13.9347 14.4892 13.7588 14.5487C13.5829 14.6082 13.3966 14.6303 13.2117 14.6136C11.1602 14.3907 9.18966 13.6897 7.45833 12.5669C5.84755 11.5433 4.48189 10.1777 3.45833 8.56689C2.33165 6.8277 1.63049 4.84756 1.41166 2.78689C1.395 2.60254 1.41691 2.41673 1.47599 2.24131C1.53508 2.06589 1.63004 1.90469 1.75484 1.76797C1.87964 1.63126 2.03153 1.52203 2.20086 1.44724C2.37018 1.37245 2.55322 1.33374 2.73833 1.33356H4.73833C5.06187 1.33038 5.37552 1.44495 5.62084 1.65592C5.86615 1.86689 6.02638 2.15986 6.07166 2.48023C6.15608 3.12027 6.31263 3.74871 6.53833 4.35356C6.62802 4.59218 6.64744 4.8515 6.59427 5.10081C6.5411 5.35012 6.41757 5.57897 6.23833 5.76023L5.39166 6.60689C6.3407 8.27592 7.72263 9.65786 9.39166 10.6069L10.2383 9.76023C10.4196 9.58099 10.6484 9.45746 10.8977 9.40429C11.1471 9.35112 11.4064 9.37053 11.645 9.46023C12.2498 9.68593 12.8783 9.84248 13.5183 9.92689C13.8422 9.97258 14.1379 10.1357 14.3494 10.3852C14.5608 10.6348 14.6731 10.9533 14.665 11.2802Z"
      stroke="#0144C7"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
