import commonAPIs from './commonAPIs'
import uiUtils from '../uiUtils'
import itineraryService from '../itineraryService'
import { EventTypes } from '../itemTypes'
import moment from 'moment'
import { store } from '../../store'

export const NIGHT_AT_HOTEL =  {startTime: {hour: 23, minute: 59}, duration: {hours:6, minutes: 1}}
export const CHECK_IN_TIME = '15:00'
export const CHECK_OUT_TIME = '11:00'

export default {
  /**
    payload = {
      location: {
        placeID: String
      },
      checkInDateTime: {year: Number, month: Number, day: Number, hour: Number, minute: Number}
      checkOutDateTime: {year: Number, month: Number, day: Number, hour: Number, minute: Number}
      travelers: {adults: Number, children: Number}
      size: Number of results expected
      keywords: String
      userID: String
    }
   */
  async search(payload) {
    let rv = []
    try {
      rv = await store.dispatch('SEARCH_HOTELS', payload)
    } catch (error) {
      rv = []
    }
    return rv
  },

  /**
    autoFill will fetch and add hotel to the ititnerary provided:
      -locationsAndDates is an array:
      [
        { placeID, arrivalDateTime, departureDateTime },
        { placeID, arrivalDateTime, departureDateTime }
        ...
      ]
      -placeID: String
      -arrivalDateTime:   moment object
      -departureDateTime: moment object
   */
  async autoFill({ locationsAndDates, itineraryID, userID, travelers, routes }) {
    const hotels = []
    const isRoundTrip = commonAPIs.isRoundTrip(routes)

    uiUtils.loadingDialog(EventTypes.LODGING)

    for (let i=0; i<locationsAndDates.length; i++) {
      const location = locationsAndDates[i]

      if (location.isLastLocation && isRoundTrip) break

      const payload = {
        location: {
          placeID: routes[i].toPlaceID,
        },
        checkInDateTime: commonAPIs.momentDateTimeToStruct(moment.parseZone(location.arrivalDateTime)),
        checkOutDateTime: commonAPIs.momentDateTimeToStruct(moment.parseZone(location.departureDateTime)),
        travelers,
        size: 1,
        userID
      }
      const hotel = await this.search(payload)

      if (Array.isArray(hotel) && hotel.length > 0)
        hotels.push(hotel[0])
    }

    if (hotels.length > 0) {
      await commonAPIs.addItems(itineraryID, userID, hotels)
    }

    uiUtils.loadingDialog()
  },

  /**
    this method will insert "night at hotel" records into existing itinerary
  */
  addNightAtHotel(records) {
    let rv = []
    let currentHotel = null

    for (let i=0; i<records.length; i++) {
      let record = {...records[i]}
      rv.push(record)
      if (itineraryService.getEventType(record) === EventTypes.LODGING) {
        currentHotel = {...record}
        const dates = this.getHotelDates(currentHotel)
        this.addNightAtHotelEvents(currentHotel, dates, rv)
        rv.push(this.getCheckOutEvent(currentHotel))
      }
    }

    return rv.sort((a,b) => {
      return new moment(a.header.startDateTime).diff(new moment(b.header.startDateTime))
    })
  },

  getCheckOutEvent(currentHotel) {
    let newEvent = {}
    newEvent.header = {...currentHotel.header}
    newEvent[EventTypes.LODGING] = {...currentHotel[EventTypes.LODGING]}
    newEvent.header.startDateTime = moment.parseZone(currentHotel.header.endDateTime).format('YYYY-MM-DDTHH:mm:ssZ')
    newEvent.header.endDateTime = moment.parseZone(currentHotel.header.endDateTime).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ')
    newEvent.header.checkOut = true

    return newEvent
  },
  addNightAtHotelEvents(currentHotel, dates, records) {
    for (let i=0; i<dates.length; i++) {
      records.push(this.getNightAtHotelEvent(currentHotel, dates[i]))
    }
  },

  getHotelDates(currentHotel) {
    let checkInDateTime = moment.parseZone(currentHotel.header.startDateTime).clone()
    let checkOutDateTime = moment.parseZone(currentHotel.header.endDateTime).clone()
    let currentDate = checkInDateTime.clone()
    let dates = []

    while(currentDate.isBefore(checkOutDateTime.set('hours', 0))) {
      dates.push(currentDate)
      currentDate = currentDate.clone().add(1, 'days')
    }
    //this.printDatesArray(dates, 'in getHotelDates')
    return dates
  },

  /**
    returns 'night at hotel' event
   */
  getNightAtHotelEvent(currentHotel, currentDate) {
    let eventStartDateTime = moment.parseZone(currentDate).clone()
    eventStartDateTime = eventStartDateTime
      .hour(NIGHT_AT_HOTEL.startTime.hour)
      .minute(NIGHT_AT_HOTEL.startTime.minute)
    let eventEndDateTime = moment.parseZone(eventStartDateTime).clone()
    eventEndDateTime = eventEndDateTime
      .add(NIGHT_AT_HOTEL.duration.hours, 'hours')
      .add(NIGHT_AT_HOTEL.duration.minutes, 'minutes')

    let newEvent = {}
    newEvent.header = {...currentHotel.header}
    newEvent[EventTypes.LODGING] = {...currentHotel[EventTypes.LODGING]}
    newEvent.header.startDateTime = eventStartDateTime.format('YYYY-MM-DDTHH:mm:ssZ')
    newEvent.header.endDateTime = eventEndDateTime.format('YYYY-MM-DDTHH:mm:ssZ')
    newEvent.header.nightAtHotel = true

    return newEvent
  },
}
