export const itineraryMobileView = [
  { type: 'agenda', text: 'Agenda', icon: 'mdi-view-list' },
  { type: 'calendar', text: 'Calendar', icon: 'mdi-calendar-week' },
]

export const itineraryView = [
  { type: 'agenda', text: 'Agenda', icon: 'mdi-view-list' },
  { type: 'calendar', text: 'Calendar', icon: 'mdi-calendar-week' },
]

