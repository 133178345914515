<template>
    <dialog-wrapper
      ref="dw"
      minWidth="270"
      offset-y="48"
      content-class="header-navigation-menu"
      @open="onOpenDialog"
      @close="onCloseDialog"
      :name="name"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on:click="on"
          color="var(--v-textPrimaryColor-base)"
          data-test-name="button-open-menu"
        >
          <v-icon size="large">$customBurger</v-icon>
        </v-btn>
      </template>

      <template v-slot:default="{ off }">
        <v-card>
          <v-list nav data-test-name="menu">
            <v-list-item-action
              class="hidden-sm-and-up my-1"
            >
              <v-btn
                data-test-name="button-close"
                variant="flat"
                @click="off"
                prepend-icon="mdi-close"
              />
            </v-list-item-action>

            <v-list-item>
              <router-link
                :to="'/' + routeNames.GENERATE_ITINERARY"
                active-class="text-primary"
                class="my-3 my-sm-0 text-decoration-none text-black"
                data-test-name="menu-item-generate-itinerary"
                @click="closeDw"
              >
                <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('Generate_For_Me') }}</v-list-item-title>
              </router-link>
            </v-list-item>

            <v-list-item>
              <router-link
                :to="'/' + routeNames.CUSTOM"
                active-class="text-primary"
                class="my-3 my-sm-0 text-decoration-none text-black"
                data-test-name="menu-item-plan-myself"
                @click="closeDw"
              >
                <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('Plan_Myself') }}</v-list-item-title>
              </router-link>
            </v-list-item>

            <v-list-item>
              <router-link
                :class="{ 'text-primary': isCommunityTemplatePage }"
                :to="'/' + routeNames.COMMUNITY"
                active-class="text-primary"
                class="my-3 my-sm-0 text-decoration-none text-black"
                data-test-name="menu-item-use-community"
                @click="closeDw"
              >
                <v-list-item-title class="text-body-1 font-weight-medium">{{ $t('Use_Community') }}</v-list-item-title>
              </router-link>
            </v-list-item>
          </v-list>
        </v-card>
      </template>

    </dialog-wrapper>
</template>

<script>
import DialogWrapper from '@/components/DialogWrapper'
import { mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import { useRoute } from 'vue-router'

export default {
  name: 'HeaderNavigationMenu',
  components: {DialogWrapper},
  data(){
    return {
      isActive: false,
      route: useRoute(),
    }
  },
  props: {
    name: {
      type: String,
      default: 'header-navigation-menu',
    },
  },
  computed: {
    ...mapGetters({
      user: 'USER',
    }),
    routeNames() {
      return RouteNames
    },
    isCommunityTemplatePage() {
      return this.route.name === RouteNames.TEMPLATE_ITINERARY
    },
  },
  methods: {
    onOpenDialog(){
      this.isActive = !this.isActive
    },
    onCloseDialog(){
      if (this.isActive === true) this.isActive = !this.isActive
    },
    closeDw() {
      this.$refs?.dw && this.$refs.dw.dialogClose()
    },
  }
}
</script>
