<template>
  <v-container data-test-name="page-reset-password">
    <v-row class="pa-4 pa-md-16">
      <v-col cols="12" align="center">
        <h1 class="text-h3 text-sm-h2 font-weight-bold reset-password" data-test-name="text-title">
          {{$t('Reset_password')}}
        </h1>
      </v-col>
      <v-row justify="center">
        <v-col cols="12" sm="6" xl="4" align="center" class="py-0 px-2 px-md-8">
          <v-col cols="12" class="pa-0">
            <label class="text-body-2 reset-message" data-test-name="text-label">
              {{ $t('Reset_password_message') }}
            </label>
          </v-col>
          <v-col cols="12" class="px-0">
            <v-text-field
              :model-value="email.value"
              :label="emailField.label"
              :error-messages="validateField(!isEmailValid && email.blurred)"
              data-test-name="input-email"
              class="pa-0 ma-0"
              color="primary"
              base-color="primary"
              bg-color="#fff"
              type="email"
              autofocus
              email
              variant="outlined"
              hide-details
              prepend-inner-icon="mdi-email-outline"
              @blur="email.blurred = true"
              @update:model-value="updateEmail"
              @keydown.enter="resetPassword"
            />
          </v-col>
          <slot name="error">
            <v-col cols="12" align="center" class="pa-0">
              <label class="text-error text-body-2 error-message" data-test-name="text-error-message">
                {{ errorMessage }}
              </label>
            </v-col>
          </slot>

          <v-btn
            width="100%"
            color="primary"
            rounded
            size="large"
            class="reset-password-btn"
            data-test-name="button-reset-password"
            :disabled="!(isEmailValid && !isRequestToResetPasswordSending)"
            @click="resetPassword"
          >
            {{ $t('Reset_password') }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-2 py-sm-6 mt-0 text-center text-caption" data-test-name="text-notes">
          This site is protected by reCAPTCHA and the Google&nbsp;<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a>&nbsp; and
          <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a>&nbsp;apply.
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { email as emailField } from '@/helpers/userProfileFields'
import { sendUserPasswordResetEmail } from '@/helpers/firebase/firebase'
import { RouteNames } from '@/helpers/routerUtils'
import axios from 'axios'
import { mapActions } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'ResetPassword',
  data() {
    return {
      email: {
        value: '',
        blurred: false
      },
      errorMessage: '',
      isRequestToResetPasswordSending: false,
      recaptchaValid: false,
      route: useRoute(),
    }
  },
  async created() {
    this.emailField = emailField
    await this.validateRecaptcha()
  },
  computed: {
    isEmailValid() {
      return !!this.preparedEmail && emailField.pattern.test(this.preparedEmail)
    },
    preparedEmail() {
      return (this.email.value || '').trim().toLowerCase()
    },
    isRecaptchaValid() {
      return this.recaptchaValid
    },
  },
  methods: {
    ...mapActions({
      logout: 'LOGOUT',
      pushAppNotification: 'PUSH_APP_NOTIFICATION',
    }),
    async validateRecaptcha() {
      this.recaptchaValid = false

      try {
        let response = await this.recaptcha()
        if (response.status === 200 && response.data) {
          this.recaptchaValid = response.data.success
        }
      } catch (ignore) {
        /** */
      }
    },
    updateEmail(value) {
      this.email.value = value
      this.errorMessage = ''
    },
    validateField(condition){
      if(condition) {
        return 'error'
      }
    },
    async resetPassword() {
      if(this.isEmailValid && this.isRecaptchaValid) {
        this.isRequestToResetPasswordSending = true
        try {
          this.logout()
          await sendUserPasswordResetEmail(this.preparedEmail)
          this.pushAppNotification({
            name: 'resetPassword',
            content: 'Reset_password_sent_message',
            timeout: 10000,
            color: 'primary'
          })
          this.goToSignInPage()
        } catch {
          this.errorMessage = this.$t('Reset_password_error_message')
        } finally {
          this.isRequestToResetPasswordSending = false
        }
      }
    },
    goToSignInPage() {
      this.$router.push({name: RouteNames.SIGN_IN, query: this.route.query})
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('resetpassword')

      return await axios.post(process.env.VUE_APP_RECAPTCHA_VALIDATE, { token: token })
    }
  },
}
</script>
