import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
//import colors from 'vuetify/lib/util/colors'

import ActivitiesIcon from '@/components/icons/ActivitiesIcon.vue'
import AgendaIcon from '@/components/icons/AgendaIcon'
import AirplaneIcon from '@/components/icons/AirplaneIcon.vue'
import AirplaneOutlineIcon from '@/components/icons/AirplaneOutlineIcon'
import AttractionIcon from '@/components/icons/AttractionIcon'
import BagIcon from '@/components/icons/BagIcon'
import BoatIcon from '@/components/icons/BoatIcon'
import BookOpenIcon from '@/components/icons/BookOpenIcon'
import BurgerIcon from '@/components/icons/BurgerIcon'
import BusIcon from '@/components/icons/BusIcon'
import CalendarIcon from '@/components/icons/CalendarIcon'
import CarIcon from '@/components/icons/CarIcon'
import ClipboardIcon from '@/components/icons/ClipboardIcon'
import CommentIcon from '@/components/icons/CommentIcon'
import CommunityIcon from '@/components/icons/CommunityIcon'
import CopyIcon from '@/components/icons/CopyIcon'
import CruiseIcon from '@/components/icons/CruiseIcon'
import CurrencyIcon from '@/components/icons/CurrencyIcon'
import CustomEventIcon from '@/components/icons/CustomEventIcon'
import DeleteOutlinedIcon from '@/components/icons/DeleteOutlinedIcon'
import DollarIcon from '@/components/icons/DollarIcon'
import EditCommentIcon from '@/components/icons/EditCommentIcon'
import EditIcon from '@/components/icons/EditIcon'
import EmailIcon from '@/components/icons/EmailIcon'
import ExerciseIcon from '@/components/icons/ExerciseIcon'
import ExploreIcon from '@/components/icons/ExploreIcon'
import FacebookIcon from '@/components/icons/FacebookIcon'
import FeedbackIcon from '@/components/icons/FeedbackIcon'
import FilterIcon from '@/components/icons/FilterIcon'
import FlagIcon from '@/components/icons/FlagIcon'
import FlightIcon from '@/components/icons/FlightIcon'
import GlobalIcon from '@/components/icons/GlobalIcon'
import HotelIcon from '@/components/icons/HotelIcon'
import HotelOutlineIcon from '@/components/icons/HotelOutlineIcon'
import HouseIcon from '@/components/icons/HouseIcon'
import InstagramIcon from '@/components/icons/InstagramIcon'
import InsuranceIcon from '@/components/icons/InsuranceIcon'
import LinkedinIcon from '@/components/icons/LinkedinIcon'
import LocationIcon from '@/components/icons/LocationIcon'
import LockIcon from '@/components/icons/LockIcon'
import LogoutIcon from '@/components/icons/LogoutIcon'
import MapIcon from '@/components/icons/MapIcon'
import NavigationArrowIcon from '@/components/icons/NavigationArrowIcon'
import PackageIcon from '@/components/icons/PackageIcon'
import PhoneIcon from '@/components/icons/PhoneIcon'
import PinterestIcon from '@/components/icons/PinterestIcon'
import PriceIcon from '@/components/icons/PriceIcon'
import RailIcon from '@/components/icons/RailIcon'
import RelaxIcon from '@/components/icons/RelaxIcon'
import RenewIcon from '@/components/icons/RenewIcon'
import RestaurantIcon from '@/components/icons/RestaurantIcon'
import RestaurantOutline from '@/components/icons/RestaurantOutline'
import SearchIcon from '@/components/icons/SearchIcon'
import SettingsIcon from '@/components/icons/SettingsIcon'
import ShareIcon from '@/components/icons/ShareIcon'
import ShareColorIcon from '@/components/icons/ShareColorIcon'
import ShoppingIcon from '@/components/icons/ShoppingIcon'
import ShowIcon from '@/components/icons/ShowIcon'
import ShuffleIcon from '@/components/icons/ShuffleIcon'
import SliderIcon from '@/components/icons/SliderIcon'
import ThumbUpFilledIcon from '@/components/icons/ThumbUpFilledIcon'
import ThumbUpOutlinedIcon from '@/components/icons/ThumbUpOutlinedIcon'
import TiktokIcon from '@/components/icons/TiktokIcon'
import TotalPriceIcon from '@/components/icons/TotalPriceIcon'
import TourIcon from '@/components/icons/TourIcon'
import TravelersIcon from '@/components/icons/TravelersIcon'
import TwitterIcon from '@/components/icons/TwitterIcon'
import UserIcon from '@/components/icons/UserIcon'
import UsersIcon from '@/components/icons/UsersIcon'
import VanIcon from '@/components/icons/VanIcon'
import ViewIcon from '@/components/icons/ViewIcon'
import YoutubeIcon from '@/components/icons/YoutubeIcon'
import ClockIcon from '@/components/icons/ClockIcon'
import ClockOutlineIcon from '@/components/icons/ClockOutlineIcon'
import CopyLinkIcon from '@/components/icons/CopyLinkIcon'
import MoonIcon from '@/components/icons/MoonIcon'
import ShareWithPeople from '@/components/icons/ShareWithPeople.vue'
import MapColorIcon from '@/components/icons/MapColorIcon.vue'

export const vuetify = createVuetify({
  components,
  directives,
  breakpoint: {
    scrollBarWidth: 7,
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        colors: {
          primary: '#0144C7',
          secondary: '#424242',
          secondaryDarken: '#767676',
          //disabled: colors.grey,
          accent: '#4FC3F7',
          error: '#B7102E',
          info: '#4FC3F7',
          success: '#24EE81',
          warning: '#FFCA28',
          // new design colors
          inactiveColor: '#F5F5F5',
          textPrimaryColor: '#000',
          textSecondaryColor: '#fff',
          darkBlue: '#002160',
          disabledColor: '#959595'
        }
      },
    },
  },
  sets: {
    mdi,
  },
  icons: {
    iconfont: 'mdi',
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      customActivities:             ActivitiesIcon,
      customAgenda:                 AgendaIcon,
      customAirplane:               AirplaneIcon,
      customAirplaneOutline:        AirplaneOutlineIcon,
      customAttraction:             AttractionIcon,
      customBag:                    BagIcon,
      customBoat:                   BoatIcon,
      customBookOpen:               BookOpenIcon,
      customBurger:                 BurgerIcon,
      customBus:                    BusIcon,
      customCalendar:               CalendarIcon,
      customCar:                    CarIcon,
      customClipboard:              ClipboardIcon,
      customClockOutline:           ClockOutlineIcon,
      customComment:                CommentIcon,
      customCommunity:              CommunityIcon,
      customCopy:                   CopyIcon,
      customCopyLink:               CopyLinkIcon,
      customCruise:                 CruiseIcon,
      customCurrency:               CurrencyIcon,
      customDelete:                 DeleteOutlinedIcon,
      customDollar:                 DollarIcon,
      customEdit:                   EditIcon,
      customEditComment:            EditCommentIcon,
      customEmail:                  EmailIcon,
      customEvent:                  CustomEventIcon,
      customExercise:               ExerciseIcon,
      customExplore:                ExploreIcon,
      customFacebook:               FacebookIcon,
      customFeedback:               FeedbackIcon,
      customFilter:                 FilterIcon,
      customFlag:                   FlagIcon,
      customFlight:                 FlightIcon,
      customGlobal:                 GlobalIcon,
      customHotel:                  HotelIcon,
      customHotelOutline:           HotelOutlineIcon,
      customHouse:                  HouseIcon,
      customInstagram:              InstagramIcon,
      customInsurance:              InsuranceIcon,
      customLinkedin:               LinkedinIcon,
      customLocation:               LocationIcon,
      customLock:                   LockIcon,
      customLogout:                 LogoutIcon,
      customMap:                    MapIcon,
      customColorMap:               MapColorIcon,
      customNavigationArrow:        NavigationArrowIcon,
      customPackage:                PackageIcon,
      customPhone:                  PhoneIcon,
      customPinterest:              PinterestIcon,
      customPrice:                  PriceIcon,
      customRail:                   RailIcon,
      customRelax:                  RelaxIcon,
      customRenew:                  RenewIcon,
      customRestaurant:             RestaurantIcon,
      customRestaurantOutline:      RestaurantOutline,
      customSearch:                 SearchIcon,
      customSettings:               SettingsIcon,
      customShare:                  ShareIcon,
      customShareColorIcon:         ShareColorIcon,
      customShopping:               ShoppingIcon,
      customShow:                   ShowIcon,
      customShuffle:                ShuffleIcon,
      customSlider:                 SliderIcon,
      customThumbUpFilled:          ThumbUpFilledIcon,
      customThumbUpOutlined:        ThumbUpOutlinedIcon,
      customTiktok:                 TiktokIcon,
      customTotalPrice:             TotalPriceIcon,
      customTour:                   TourIcon,
      customTravelers:              TravelersIcon,
      customTwitter:                TwitterIcon,
      customUser:                   UserIcon,
      customUsers:                  UsersIcon,
      customVan:                    VanIcon,
      customView:                   ViewIcon,
      customYoutube:                YoutubeIcon,
      customClock:                  ClockIcon,
      customMoon:                   MoonIcon,
      customShareWithPeople:        ShareWithPeople
    }
  }
})

export default { vuetify }