<template>
  <v-card-actions class="bg-blue-grey-lighten-4 dialog-footer">
    <v-row class="d-flex justify-space-between pl-1 pr-1 pt-2 pb-2 ma-0">
      <v-col cols="3" sm="4" class="d-flex justify-start">
        <v-btn
          v-if="!readonly && deleteButtonVisible"
          @click="handleDelete"
          color="secondary"
          :width="$vuetify.display.xs ? '100%': '10rem'"
          :class="['mr-auto px-2', { 'mb-0': $vuetify.display.xs}]"
          variant="plain"
          rounded
          data-test-name="button-delete"
          prepend-icon="$customDelete"
        >
          <span v-if="!$vuetify.display.xs">{{ $t('Delete_Event') }}</span>
        </v-btn>
      </v-col>
      <v-col cols="9" sm="4" class="d-flex justify-space-between justify-end">
        <v-btn
          @click="handleCancel"
          color="primary"
          :width="$vuetify.display.xs ? '47%' : '160'"
          :class="$vuetify.display.xs ? 'mx-0' : 'mr-4'"
          rounded
          :variant="readonly ? 'flat' : 'outlined'"
          data-test-name="button-cancel"
        >
          {{ readonly ? $t('Close') : $t('Cancel') }}
        </v-btn>
        <v-btn
          v-if="!readonly"
          @click="handleSubmit"
          :disabled="submitButtonDisabled"
          :loading="submitButtonLoading"
          :width="$vuetify.display.xs ? '47%' : '160'"
          :class="$vuetify.display.xs ? 'ml-0' : ''"
          color="primary"
          variant="flat"
          rounded
          data-test-name="button-submit"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script>
export default {
  name: 'EventPopupButtons',
  emits: ['submit', 'cancel', 'delete'],
  props: {
    submitButtonLoading: {
      type: Boolean,
      default: false,
    },
    submitButtonDisabled: {
      type: Boolean,
      default: false,
    },
    deleteButtonVisible: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit')
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleDelete() {
      this.$emit('delete')
    }
  },
}
</script>

<style scoped>
.dialog-footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>