const CATEGORY_TRANSPORTATION = 'transportation'
const CATEGORY_ACTIVITY = 'activity'

const EventTypes = {
  ATTRACTION:   'attraction',
  FERRY:        'ferry',
  BUS:          'bus',
  CAR_RENTAL:   'carRental',
  CRUISE:       'cruise',
  CUSTOM_EVENT: 'customEvent',
  DRIVE:        'drive',
  EXERCISE:     'exercise',
  EXPLORE:      'explore',
  FLIGHT:       'flight',
  INSURANCE:    'insurance',
  LODGING:      'lodging',
  RELAX:        'relax',
  RESTAURANT:   'restaurant',
  SHOPPING:     'shopping',
  SHOW:         'show',
  TOUR:         'tour',
  TRAIN:        'train',
  TRANSFER:     'transfer',
}

/**
  sortingOptions below comprised of
  id - field to sort by (it could be a path in an object)
  title - used for display as a lable
  sortPrimer - function to be used to propely present the data for sorting
  (for example in dates it should allow to get the value in primitive type, like seconds )
  in case when sortPrimer is not provided - string value will be used
*/

const itemTypes = [
  {
    type: EventTypes.FLIGHT,
    label:'Flight',
    labelKey: 'Flight_at',
    icon: '$customFlight',
    groupDivider: false,
    category: CATEGORY_TRANSPORTATION,
    color: '#0144C7',
    price: 'flight.totalPrice',
    sortingOptions: [
      {id: 'flight.duration', title: 'Duration', sortPrimer: parseFloat},
      {id: 'flight.totalPrice.amount', title: 'Price', sortPrimer: parseFloat},
      {id: 'flight.segments[0].departure.at', title: 'Departure', sortPrimer: Date.getTime}
    ],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/flight.png',
    defaultDuration: 180,
    segmentGapMinutes: 120,
  },
  {
    type: EventTypes.TRAIN,
    label:'Train',
    labelKey: 'Train_at',
    icon: '$customRail',
    groupDivider: false,
    category: CATEGORY_TRANSPORTATION,
    color: '#F3AF00',
    price: 'train.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/train.png',
    defaultDuration: 180,
    segmentGapMinutes: 60,
  },
  {
    type: EventTypes.BUS,
    label:'Bus / Coach',
    labelKey: 'Bus_at',
    icon: '$customBus',
    groupDivider: false,
    category: CATEGORY_TRANSPORTATION,
    color: '#F16500',
    price: 'bus.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/bus.png',
    defaultDuration: 60,
    segmentGapMinutes: 60,
  },
  {
    type: EventTypes.FERRY,
    label:'Boat / Ferry',
    labelKey: 'Ferry_at',
    icon: '$customBoat',
    groupDivider: false,
    category: CATEGORY_TRANSPORTATION,
    color: '#2196f3',
    price: 'ferry.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/ferry.png',
    defaultDuration: 120,
    segmentGapMinutes: 60,
  },
  {
    type: EventTypes.TRANSFER,
    label:'Transfer',
    labelKey: 'Transfer_to',
    icon: '$customVan',
    groupDivider: false,
    category: CATEGORY_TRANSPORTATION,
    color: '#0B9139',
    price: 'transfer.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/transfer.png',
    defaultDuration: 60,
    segmentGapMinutes: 60,
  },
  {
    type: EventTypes.CAR_RENTAL,
    label:'Car Rental',
    labelKey: 'CarRental_at',
    icon: '$customCar',
    groupDivider: false,
    category: CATEGORY_TRANSPORTATION,
    color: '#F92370',
    price: 'carRental.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/carRental.png',
    defaultDuration: 1440,  //24 hours
  },
  {
    type: EventTypes.DRIVE,
    label:'Drive',
    labelKey: 'Drive_to',
    icon: '$customCar',
    groupDivider: true,
    category: CATEGORY_TRANSPORTATION,
    color: '#6C3428',
    price: 'drive.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/carRental.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.LODGING,
    label:'Lodging/Hotel',
    labelKey: 'Hotel_lodging_name',
    icon: '$customHotel',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#2EA57A',
    price: 'lodging.totalPrice',
    sortingOptions: [
      {id: 'lodging.roData.userRating.aggregateRating', title: 'Rating', sortPrimer: parseFloat},
      {id: 'lodging.roData.distanceFromCenter.distance', title: 'Distance from Center', sortPrimer: parseFloat}
    ],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/lodging.png',
  },
  {
    type: EventTypes.CRUISE,
    label:'Cruise',
    labelKey: 'Cruise_name',
    icon: '$customCruise',
    groupDivider: true,
    category: CATEGORY_ACTIVITY,
    color: '#0279BC',
    price: 'cruise.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/cruise.png',
    defaultDuration: 120,
  },
  {
    type: EventTypes.RESTAURANT,
    label:'Restaurant',
    labelKey: 'Restaurant_at',
    icon: '$customRestaurant',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#C70101',
    price: 'restaurant.totalPrice',
    sortingOptions: [
      {id: 'restaurant.roData.userRating.aggregateRating', title: 'User Rating', sortPrimer: parseFloat},
      {id: 'restaurant.roData.priceLevel', title: 'Price', sortPrimer: parseFloat},
    ],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/restaurant.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.ATTRACTION,
    label:'Attraction',
    labelKey: 'Attraction_name',
    icon: '$customAttraction',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#00CBE7',
    price: 'attraction.totalPrice',
    sortingOptions: [
      {id: 'attraction.roData.userRating.aggregateRating', title: 'User Rating', sortPrimer: parseFloat}
    ],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/attraction.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.TOUR,
    label:'Tour',
    labelKey: 'Tour_name',
    icon: '$customTour',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#DA26A8',
    price: 'tour.totalPrice',
    sortingOptions: [
      {id: 'tour.roData.userRating.aggregateRating', title: 'User Rating', sortPrimer: parseFloat},
      {id: 'tour.totalPrice.amount', title: 'Price', sortPrimer: parseFloat},
    ],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/tour.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.SHOW,
    label:'Show',
    labelKey: 'Show_at',
    icon: '$customShow',
    groupDivider: true,
    category: CATEGORY_ACTIVITY,
    color: '#A454E2',
    price: 'show.totalPrice',
    sortingOptions: [
      {id: 'show.roData.userRating.aggregateRating', title: 'User Rating', sortPrimer: parseFloat},
      {id: 'show.totalPrice.amount', title: 'Price', sortPrimer: parseFloat},
    ],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/show.png',
    defaultDuration: 120,
  },
  {
    type: EventTypes.EXPLORE,
    label:'Explore / Walk',
    labelKey: 'Explore_at',
    icon: '$customExplore',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#8400D4',
    price: 'explore.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/explore.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.SHOPPING,
    label:'Shopping',
    labelKey: 'Shopping_name',
    icon: '$customShopping',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#FF5757',
    price: 'shopping.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/shopping.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.EXERCISE,
    label:'Exercise',
    labelKey: 'Exercise_at',
    icon: '$customExercise',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#5BD132',
    price: 'exercise.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/exercise.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.RELAX,
    label:'Relax',
    labelKey: 'Relax_at',
    icon: '$customRelax',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#5ED7C1',
    price: 'relax.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/relax.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.CUSTOM_EVENT,
    label:'Custom Event',
    labelKey: 'Title',
    icon: '$customEvent',
    groupDivider: true,
    category: CATEGORY_ACTIVITY,
    color: '#808080',
    price: 'customEvent.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: false,
    defaultImageUrl: '/assets/logos/event/custom.png',
    defaultDuration: 60,
  },
  {
    type: EventTypes.INSURANCE,
    label:'Trip protection',
    labelKey: 'Insurance_name',
    icon: '$customInsurance',
    groupDivider: false,
    category: CATEGORY_ACTIVITY,
    color: '#000000', //black
    price: 'userData.totalPrice',
    sortingOptions: [],
    addManually: true,
    addAutomatically: true,
    defaultImageUrl: '/assets/logos/event/insurance.png',
    defaultDuration: 60,
  },
]

/**
  takes `item` as a parameter
 */
const itemTypeLookup = ((item) => {
  return [...itemTypes].find(typeItem => {
    if (item[typeItem.type]) return typeItem.type
  })
})

/**
  takes `item type` as a parameter. e.g. show, hotel etc. */
const getItemTypeByType = (type) => itemTypes.find(typeItem => {
  if (typeItem.type === type) return typeItem
})

const findEventByType = eventType => itemTypes.find(item => item.type === eventType)

module.exports = {
  itemTypeLookup, itemTypes, getItemTypeByType, findEventByType, CATEGORY_ACTIVITY, CATEGORY_TRANSPORTATION, EventTypes
}
