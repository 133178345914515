import axios from 'axios'
import { logAppError } from '@/logger'
import datetime from '@/helpers/datetime'

var URITemplate = require('urijs/src/URITemplate')

export const getters = {
  CURRENT_ITINERARY: state => state.currentItinerary,
  IS_SHARED_ITINERARY: state => state.isShared,
  IS_OWNER: state => state.currentItineraryRole === 'OWNER',
  IS_VIEWER: state => state.currentItineraryRole ==='VIEWER',
  IS_EDITOR: state => state.currentItineraryRole === 'OWNER' || state.currentItineraryRole === 'EDITOR',
  IS_COMMENTER: state => state.currentItineraryRole === 'COMMENTER',
  GET_CURRENT_ITINERARY_ID: state => state.currentItinerary.header.itineraryID,
  CURRENT_ITINERARY_ROLES: state => state.currentItineraryRoles,
  CURRENT_ITINERARY_ROLE: state => state.currentItineraryRole,
}

export const mutations = {
  SET_CURRENT_ITINERARY: (state, payload) => {
    state.currentItinerary = payload
  },
  SET_CURRENT_ITINERARY_HEADER: (state, payload) => {
    if (state.currentItinerary)
      state.currentItinerary.header = payload
  },
  SET_SHARED_FLAG: (state, payload) => {
    state.isShared = payload
  },
  SET_CURRENT_ITINERARY_ROLES: (state, payload) => {
    state.currentItineraryRoles = payload
  },
  SET_CURRENT_ITINERARY_ROLE: (state, payload) => {
    state.currentItineraryRole = payload
  }
}

export const actions = {
  SEND_ITINERARY_TO_RECIPIENTS: async ( context, payload ) => {
    return await axios.post(process.env.VUE_APP_SHARED_ITINERARY_EMAILING_URL, payload)
  },
  SET_CURRENT_ITINERARY: ({ commit, dispatch }, payload) => {
    commit('SET_CURRENT_ITINERARY', payload)
    commit('SET_CURRENT_ITINERARY_ROLES', null)
    commit('SET_CURRENT_ITINERARY_ROLE', null)
    dispatch('GET_ITINERARY_ROLE')
  },
  CLEAR_CURRENT_ITINERARY: ({commit}) => {
    commit('SET_CURRENT_ITINERARY', '')
    commit('SET_CURRENT_ITINERARY_ROLE', null)
  },
  GENERATE_ITINERARY: async ({ dispatch }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: ''
    }).replace(/\/$/, '')
    return await axios.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch('SET_CURRENT_ITINERARY', response.data)
          return response.data
        } else {
          dispatch('PUSH_APP_NOTIFICATION', {
            name: 'initErr',
            content: 'itinerary_init_error',
            color: 'error'
          })
        }
      })
      .catch((error) => {
        dispatch('PUSH_APP_NOTIFICATION', {
          name: 'initErr',
          content: error.response?.status === 404 ? 'itinerary_init_error' : 'Smth_went_wrong_message',
          color: 'error'
        })
        logAppError(`${error}: ${JSON.stringify(payload)}`)
      })
  },
  GET_ITINERARY_BY_ID: async ({ commit, dispatch }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')

    return await axios.get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch('SET_CURRENT_ITINERARY', response.data)
          commit('SET_SHARED_FLAG', false)
        }
      })
  },
  GET_SHARED_ITINERARY_BY_ID: async ({ commit, dispatch }, payload) => {
    const template = URITemplate(process.env.VUE_APP_SHARED_ITINERARY_URL)
    const url = template.expand({
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')

    return await axios.get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch('SET_CURRENT_ITINERARY', response.data)
          commit('SET_SHARED_FLAG', true)
        }
      })
  },

  UPDATE_WHAT_TO_BRING_FOR_SHARED_ITINERARY: async ({ dispatch }, payload) => {
    const template = URITemplate(process.env.VUE_APP_SHARED_ITINERARY_URL)
    const url = template.expand({
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')
    return await axios.put(url, {
      userID: payload.userID,
      userData: { whatToBring: payload.userData.whatToBring },
    }).then((response) => {
      if (response.status === 200) {
        dispatch('SET_CURRENT_ITINERARY', response.data)
      }
    })
  },
  GET_ITINERARIES: async (_, payload) => {
    const template = URITemplate(payload.isShared? process.env.VUE_APP_SHARED_WITH_USER_ITINERARIES_URL: process.env.VUE_APP_USER_ITINERARIES_URL)
    const url = template.expand({
      userID: payload.userID,
      from: payload.from,
      size: payload.size,
      sort: payload.sort,
      destination: payload.destination
    }).replace(/\/$/, '')
    try {
      return (await axios.get(url)).data
    } catch(error) {
      return []
    }
  },
  REMOVE_SHARED_ITINERARY: async (_, payload) => {
    const template = URITemplate(process.env.VUE_APP_SHARED_ITINERARY_OF_USER_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')

    return await axios.delete(url)
  },
  REMOVE_ITINERARY: async (_, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')

    return await axios.delete(url)
  },
  UPDATE_ITINERARY_HEADER: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const url = template.expand({
      userID: payload.header.userID,
      itineraryID: payload.header.itineraryID
    }).replace(/\/$/, '')

    return await axios.put(url, { header: payload.header })
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY_HEADER', response.data)
        }
      })
  },
  ADD_ITEM_TO_ITINERARY: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_ITEM_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')
    return await axios.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY', response.data)
        }
      })
  },
  ADD_INSURANCE: async ({ commit }, payload) => {
    const { userID, itinIdParam, ...data } = payload
    const template = URITemplate(process.env.VUE_APP_TRIP_INSURANCE_URL)
    const url = template.expand({
      userID,
      itineraryID: itinIdParam
    }).replace(/\/$/, '')

    return await axios.post(url, { tripProtection: data })
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY', response.data)
        }
        return response
      })
  },
  UPDATE_INSURANCE: async ({ commit }, payload) => {
    const { userID, itinIdParam, ...data } = payload
    const template = URITemplate(process.env.VUE_APP_TRIP_INSURANCE_URL)
    const url = template.expand({
      userID,
      itineraryID: itinIdParam
    }).replace(/\/$/, '')
    return await axios.put(url, {tripProtection: data})
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY', response.data)
        }
        return response
      })
  },
  REMOVE_ITINERARY_ITEM: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_ITEM_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: payload.itineraryID,
      itemID: payload.itemID
    }).replace(/\/$/, '')
    return await axios.delete(url)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY', response.data)
        }
      })
  },
  REMOVE_INSURANCE: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_TRIP_INSURANCE_URL)
    const url = template.expand({
      userID: payload.userID,
      itineraryID: payload.itineraryID
    }).replace(/\/$/, '')
    return await axios({
      method: 'DELETE',
      url: url,
      data: {
        tripProtectionID: payload.tripProtectionID
      }})
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY', response.data)
        }
      })
  },
  REPLACE_ITINERARY_ITEM: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_ITEM_URL)
    const url = template.expand({
      userID:       payload.userID,
      itineraryID:  payload.itineraryID,
      itemID:       payload.itemID
    }).replace(/\/$/, '')
    return await axios.put(url, payload)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_ITINERARY', response.data)
        }
      })
  },
  SEARCH_FLIGHTS: async (context, payload) => {
    return await axios.post(process.env.VUE_APP_TRIP_FLIGHTS_URL, payload).then(({data}) => data)
  },
  SEARCH_HOTELS: async (context, payload) => {
    return await axios.post(process.env.VUE_APP_TRIP_HOTELS_URL, payload).then(({data}) => data)
  },
  SEARCH_RESTAURANTS: async (context, payload) => {
    return await axios.post(process.env.VUE_APP_TRIP_RESTAURANTS_URL, payload).then(({data}) => data)
  },
  SEARCH_ATTRACTIONS: async (context, payload) => {
    return await axios.post(process.env.VUE_APP_TRIP_ATTRACTIONS_URL, payload).then(({data}) => data)
  },
  SEARCH_SHOWS: async (context, payload) => {
    return await axios.post(process.env.VUE_APP_TRIP_SHOWS_URL, payload).then(({data}) => data)
  },
  SEARCH_TOURS: async (context, payload) => {
    return await axios.post(process.env.VUE_APP_TRIP_TOURS_URL, payload).then(({data}) => data)
  },
  CREATE_EVENT_MANUALLY: async (context, payload) => {
    // return await axios.post(process.env.VUE_APP_TRIP_CUSTOM_EVENT_URL, payload)
    let { location, arrivalLocation, startDateTime, endDateTime, checkInDateTime, checkOutDateTime, travelers, ...rest } = payload
    if (typeof startDateTime === 'object') {
      startDateTime = await datetime.convertDateTimeStructToString(startDateTime, location?.placeID)
      endDateTime = await datetime.convertDateTimeStructToString(endDateTime, arrivalLocation?.placeID || location?.placeID)
    }
    return await axios.post(process.env.VUE_APP_TRIP_CUSTOM_EVENT_URL, {
      startTimestamp: startDateTime,
      endTimestamp: endDateTime,
      location,
      checkInDateTime,
      checkOutDateTime,
      travelers,
      oneOf: rest,
    })
  },
  SET_SHARED_FLAG: ({ commit }, payload) => {
    commit('SET_SHARED_FLAG', payload)
  },
  UPDATE_ITINERARY_ROLES: async ({ commit, rootState, state }, roles) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const itineraryID = state.currentItinerary.header.itineraryID
    const url = template.expand({
      userID: rootState.user.user.id,
      itineraryID
    }).replace(/\/$/, '')
    return await axios.put(url + '/roles', {roles})
      .then(({data}) => {
        if(itineraryID === state.currentItinerary?.header.itineraryID) {
          commit('SET_CURRENT_ITINERARY_ROLES', data)
        }
      })
  },
  GET_ITINERARY_ROLES: async ({ commit, rootState, state }) => {
    if(state.currentItineraryRoles) {
      return
    }
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const itineraryID = state.currentItinerary.header.itineraryID
    const url = template.expand({
      userID: rootState.user.user.id,
      itineraryID
    }).replace(/\/$/, '')
    return await axios.get(url + '/roles')
      .then(({data}) => {
        if(itineraryID === state.currentItinerary?.header.itineraryID) {
          commit('SET_CURRENT_ITINERARY_ROLES', data)
        }
      })
      .catch(() => {
        commit('SET_CURRENT_ITINERARY_ROLES', {})
      })
  },
  GET_ITINERARY_ROLE: async ({ commit, rootState, state }) => {
    const template = URITemplate(process.env.VUE_APP_USER_ITINERARY_URL)
    const url = template.expand({
      userID: rootState.user.user.id,
      itineraryID: state.currentItinerary.header.itineraryID
    }).replace(/\/$/, '')
    return await axios.get(url + '/roles/current')
      .then(({data}) => {
        commit('SET_CURRENT_ITINERARY_ROLE', data)
      })
  },
  GET_SHARED_ITINERARIES_ROLES: async (context, payload) => {
    const template = URITemplate(process.env.VUE_APP_ROLES_FOR_SHARED_WITH_USER_ITINERARIES_URL)
    const url = template.expand({
      userID: payload.userID,
      from: payload.from,
      size: payload.size
    }).replace(/\/$/, '')
    try {
      return (await axios.get(url)).data
    } catch(error) {
      return {}
    }
  },
  GET_SHARED_STATUSES_FOR_ITINERARIES: async (context, payload) => {
    const template = URITemplate(process.env.VUE_APP_SHARED_STATUSES_FOR_USER_ITINERARIES_URL)
    const url = template.expand({
      userID: payload.userID,
      from: payload.from,
      size: payload.size
    }).replace(/\/$/, '')
    try {
      return (await axios.get(url)).data
    } catch(error) {
      return []
    }
  },
  //valodates whether an itinerary can be converted into a template
  CHECK_ITINERARY_CONVERTIBLE_TO_TEMPLATE: async ({ state, rootState }, payload) => {
    const template = URITemplate(process.env.VUE_APP_CHECK_ITINERARY_CONVERTIBLE_TO_TEMPLATE_URL)
    const url = template.expand({
      userID: rootState.user.user.id,
      itineraryID: state.currentItinerary.header.itineraryID
    }).replace(/\/$/, '')
    const response = await axios.post(url, payload)
    return (response && response.status === 200 && response.data.isConvertible)
  },
  CONVERT_ITINERARY_TO_TEMPLATE: async ({ dispatch, state, rootState }, payload) => {
    const template = URITemplate(process.env.VUE_APP_CONVERT_ITINERARY_TO_TEMPLATE_URL)
    const url = template.expand({
      userID: rootState.user.user.id,
      itineraryID: state.currentItinerary.header.itineraryID
    }).replace(/\/$/, '')
    await axios.post(url, payload)
      .then(res => {
        if (res.status === 200) {
          dispatch('PUSH_APP_NOTIFICATION', {
            name: 'shareAsTemplate',
            content: 'Itinerary_shared',
            color: 'primary'
          })
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch('PUSH_APP_NOTIFICATION', {
            name: 'shareAsTemplate',
            content: 'Already_shared',
            color: 'warning'
          })
        } else {
          logAppError(`${error}: ${JSON.stringify(payload)}`)
        }
      })
  },
  CLONE_ITINERARY: async (context, payload) => {
    const template = URITemplate(process.env.VUE_APP_CLONE_ITINERARY_URL)
    const { itineraryID, userId, startDate, fromPlaceID, travelers } = payload
    const url = template.expand({
      itineraryID,
      userId,
    }).replace(/\/$/, '')
    return await axios.post(url, {
      startDate,
      fromPlaceID,
      travelers,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      })
  },
}

export default {
  state: {
    currentItinerary: '',
    isShared: false,
    currentItineraryRoles: null,
    currentItineraryRole: null,
  },
  getters,
  mutations,
  actions
}
