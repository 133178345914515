<template>
  <v-card elevation="0">
    <v-row class="mt-1 mt-sm-2" no-gutters>
      <v-col cols="6" v-if="$vuetify.display.xs" class="mx-0">
        <passengers-selector
          :value="travelers"
          :readonly="isReadOnly"
          @input="updateTravelers"
          name="generate-custom-event"
          class="passengers-selector"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="totalTravelersNumber"
              :label="$t('Travelers') + '*'"
              class="pa-0 ma-0 mr-2 trigger-change-travelers"
              :class="{ activator: !isReadOnly }"
              bg-color="#fff"
              variant="outlined"
              color="primary"
              base-color="primary"
              weight="100%"
              readonly
              hide-details
              prepend-inner-icon="$customTravelers"
              v-bind="props"
            />
          </template>
        </passengers-selector>
      </v-col>
      <v-col
        cols="6"
        :sm="showBookBtn ? '9' : '12'"
        :md="showBookBtn ? '10' : '12'"
      >
        <event-booking
          :isBooked="isBooked"
          :readonly="isReadOnly"
          @updateBooked="updateBooked"
          class="d-flex justify-end"
        />
      </v-col>
      <v-col
        v-if="showBookBtn"
        cols="12"
        sm="3"
        md="2"
        align-self="center"
        class="d-flex justify-end"
      >
        <v-btn
          :color="isBooked ? 'rgba(1, 68, 199)' : '#F3A742'"
          :width="$vuetify.display.xs ? '47%' : '160'"
          rounded
          :disabled="!isEditor"
          variant="flat"
          @click="proceedToWebsite"
          data-test-id="partner-booking__button"
        >
          {{ isBooked ? $t('View') : $t('Book') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="imageURL || description || (Number(userRating?.aggregateRating) > 0)" no-gutters>
      <v-col cols="12" sm="6" class="pt-4 pt-sm-0 pt-0 pb-2 pr-0 pr-sm-6">
        <v-img
          :src="thumbnailURL"
          class="rounded-lg"
          data-test-id="attraction-details-tool__image"
          @error="handleImageError"
          :height="$vuetify.display.mdAndDown ? '220' : '280'"
          cover
        />
      </v-col>
      <v-col cols="12" sm="6" class="pt-0">
        <v-col v-if="!$vuetify.display.mdAndDown" class="pa-0">
          <v-divider class="pt-6 my-0"></v-divider>
        </v-col>
        <v-row>
          <v-col cols="12" sm="7" class="d-flex">
            <user-rating :userRating="userRating" />
          </v-col>
        </v-row>
        <v-row v-if="description">
          <v-col cols="12">
            <div class="font-weight-medium">{{ $t('Description')}}</div>
            <div class="attraction-details-tool__description" data-test-id="attraction-details-tool__description">
              {{ description }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="6" class="pb-0 pb-sm-6 pb-md-0">
        <v-text-field
          :model-value="address"
          :label="$t('Address')"
          :readonly="isReadOnly"
          @update:model-value="updateAddress"
          class="pa-0 ma-0"
          variant="outlined"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          data-test-id="attraction-details-tool__simpleAddress"
          hide-details="auto"
          :rules="addressRules"
        >
          <template v-slot:prepend-inner>
            <v-icon size="small">$customHouse</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="4" class="pb-0 pb-sm-6 pb-md-0 text-truncate">
        <v-select
          prepend-inner-icon="$customCurrency"
          :model-value="totalPrice?.currency"
          @update:model-value="updateCurrency"
          :label="$t('Currency')"
          :readonly="isReadOnly"
          :items="currencyList"
          class="pa-0 ma-0"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          variant="outlined"
          hide-details
          data-test-id="attraction-details-tool__currencySelector"
        />
      </v-col>
      <v-col cols="6" sm="2" class="pb-0 pb-sm-6 pb-md-0">
        <v-currency-field
          :model-value="Number(totalPrice?.amount)"
          @update="updateAmount"
          variant="outlined"
          class="pa-0 ma-0"
          color="primary"
          :label="`${$t('Total_price')}`"
          :readonly="isReadOnly"
        />
      </v-col>
    </v-row>

    <v-row class="mt-lg-0">
      <v-col cols="12" class="mb-8">
        <v-textarea
          :model-value="notes"
          :label="$t('Notes')"
          :auto-grow="false"
          :readonly="readonly"
          @update:model-value="updateNotes"
          class="pa-0 ma-0 mb-8"
          rows="3"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          variant="outlined"
          no-resize
          hide-details="auto"
          :rules="notesRules"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCurrencySelectList } from '@/helpers/currency'
import EventBooking from '@/components/popups/event-booking/EventBooking'
import { getItemTypeByType, convertFromStringToNumber } from '@/helpers'
import PassengersSelector from '@/components/popups/PassengersSelector'
import UserRating from '@/components/UserRating'
import VCurrencyField from '@/components/VCurrencyField'

export default {
  name: 'AttractionDetailsTool',
  components: {
    EventBooking,
    UserRating,
    PassengersSelector,
    VCurrencyField,
  },
  emits: [
    'updateNotes',
    'updateTravelers',
    'updateBooked',
    'updateAddress',
    'updateTitle',
    'updatePrice'
  ],
  props: {
    imageURL: {
      type: String,
    },
    productURL: {
      type: String,
    },
    userRating: {
      type: Object,
    },
    isBooked: {
      type: Boolean,
    },
    address: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    totalPrice: {
      type: Object,
    },
    travelers: {
      type: Object,
      required: false,
    },
    eventType: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    isTemplateView: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: String,
    },
    addressRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    notesRules: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      currencyList: [],
    }
  },
  created() {
    this.currencyList = getCurrencySelectList(this.isMobile)
  },
  computed: {
    ...mapGetters({
      isEditor: 'IS_EDITOR',
      isMobile: 'IS_MOBILE',
    }),
    isReadOnly() {
      return !this.isEditor
    },
    totalTravelersNumber() {
      return (this.travelers?.adults || 0) + (this.travelers?.children || 0)
    },
    thumbnailURL() {
      return this.imageURL || getItemTypeByType(this.eventType).defaultImageUrl
    },
    showBookBtn() {
      return !this.isReadOnly && this.productURL && this.productURL.length > 0
    }
  },
  methods: {
    proceedToWebsite() {
      window.open(this.productURL, '_blank')
    },
    updateNotes(value) {
      this.$emit('updateNotes', value)
    },
    updateTravelers(value){
      this.$emit('updateTravelers', value)
    },
    updateBooked(value) {
      this.$emit('updateBooked', value)
    },
    updateAddress(value) {
      this.$emit('updateAddress', value)
    },
    updateCurrency(value) {
      this.$emit('updatePrice', { currency: value, amount: this.totalPrice?.amount })
    },
    updateAmount(value) {
      this.$emit('updatePrice', { currency: this.totalPrice?.currency, amount: convertFromStringToNumber(value) })
    },
    handleImageError() {
      this.$emit('handleImageError')
    },
  },
}
</script>
