<template>
  <common-popup
    :confirm-label="confirmLabel"
    :isDiscardBtnVisible="false"
    :cancelButtonVisible="false"
    :maxWidth="1000"
    @confirm="close"
    @cancel="close"
    @close="close"
  >
    <v-row no-gutters class="d-flex justify-space-between pt-3">
      <v-col cols="12" sm="6" class="pr-sm-8">
        <v-img
          :src="imageURL"
          class="rounded-lg"
          :height="$vuetify.display.mdAndDown ? '220' : '260'"
          cover
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-row no-gutters>
          <v-col cols="12" class="my-2 my-sm-4 mt-6 mt-sm-0">
            <v-text-field
              :model-value="title"
              :label="$t('Title')"
              :readonly="true"
              class="pa-0 ma-0"
              variant="outlined"
              color="primary"
              base-color="primary"
              bg-color="#fff"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="my-2 my-sm-4">
            <v-text-field
              :model-value="link"
              :label="$t('Booking_link')"
              :readonly="true"
              prepend-inner-icon="link"
              class="pa-0 ma-0"
              variant="outlined"
              color="primary"
              base-color="primary"
              bg-color="#fff"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="my-2 my-sm-4">
            <v-text-field
              :model-value="address"
              :label="$t('Address')"
              :readonly="true"
              class="pa-0 ma-0"
              variant="outlined"
              color="primary"
              base-color="primary"
              bg-color="#fff"
              hide-details="auto"
            >
              <template v-slot:prepend-inner>
                <v-icon class="pr-2">$customHouse</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-row no-gutters class="my-2 my-sm-4 mt-2 mt-sm-6">
        <v-col cols="12">
          <v-textarea
            :model-value="description"
            :label="$t('Description')"
            :auto-grow="false"
            :readonly="true"
            class="pa-0 ma-0 rounded"
            :rows="3"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            no-resize
            hide-details
          />
        </v-col>
      </v-row>
    </v-row>
  </common-popup>
</template>

<script>
import CommonPopup from '@/components/popups/CommonPopup'

export default {
  name: 'ItineraryTemplatePopup',
  components: {
    CommonPopup,
  },
  emits: ['close'],
  props: {
    confirmLabel: {
      type: String,
      required: true
    },
    discardLabel: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    link: {
      type: String,
    },
    imageURL: {
      type: String,
    },
    address: {
      type: String,
    },
  },
  methods: {
    close(){
      this.$emit('close')
    },
  }
}
</script>
