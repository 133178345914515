import { RouteNames } from './routerUtils'
/**
    returns displayable user name as 'firstname lastname'
   */
export const getDisplayUserName = (user) => {
  //to avoid having 'undefined' or 'null' in the result
  return [user?.firstName, user?.lastName].join(' ')
}

export const getUserFirstLetter = (user) => {
  return user?.firstName ? user?.firstName[0] : ''
}

export const proceedToItinerary = ($router, itineraryID, view) => {
  $router.push({
    name: RouteNames.ITINERARY,
    params: { id: itineraryID, view }
  }).then(() => $router.go())
}
