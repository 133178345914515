import { createI18n } from 'vue-i18n'
import messages from '../lang/en'
import axios from 'axios'


const locale = 'en' // default locale

export const allLanguages = [
  {text: 'English', value: 'en'},
  {text: 'Русский', value: 'ru'}
]

export const i18n = createI18n({
  locale: locale, // set locale
  fallbackLocale: locale,
  globalInjection: true,
  messages: {
    'en': messages
  } // set locale messages
})

const loadedLanguages = [locale] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return await setI18nLanguage(lang)
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return await setI18nLanguage(lang)
  }
  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `../lang/${lang}.js`).then(
    messages => {
      i18n.global.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}
