<template>
  <div>
    <v-text-field
      ref="inputRef"
      type="text"
      :variant="variant"
      :model-value="formattedValue"
      :label="label"
      :readonly="readonly"
      :disabled="disabled"
      color="primary"
      base-color="primary"
      bg-color="#fff"
      :hide-details="isErrorHidden"
      :error-messages="isValid ? '' : $t('Price_error')"
    />
  </div>
</template>

<script>
import { watch, computed } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'VCurrencyField',
  emits: ['update', 'change'],
  props: {
    modelValue: {
      type: Number,
    },
    color: {
      type: String,
    },
    variant: {
      type: String,
    },
    label: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    positiveOnly: {
      type: Boolean,
      default: true,
    },
    isErrorHidden: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const options = {
      currency: 'USD',
      currencyDisplay: 'hidden',
      autoDecimalDigits: false,
      precision: { min: 2, max: 2 },
      valueRange: { min: 0, max: 99999999999.99 } // A maximum of 11 integers and 2 decimal symbols, or 14 symbols in total.
    }
    const { inputRef, setValue, numberValue, formattedValue } = useCurrencyInput(options, false)  //false to disable autoEmit

    const isValid = computed(() =>
      props.positiveOnly && numberValue.value >= 0
    )

    watch(
      numberValue,
      (value) => {
        emit('update', value)
      }
    )

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value)
      }
    )

    watch(inputRef, (newValue) => {
      if (newValue) {
        setValue(props.modelValue)
      }
    })

    return { inputRef, formattedValue, isValid }
  }
}
</script>
