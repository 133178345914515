import itineraryService from '../itineraryService'
import moment from 'moment'
import flight from './flight'
import lodging from './lodging'
import restaurants from './restaurants'
import attractions from './attractions'
import shows from './shows'
import tours from './tours'
import transportation from './transportation'
import cruise from './cruise'
import insurance from './insurance'
import { getItemTypeByType, EventTypes } from '@/helpers/itemTypes'
import uiUtils from '../uiUtils'
import { store } from '../../store'
import { logAppError } from '@/logger'
import datetime from '@/helpers/datetime'
import placeSearch from '../placeSearch'

// buffer time after arrival and before departure
export const BUFFER_AFTER_ARRIVAL    =  {hours:1, minutes: 0}
export const BUFFER_BEFORE_DEPARTURE =  {hours:2, minutes: 0}

export default {
  /**
    adds items to the itinerary
    replaceId is optional
   */
  async addItems(itineraryID, userID, items, replaceId) {
    await store.dispatch('ADD_ITEM_TO_ITINERARY', {
      itineraryID:  itineraryID,
      userID:       userID,
      items:        items
    }).then(async () => {
      if (replaceId) {
        await this.removeItem(itineraryID, userID, replaceId)
      }
    })
  },
  async removeItem(itineraryID, userID, itemID) {
    await store.dispatch('REMOVE_ITINERARY_ITEM', {
      itineraryID:  itineraryID,
      userID:       userID,
      itemID:       itemID
    })
  },

  async addInsurance(itineraryID, userID, tripProtection) {
    await store.dispatch('ADD_INSURANCE', {
      itinIdParam:    itineraryID,
      userID:         userID,
      ...tripProtection
    })
  },
  async removeInsurance(itineraryID, userID, tripProtectionID) {
    await store.dispatch('REMOVE_INSURANCE', {
      itineraryID:      itineraryID,
      userID:           userID,
      tripProtectionID: tripProtectionID
    })
  },

  async replaceItem(itineraryID, userID, item, replaceId) {
    return await store.dispatch('REPLACE_ITINERARY_ITEM', {
      itineraryID:  itineraryID,
      userID:       userID,
      itemID:       replaceId,
      item:         item
    })
  },

  async addManualTransportationOptions({ options, routes }) {
    const payload = {
      itineraryID: store.getters.CURRENT_ITINERARY.header.itineraryID,
      userID: store.getters.USER.id,
      travelers: {
        adults:   Number(store.getters.CURRENT_ITINERARY.header.travelers.adults),
        children: Number(store.getters.CURRENT_ITINERARY.header.travelers.children) || 0,
      },
      routes,
      options: options,
    }

    if (Object.hasOwn(options, EventTypes.FLIGHT)) {
      await this.createTransportationEventsManually({ ...payload, eventType: EventTypes.FLIGHT })
    }
    if (Object.hasOwn(options, EventTypes.TRAIN)) {
      await this.createTransportationEventsManually({ ...payload, eventType: EventTypes.TRAIN })
    }
    if (Object.hasOwn(options, EventTypes.FERRY)) {
      await this.createTransportationEventsManually({ ...payload, eventType: EventTypes.FERRY })
    }
    if (Object.hasOwn(options, EventTypes.BUS)) {
      await this.createTransportationEventsManually({ ...payload, eventType: EventTypes.BUS })
    }
    if (Object.hasOwn(options, EventTypes.CAR_RENTAL)) {
      await this.createTransportationEventsManually({ ...payload, eventType: EventTypes.CAR_RENTAL })
    }
    if (Object.hasOwn(options, EventTypes.TRANSFER)) {
      await this.createTransportationEventsManually({ ...payload, eventType: EventTypes.TRANSFER })
    }
  },

  async autoFillItinerary({ options, routes }) {
    let payload = {
      itineraryID: store.getters.CURRENT_ITINERARY.header.itineraryID,
      userID: store.getters.USER.id,
      travelers: {
        adults:   Number(store.getters.CURRENT_ITINERARY.header.travelers.adults),
        children: Number(store.getters.CURRENT_ITINERARY.header.travelers.children) || 0,
      },
      routes,
    }
    //transportation options
    if (options.flight) {
      await flight.autoFill(payload)
    }
    if (options.trainRide) {
      await transportation.autoFill(payload)
    }
    if (options.ferry) {
      await transportation.autoFill(payload)
    }
    if (options.busRide) {
      await transportation.autoFill(payload)
    }
    if (options.carRental) {
      await transportation.autoFill(payload)
    }
    if (options.transfer) {
      await transportation.autoFill(payload)
    }

    //other events: we need to pass both locationsAndDates and routes
    //as there is not enough informations on the duration of stay in each location in the routes only
    let locationsAndDates = this.getItineraryLocations(store.getters.CURRENT_ITINERARY, routes)
    payload = {
      locationsAndDates,
      itineraryID: store.getters.CURRENT_ITINERARY.header.itineraryID,
      userID: store.getters.USER.id,
      travelers: {
        adults:   Number(store.getters.CURRENT_ITINERARY.header.travelers.adults),
        children: Number(store.getters.CURRENT_ITINERARY.header.travelers.children) || 0,
      },
      routes,
    }

    if (options.lodging) {
      await lodging.autoFill(payload)
    }
    if (options.restaurant) {
      await restaurants.autoFill(payload)
    }
    if (options.attraction) {
      await attractions.autoFill(payload)
    }
    if (options.show) {
      await shows.autoFill(payload)
    }
    if (options.tour) {
      await tours.autoFill(payload)
    }
    if (options.insurance) {
      await insurance.autoFill(payload)
    }

    if (options.cruise) {
      await cruise.autoFill(payload)
    }
  },

  /**
    takes itinerary object and returns array of locationsAndDates:
    [
      { placeID, arrivalDateTime, departureDateTime },
      { placeID, arrivalDateTime, departureDateTime }
    ]
      -placeID:          String
      -arrivalDateTime:   Moment Object
      -departureDateTime: Moment Object
      -isLastLocation:    Boolean
   */
  getItineraryLocations(itinerary, routes = []) {
    let locationsAndDates = []
    // we really need to look at flights for now
    // TODO: when other transporation options are in place this will needs to be adjusted
    let flights = this.getItineraryFlights(itinerary)

    for (let i=0; i< flights.length; i++) {
      let flightItem = flights[i].flight
      let isLastLocation = (i === flights.length - 1)

      let arrivalSegment = flightItem.segments[flightItem.segments.length - 1]
      let dateTimeIn = moment.parseZone(flights[i].header.endDateTime, 'YYYY-MM-DDTHH:mm:ssZ')
      let dateTimeOut = ''
      if (isLastLocation) {
        // in case when it's not a round trip,
        // we assume that the traveler will stay for 3 days at the last location
        // skip for round trips
        if (this.isRoundTrip(routes)) break // this is needed to avoid extra search calls
        dateTimeOut = dateTimeIn.clone().add(3, 'days')
      } else {
        dateTimeOut = moment.parseZone(flights[i+1].header.startDateTime, 'YYYY-MM-DDTHH:mm:ssZ')
      }

      locationsAndDates.push({
        iataCode:             arrivalSegment.arrival.location.iataCode,
        placeID:              arrivalSegment.arrival.location.placeID,
        arrivalDateTime:      dateTimeIn,
        departureDateTime:    dateTimeOut,
        isLastLocation:       isLastLocation,
      })
    }

    //if itinerary was created without flights we should rely on routes from tripRequest
    if(locationsAndDates.length === 0) {
      for (let i=0; i< routes.length - 1; i++) {
        const routeItem = routes[i]
        const isLastLocation = (i === routes.length - 1)
        const dateTimeIn = moment({
          year:   routeItem.departureDate.year,
          month:  routeItem.departureDate.month - 1,
          day:    routeItem.departureDate.day
        }).parseZone('YYYY-MM-DDTHH:mm:ssZ')
        let dateTimeOut
        if (isLastLocation && !this.isRoundTrip(routes)) {
          dateTimeOut = dateTimeIn.clone().add(3, 'days')
        } else {
          dateTimeOut = moment({
            year:   routes[i+1].departureDate.year,
            month:  routes[i+1].departureDate.month - 1,
            day:    routes[i+1].departureDate.day
          }).parseZone('YYYY-MM-DDTHH:mm:ssZ')
        }

        locationsAndDates.push({
          //it is necessary to provide toPlaceID and fromPlaceID for manual transportation events creation
          toPlaceID:            routeItem.toPlaceID,
          fromPlaceID:          routeItem.fromPlaceID,
          placeID:              routeItem.toPlaceID,
          arrivalDateTime:      dateTimeIn,
          departureDateTime:    dateTimeOut,
          isLastLocation:       isLastLocation,
        })
      }
    }

    return locationsAndDates
  },

  getItineraryFlights(itinerary) {
    if (!Array.isArray(itinerary?.items)) return []

    // we really need to look at flights for now
    // TODO: when other transporation options are in place this will needs to be adjusted
    let flights = itinerary.items.filter((item) => {
      return itineraryService.getEventType(item) === EventTypes.FLIGHT
    })

    return flights
  },

  isRoundTrip(routes = []) {
    return routes.length > 1 && (routes[0].fromPlaceID === routes[routes.length - 1].toPlaceID)
  },

  daysInItinerary(locationsAndDates) {
    let firstDay = locationsAndDates[0].arrivalDateTime
    let lastDay =  locationsAndDates[locationsAndDates.length - 1].departureDateTime

    return lastDay.diff(firstDay, 'days')
  },

  daysAtLocation(location) {
    let firstDay = location.arrivalDateTime
    let lastDay = location.departureDateTime

    return lastDay.diff(firstDay, 'days') + 1
  },

  /**
  input momentDateTimeIn
  returns dateTime as {year: Number, month: Number, day: Number, hour: Number, minute: Number}
   */
  momentDateTimeToStruct(momentDateTimeIn, withHours = true) {
    const result = {
      year:   Number(momentDateTimeIn.format('YYYY')),
      month:  Number(momentDateTimeIn.format('MM')),
      day:    Number(momentDateTimeIn.format('DD')),
    }
    if(withHours){
      result.hour = Number(momentDateTimeIn.format('HH'))
      result.minute = Number(momentDateTimeIn.format('mm'))
    }
    return result
  },

  /**
    checks if checkDateTime is between dateTime1 and dateTime2
    checkMoment is the moment date obj
    dateTime1 and dateTime2 params are in form {year: Number, month: Number, day: Number, hour: Number, minute: Number}
   */
  isBetweenDates(checkMoment, dateTime1, dateTime2) {
    return checkMoment.isBetween(dateTime1, dateTime2)
  },

  /**
    autoFillEvents will fetch and return events to be added to the ititnerary provided:
      -locationsAndDates is an array:
      [
        { placeID, arrivalDateTime, departureDateTime },
        { placeID, arrivalDateTime, departureDateTime }
        ...
      ]
      -placeID: String
      -arrivalDateTime:   { year: Number, month: Number, day: Number, hour: Number, minute: Number }
      -departureDateTime: { year: Number, month: Number, day: Number, hour: Number, minute: Number }
      Couple of rules:
        - We will assume 1 (one) hour duration, and ignore departureDateTime)

      -eventTimes is an array representing events times:
      [
        {hour: Number, minute: Number},
        {hour: Number, minute: Number},
        ...
      ]
      -searchCallback is the reference to search method to be called taking these parameters:
        -placeID,
        -startDateTime,
        -endDateTime,
        -size
   */
  async autoFillEvents(locationsAndDates, eventTimes, searchCallback, routes) {
    let events = []

    for (let i=0; i<locationsAndDates.length; i++) {
      let location = locationsAndDates[i]
      let daysAtLocation = this.daysAtLocation(location)
      let searchResults = await searchCallback(
        routes[i]?.toPlaceID,
        this.momentDateTimeToStruct(location.arrivalDateTime),
        this.momentDateTimeToStruct(location.departureDateTime),
        daysAtLocation * eventTimes.length)

      //if(searchResults.coverageDetails!=undefined) events.push(searchResults)

      let daysCount = 0
      for (let r=0; r<searchResults.length; ) {

        for (let t=0; t<eventTimes.length; t++) {
          let searchResult = searchResults[r]
          let eventTime = eventTimes[t]
          let mStartDateTime = moment.parseZone(searchResult.header.startDateTime)
          let eventStartDateTime = mStartDateTime.clone()

          eventStartDateTime = eventStartDateTime
            .hour(eventTime.startTime.hour)
            .minute(eventTime.startTime.minute)
            .add(daysCount, 'days')

          let eventEndDateTime = eventStartDateTime.clone()
            .add(eventTime.duration.hours, 'hour')
            .add(eventTime.duration.minutes, 'minute')

          if (
            this.isBetweenDates(
              moment.parseZone(eventStartDateTime)
                .subtract({hours: BUFFER_AFTER_ARRIVAL.hours, minutes: BUFFER_AFTER_ARRIVAL.minues}),
              location.arrivalDateTime,
              location.departureDateTime) &&
            this.isBetweenDates(
              moment.parseZone(eventEndDateTime)
                .add({hours: BUFFER_BEFORE_DEPARTURE.hours, minutes: BUFFER_BEFORE_DEPARTURE.minues}),
              location.arrivalDateTime,
              location.departureDateTime)) {

            searchResult.header.startDateTime = eventStartDateTime.format('YYYY-MM-DDTHH:mm:00Z')
            searchResult.header.endDateTime = eventEndDateTime.format('YYYY-MM-DDTHH:mm:00Z')
            events.push(searchResult)
            r++
            if (r >= searchResults.length) break
          }
        }

        daysCount ++
        if (daysCount > daysAtLocation) {
          break
        }
      }
    }
    return events
  },

  async createTransportationEventsManually({ itineraryID, userID, travelers, eventType, routes }) {
    const events = []
    const eventConfig = getItemTypeByType(eventType)
    uiUtils.loadingDialog(eventConfig.type)

    for (let i = 0; i < routes.length; i++) {
      const startAndEndDateStruct = routes[i].departureDate
      const totalPrice = { amount: 10000, currency: 'USD' }

      //for flights find nearest airports
      let fromPlaceID = routes[i].fromPlaceID
      let toPlaceID = routes[i].toPlaceID
      if (eventType === EventTypes.FLIGHT) {
        let locations = await placeSearch.lookupPlacesByIds([fromPlaceID, toPlaceID])
        if (locations) {
          locations.forEach(async (location) => {
            if (location?.cityPlace) {
              const airports = await placeSearch.lookupPlacesInRange(location.latLon, 'airport', '100mi', 1)
              if (airports && airports?.[0]) {
                const airport = airports[0]
                fromPlaceID = (location?.placeID === fromPlaceID && airport?.airportPlace?.iataCode) ? airport?.placeID : fromPlaceID
                toPlaceID = (location?.placeID === toPlaceID && airport?.airportPlace?.iataCode) ? airport?.placeID : toPlaceID
              }
            }
          })
        }
      }

      const startDateTime = await datetime.convertDateTimeStructToString({ ...startAndEndDateStruct, hour: 10, minute: 0 }, fromPlaceID)
      let endDateTime = (await datetime.calculateDateTimeStringOffset(startDateTime, toPlaceID, 0)).at(0)

      // duration of the [fake] flight is time zone diff + 2 hours
      let durationHours = Math.abs(moment.parseZone(endDateTime).utcOffset() - moment.parseZone(startDateTime).utcOffset()) / 60
      endDateTime = moment.parseZone(endDateTime).add(durationHours + 2, 'hour')

      const payload = {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        location: { placeID: fromPlaceID },
        travelers: travelers,
      }

      switch (eventType) {
      case EventTypes.FLIGHT:
      case EventTypes.TRAIN:
      case EventTypes.BUS:
      case EventTypes.FERRY:
      case EventTypes.CAR_RENTAL:
      case EventTypes.TRANSFER:
        payload[eventType] = await transportation.createEventStruct(
          fromPlaceID,
          startDateTime,
          toPlaceID,
          endDateTime,
          totalPrice
        )
        break

      default:
        break
      }
      try {
        const response = await store.dispatch('CREATE_EVENT_MANUALLY', payload)
        if (response.status === 200) {
          events.push(response.data)
        }
      } catch(err) {
        logAppError(err)
      }
    }

    if (events && events.length > 0) {
      await this.addItems(itineraryID, userID, events)
    }

    uiUtils.loadingDialog()
  }

}
