<template>
  <question-wrapper
    :step="step"
    :footerText="$t('Hotels_Caption')"
    class="qwrapper"
  >
    <template v-slot:body>
      <v-col class="pa-0">
        <hotels-selector
          :hotelPreferences="hotels"
          @update="updateHotels"
        />
      </v-col>
    </template>
  </question-wrapper>
</template>

<script>
import QuestionWrapper from '../QuestionWrapper.vue'
import { mapActions, mapGetters } from 'vuex'
import checkEquality from  'fast-deep-equal/es6'
import HotelsSelector from '@/components/selector/HotelsSelector'
import { defaultPreferences } from '@/store/modules/user'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'HotelChainQuestion',
  components: {
    QuestionWrapper,
    HotelsSelector
  },
  props: {
    step: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    hotels: [],
  }),
  created() {
    this.hotels = cloneDeep(this.user.preferences?.hotelMembership) || []
  },
  computed: {
    ...mapGetters({
      user: 'USER'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updateHotels(hotels) {
      this.hotels = hotels
    },
    updateProfile() {
      const isHotelsChanged = !checkEquality(this.hotels, this.user.preferences?.hotelMembership)
      if(isHotelsChanged){
        const preferences = cloneDeep(this.user.preferences || defaultPreferences)
        preferences.hotelMembership = this.hotels
        this.updateUser({ preferences })
      }
    }
  },
  beforeUnmount() {
    this.updateProfile()
  }
}
</script>
