<template>
  <div data-test-name="content-timeline">
    <v-row class="ma-4 mb-n2">
      <v-col
        v-for="(segment, i) in segmentsComputed"
        :key="`segment-${i}`"
        :cols="segments?.length < 2 ? 12 : (segment?.isLayover ? 1 : 11 / segments?.length)"
        class="px-0"
      >
        <div
          v-if="segment?.isLayover"
          class="d-flex flex-column align-center justify-end h-100 text-center"
        >
          <hr class="green-line green-line--dashed" />
          <span class="text-caption text-secondaryDarken dashed-duration-time">{{ segment?.duration }}</span>
        </div>
        <div class="d-flex flex-column justify-space-between h-100" v-else>
          <v-row no-gutters>
            <v-col class="d-flex flex-column align-start" cols="6">
              <div class="text-caption text-sm-h6 font-weight-bold w-100 d-flex align-center">
                <span class="text-truncate">
                  <async-place-label :placeID="segment?.departure?.location?.placeID" />
                </span>
                <v-icon v-if="!$vuetify.display.xs" color="primary" :size="segment?.isNightDeparture ? 16 : 24">
                  {{ segment?.isNightDeparture ? '$customMoon' : 'mdi-weather-sunny' }}
                </v-icon>
              </div>
              <span v-if="!$vuetify.display.xs" class="text-primary">
                {{ formatDate(segment?.departure?.at) }}
              </span>
              <span v-if="!$vuetify.display.xs" class="text-secondaryDarken">
                {{ formatTime(segment?.departure?.at) }}
              </span>
            </v-col>
            <v-col class="d-flex flex-column align-end" cols="6">
              <div class="text-caption text-sm-h6 font-weight-bold w-100 d-flex justify-end align-center">
                <span class="text-truncate">
                  <async-place-label :placeID="segment?.arrival?.location?.placeID" />
                </span>
                <v-icon v-if="!$vuetify.display.xs" color="primary" :size="segment?.isNightArrival ? 16 : 24">
                  {{ segment.isNightArrival ? '$customMoon' : 'mdi-weather-sunny' }}
                </v-icon>
              </div>
              <span v-if="!$vuetify.display.xs" class="text-primary">
                {{ formatDate(segment?.arrival?.at) }}
              </span>
              <span v-if="!$vuetify.display.xs" class="text-secondaryDarken">
                {{ formatTime(segment?.arrival?.at) }}
              </span>
            </v-col>
          </v-row>
          <div>
            <duration-line />
            <v-row class="justify-center">
              <v-col v-if="!$vuetify.display.xs" cols="2" class="text-left">
                <v-icon :icon="departureIcon" />
              </v-col>
              <v-col class="text-center" cols="8">
                <span class="text-caption text-secondaryDarken">{{ segment?.duration }}</span>
              </v-col>
              <v-col v-if="!$vuetify.display.xs" cols="2" class="text-right">
                <v-icon :icon="arrivalIcon" />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <span
          v-if="duration?.total"
          class="text-caption text-sm-subtitle-1 font-weight-medium"
        >
          {{ $t('Total_Duration') + ': ' + duration?.total }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DurationLine from './DurationLine'
import { EventTypes } from '@/helpers/itemTypes'
import AsyncPlaceLabel from '@/components/itinerary-edit/AsyncPlaceLabel'

export default {
  name: 'TimeLine',
  components: {
    DurationLine,
    AsyncPlaceLabel,
  },
  props: {
    duration: {
      type: Object,
    },
    segments: {
      type: Array,
      required: true,
    },
    eventType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
      dateFormat: 'DATE_FORMAT',
    }),
    segmentsComputed() {
      const segmentsWithLayovers = []
      this.segments.forEach((item, i) => {
        const isNightDeparture = this.isNight(item?.departure?.at)
        const isNightArrival = this.isNight(item?.arrival?.at)
        segmentsWithLayovers.push({ ...item, duration: this.duration.segments?.[i], isNightDeparture, isNightArrival })
        if (i < this.segments.length - 1) segmentsWithLayovers.push({ isLayover: true, duration: this.duration.layovers?.[i] })
      })

      return segmentsWithLayovers
    },
    departureIcon() {
      let rv = 'mdi-airplane-takeoff'

      switch (this.eventType) {
      case EventTypes.FLIGHT:
        rv = 'mdi-airplane-takeoff'
        break

      case EventTypes.TRAIN:
        rv = '$customRail'
        break

      case EventTypes.BUS:
        rv = '$customBus'
        break

      case EventTypes.FERRY:
        rv = '$customBoat'
        break

      case EventTypes.CAR_RENTAL:
      case EventTypes.DRIVE:
        rv = '$customCar'
        break
      }

      return rv
    },
    arrivalIcon() {
      let rv = 'mdi-airplane-landing'

      switch (this.eventType) {
      case EventTypes.FLIGHT:
        rv = 'mdi-airplane-landing'
        break

      case EventTypes.TRAIN:
        rv = '$customRail'
        break

      case EventTypes.BUS:
        rv = '$customBus'
        break

      case EventTypes.FERRY:
        rv = '$customBoat'
        break

      case EventTypes.CAR_RENTAL:
      case EventTypes.DRIVE:
        rv = '$customCar'
        break
      }

      return rv
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      return this.$moment.parseZone(date).format(this.dateFormat)
    },
    formatTime(time) {
      if (!time) return ''
      return this.$moment.parseZone(time).format(this.timeFormat)
    },
    isNight(time) {
      const currentHour = Number(this.$moment(time).format('HH'))
      return currentHour > 23 || currentHour < 6
    },
  },
}
</script>

<style scoped lang="scss">
.green-line {
  display: block;
  width: 100%;
  border: 0;
  border-top: 1px solid rgb(var(--v-theme-success));
  &--dashed {
    margin-bottom: 9px;
    border-top: 1px dashed rgb(var(--v-theme-success));
  }
}
.dashed-duration-time {
  height: 19px;
}
</style>
