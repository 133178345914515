<template>
  <v-card
    flat
    class="py-1 mr-2 rounded-lg"
    data-test-name="card-comment"
    :class="{'px-2':isUnread}"
    :color="commentColor">
    <v-row class="pr-0 mb-n3 mb-sm-n1 justify-space-between" no-gutters>
      <v-card-title class="ma-0 pa-0 text-caption">
        <span class="font-weight-bold text-black" data-test-name="text-user-name">{{ commentUserName }}</span>
        <v-icon size="11" icon="mdi-circle-small" data-test-name="icon" />
        <span class="text-grey text-caption" data-test-name="text-date">{{ postDate }}</span>
      </v-card-title>
      <div class="d-flex flex-row">
        <v-btn
          v-if="isOwnComment && !isEditModeActivated"
          variant="plain"
          size="medium"
          data-test-id='comment__edit-button'
          :title="$t('Edit')"
          @click.stop="activateEditMode"
          prepend-icon="$customEditComment"
          data-test-name="button-edit"
          class="px-1 mx-1"
        />
        <v-btn
          v-else-if="isOwnComment && isEditModeActivated"
          variant="plain"
          size="medium"
          data-test-id='comment__save-button'
          :title="$t('Edit')"
          @click.stop="saveChanges"
          prepend-icon="mdi-check"
          data-test-name="button-save"
          class="px-1 mx-1"
        />
        <v-btn
          v-if="isOwnComment || isOwner"
          variant="plain"
          size="medium"
          data-test-id='comment__delete-button'
          :title="$t('Delete')"
          @click.stop="isDeleteCommentConfirmationVisible = true"
          prepend-icon="$customDelete"
          data-test-name="button-delete"
          class="px-1 px-1"
        />
      </div>
    </v-row>
    <v-textarea
      class="mt-n4 py-n2 text-body-2 text-black"
      ref="textarea"
      variant="plain"
      density="compact"
      auto-grow
      hide-details
      :rows="areaRows"
      maxlength="300"
      v-model="commentText"
      :readonly="!isEditModeActivated"
      @keydown.enter="saveChanges"
      data-test-name="input-comment"
    />
    <common-popup
      v-if="isDeleteCommentConfirmationVisible"
      :body-content="$t('Delete_this_comment')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="deleteComment"
      @cancel="discardDeletion"
      @close="discardDeletion"
    />
  </v-card>
</template>

<script>
import CommonPopup from '@/components/popups/CommonPopup'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommentItem',
  components: {CommonPopup},
  props:{
    comment: Object,
    itemID: String,
    itineraryID: String,
    isUnread: Boolean,
  },
  data() {
    return {
      commentText: this.comment?.text,
      isEditModeActivated: false,
      isDeleteCommentConfirmationVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
      dateFormat: 'DATE_FORMAT',
      isEditor: 'IS_EDITOR',
      isOwner: 'IS_OWNER',
      user: 'USER',
      itineraryComments: 'COMMENTS',
    }),
    commentUserName() {
      return this.comment?.userName
    },
    commentColor() {
      return 'textSecondaryColor'
    },
    postDate() {
      return this.$moment(this.comment.date).local().format(`${this.dateFormat} ${this.timeFormat}`)
    },
    isOwnComment() {
      return this.user?.id === this.comment?.userID
    },
    areaRows() {
      return this.isOwnComment && this.isEditModeActivated ? 2 : 1
    }
  },
  methods: {
    ...mapActions({
      updateComment: 'UPDATE_COMMENT',
      removeComment: 'REMOVE_COMMENT',
    }),
    discardDeletion() {
      this.isDeleteCommentConfirmationVisible = false
      this.commentText = this.comment?.text
    },
    deactivateEditMode() {
      this.isEditModeActivated = false
    },
    async saveChanges() {
      if(this.commentText === '') {
        this.isDeleteCommentConfirmationVisible = true
      } else if(this.commentText !== this.comment?.text) {
        await this.putComment(this.commentText, this.comment.id, this.comment.text)
      }
      this.deactivateEditMode()
    },
    activateEditMode() {
      this.isEditModeActivated = true
      this.$nextTick(() => {
        this.$refs.textarea.focus()
      })
      navigator.virtualKeyboard?.show()
    },
    async putComment(value, commentId, oldValue) {
      if(value !== oldValue) {
        const payload = { commentId, text: value }
        if(this.itemID) {
          payload.itemID = this.itemID
        }
        await this.updateComment(payload)
      }
    },
    async deleteComment() {
      const commentId = this.comment.id
      const payload = { commentId }
      if(this.itemID) {
        payload.itemID = this.itemID
      }
      await this.removeComment(payload)
      this.deactivateEditMode()
      this.isDeleteCommentConfirmationVisible = false
    }
  }
}
</script>

