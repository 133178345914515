<template>
  <v-row class="route-wrapper py-4 py-sm-1" no-gutters>
    <v-col
      cols="12"
      sm="6"
      md="3"
      class="input-column"
    >
      <place-input
        :value="value.fromPlaceID"
        :placeholder="`${$t('Where_from')}?`"
        :isValid="isLocationsValid"
        name="Origin"
        content-class="Origin"
        min-width="450"
        icon="$customLocation"
        size="x-small"
        @update="updateDeparture"
        class="mb-n5 mr-2 mx-0 mx-sm-4"
        variant="plain"
        data-test-name="input-place-from"
        :isAllowAirportsInSearch="isAllowAirportsInSearch"
      />
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="3"
      class="pt-3 pt-sm-0 input-column"
    >
      <place-input
        :value="value.toPlaceID"
        :placeholder="`${$t('Where_to')}?`"
        :isValid="isLocationsValid"
        name="destination"
        content-class="destination"
        min-width="450"
        icon="$customLocation"
        @update="updateArrival"
        class="mb-n5 mx-0 mx-sm-4"
        data-test-name="input-place-to"
        :isAllowAirportsInSearch="isAllowAirportsInSearch"
      />
    </v-col>

    <v-col
      cols="12"
      md="4"
      class="pb-0"
    >
      <date-picker
        :model-value="value?.date"
        variant="plain"
        :range="isRoundTrip"
        :min-date="value?.minDate"
        :placeholder="!isRoundTrip ? $t('Date') : $t('Dates')"
        :name="`datePickerTripRequestRoute-${routeIndex}`"
        min-width="320"
        @update:model-value="updateDate"
        class="date-picker px-xs-0 px-4"
      />
      <p v-if="!isDateValid" class="py-0 my-0 text-red-lighten-4 text-caption">
        {{ $t('Incorrect_date_message') }}
      </p>
    </v-col>

    <v-col
      v-if="isDeleteBtnVisible"
      cols="12"
      md="auto"
      :class="{ 'mt-6' : $vuetify.display.smAndDown }"
      class="d-flex align-center ml-auto"
    >
      <v-btn
        variant="tonal"
        :class="{ 'mobile-button': $vuetify.display.smAndDown }"
        @click="onClickDelete"
        color="primary"
        rounded
        data-test-name="button-delete"
      >
        <v-icon color="secondary" size="24" icon="mdi-delete" />
        <label v-if="$vuetify.display.smAndDown">{{ $t('Remove') }}</label>
      </v-btn>
    </v-col>

    <v-col
      v-if="isLastRoute"
      cols="12"
      :sm="isAddNewButtonVisible? 'auto' : 3"
      class="pb-0 pb-sm-2 d-flex align-center ml-auto mt-6 mt-sm-0"
    >
      <v-col cols="4" class="pa-0">
        <v-btn
          v-if="isAddNewButtonVisible && isLastRoute"
          @click="onClickAdd"
          color="primary"
          class="pa-0 px-3 mr-2"
          :class="{'mobile-button': $vuetify.display.smAndDown}"
          rounded
          variant="tonal"
          data-test-name="button-add"
        >
          <v-icon size="24" icon="mdi-plus" />
          {{ $t('Add') }}
        </v-btn>
      </v-col>
      <v-col cols="8" class="pa-0 d-flex justify-end">
        <v-btn
          :disabled="isCreateButtonDisabled"
          @click="onClickCreate"
          color="primary"
          class="px-0 text-uppercase"
          rounded
          data-test-name="button-create"
        >
          <div
            class="mx-8 mx-md-1 mx-lg-8 text-sm-subtitle-3 text-sm-subtitle-2 route-wrapper__btn"
          >
            {{ $t(createButtonLabel) }}
          </div>
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from '../components/DatePicker'
import PlaceInput from '../components/PlaceInput'

export default {
  name: 'TripRequestRoute',
  components: { PlaceInput, DatePicker },
  emits: ['input', 'onCreate', 'onAdd', 'onDelete'],
  props: {
    value: Object,
    isRoundTrip: Boolean,
    isCreateButtonDisabled: {
      type: Boolean,
      default: false,
    },
    createButtonLabel: {
      type: String,
      default: 'Create_itinerary'
    },
    routeIndex: {
      type: Number,
      required: true,
    },
    routesCount: {
      type: Number,
      default: 1,
    },
    isAllowMultipleRoutes: {
      type: Boolean,
      default: true,
    },
    routesLimit: {
      type: Number,
      default: 1,
    },
    isAllowAirportsInSearch: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    isAddNewButtonVisible() {
      return !this.isRoundTrip && this.isAllowMultipleRoutes && this.routesCount < this.routesLimit
    },
    isLastRoute() {
      return this.routeIndex === this.routesCount - 1
    },
    isLocationsValid() {
      return (!this.value.fromPlaceID || !this.value.toPlaceID) || this.value.fromPlaceID !== this.value.toPlaceID
    },
    isDateValid() {
      //error message should be shown if round trip is selected, start date is selected, end date isn't selected
      return Array.isArray(this.value.date) && this.isRoundTrip ? this.value.date.length !== 1 : true
    },
    isDeleteBtnVisible() {
      //show delete in the following cases: when more than one row and not a round trip
      return !this.isRoundTrip && this.routesCount > 1
    }
  },
  methods: {
    updateDeparture(from) {
      this.updateRoute({fromPlaceID: from})
    },
    updateArrival(to) {
      this.updateRoute({toPlaceID: to})
    },
    updateDate(date) {
      this.updateRoute({date})
    },
    updateRoute(updatedParam) {
      this.$emit('input', {...this.value, ...updatedParam})
    },
    onClickCreate() {
      this.$emit('onCreate')
    },
    onClickAdd() {
      this.$emit('onAdd')
    },
    onClickDelete() {
      this.$emit('onDelete')
    },
  },
}
</script>

<style lang="scss" scoped>
.route-wrapper {
  position: relative;

  &:last-child::before {
    display: none;
  }

  &:before {
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    height: 1px;
    content: '';
    background-color: rgb(var(--v-theme-primary));
    opacity: 0.1;
  }

  &__btn {
    width: 150px;
  }

  .v-col {
    &:last-child::before {
      @media (max-width: 600px) {
        margin-block: 0.5rem;
      }
    }
  }
  .input-column {
    position: relative;

    @media (min-width: 600px) {
      &:before {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        width: 1px;
        margin: 0.5rem 0;
        content: '';
        background-color: rgb(var(--v-theme-primary));
        opacity: 0.1;
      }
    }
    .input-field {
      width: 100%;

      .input-field__label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }

  .date-picker {
    :deep(.v-text-field) {
      margin-top: 0 !important
    }

    :deep(.v-field__prepend-inner) {
      padding-top: 19px;
    }
  }
}
</style>
