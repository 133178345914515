import { getCurrencySelectList, convertAmountToServerFormat, getDatesAndTimeAsISOString, convertFromStringToNumber } from '@/helpers'
import { mapGetters } from 'vuex'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { initialTripRequest } from '@/store/modules/tripRequest'
import { rangeHour } from '@/mixins/RangeHourMixin'
import PassengersSelector from '@/components/popups/PassengersSelector'
import commonPopup from '@/components/popups/CommonPopup'
import DatePicker from '@/components/DatePicker'
import TimePicker from '@/components/TimePicker'
import PlaceInput from '@/components/PlaceInput'
import EventBooking from '@/components/popups/event-booking/EventBooking'
import EventPopupButtons from '@/components/itinerary-edit/EventPopupButtons'
import { getItemTypeByType } from '@/helpers/itemTypes'
import checkEquality from 'fast-deep-equal/es6'
import { checkAddressInput, checkNotesInput } from '@/helpers/inputValidators'
import datetime from '@/helpers/datetime'

export const eventFormMainStructure = {
  components: {
    PassengersSelector,
    commonPopup,
    DatePicker,
    PlaceInput,
    EventBooking,
    EventPopupButtons,
    TimePicker,
  },
  mixins: [rangeHour],
  emits: ['save', 'close', 'cancel', 'deleteEvent', 'updateTitle'],
  props: {
    title: String,
    isDialogVisible: Boolean,
    defaultStartDateTime: String,
    record: {
      type: Object,
      required: false,
    },
    createNew: {
      type: Boolean,
      default: true,
    },
    isSubmitButtonLoading: {
      type: Boolean,
      default: false
    },
    eventType: String,
    readonly: {
      type: Boolean
    },
    headerHeight: Number,
  },
  data() {
    return {
      initialRecord: null,
      detailsTabCachedRecord: null,
      isAfterStartTime: true,
      currencyList: [],
      imageURL: '',
      infoList: new Map(),
      isBooked: false,
      isDialogToDeleteEventVisible: false,
      location: '',
      description: '',
      notes: '',
      selectedEvent: null,
      simpleAddress: '',
      stars: 0,
      tab: 0,
      tabItems: ['Tab_Details', ...this.readonly ? [] : ['Tab_Search']],
      totalPrice: { amount: 0, currency: 'USD' },
      travelers: initialTripRequest.travelers,
      dataSource: null,
      dialogMarginY: 90,
      rules: {
        afterStartTime: async () => {
          this.isAfterStartTime = false
          this.isAfterStartTime = await datetime.validateEndDateAfterStart(
            this.startDate,
            this.startTime,
            this.location?.placeID,
            this.endDate,
            this.endTime,
            this.location?.placeID
          )
          return this.isAfterStartTime || this.$t('Before_Start_Error')
        },
        validateAddress: (v) => {
          return checkAddressInput(v) || this.$t('Input_max_length_error', [80])
        },
        validNotes: (v) => {
          return checkNotesInput(v) || this.$t('Input_max_length_error', [1000])
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      currentItinerary: 'CURRENT_ITINERARY',
      user: 'USER',
      isEditor: 'IS_EDITOR',
      userCurrency: 'CURRENCY',
      isMobile: 'IS_MOBILE',
    }),
    isReadOnly() {
      return this.readonly
    },
    currentDate() {
      return this.$moment(Date.now()).format('YYYY-MM-DD')
    },
    isValidPrice() {
      let rv = true
      if (this.totalPrice?.amount && this.totalPrice?.amount > 0) {
        //if amount entered - currency is required too
        rv = this.totalPrice?.amount && !!this.totalPrice?.currency
      }
      if (this.totalPrice?.amount < 0) {
        rv = false
      }
      return rv
    },
    isValidAddress() {
      return checkAddressInput(this.simpleAddress)
    },
    isValidTitle() {
      return this.title && this.title.trim().length > 2
    },
    isLocationValid() {
      return !!this.location?.placeID
    },
    isDatesValid() {
      return (
        !!this.startDate &&
        !!this.startTime &&
        !!this.endDate &&
        !!this.endTime &&
        this.isAfterStartTime
      )
    },
    isDatesLocationValid() {
      return this.isLocationValid && this.isDatesValid
    },
    // each event will implement its own specific validation
    isEventValid() {
      return true
    },
    // should not return promise otherwise it will return true before the promise is resolved
    isValidForm() {
      return (
        this.isDirty
        && this.isDatesLocationValid
        && this.isValidTitle
        && this.isValidPrice
        && this.isValidAddress
        && checkNotesInput(this.notes)
        && this.isEventValid
      )
    },
    totalTravelersNumber() {
      return (this.travelers?.adults || 0) + (this.travelers?.children || 0)
    },
    newRecord() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      const { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let eventData
      if (this.selectedEvent) {
        eventData = this.selectedEvent[this.eventType]
      } else if (this.record) {
        eventData = this.record[this.eventType]
      }

      let rv = {
        startDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(startDateTime)),
        endDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(endDateTime)),
        location: this.location,
        travelers: this.travelers,
        [this.eventType]: {
          ...(this.title) && { title: this.title },
          ...(this.simpleAddress) && { simpleAddress: this.simpleAddress },
          ...(this.notes) && { notes: this.notes },
          ...(this.isBooked) && { isBooked: this.isBooked },
          ...(this.description) && { shortDescription: this.description },
          ...(convertAmountToServerFormat(this.totalPrice?.amount))
          && {
            totalPrice: {
              currency: this.totalPrice?.currency,
              amount: convertAmountToServerFormat(this.totalPrice?.amount)
            },
          },
          //roData is never updated by the user -- just pass it on to the BE when present
          ...(eventData?.roData) && { roData: eventData.roData },
        }
      }

      return rv
    },
    minStartDate() {
      let date = this.$moment.parseZone(this.currentDate).format('YYYY-MM-DD HH:mm')
      return date
    },
    minEndDate() {
      let date = this.$moment.parseZone(this.startDate || this.currentDate).format('YYYY-MM-DD HH:mm')
      return date
    },
    isDirty() {
      if (!this.initialRecord) return false
      return !checkEquality(this.newRecord, this.initialRecord)
    },
  },
  watch: {
    isDialogVisible: {
      handler(newVal) {
        if (!newVal) {
          this.tab = 0
        }
        if (newVal && this.record) {
          this.resetValues()
        } else if (newVal && !this.record) {
          this.fillDefaultValues()
        }
      },
      immediate: true
    },
    tab(newTab) {
      this.cacheAndMapDetailsTabRecord(newTab)
    }
  },
  created() {
    this.currencyList = getCurrencySelectList(this.isMobile)
  },
  methods: {
    updateLocation(location) {
      this.updateAlternativeVisibility(location, this.location)
      this.location = location
    },
    updateStartDate(val) {
      this.updateAlternativeVisibility(val, this.startDate)
      this.startDate = val
    },
    updateEndDate(val) {
      this.updateAlternativeVisibility(val, this.endDate)
      this.endDate = val
    },
    updateStartTime(val) {
      this.updateAlternativeVisibility(val, this.startTime)
      this.startTime = val
    },
    updateEndTime(val) {
      this.updateAlternativeVisibility(val, this.endTime)
      this.endTime = val
    },
    updateAlternativeVisibility(newVal, oldVal) {
      if (newVal !== oldVal && this.tab === 1) {
        this.hideAlternatives()
      }
    },
    hideAlternatives() {
      this.$refs.alternatives.hideAlternatives()
    },
    onClickSave() {
      this.$emit('save', this.newRecord)
    },
    onClickCancel() {
      this.$emit('cancel')
    },
    onClickClose(params) {
      this.tab = 0
      this.selectedEvent = null
      this.$emit('close', params)
    },
    async onDeleteEvent() {
      this.$emit('deleteEvent')
    },
    updateCurrency(value) {
      this.totalPrice = { currency: value, amount: this.totalPrice?.amount }
    },
    updateAmount(value) {
      this.totalPrice = { currency: this.totalPrice?.currency, amount: convertFromStringToNumber(value) }
    },
    /**
     * resetValues sets the common values for events,
     * each event will set its unique values separately in the respective component
     */
    resetValues() {
      const record = this.record.header || this.record
      const startDate = record.startDateTime || record.startDate || new Date()
      const endDate = record.endDateTime || record.endDate || ''
      this.initEventDates(startDate, endDate)
      this.location = record.locations?.[0]
      this.travelers = {
        adults: record.travelers.adults || 2,
        children: record.travelers.children || 0,
      }
    },
    /**
     * initEventDates will initialize the dates for UI form using the following rules:
     *  - if a new event is added,
     *    startDate will be set to the itinerary start date/time
     *    endDate will be set based on the event default duration and can be changed later by user
     *  - for the existing events the current values are set
     */
    initEventDates(initialStartDate = this.currentItinerary?.header?.startDate, initialEndDate) {
      if (this.isValidDateStruct(initialStartDate) && this.isValidDateStruct(initialEndDate)) {
        this.setDatesFromStructure(initialStartDate, initialEndDate)
        return
      }

      let startDate = this.$moment.parseZone(initialStartDate)
      let endDate
      if (initialEndDate) {
        endDate = this.$moment.parseZone(initialEndDate)
      } else {
        // default duration in minutes
        const defaultDuration = getItemTypeByType(this.eventType).defaultDuration
        endDate = startDate.clone().add(defaultDuration, 'minutes')
      }

      this.startDate = startDate.format('YYYY-MM-DD')
      this.startTime = startDate.format('HH:mm')
      // End Date/Time watcher should call after Start Date/Time only
      this.$nextTick(() => {
        this.endDate = endDate.format('YYYY-MM-DD')
        this.endTime = endDate.format('HH:mm')
        if (!this.initialRecord) this.updateInitialRecord()
      })
    },
    isValidDateStruct(dateStruct) {
      return typeof dateStruct === 'object'
        && typeof dateStruct.year === 'number'
        && typeof dateStruct.month === 'number'
        && typeof dateStruct.day === 'number'
    },
    /**
     * data structure as {year: 2023, month: 3, day: 31, hour: 10, minute: 0}
     */
    setDatesFromStructure(startDateTimeStruct, endDateTimeStruct) {
      //month is zero based in moment library, need to properly adjust the input
      let startStruct = { ...startDateTimeStruct }
      startStruct.month--
      let endStruct = { ...endDateTimeStruct }
      endStruct.month--
      let startDate = this.$moment.parseZone(startStruct)
      let endDate = this.$moment.parseZone(endStruct)
      this.startDate = startDate.format('YYYY-MM-DD')
      this.startTime = startDate.format('HH:mm')
      this.endDate = endDate.format('YYYY-MM-DD')
      this.endTime = endDate.format('HH:mm')
    },
    fillDefaultValues() {
      let startDate = this.currentItinerary?.header?.startDate
      if (this.defaultStartDateTime) {
        //round hours as calendar click is not precise
        startDate = this.$moment.parseZone(this.defaultStartDateTime).format('YYYY-MM-DD HH:00')
      }
      this.initEventDates(startDate)

      this.$emit('updateTitle', '')
      this.travelers = {
        adults: this.currentItinerary?.header?.travelers?.adults || 2,
        children: this.currentItinerary?.header?.travelers?.children || 0,
      }
      this.totalPrice.currency = this.userCurrency || 'USD'

      // this will let events set default values specific to those events
      this.$nextTick(() => {
        this.fillEventDefaultValues()
        this.updateInitialRecord()
      })
    },
    /**
     *
     * IMPORTANT - fillEventDefaultValues method should remain empty
     * Each respective event should implement this method if needed
     *
     */
    fillEventDefaultValues() {
    },
    handleImageError() {
      this.imageURL = getItemTypeByType(this.eventType).defaultImageUrl
    },
    updateDataSource(data) {
      this.dataSource = data
    },
    updateInitialRecord() {
      this.initialRecord = this.newRecord
    },
    cacheAndMapDetailsTabRecord(newTab) {
      if (newTab === 1) {
        const record = this.newRecord
        this.detailsTabCachedRecord = {
          record,
        }
        this.detailsTabCachedRecord.startDate = this.startDate
        this.detailsTabCachedRecord.startTime = this.startTime
        this.detailsTabCachedRecord.endDate = this.endDate
        this.detailsTabCachedRecord.endTime = this.endTime
      } else if (newTab === 0 && this.detailsTabCachedRecord) {
        const { startDate, startTime, endDate, endTime } = this.detailsTabCachedRecord
        const record = this.detailsTabCachedRecord.record
        const eventData = record[this.eventType]

        this.travelers = record.travelers
        this.location = record.location

        this.startDate = startDate
        this.startTime = startTime
        this.endDate = endDate
        this.endTime = endTime

        this.mapDataToFormFields(eventData)
        this.detailsTabCachedRecord = null
      }
    },
    mapDataToFormFields() {
      //each event will implement its own method
    },
    updateTitle(value) {
      this.$emit('updateTitle', value)
    },
  },
}
