<template>
  <div class="d-flex align-center justify-space-between w-100">
    <div class="green-dot__outher">
      <div class="green-dot__inner" />
    </div>
    <hr :class="['green-line', {'green-line--dashed': dashed}]" />
    <div class="green-dot__outher">
      <div class="green-dot__inner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DurationLine',
  props: {
    dashed: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.green-dot {
  &__outher {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(var(--v-theme-success), 0.3);
  }
  &__inner {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: rgb(var(--v-theme-success));
  }
}
.green-line {
  display: block;
  width: 100%;
  border: 0;
  border-top: 1px solid rgb(var(--v-theme-success));
  &--dashed {
    border-top: 1px dashed rgb(var(--v-theme-success));
  }
}
</style>