<template>
  <v-dialog
    :model-value="true"
    :fullscreen="true"
    scrollable
    persistent
    no-click-animation
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="overflow-hidden">
      <v-card-title
        class="pa-4 pl-sm-8 pb-0"
        :class="{'pb-6': titleContent}"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn
              variant="flat"
              class="ml-auto d-block"
              @click="close"
              append-icon="mdi-close"
              data-test-name="button-close"
            />
          </v-col>
          <v-col v-if="titleContent">
            <h6
              class="text-h6 font-weight-bold"
              :class="isTitleLargeSize ? 'text-sm-h3': ''"
              data-test-name="text-title"
            >
              {{ titleContent }}
            </h6>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="slotFilled || bodyContent" class="px-4 px-sm-8 py-0">
        <slot>
          <span class="text-black text-h6 font-weight-bold mb-0" data-test-name="text-content">
            {{ bodyContent }}
          </span>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EventMenu',
  emits: ['close'],
  props: {
    directionsLink: String,
    titleContent: {
      type: String,
      required: false
    },
    isTitleLargeSize: {
      type: Boolean,
      required: false,
      default: false
    },
    bodyContent: {
      type: String,
      required: false,
    },
    isFullScreen: {
      type: Boolean,
      required: false,
      default: false
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 650
    },
  },
  computed:{
    slotFilled(){
      return !!this.$slots['default']
    }
  },
  methods: {
    close(){
      this.$emit('close')
    }
  }
}
</script>
