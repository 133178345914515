<template>
  <common-popup
    :title-content="$t('Comments')"
    :full-screen="isMobile"
    :confirm-button-disabled="!validateText || isCommentsReadonly"
    :max-width="870"
    :confirm-label="$t('Save')"
    :cancel-label="$t('Cancel')"
    @confirm="submitComment"
    @cancel="close"
    @close="close"
  >
      <v-row no-gutters class="pt-1">
        <v-col cols="12">
          <div v-if="comments?.length || unReadComments?.length"
            ref="scrollableContainer"
            class="overflow-y-auto"
            :class="{'container-height' : $vuetify.display.smAndUp}"
          >
            <comment-item
              v-for="comment in comments"
              :key="comment.id"
              :comment="comment"
              :itemID="itemID"
              :itineraryID="itineraryID"
              :isUnread="false"
              data-test-id="comments__item"
            />
            <v-divider class="border-opacity-50 pb-4" v-if="unReadComments?.length" color="error"></v-divider>
            <comment-item
              v-for="comment in unReadComments"
              :key="comment.id"
              :comment="comment"
              :itemID="itemID"
              :itineraryID="itineraryID"
              :isUnread="true"
              data-test-id="comments__item"
            />
          </div>
          <v-sheet
            v-else
            class="d-flex justify-center align-center fill-height text--disabled"
            height="150"
            data-test-name="text-message-no-comments"
          >
            {{ $t('No_comments_message') }}
          </v-sheet>
        </v-col>
      </v-row>
      <v-row
        v-if="!isCommentsReadonly"
        class="pt-2 pb-8 d-flex flex-column align-stretch"
      >
        <v-col cols="12">
          <span class="text-body-1 text-primary font-weight-bold" data-test-name="text-label">
            {{ $t('Write_comment') }}
          </span>
          <v-textarea
            class="mt-2 text-caption"
            variant="outlined"
            density="compact"
            maxlength="300"
            auto-grow
            rows="4"
            row-height="2"
            data-test-id="comments__textarea"
            data-test-name="input-comment"
            hide-details="auto"
            :rules="[rules.validText]"
            :model-value="text"
            @keydown.enter="processKeydown"
            @update:model-value="updateText"
            :placeholder="$t('Type_text_here')"
            color="primary"
            bg-color="#fff"
            base-color="primary"
          />
        </v-col>
      </v-row>
  </common-popup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommentItem from '@/components/popups/comment/CommentItem'
import CommonPopup from '@/components/popups/CommonPopup.vue'

export default {
  name: 'CommentWindow',
  components: { CommentItem, CommonPopup },
  emits: ['close'],
  props: {
    itemID: String,
    itineraryID: String,
  },
  data() {
    return {
      text: '',
      timerToReloadComments: null,
      store: null,
      textMaxLength: 300,
      rules: {
        validText: (v) => {
          return (v.length <= this.textMaxLength) || this.$t('Input_max_length_error', [this.textMaxLength])
        }
      },
    }
  },
  async created() {
    await this.loadComments()
    setTimeout(() => {
      this.readComments()
    }, 1000)
  },
  computed: {
    ...mapGetters({
      isMobile: 'IS_MOBILE',
      itineraryUserRole: 'CURRENT_ITINERARY_ROLE',
      isViewer: 'IS_VIEWER',
      user: 'USER',
      timeFormat: 'TIME_FORMAT',
      dateFormat: 'DATE_FORMAT',
      itineraryComments: 'COMMENTS'
    }),
    comments() {
      if (this.itemID) return this.itineraryComments?.[this.itineraryID]?.itemsComments?.[this.itemID]?.read
      return this.itineraryComments?.[this.itineraryID]?.itineraryComments?.read
    },
    unReadComments() {
      if (this.itemID) return this.itineraryComments?.[this.itineraryID]?.itemsComments?.[this.itemID]?.unread
      return this.itineraryComments?.[this.itineraryID]?.itineraryComments?.unread
    },
    preparedText() {
      return this.text.trim()
    },
    isCommentsReadonly() {
      return !this.itineraryUserRole || this.isViewer
    },
    validateText() {
      return (this.preparedText.length > 0 && this.preparedText.length <= this.textMaxLength)
    }
  },
  watch: {
    unReadComments: {
      handler() {
        this.scrollBottom()
      },
      immediate: true,
      deep: true
    },
  },
  beforeUnmount() {
    if (this.timerToReloadComments) {
      clearInterval(this.timerToReloadComments)
    }
  },
  methods: {
    ...mapActions({
      sendComment: 'SEND_COMMENT',
      sendLastReadCommentId: 'SEND_LAST_READ_COMMENT_ID',
      loadComments: 'LOAD_COMMENTS',
    }),
    updateText(value) {
      this.text = value
    },
    close() {
      this.$emit('close')
    },
    async submitComment() {
      if (this.preparedText) {
        const payload = {text: this.preparedText}
        if (this.itemID) {
          payload.itemID = this.itemID
        }
        await this.sendComment(payload)
        this.clearInputText()
      }
    },
    async readComments(lastUnReadCommentId = null) {
      const comments = this.unReadComments
      if(comments) {
        const lastReadCommentPayload = {commentId: lastUnReadCommentId ?? comments[comments.length - 1]?.id}
        if (this.itemID) {
          lastReadCommentPayload.itemID = this.itemID
        }
        if (this.itineraryUserRole === 'OWNER' && lastReadCommentPayload.commentId) {
          await this.sendLastReadCommentId(lastReadCommentPayload)
          await this.loadComments()
        }
      }
    },
    clearInputText() {
      this.text = ''
    },
    processKeydown(event) {
      if (!event.shiftKey) {
        event.preventDefault()
        this.submitComment()
      }
    },
    scrollBottom() {
      this.$nextTick(() => {
        const scrollableContainer = this.$refs.scrollableContainer
        scrollableContainer?.scrollTo(0, scrollableContainer.scrollHeight)
      })
    },
  },
}
</script>
