<template>
  <question-wrapper
    :step="step"
    :footerText="$t('Flight_Caption')"
  >
    <template v-slot:body>
      <v-col class="pa-0">
        <flight-class-selector
          :preferredFlightClassRating="preferredFlightClass"
          @update="updateFlightClass"
        />
      </v-col>
    </template>
  </question-wrapper>
</template>

<script>
import QuestionWrapper from '../QuestionWrapper.vue'
import { mapActions, mapState } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import FlightClassSelector from '@/components/selector/FlightClassSelector'
import { defaultPreferences } from '@/store/modules/user'

export default {
  name: 'FlightQuestion',
  components: {
    QuestionWrapper,
    FlightClassSelector
  },
  props: {
    step: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    preferredFlightClass: null
  }),
  created() {
    this.preferredFlightClass = this.user.preferences?.preferredFlightClass
  },
  computed:{
    ...mapState({
      user: state => state.user.user
    }),
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updateFlightClass(value) {
      this.preferredFlightClass = value
    },
    updateProfile() {
      if(this.preferredFlightClass && this.preferredFlightClass !== this.user.preferences?.preferredFlightClass){
        const preferences = cloneDeep(this.user.preferences || defaultPreferences)
        preferences.preferredFlightClass = this.preferredFlightClass
        this.updateUser({ preferences })
      }
    }
  },
  beforeUnmount() {
    this.updateProfile()
  }
}
</script>
