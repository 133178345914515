export const checkFilled = (field) => {
  return !field.value ? field.errorEmpty : null
}

export const checkCorrect = (field) => {
  return !field.pattern.test(field.value) ? field.errorIncorrect : null
}

export const checkHasEmptySpace = (field) => {
  return /\s/.test(field.value) ? field.errorSpace : null
}

export const checkMatch = (one, two) => {
  return one.value !== two.value ? two.errorMatch : null
}

export const checkMismatch = (one, two) => {
  return one.value === two.value ? two.errorMismatch : null
}

export const checkLength = (field) => {
  return field.value.length < field.requiredLength.min || field.value.length > field.requiredLength.max ? field.errorLength : null
}

export const checkAddressInput = (val) => {
  return !val || (val && val.length <= 80)
}

export const checkNotesInput = (val) => {
  return !val || (val && val.length <= 1000)
}

export default {
  checkFilled,
  checkCorrect,
  checkMatch,
  checkMismatch,
}
