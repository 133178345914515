<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 7.3335H3.33333C2.59695 7.3335 2 7.93045 2 8.66683V13.3335C2 14.0699 2.59695 14.6668 3.33333 14.6668H12.6667C13.403 14.6668 14 14.0699 14 13.3335V8.66683C14 7.93045 13.403 7.3335 12.6667 7.3335Z"
      stroke="#0144C7"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66797 7.3335V4.66683C4.66797 3.78277 5.01916 2.93493 5.64428 2.30981C6.2694 1.68469 7.11725 1.3335 8.0013 1.3335C8.88536 1.3335 9.7332 1.68469 10.3583 2.30981C10.9834 2.93493 11.3346 3.78277 11.3346 4.66683V7.3335"
      stroke="#0144C7"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
