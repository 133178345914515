<template>
  <div class="text-body-1 d-sm-flex flex-sm-column my-2" data-test-name="content-sign-in-notification">
    <div class="mb-3" data-test-name="text-message">
      {{ $t('Sign_in_notification') }}
    </div>
    <div class="d-flex justify-end">
      <v-btn @click="goToSignInPage" variant="flat" data-test-name="button-sign-in">
        {{ $t('Sign_in') }}
      </v-btn>
      <v-divider vertical class="mx-2" />
      <v-btn @click="goToSignUpPage" variant="flat" data-test-name="button-sign-up">
        {{ $t('Sign_up') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { RouteNames } from '@/helpers/routerUtils'
import { NotificationNames } from '@/helpers/notificationUtils'
import { useRoute } from 'vue-router'
import { mapActions } from 'vuex'

export default {
  name: 'SignInNotificationContent',
  data() {
    return {
      route: useRoute(),
    }
  },
  methods: {
    ...mapActions({
      closeNotificationByName: 'CLOSE_NOTIFICATION_BY_NAME',
    }),
    goToSignInPage() {
      this.$router.push({name: RouteNames.SIGN_IN, query: {redirect: this.route.path}})
      this.closeNotificationByName(NotificationNames.SIGN_IN)
    },
    goToSignUpPage() {
      this.$router.push({name: RouteNames.SIGN_UP, query: {redirect: this.route.path}})
      this.closeNotificationByName(NotificationNames.SIGN_IN)
    }
  }
}
</script>
