import axios from 'axios'
import URITemplate from 'urijs/src/URITemplate'

export const getters = {
  ALL_BLOG_POSTS: state => state.allBlogPosts,
  CURRENT_BLOG_POST: state => state.blogPost,
}

export const mutations = {
  STORE_BLOG_POSTS: (state, posts) => {
    const index = state.allBlogPosts.findIndex(el => el.pageToken === posts.pageToken)
    if(index >= 0) {
      state.allBlogPosts.splice(index, 1, {
        ...state.allBlogPosts[index],
        ...posts,
      })
    } else {
      state.allBlogPosts.push(posts)
    }
  },
  STORE_CURRENT_BLOG_POST: (state, post) => {
    state.blogPost = post
  },
  CLEAR_BLOG_POSTS: (state) => {
    state.allBlogPosts = []
  },
}

export const actions = {
  LOAD_BLOG_POSTS: async ({commit}, payload = {}) => {
    const { nextPageToken = '', labels = '' } = payload
    if(!nextPageToken) {
      commit('CLEAR_BLOG_POSTS')
    }
    commit('STORE_BLOG_POSTS', { pageToken: nextPageToken })
    let url = process.env.VUE_APP_ALL_BLOG_POSTS
    url += nextPageToken? `&pageToken=${nextPageToken}` : ''
    url += labels? `&labels=${labels}` : ''
    const { data } = await axios.get(url)
    commit('STORE_BLOG_POSTS', {
      ...data,
      pageToken: nextPageToken,
    })
    return data
  },
  LOAD_BLOG_POST_BY_ID: async ({commit}, postUrl) => {
    const template = URITemplate(process.env.VUE_APP_BLOG_POST_BY_ID)
    const url = template.expand({
      postId: postUrl,
    }).replace(/\/$/, '')
    const { data } = await axios.get(url)
    commit('STORE_CURRENT_BLOG_POST', data)
    return data
  },
}

export default {
  state: {
    allBlogPosts: [],
    blogPost: null,
  },
  getters,
  mutations,
  actions
}
