<template>
  <qalendar
    :events="events"
    :config="config"
    :selected-date="selectedDate"
    @event-was-dragged="$emit('event-was-dragged', $event)"
    @date-was-clicked="$emit('date-was-clicked', $event)"
    @datetime-was-clicked="$emit('datetime-was-clicked', $event)"
  >
    <template #eventDialog="{eventDialogData, closeEventDialog}">
      <div v-if="eventDialogData && eventDialogData.title">
        <calendar-event-dialog
          :event-data="eventDialogData"
          :record="eventDialogData.record"
          @close="closeEventDialog"
          @edit-event="$emit('edit-event', $event)"
          @delete-event="$emit('delete-event', $event)"
          @show-comments="$emit('show-comments', $event)"
        />
      </div>
    </template>
  </qalendar>
</template>

<script>
import { mapGetters } from 'vuex'
import { Qalendar } from 'qalendar'
import { itemTypes } from '@/helpers'
import CalendarEventDialog from '@/components/popups/CalendarEventDialog.vue'
/**
 * Component for display calendar
 * Qalendar, doc: https://tomosterlund.github.io/qalendar/guide.html
 * Event example:
 * {
      id!: string,
      title!: string,
      time!: Obj like { start: "2023-03-21 12:05", end: "2023-03-21 13:35" },
      with?: string,
      color?: string, ['blue', 'yellow', 'green' or 'red'
      colorScheme?: string, overwrites 'color'
      isEditable?: boolean, Yields icons for editing and deleting an event
      disableDnD?: boolean, Disable drag & drop for an event in the specified modes
      disableResize?: boolean, Disable resizing for an event in the specified modes
      description?: string, HTML content can be anything that can be descendant of a p-tag
    }
 */
export default {
  name: 'CalendarView',
  components: { Qalendar, CalendarEventDialog },
  props: {
    events: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      required: false,
      default: 'week',
    },
    selectedDate: {
      type: Date,
      required: false,
      default: new Date(),
    },
  },
  data: () => ({
    config: {
      week: {
        startsOn: 'sunday',
        nDays: 7,
        scrollToHour: 5,
      },
      month: {
        // Hide leading and trailing dates in the month view (defaults to true when not set)
        showTrailingAndLeadingDates: false,
      },
      style: {},
      // To customize the dialog which is opened when an event is clicked
      eventDialog: {
        isCustom: true,
      },
      // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
      // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
      defaultMode: 'week',
      // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
      isSilent: true,
      showCurrentTime: true,
    },
  }),
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
    }),
  },
  mounted() {
    this.generateColorSchemas()
  },
  methods: {
    generateColorSchemas() {
      const colorSchemes = {}
      itemTypes.forEach((item) => {
        colorSchemes[item.type] = {
          color: '#fff',
          backgroundColor: item.color,
        }
      })
      this.config.style.colorSchemes = colorSchemes
    },
  },
}
</script>

<style lang="scss">
  @import '../../../node_modules/qalendar/dist/style.css';
  .calendar-header__mode-picker {
    padding-right: 20px;
  }

  .calendar-header__mode-picker:hover {
    background-color: #cecece;
  }

  .calendar-header__mode-picker::after {
    content: '\25BE'; /* Unicode character for a down arrow */
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    opacity: .7;
    pointer-events: none; /* Ensure the arrow doesn't interfere with the select */
  }
</style>
