<template>
  <v-dialog
    :model-value="true"
    :fullscreen="fullScreen || $vuetify.display.xs"
    :max-width="maxWidth"
    scrollable
    persistent
    no-click-animation
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="overflow-hidden" data-test-name="popup">
      <v-card-title
        class="pa-4 pl-sm-8 pb-0"
        :class="{ 'pb-6': titleContent }"
        >
        <v-row no-gutters>
          <v-col
            v-if="titleContent"
            data-test-name="text-title"
            class="text-h6 font-weight-bold"
            :class="largeTitle ? 'text-sm-h4': ''"
          >
            {{ titleContent }}
          </v-col>
          <v-col>
            <v-btn
              data-test-name="button-close"
              variant="flat"
              class="ml-auto d-block"
              @click="close"
            >
              <v-icon size="24" color="primary" icon="mdi-close" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="slotFilled || bodyContent" class="px-4 px-sm-8 py-0">
        <slot>
          <span class="text-black text-h6 font-weight-bold mb-0" data-test-name="text-subtitle">{{ bodyContent }}</span>
        </slot>
      </v-card-text>
      <v-card-actions
        v-if="buttonsVisible"
        class="px-4 px-sm-8 pt-6 pb-8"
        >
        <v-row
          justify="end"
          no-gutters
        >
          <v-btn
            v-if="cancelButtonVisible"
            color="primary"
            variant="outlined"
            elevation="0"
            rounded
            size="large"
            class="mr-1 mr-sm-6"
            max-width="170"
            data-test-name="button-cancel"
            :width="$vuetify.display.xs ? '47%' : 170"
            @click="cancel"
          >
            {{ cancelLabel }}
          </v-btn>
          <v-btn
            color="primary"
            variant="flat"
            rounded
            elevation="0"
            size="large"
            max-width="170"
            data-test-name="button-submit"
            :width="$vuetify.display.xs ? '47%' : 170"
            :disabled="confirmButtonDisabled"
            @click="confirm"
          >
            {{ confirmLabel }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommonPopup',
  emits: ['confirm', 'cancel', 'close'],
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 650
    },
    titleContent: {
      type: String,
    },
    largeTitle: {
      type: Boolean,
      default: false
    },
    bodyContent: {
      type: String,
      required: false,
    },
    buttonsVisible: {
      type: Boolean,
      default: true
    },
    confirmLabel: {
      type: String,
      default: 'Yes'
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false
    },
    cancelLabel: {
      type: String,
      default: 'No'
    },
    cancelButtonVisible: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    slotFilled() {
      return !!this.$slots['default']
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
