<template>
  <div>
    <v-footer class="pt-3 pb-8 pb-sm-4 bg-darkBlue" data-test-name="content-footer">
      <v-row class="px-4 px-md-16 d-flex align-center justify-center" no-gutters>
        <v-col cols="12" sm="0" md="12">
          <v-row dense class="py-4 d-flex flex-column flex-md-row justify-sm-space-between">
            <v-col
              cols="12"
              sm="auto"
              class="pt-2"
            >
              <router-link
                class="px-0 text-decoration-none text-white font-weight-medium text-body-2 text-sm-body-1 text-no-wrap"
                :to="{}"
                @click.prevent="directToAboutUsRoute"
                data-test-name="link-to-about-us-page"
              >
                {{ $t('About_us') }}
              </router-link>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              class="pt-4 pt-md-2 pr-14 pr-lg-10"
            >
              <router-link
                class="text-decoration-none text-white font-weight-medium text-body-2 text-sm-body-1 text-no-wrap"
                :to="{}"
                @click.prevent="directToContactUsRoute"
                data-test-name="link-to-contact-us-page"
              >
                {{ $t('Contact_Us') }}
              </router-link>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pr-10 pl-0"
            >
              <v-card-actions
                flat
                class="px-0 pt-4 pt-md-0 flex-wrap flex-sm-nowrap justify-start justify-md-center"
                data-test-name="social-networks"
              >
                <v-btn
                  v-for="social in socialNetworks"
                  :key="social.iconName"
                  data-test-name="button"
                  class="my-3 my-md-0 mr-4 mr-lg-6 ml-0 text-body-2 font-weight-regular text-decoration-none text-white text-none"
                  icon
                  @click="directToSocial(social.link)"
                >
                  <v-icon
                    class="text-white"
                    size="24"
                    :icon="social.iconName"
                  />
                </v-btn>
              </v-card-actions>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              sm="4"
              class="pt-1 pb-sm-6 pd-md-3 d-none d-sm-flex justify-md-end justify-start align-start"
            >
              <router-link
                class="pt-1 text-decoration-none text-white font-weight-medium text-body-1 text-no-wrap"
                data-test-name="link-to-feedback-form"
                :to="{}"
                @click.prevent="handleOpeningFeedbackForm"
              >
                <v-icon class="text-white pr-2 pt-1" size="30" icon="$customFeedback" data-test-name="icon-feedback" />
                {{ $t('Leave_Feedback') }}
              </router-link>
            </v-col>
            <v-col
              cols="7"
              sm="3"
              class="pt-0 pb-4 pb-sm-8 d-flex d-sm-none justify-sm-end"
            >
              <router-link
                class="text-decoration-none text-white font-weight-medium text-body-2 text-no-wrap"
                data-test-name="link-to-feedback-form"
                :to="{}"
                @click.prevent="handleOpeningFeedbackForm"
              >
                <v-icon class="pr-2 pt-1 text-white" size="30" icon="$customFeedback" data-test-name="icon-feedback" />
                {{ $t('Leave_Feedback') }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="pt-0"
          >
          <v-row
            class="d-flex flex-column justify-center"
          >
            <v-col
              class="py-0 mb-12 mb-sm-3 mb-md-0 d-flex justify-start justify-md-center align-end"
            >
              <div class="d-flex flex-column flex-sm-row fontSizeSmall text-white">
                <span class="pr-4 pr-md-16" data-test-name="text-copyright">
                  {{ $t('Copyright') }}
                </span>
                <router-link
                  data-test-name="link-to-privacy-policy-page"
                  class="pt-3 pt-sm-0 justify-center text-white text-decoration-none text-no-wrap"
                  :to="{name: PRIVACY}"
                >
                  {{ $t('Privacy_Policy') }}
                </router-link>
                <span class="pt-3 pt-sm-0 px-2 px-md-4 d-sm-inline d-none">|</span>
                <router-link
                  data-test-name="link-to-terms-of-service-page"
                  class="pt-3 pt-sm-0 justify-center text-white text-decoration-none text-no-wrap"
                  :to="{name: TERMS_OF_SERVICE}"
                >
                  {{ $t('Terms_of_Service') }}
                </router-link>
                <span v-if="!isAnonymous" class="pt-3 pt-sm-0 px-2 px-md-4 d-sm-inline d-none">|</span>
                <router-link
                  v-if="!isAnonymous"
                  data-test-name="link-to-delete-user-data-page"
                  class="pt-3 pt-sm-0 pb-2 pr-4 pr-sm-0 justify-center text-white text-decoration-none erase-data text-no-wrap"
                  :to="{name: PERSONAL_DATA_DELETION}"
                >
                  {{ $t('Clear_data') }}
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import {allLanguages, loadLanguageAsync} from '@/helpers/i18n'
import mitt from 'mitt'
import { mapGetters } from 'vuex'
import FeedbackFormMixin from '@/mixins/FeedbackFormMixin'
import { RouteNames } from '@/helpers/routerUtils'
import { socialNetworks } from '@/helpers/commonConfigs'

export default {
  name: 'TheFooterBar',
  mixins: [FeedbackFormMixin],
  data() {
    return {
      lang: '',
      travaEmail:'contact@trava.ai',
      travaAdress: '90 East Halsey rd STE 333 2443, Parsippany, NJ 07054',
      eventBus: null,
    }
  },
  created() {
    // init non reactive data
    this.languages = allLanguages
    this.socialNetworks = socialNetworks
    this.CONTACT_US = RouteNames.CONTACT_US
    this.PERSONAL_DATA_DELETION = RouteNames.PERSONAL_DATA_DELETION
    this.TERMS_OF_SERVICE = RouteNames.TERMS_OF_SERVICE
    this.PRIVACY = RouteNames.PRIVACY
  },
  computed: {
    ...mapGetters({
      isAnonymous: 'IS_ANONYMOUS',
      version: 'APP_VERSION',
      user: 'USER'
    })
  },
  mounted() {
    this.eventBus = mitt()
    this.eventBus.on('openFeedbackForm', () => {
      this.showFeedbackForm()
    })
  },
  beforeUnmount() {
    this.eventBus.off('openFeedbackForm')
  },
  methods: {
    changeLanguage(val) {
      loadLanguageAsync(val).then(() => {
        this.$i18n.locale = val
        this.lang = val
      })
    },
    directToAboutUsRoute() {
      this.$router.push({name: RouteNames.ABOUT_US})
    },
    directToContactUsRoute() {
      if (this.user.id){
        this.$router.push({name: RouteNames.CONTACT_US})
      } else {
        this.$router.push({name: RouteNames.SIGN_IN, query: {redirect: RouteNames.CONTACT_US}})
      }
    },
    directToSocial(link) {
      window.open(link, '_blank')
    }
  },
  watch: {
    'user.settings.language': {
      handler(newVal, oldVal) {
        if(newVal && newVal !== oldVal) {
          this.changeLanguage(newVal)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
  .fontSizeSmall {
    font-size: 0.8rem;
  }

</style>
