<template>
  <v-card class="card--show overflow-visible" elevation="0">

    <v-row class="mx-sm-2 mx-4 pt-2 mb-2">
      <v-row class="mx-0 mx-sm-2 my-2 mb-4 text-body-2">
        <v-col cols="12" class="px-0">{{ $t('Insurance_Text_p1') }}</v-col>
        <v-col cols="12" class="px-0">{{ $t('Insurance_Text_p2') }}</v-col>
      </v-row>

      <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 mx-sm-2" no-gutters>
        <div class="text-primary font-weight-medium text-uppercase">{{ $t('Tab_Details') }}</div>
      </v-row>
    </v-row>

    <v-card-text class="event-content">
      <v-row class="mt-0 mx-0">
        <v-col cols="6" sm="4" class="px-0 pr-2">
          <date-picker
            v-model="startDate"
            :placeholder="$t('Start_date')"
            :label="`${$t('Start_date')}*`"
            :readonly="isReadOnly"
            min-width="320"
            name="StartDate"
          />
        </v-col>
        <v-col cols="6" sm="4" class="pr-0 pr-sm-3 pl-sm-4">
          <date-picker
            v-model="endDate"
            :min-date="minEndDate"
            :readonly="isReadOnly"
            :placeholder="$t('End_date')"
            :label="`${$t('End_date')}*`"
            min-width="320"
            name="EndDate"
          />
        </v-col>
        <v-col cols="4" v-if="!$vuetify.display.xs" class="pr-0 mt-3">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="`${$t('Travelers')}*`"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                variant="outlined"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
      </v-row>

      <v-row class="mx-0 mx-sm-3">
        <v-col cols="6" v-if="$vuetify.display.xs" class="px-0 px-sm-1 pr-2">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                variant="outlined"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
        <v-col
          cols="6"
          :sm="!isReadOnly ? '9' : '12'"
          :md="!isReadOnly ? '10' : '12'"
        >
          <event-booking
            :isBooked="isBooked"
            :readonly="isReadOnly"
            @updateBooked="isBooked = !isBooked"
            class="d-flex justify-end"
          />
        </v-col>
        <v-col
          v-if="!isReadOnly"
          cols="12"
          sm="3"
          md="2"
          align-self="center"
          class="d-flex justify-end"
        >
          <v-btn
            color="#F3A742"
            :width="$vuetify.display.xs ? '47%' : '160'"
            rounded
            variant="flat"
            :disabled="isReadOnly"
            @click="proceedToWebsite"
          >
            {{ $t('Book') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="py-0 my-0 mt-6 mb-8">
        <v-col cols="12" sm="6" class="pb-0 pb-md-0 pt-0 mb-3">
          <v-text-field
            v-model="countriesOfTravel"
            :label="$t('Countries_of_travel')"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0 pt-2"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateCountries]"
          >
            <template v-slot:prepend-inner>
              <v-icon class="pr-2">$customLocation</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="4" class="pb-0 pb-sm-6 pb-md-0 pt-0 mt-0 mb-3 text-truncate">
          <v-select
            prepend-inner-icon="$customCurrency"
            v-model="totalPrice.currency"
            :label="$t('Currency')"
            :readonly="isReadOnly"
            :items="currencyList"
            class="pa-0 ma-0 pt-2"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="2" class="pb-0 pb-sm-6 pb-md-0 pt-0 mt-0 mb-3">
          <v-currency-field
            :model-value="Number(totalPrice?.amount)"
            @update="updateAmount"
            variant="outlined"
            class="pa-0 ma-0 pt-2"
            color="primary"
            :label="`${$t('Total_price')}`"
            :readonly="isReadOnly"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <event-popup-buttons
      :submit-button-disabled="!isValidForm || !areCountriesValid"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
  </v-card>
</template>

<script>
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { INSURANCE_WEBSITE_URL } from '@/helpers/partnersWebsites'
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import { convertAmountToServerFormat, convertAmountToUIFormat, getDatesAndTimeAsISOString, getISOFormatDateFromObject } from '@/helpers'
import { mapActions } from 'vuex'
import { logAppError } from '@/logger'
import VCurrencyField from '@/components/VCurrencyField'
//import placeSearch from '@/helpers/placeSearch'

export default {
  name: 'AddInsuranceForm',
  mixins: [eventFormMainStructure],
  components: {
    VCurrencyField,
  },
  emits: ['close', 'updateTitle'],
  data() {
    return {
      countriesOfTravel: '',
      rules: {
        validateCountries: (v) => {
          const errors = []
          if (!this.validateCountriesChars(v)) {
            errors.push(this.$t('No_special_chars_error'))
          }
          if (!this.validateCountriesLength(v)) {
            errors.push(this.$t('Input_max_length_error', [300]))
          }
          return !errors.length || errors.join('. ')
        },
      },
    }
  },
  computed: {
    //this needs to be changed when we can handle images based not on IATA codes
    placeIDLocations() {
      const { header: { placeIDLocations } } = this.currentItinerary
      return placeIDLocations
    },
    newRecord() {
      const dates = [this.startDate, '0:00:00', this.endDate, '0:00:00']
      const {startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let rv = {
        startDate: commonAPIs.momentDateTimeToStruct(this.$moment(startDateTime)),
        endDate: commonAPIs.momentDateTimeToStruct(this.$moment(endDateTime)),
        location: this.location,
        travelers: this.travelers,
        ...(this.title) && { insuringCompanyName: this.title },
        ...(this.isBooked) && { isBooked: this.isBooked },
        ...(this.countriesOfTravel) && {  countriesOfTravel: this.countriesOfTravel },
        ...(convertAmountToServerFormat(this.totalPrice?.amount))
          && { totalPrice: {
            currency: this.totalPrice?.currency,
            amount: convertAmountToServerFormat(this.totalPrice?.amount)
          },
          },
      }

      return rv
    },
    isLocationValid() {
      return true
    },
    isValidTitle() {
      return true
    },
    areCountriesValid() {
      return this.validateCountriesChars(this.countriesOfTravel) && this.validateCountriesLength(this.countriesOfTravel)
    }
  },
  watch: {
    isDialogVisible: {
      async handler(visible) {
        if(visible && this.record) {
          await this.mapDataToFormFields(this.record)
          if (!this.initialRecord) this.updateInitialRecord()
        }
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions({
      updateInsurance: 'UPDATE_INSURANCE',
      addInsurance: 'ADD_INSURANCE',
    }),
    async getPlaceLookUpList() {
      const countriesList = []
      /*
      * TODO fix this code -- right now the itinerary header contains placeIDLocations
      * for all the places itinerary flights take to, there is no API to lookup places by iataCode
      * Once BE adds such an API - this code should be adjusted to produce a list of countries.
      * For now the list will be empty...
      for (const item of this.placeIDLocations) {
        const result = await placeSearch.lookupPlacesByIds(item) //TODO this will not work for now -- need a look up by iata codes
        if (result && result?.[0]) {
          countriesList.push(result[0]?.country)
        }
      }*/
      return countriesList
    },
    async onClickSave() {
      let data = {
        ...this.newRecord,
        userID: this.user.id,
        itinIdParam: this.currentItinerary.header.itineraryID,
        id: this.record?.id
      }
      try {
        if (this.record?.id) {
          await this.updateInsurance(data)
        } else {
          await this.addInsurance(data)
        }

        // added to skip isDirty check
        this.updateInitialRecord()

        this.$emit('close')
      } catch(err) {
        logAppError(err)
      }
    },
    async onDeleteEvent() {
      try {
        await commonAPIs.removeInsurance(
          this.currentItinerary.header.itineraryID,
          this.user.id,
          this.record.id
        )
        this.$emit('close')
      } catch(err) {
        logAppError(err)
      }
    },
    proceedToWebsite() {
      window.open(INSURANCE_WEBSITE_URL, '_blank')
    },
    async fillCountriesOfTravel() {
      const uniqueIAtaCodes = new Set([...await this.getPlaceLookUpList()])

      return [...uniqueIAtaCodes].join(', ')
    },
    async mapDataToFormFields(eventData) {
      const { startDate, endDate, insuringCompanyName, isBooked, totalPrice, countriesOfTravel } = eventData
      this.$emit('updateTitle', insuringCompanyName)
      this.isBooked = Boolean(isBooked)
      this.totalPrice.amount = convertAmountToUIFormat(totalPrice?.amount)
      this.totalPrice.currency = totalPrice?.currency || this.userCurrency || 'USD'
      this.startDate = getISOFormatDateFromObject(startDate)
      this.endDate = getISOFormatDateFromObject(endDate)

      //this will update the countries of travel every time event is opened
      this.countriesOfTravel = countriesOfTravel || await this.fillCountriesOfTravel()
    },
    async fillEventDefaultValues() {
      this.$emit('updateTitle', '')
      const dateFormat = 'YYYY-MM-DD'
      this.startDate = this.$moment.parseZone(this.currentItinerary?.header?.startDate).format(dateFormat)
      this.endDate = this.$moment.parseZone(this.currentItinerary?.header?.endDate).format(dateFormat)
      this.countriesOfTravel = await this.fillCountriesOfTravel()
    },
    validateCountriesChars(v) {
      const specialChars = /[$%^*#@!~|?&<>:;'"\\/+=]/g
      return !specialChars.test(v)
    },
    validateCountriesLength(v) {
      return v.length <= 300
    }
  }
}
</script>
