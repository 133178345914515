<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <v-row no-gutters class="page-wrapper" data-test-name="page-planner-assistant">
    <v-col cols="11" md="8" class="mx-auto">
      <v-window v-model="step">
        <v-window-item
          v-for="(currentStep, i) in availableSteps"
          :key="`${i}-step`"
        >
        <v-card class="mt-16 px-6 px-md-8 py-6 pb-md-10 bg-white rounded">
          <v-card-subtitle>
            <v-btn
              icon
              variant="flat"
              size="small"
              class="ml-auto d-block"
              data-test-name="button-close"
              @click="close"
            >
              <v-icon color="primary" icon="mdi-close" />
            </v-btn>
            <p
              class="step-title font-weight-medium text-subtitle-1 ma-0 text-primary"
              data-test-name="text-step"
            >
              {{ $t('Step') }} {{ step + 1 }}
            </p>
          </v-card-subtitle>
          <v-card-title
            class="pa-1 py-2 mt-8 mt-sm-6 text-md-h4 text-sm-h5 text-h6 font-weight-bold"
            data-test-name="text-title-step"
          >
            <span class="assistant-title">{{ $t(currentStep.title) }}</span>
          </v-card-title>

          <component
            :is="currentStep.type"
            :key="currentStep.type"
            :step="step"
            :class="currentStep.type === 'login-question' ? 'mt-4' : 'mt-10'"
            @close="close"
          />

          <v-card-text
            v-if="currentStep.footerText"
            class="text-secondary text-center text-subtitle-2 font-weight-light pa-0 pt-2"
            data-test-name="text-step-footer"
          >
            {{ $t(currentStep.footerText) }}
          </v-card-text>

          <v-card-actions class="d-flex flex-column-reverse flex-sm-row pa-0 mt-8">
            <v-btn
              v-if="!isLastStep"
              variant="text"
              size="large"
              :block="$vuetify.display.xs"
              color="primary"
              class="pa-0"
              data-test-name="button-close"
              @click="close"
            >
              {{ $t('Skip_All') }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="!isFirstStep"
              color="primary"
              variant="flat"
              size="large"
              :block="$vuetify.display.xs"
              rounded
              class="py-0 px-5 px-md-10 mb-4 mb-sm-0"
              data-test-name="button-go-back"
              @click="updateStep(step - 1)"
            >
              {{ $t('Back') }}
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              rounded
              size="large"
              :block="$vuetify.display.xs"
              class="py-0 px-5 px-md-10 ma-0 ml-sm-6 mb-4 mb-sm-0"
              data-test-name="button-go-forward"
              @click="updateStep(step + 1)"
            >
              {{ $t(currentStep.applyButtonCaption) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-window-item>
    </v-window>
    </v-col>
  </v-row>
</template>

<script>
import AirlineQuestion from '@/components/planner-assistant/questions/AirlineQuestion.vue'
import HotelQuestion from '@/components/planner-assistant/questions/HotelQuestion.vue'
import RestaurantQuestion from '@/components/planner-assistant/questions/RestaurantQuestion.vue'
import LoginQuestion from '@/components/planner-assistant/questions/LoginQuestion.vue'
import HotelChainQuestion from '@/components/planner-assistant/questions/HotelChainQuestion.vue'
import FlightQuestion from '@/components/planner-assistant/questions/FlightQuestion.vue'
import { mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import { useRoute } from 'vue-router'

export const PLANNER_ASSISTANT_STEP = 'plannerAssistantStep'

export default {
  name: 'PlannerAssistant',
  components: {
    'flight-question':      FlightQuestion,
    'airline-question':     AirlineQuestion,
    'hotel-question':       HotelQuestion,
    'restaurant-question':  RestaurantQuestion,
    'login-question':       LoginQuestion,
    'hotel-chain-question': HotelChainQuestion,
  },
  data() {
    return {
      step: 0,
      steps: [
        {
          type: 'flight-question',
          title: 'Preferred_Flight_Class',
          applyButtonCaption: 'Next',
        },
        {
          type: 'airline-question',
          title: 'Airlines_Preferences',
          applyButtonCaption: 'Next',
        },
        {
          type: 'hotel-question',
          title: 'Preferred_Hotels_Rating',
          applyButtonCaption: 'Next',
        },
        {
          type: 'hotel-chain-question',
          title: 'Hotels_Preferences',
          applyButtonCaption: 'Next',
        },
        {
          type: 'restaurant-question',
          title: 'Restaurants_Preferences',
          applyButtonCaption: 'Done',
        },
        {
          type: 'login-question',
          title: 'Join_Trava',
          footerText: 'Login_Message_Planner',
          applyButtonCaption: 'Skip',
          visibilityCondition: this.isAnonymous
        }
      ],
      route: useRoute(),
    }
  },
  created() {
    const storedStep = localStorage.getItem(PLANNER_ASSISTANT_STEP) || 0
    localStorage.setItem(RouteNames.ITINERARY_TYPE, this.route.query.type)

    if (storedStep) {
      this.step = +storedStep
    }
  },
  computed: {
    ...mapGetters({
      isAnonymous: 'IS_ANONYMOUS'
    }),
    isLastStep() {
      return this.step === this.availableSteps.length - 1
    },
    isFirstStep() {
      return this.step === 0
    },
    availableSteps() {
      return this.steps.filter(step => 'visibilityCondition' in step ? step.visibilityCondition : true)
    },
  },
  methods: {
    updateStep(newStep) {
      if(newStep >= this.availableSteps.length) {
        this.close()
      } else {
        localStorage.setItem(PLANNER_ASSISTANT_STEP, newStep)
        this.step = newStep
      }
    },
    close() {
      localStorage.removeItem(PLANNER_ASSISTANT_STEP)
      localStorage.removeItem(RouteNames.ITINERARY_TYPE)
      this.$router.push({
        name: this.route.query.type || RouteNames.GENERATE_ITINERARY,
        query: {
          completePlannerAssistant: true,
          keepAliveTripRequest: true
        }
      })
    }
  }
}
</script>

<style lang="scss">
.assistant-title {
  word-break: break-word;
}
.v-stepper__step__step {
  border-radius: 20px;
  width: 100%;
  height: 5px;
  margin-right: 0px!important;
}
.v-stepper__header {
  height: 5%;
}
.page-wrapper {
  background-image: url('../../public/assets/images/generate-for-me-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  //74px -is height of header
  min-height: calc(100vh - 74px);
  height: 100%;
}
</style>
