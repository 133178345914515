<template>
  <alternative-card
    :imageSrc="imageSrc"
    :userRating="userRating"
    @click="selectItem"
  >
    <template v-slot:title>
      <div class="my-2 w-100 text-wrap">
        {{ title }}
      </div>
    </template>
    <template v-slot:body>
      <v-badge
        color="transparent">
        <div class="mb-2 d-flex">
          <v-rating
            :model-value="priceLevel"
            full-icon="$customDollar"
            empty-icon=""
            color="primary"
            density="compact"
            readonly
            size="x-small"
          />
        </div>
      </v-badge>
      <div class="mb-2 text-body-1 alternative-item-description">{{ address }}</div>
    </template>
  </alternative-card>
</template>

<script>
import AlternativeCard from './AlternativeCard'
import { EventTypes, getItemTypeByType } from '@/helpers'
import { getGoogleImageUrl } from '@/helpers/imageUtil'

export default {
  name: 'AlternativeRestaurantItem',
  emits: ['select'],
  components: {AlternativeCard},
  props: {
    item: {
      type: Object,
    },
    selectedItem: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    itemDetails() {
      return this.item.restaurant
    },
    title() {
      return this.itemDetails?.title
    },
    address() {
      return this.itemDetails?.simpleAddress
    },
    imageSrc() {
      return getGoogleImageUrl(this.itemDetails?.roData?.googleImages) || getItemTypeByType(EventTypes.RESTAURANT).defaultImageUrl
    },
    userRating() {
      return this.itemDetails?.roData?.userRating
    },
    priceLevel() {
      return this.itemDetails?.roData?.priceLevel
    },
  },
  methods: {
    selectItem() {
      this.$emit('select',  this.item)
    },
  }
}
</script>
