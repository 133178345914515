<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <v-container
    v-if="isTemplateDataLoaded"
    fluid
    class="py-0 px-0 templatesPage bgTemplates"
    data-test-name="page-template-itinerary"
  >
    <v-row no-gutters class="bgHeader d-flex justify-center align-center py-10 px-10">
      <v-col cols="12" sm="6" md="5" lg="4" class="py-0" >
        <v-col v-if="$vuetify.display.smAndUp" cols="10">
          <v-btn
            @click="proceedToCommunityPage"
            color="primary"
            variant="plain"
            rounded
            size="large"
            prepend-icon="mdi-arrow-left"
            data-test-name="button-open-community-page"
            :width="$vuetify.display.xs ? '100%' : '240'"
          >
            {{ $t('Back_to_community') }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="pb-0 d-flex justify-center justify-sm-start">
          <div v-if="$vuetify.display.smAndUp" class="d-flex flex-column">
            <div
              class="rounded-t-lg bg-primary"
              style="width: 65px;"
            >
              <p class="py-1 d-flex justify-center text-textSecondaryColor text-h5 font-weight-medium">
                {{ days }}
              </p>
            </div>
            <div
              class="rounded-b-lg bg-textSecondaryColor"
              style="width: 65px;"
            >
              <p class="py-1 d-flex justify-center text-primary text-body-1 font-weight-medium">days</p>
            </div>
          </div>
          <div v-else>
            <div
              class="rounded-lg bg-primary"
              style="width: 80px;"
            >
              <p class="py-1 mb-2 d-flex justify-center text-textSecondaryColor text-body-1 font-weight-medium">
                {{ days }} days
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="10" class="pt-2 pt-sm-6 pb-0 d-flex justify-space-between align-center">
          <v-col cols="8" sm="9" class="px-0 pt-4 pt-sm-0">
            <h1
              class="text-h5 text-md-h4 font-weight-bold templateTitle"
              data-test-name="text-title"
            >
              {{ title }}
            </h1>
          </v-col>
          <v-col class="px-0 mr-4 d-flex justify-end" cols="2">
            <v-btn
              @click="openMapDirectionsLink"
              icon="$customColorMap"
              data-test-name="button-view-on-map"
              size="x-small"
              :title="$t('View_on_map')"
            ></v-btn>
          </v-col>
          <v-col cols="2" sm="2">
            <div class="ml-auto d-flex justify-center thumb-btn">
              <thumb-up-votes
                @toggleLike="onLike"
                :count="likesCount"
                :is-user-voted="isLiked"
                :isReadOnly="isAnonymous"
                class="px-1"
                variant="flat"
              />
            </div>
          </v-col>
        </v-col>
        <v-col cols="12" sm="11" lg="10" class="py-0">
          <v-divider class="text-primary" />
        </v-col>
        <v-col cols="12" sm="10" class="d-flex flex-wrap justify-center justify-sm-start">
          <span
            v-for="(tag, index) in tags"
            :key="index"
            data-test-name="text-tag"
            class="decorator text-primary text-overline"
          >
            {{ tag }}
          </span>
        </v-col>
        <v-col
          v-if="description"
          cols="12"
          sm="11"
          lg="10"
          class="pt-0 text-center text-sm-start"
          data-test-name="text-description"
        >
          <span class="text-textPrimaryColor text-body-2 text-md-body-1">
            {{ description }}
          </span>
        </v-col>
        <v-col v-if="$vuetify.display.smAndUp" cols="12">
          <v-btn
            @click="openCreateItineraryPopup"
            class="ml-4 ml-sm-0 submit"
            color="primary"
            variant="flat"
            rounded
            size="large"
            data-test-name="button-open-create-itinerary-popup"
            :width="$vuetify.display.xs ? '90%' : '240'"
          >
            {{ $t('Select') }}
          </v-btn>
        </v-col>

      </v-col>
      <v-col cols="12" sm="6" md="5" lg="4" class="py-0 d-flex justify-center align-center">
        <v-img
          :src="bannerImgUrl"
          class="rounded-xl"
          :aspect-ratio="16/9"
          @error="setDefaultBanner"
          cover
          data-test-name="image-banner"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="$vuetify.display.xs"
      no-gutters
      class="pt-0 pt-sm-10 pb-2 px-4 d-flex justify-center bgTemplates actionButtons"
    >
      <v-col cols="12" md="10" lg="9" xl="8" class="d-sm-flex justify-sm-space-between">
        <v-btn
          @click="proceedToCommunityPage"
          class="px-10 mr-0 mr-sm-6 mb-3 mb-sm-0"
          color="primary"
          variant="plain"
          rounded
          size="large"
          prepend-icon="mdi-arrow-left"
          data-test-name="button-open-community-page"
          :width="$vuetify.display.xs ? '100%' : '240'"
        >
          {{ $t('Back_to_community') }}
        </v-btn>
        <v-btn
          @click="openCreateItineraryPopup"
          class="submit ml-4 ml-sm-0"
          color="primary"
          variant="flat"
          rounded
          size="large"
          data-test-name="button-open-create-itinerary-popup"
          :width="$vuetify.display.xs ? '90%' : '240'"
        >
          {{ $t('Select') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="center"
      class="mt-12 mx-4 bgTemplates"
    >
      <v-col
        cols="12"
        md="10"
        lg="9"
        xl="8"
      >
        <span class="text-primary travelDescription">
          {{ $t('Travel_description') }}
        </span>
        <div v-if="records.size">
          <v-expansion-panels
            v-model="panelsIndexesList"
            multiple
            variant="accordion"
            class="d-flex flex-column"
          >
            <v-col
              v-for="[day, recordsByDay] in records"
              class="pa-0 dayContainer"
              :key="day"
            >
              <v-expansion-panel
                elevation="0"
                class="expansionPanel"
                :value="dayIndex(day)"
              >
                <v-expansion-panel-title class="pl-0 test">
                  <template #default="{ expanded }">
                    <span class="text-h5 text-black mr-4 dayLabel">{{ formatDayText(day) }}</span>
                    <ul v-if="$vuetify.display.smAndUp && !expanded" class="eventsLabelsList">
                      <li
                        v-for="(eventLabel, index) in getEventsList(recordsByDay, 'label')"
                        :key="index"
                        class="text-h6 font-weight-medium eventLabel"
                      >
                        {{ eventLabel }}
                      </li>
                    </ul>
                  </template>
                  <template #actions="{ expanded }">
                    <v-icon v-if="expanded" color="primary" icon="mdi-chevron-double-up" />
                    <v-icon v-else color="primary" icon="mdi-chevron-double-down" />
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <itinerary-events-list
                    :records-list="recordsByDay"
                    :day="day"
                  />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-col>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
    <create-itinerary-popup
      v-if="isPopupVisible"
      :title="$t('Generate_my_own_itinerary')"
      :placeIDLocations="placeIDLocations"
      :confirm-label="$t('Create')"
      :cancel-label="$t('Cancel')"
      :travelers="travelers"
      @close="closeCreateItineraryPopup"
      @confirm="onCreateItinerary"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useRoute } from 'vue-router'

import ItineraryEventsList from '@/components/itinerary-template/ItineraryEventsList'
import CreateItineraryPopup from '@/components/popups/itinerary/CreateItineraryPopup'
import ThumbUpVotes from '@/components/ThumbUpVotes'
import { proceedToItinerary } from '@/helpers'
import addressUtil from '@/helpers/addressUtil'
import datetime from '@/helpers/datetime'
import { itemTypeLookup } from '@/helpers/itemTypes'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { RouteNames } from '@/helpers/routerUtils'
import { generateUrlByPlaceID } from '@/helpers/staticImagesUtil'
import { getItineraryTemplate, getTemplatesWithLikesData, parseTemplateItem, toggleLike } from '@/helpers/templates'


const defaultBannerImgUrl = '/assets/images/community-bg.jpg'

export default {
  name: 'TemplateItinerary',
  components: {
    CreateItineraryPopup,
    ItineraryEventsList,
    ThumbUpVotes,
  },
  data() {
    return {
      templateResponse: null,
      isTemplateDataLoaded: false,
      templateLikesResponse: null,
      isPopupVisible: false,
      bannerImgUrl: '',
      route: useRoute(),
      panelsIndexesList: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      eventFilter: 'EVENT_FILTER',
      isAnonymous: 'IS_ANONYMOUS',
      isUserNew: 'IS_NEW',
      isMobile: 'IS_MOBILE',
    }),
    travelers() {
      return { adults: 2, children: 2 }
    },
    templateHeader() {
      return this.templateResponse?.header
    },
    templateDatesList() {
      const result = new Set()
      this.templateItemsList.forEach(item => {
        datetime.getDatesList(item.header.startDateTime, item.header.endDateTime).forEach(date => {
          result.add(date)
        })
      })

      const sorted = Array.from(result).sort((date0, date1) => this.$moment(date0).diff(this.$moment(date1), 'days'))

      return sorted
    },
    templateItemsList() {
      return this.templateResponse?.items ?? []
    },
    records() {
      const result = new Map(this.templateDatesList.map(value => [value, []]))
      this.templateItemsList.forEach(item => {
        const parsed = parseTemplateItem(item)
        parsed.forEach(item => {
          const day = datetime.toDate(item.header.todayStartDateTime)
          if (!result.has(day)) {
            result.set(day, [])
          }

          result.get(day).push(item)
        })
      })

      return result
    },
    title() {
      return this.templateHeader?.templateName
    },
    description() {
      return this.templateHeader?.templateDescription
    },
    tags() {
      return this.templateHeader?.tags
    },
    iataLocations() {
      return this.templateHeader?.iataLocations
    },
    days() {
      return this.templateHeader?.days
    },
    placeIDLocations() {
      return this.templateHeader?.placeIDLocations
    },
    likesCount() {
      return this.templateLikesResponse?.likesCount ?? 0
    },
    isLiked() {
      return this.templateLikesResponse?.isLiked ?? false
    }
  },
  async created() {
    const templateResponse = await getItineraryTemplate(this.route.params.id)
    this.templateResponse = templateResponse
    if (!templateResponse?.header) {
      return
    }

    const likesResponse = await getTemplatesWithLikesData([this.templateResponse.header], this.user?.id)
    if (likesResponse?.length > 0) {
      this.templateLikes = likesResponse[0]
    }
  },
  methods: {
    ...mapActions({
      showLoader: 'LOADING_DIALOG',
      showNotification: 'PUSH_APP_NOTIFICATION',
      addNewUser: 'ADD_NEW_USER',
      createItineraryFromTemplate: 'CREATE_ITINERARY_FROM_TEMPLATE',
    }),
    async onLike() {
      await toggleLike(this.templateData?.header?.templateID, this.user?.id)
    },
    async onCreateItinerary(data) {
      if (!data) return

      try {
        await this.showLoader({
          visible: true,
          opacity: 0.5,
          overlayColor: 'var(--v-textSecondaryColor-base)'
        })
        if (this.isAnonymous && this.isUserNew) {
          //need to create new user locally
          await this.addNewUser({ user: this.user })
        }

        const payload = {
          userId: this.user?.id,
          templateId: this.route?.params?.id,
          travelers: data?.travelers,
          fromPlaceID: data.location?.placeID,
          startDate: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(data.startDate))
        }

        const result = await this.createItineraryFromTemplate(payload)
        if (result) {
          this.closeCreateItineraryPopup()
          const { header: { itineraryID: itineraryID } } = result
          proceedToItinerary(this.$router, itineraryID, this.user.settings.view)
        }
      } catch (err) {
        this.showNotification({
          name: 'create_itinerary_from_template_error',
          content: 'Itinerary_error',
          color: 'error'
        })
      } finally {
        this.showLoader(null)
      }
    },
    openCreateItineraryPopup() {
      this.isPopupVisible = true
    },
    closeCreateItineraryPopup() {
      this.isPopupVisible = false
    },
    proceedToCommunityPage() {
      this.$router.push({
        name: RouteNames.COMMUNITY,
      })
    },
    setDefaultBanner() {
      this.bannerImgUrl = defaultBannerImgUrl
    },
    openMapDirectionsLink() {
      const records = []
      this.records.forEach(value => {
        records.push(...value)
      })

      const googleMapLink = addressUtil.buildMultipleDirections(records, this.isMobile, null, true)
      !!googleMapLink && window.open(googleMapLink, '_blank')
    },
    dayIndex(date) {
      return this.templateDatesList.indexOf(date)
    },
    formatDayText(date) {
      const dayNumber = this.dayIndex(date) + 1

      return `${this.$t('Day')} ${dayNumber}:`
    },
    getEventsList(recordsList, key) {
      const result = new Set()
      recordsList.forEach(record => {
        const value = itemTypeLookup(record)[key]
        result.add(value)
      })

      return Array.from(result)
    },
  },
  watch: {
    templateResponse(data) {
      this.bannerImgUrl = data?.header?.placeIDLocations?.[0]
        ? generateUrlByPlaceID(data?.header.placeIDLocations?.[0])
        : defaultBannerImgUrl

      this.isTemplateDataLoaded = true
    },
    templateDatesList(value) {
      if (value?.length) {
        this.panelsIndexesList = new Array(value.length).fill(0).map((_, index) => index)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.templatesPage {
  & templateBanner {
    max-height: 530px;
  }

  & templateTitle {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

  }
  .actionButtons {
    position: sticky;
    top: 74px;
    z-index: 2;
  }

  & .travelDescription {
    font-size: 1.125rem;
    font-weight: 600;
  }

  & .dayLabel {
    font-size: 1.375rem;
    font-weight: 600;
  }

  & .eventsLabelsList {
    list-style-type: none;

    & li {
      display: inline;
    }

    & li:not(:last-of-type):after {
      content:'\00A0\00B7';
      padding-right: 0.5rem;
    }
  }
  & .eventLabel {
    font-size: 1rem;
    color: #828282;
  }
}

.expansionPanel {
  background: #F9F9F9;

  & :deep(.v-expansion-panel-title__overlay) {
    opacity: 0 !important;
  }

  & :deep(.v-expansion-panel-text__wrapper) {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0
  }
}

.bgTemplates {
  background: #F9F9F9;
}

.bgHeader {
  background-color: rgba(1, 68, 199, 0.05);
}

.dayContainer:not(:last-of-type) {
  border-bottom: 1px solid rgba(1, 68, 199, 0.6);
}

.theme--light.v-btn.v-btn--has-bg {
  background: none;
}
.v-btn--variant-elevated {
    box-shadow: none;
  }
</style>
