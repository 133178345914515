import { getItemTypeByType } from '@/helpers/itemTypes'
import { store } from '../store'

export default {
  /**
    message to display at the bottom of the screen
    if none is passed - bottom sheet will be hidden
    bottomSheet structure: {
      visible: Boolean,
      showProgressIndicator: Boolean,
      message: String
    }
   */
  showBottomSheet(message = '', showProgressIndicator = true) {
    store.dispatch('BOTTOM_SHEET', {
      visible: (message !== ''),
      showProgressIndicator: showProgressIndicator,
      message: message
    })
  },

  /**
    displays "loading" dialog with the spinner and the type of item passed
    type is one of the defined in `itemTypes`
   */
  loadingDialog(type) {
    if (!type) {
      store.dispatch('LOADING_DIALOG', null)
      return
    }

    const props = getItemTypeByType(type)
    store.dispatch('LOADING_DIALOG', {
      visible: !!type,
      type: props?.type,
      icon: props?.icon,
      color: props?.color,
      text:
        'Adding ' +
        `${ props?.label === 'Trip protection' ? props?.label : props?.label + 's' || '' }`.toLowerCase(),
    })
  }
}
