<template>
  <v-card-text class="pa-0">
    <v-row no-gutters>
      <slot name="body"/>
    </v-row>
    <span
      class="text-secondary d-flex justify-center text-center mt-2 font-weight-light text-subtitle-2 text-caption"
      data-test-name="text-footer"
    >
      {{ footerText }}
    </span>
  </v-card-text>
</template>

<script>

export default {
  name: 'QuestionWrapper',
  props: {
    step: {
      type: Number,
      required: true
    },
    footerText: {
      type: String,
      required: false,
      default: ''
    },
  }
}
</script>
