import axios from 'axios'
var URITemplate = require('urijs/src/URITemplate')

export const getters = {
  HOTEL_BY_CHAIN_CODES: state => chainCodes => state.hotels.filter(hotel => chainCodes.indexOf(hotel.chainCode) >= 0)
}

export const mutations = {
  // expects an array of hotels
  CACHE_HOTEL: (state, hotel) => {
    let hotels = []
    if (!Array.isArray(hotel)) {
      hotels.push(hotel)
    } else {
      hotel.forEach(el => hotels.push(el))
    }
    hotels.forEach(item => {
      if (!state.hotels.find(h => h.chainCode === item.chainCode)) {
        state.hotels.push(item)
      }
    })
  }
}

const initialValue = {
  allText: 'DataMissig',
  chainCode: '',
  chainName: '',
  masterChainCode: 'DataMissing',
}

export const actions = {
  LOOKUP_HOTEL_BY_CHAIN_CODES: async ({ commit, getters }, chainCodes) => {
    if (!Array.isArray(chainCodes)) {
      throw Error('Wrong parameter type: expected Array got String')
    }
    let hotels = getters.HOTEL_BY_CHAIN_CODES(chainCodes)
    if (!hotels || (Array.isArray(hotels) && hotels.length !== chainCodes.length)) {
      const template = URITemplate(process.env.VUE_APP_HOTELS_LOOKUP_BY_CHAIN_CODE_URL)
      const url = template.expand({
        codes: chainCodes
      }).replace(/\/$/, '')
      let { data } = await axios.get(url)
      data?.length ?
        commit('CACHE_HOTEL', data) :
        commit('CACHE_HOTEL', [{...initialValue, chainCodes}])
      hotels = getters.HOTEL_BY_CHAIN_CODES(chainCodes)
    }
    return hotels
  }
}

export default {
  state: {
    hotels: []
  },
  getters,
  mutations,
  actions
}
