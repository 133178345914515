import Rollbar from 'rollbar'

// for production errors will be sent to Rollbar API
// for development =>  console.log
// usage in components:
//    import { logAppError } from './logger'
//    logAppError('Some critical error')

export const logAppError = (message) => {
  if (process.env.NODE_ENV === 'production') {
    const rollbar = new Rollbar({
      accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: 'production'
      },
      enabled: process.env.NODE_ENV === 'production'
    })
    // In production, log errors to Rollbar
    rollbar.error(message)
  } else {
    // In development, log errors to the console
    // eslint-disable-next-line no-console
    console.error(message)
  }
}

export default { logAppError }