<template>
  <div data-test-name="card-shared-plan">
    <common-card
      :imgUrl="imageUrl"
      :title="card?.name"
      :templateID="card?.templateID"
      :likes="card?.likesCount"
      :isLiked="card?.isLiked"
      :description="card?.description"
      :tags="card?.tags"
      :isDividerVisible="true"
      tagsDisabled
      @select="onCardClick"
      @imageError="setDefaultImg"
    >
      <template #actions>
        <v-btn
          v-if="canDeleteTemplate"
          variant="plain"
          size="x-small"
          :title="$t('Delete')"
          data-test-id="trash-icon"
          @click.stop="onDeleteTemplate"
          icon="$customDelete"
          data-test-name="button-delete"
        />
      </template>
    </common-card>
    <common-popup
      v-if="isVisibleDeleteConfirmationPopup"
      :body-content="$t('Delete_this_template')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="callDeleteTemplate"
      @cancel="isVisibleDeleteConfirmationPopup= false"
      @close="isVisibleDeleteConfirmationPopup= false"
    />
  </div>
</template>

<script>
import CommonCard from './CommonCard.vue'
import { getDefaultImageUrl } from '@/helpers/staticImagesUtil'
import { mapGetters, mapActions } from 'vuex'
import CommonPopup from '@/components/popups/CommonPopup'
import { logAppError } from '@/logger'

export default {
  name: 'SharedPlanCard',
  components: { CommonCard, CommonPopup },
  emits: ['select', 'delete'],
  data() {
    return {
      isVisibleDeleteConfirmationPopup: false,
      imageUrl: this.card?.img,
    }
  },
  props: {
    card: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isAdmin: 'IS_ADMIN',
    }),
    isTemplateOwner() {
      return this.card.userID === this.user.id
    },
    canDeleteTemplate() {
      return this.isAdmin || this.isTemplateOwner
    }
  },
  methods: {
    ...mapActions({
      showNotification: 'PUSH_APP_NOTIFICATION',
      showLoader: 'LOADING_DIALOG',
      deleteTemplate: 'DELETE_TEMPLATE',
    }),
    onCardClick() {
      this.$emit('select', this.card.templateID)
    },
    setDefaultImg() {
      this.imageUrl = getDefaultImageUrl(this.card?.placeIDLocations?.[0])
    },
    onDeleteTemplate() {
      this.isVisibleDeleteConfirmationPopup= true
    },
    async callDeleteTemplate() {
      this.showLoader({
        visible: true,
        opacity: 0.5,
        overlayColor: 'rgb(var(--v-theme-textSecondaryColor))'
      })
      try {
        let templateID = this.card.templateID
        const res = await this.deleteTemplate({
          templateId: templateID,
          userId: this.user?.id
        })
        if (res.status === 200) {
          this.$emit('delete', templateID)
        }
      } catch(err) {
        this.showNotification({
          name: 'deleteTemplate',
          content: 'Smth_went_wrong_message',
          color: 'error'
        })
        logAppError(err)
      } finally {
        this.isVisibleDeleteConfirmationPopup= false
        this.showLoader()
      }
    }
  },
}
</script>
