<template>
  <vue-date-picker
    :model-value="timeValue"
    :time-picker="true"
    :clearable="clearable"
    :is24="is24"
    :disabled="disabled"
    :readonly="readonly"
    :teleport="teleport"
    @update:model-value="updateTime"
    ref="timePicker"
    data-test-name="time-picker"
    :action-row="{ showSelect: true, showCancel: false, showPreview: false }"
    :config="{ modeHeight: 110 }"
  >
    <template #dp-input="{ value, onInput }">
      <v-text-field
        :model-value="value"
        :variant="variant"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        :disabled="disabled"
        :class="{ activator: !readonly }"
        readonly
        :density="density"
        class="mt-3"
        @update:model-value="onInput"
        color="primary"
        base-color="primary"
        bg-color="#fff"
        hide-details="auto"
      >
        <template v-if="prependInnerIcon" #prepend-inner>
          <v-icon color="primary">{{ prependInnerIcon }}</v-icon>
        </template>
      </v-text-field>
    </template>
    <template #action-select="{ value }">
      <v-btn variant="tonal" @click="closeMenu(value)" color="primary">Select</v-btn>
    </template>
    <template #am-pm-button="{ toggle, value }">
      <v-btn variant="flat" @click="toggle" :class="value === 'AM' ? 'bg-primary' : ''">AM</v-btn>
      <v-btn variant="flat" @click="toggle" :class="value === 'PM' ? 'bg-primary' : ''">PM</v-btn>
    </template>
  </vue-date-picker>
</template>

<script>
import { mapGetters } from 'vuex'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'TimePicker',
  emits: ['update:model-value'],
  components: { VueDatePicker },
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'outlined',
    },
    disabled: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    teleport: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: 'default',
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    prependInnerIcon: {
      type: String,
      default: 'mdi-clock-time-five-outline'
    }
  },
  date() {
    return {
      is24: false,
    }
  },
  created() {
    this.is24 = this.timeFormat.includes('H')
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
    }),
    timeValue() {
      if (!this.modelValue) return ''
      const [ hours, minutes ] = this.modelValue.split(':')
      return { hours: hours, minutes: minutes }
    }
  },
  methods: {
    updateTime(val) {
      const time = this.$moment.parseZone(val).format('HH:mm')
      this.$emit('update:model-value', time)
    },
    closeMenu(val) {
      this.updateTime(val)
      this.$refs.timePicker.closeMenu()
    }
  },
}
</script>

<style scoped lang="scss">
:deep(.dp__input_wrap) {
  box-sizing: border-box;
}
:deep() {
  --dp-action-button-height: 35px;
}
:deep(.dp__action_select) {
  color: #fff;
  background-color: #0144C7;
}
:deep(.dp__action_row) {
  --dp-preview-font-size: 1em;
}
</style>
