<template>
  <v-expand-transition>
  <v-row>
  <v-col cols="12">
    <v-divider class="bg-secondary-lighten-4"></v-divider>
    <v-card color="white" rounded="0" class="d-flex justify-space-between justify-sm-end" elevation="0">
      <div class="d-flex justify-space-between align-center">
        <v-row no-gutters>
          <div v-for="(event, index) in events" :key="index">
            <v-col @click="toggle(event.type)" class="pa-0 ma-1 py-1">
              <v-badge
                :color="`${event.color.split(' ')[0]} lighten-4`"
                right
              >
                <template v-slot:badge>
                  <v-icon v-if="value[event.type]" :color="event.color" size="small">
                    mdi-check
                  </v-icon>
                </template>
                <v-btn
                  class="mx-1"
                  elevation="0"
                  :color="event.color"
                  :variant="!value[event.type] && 'outlined'"
                  size="x-small">
                  <v-icon>{{event.icon}}</v-icon>
                </v-btn>
              </v-badge>
            </v-col>
          </div>
        </v-row>
      </div>
      <v-icon class="px-4 pr-sm-7" @click="hide" color="primary">keyboard_arrow_up</v-icon>
    </v-card>
  </v-col>
  </v-row>
  </v-expand-transition>
</template>

<script>
import { itemTypes } from '@/helpers/itemTypes'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EventFilter',
  emits: ['hide'],
  data() {
    return {
      value: this.eventFilter,
    }
  },
  computed: {
    ...mapGetters({
      eventFilter: 'EVENT_FILTER',
    }),
    events() {
      return itemTypes
    }
  },
  methods: {
    ...mapActions({
      toggleEventFilter: 'TOGGLE_EVENT_FILTER',
    }),
    toggle(type) {
      this.toggleEventFilter({type: type, value: !this.value[type]})
    },
    hide() {
      this.$emit('hide')
    }
  },
}

</script>
