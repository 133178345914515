<template>
  <div class="px-3 pb-md-12 pb-10 mt-md-15 mt-8" data-test-name="page-contact-us">
    <v-row class="flex-column" align="center">
      <v-col lg="10" xl="8">
        <h2
          class="mb-md-16 mb-8 font-weight-bold text-h3 text-sm-h2 text-center"
          data-test-name="text-title"
          >
            {{ $t('Contact_Us') }}
          </h2>
          <v-row class="flex-between">
            <contact-us-text />
            <v-col cols="5" v-if="$vuetify.display.mdAndUp">
              <v-img
                data-test-name="image"
                class="cardBorderRadius"
                src="/assets/images/contact-us-img.jpg"
              >
              </v-img>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ContactUsText from '@/components/ContactUsText.vue'

export default {
  name: 'ContactUs',
  components: { ContactUsText },
}
</script>
