<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.332 3.3335H14.9987C15.4407 3.3335 15.8646 3.50909 16.1772 3.82165C16.4898 4.13421 16.6654 4.55814 16.6654 5.00016V16.6668C16.6654 17.1089 16.4898 17.5328 16.1772 17.8453C15.8646 18.1579 15.4407 18.3335 14.9987 18.3335H4.9987C4.55667 18.3335 4.13275 18.1579 3.82019 17.8453C3.50763 17.5328 3.33203 17.1089 3.33203 16.6668V5.00016C3.33203 4.55814 3.50763 4.13421 3.82019 3.82165C4.13275 3.50909 4.55667 3.3335 4.9987 3.3335H6.66536"
      stroke="#0144C7"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5013 1.6665H7.5013C7.04106 1.6665 6.66797 2.0396 6.66797 2.49984V4.1665C6.66797 4.62674 7.04106 4.99984 7.5013 4.99984H12.5013C12.9615 4.99984 13.3346 4.62674 13.3346 4.1665V2.49984C13.3346 2.0396 12.9615 1.6665 12.5013 1.6665Z"
      stroke="#0144C7"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
