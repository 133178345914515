<template>
  <v-container :fluid="$vuetify.display.lgAndDown" class="py-0" data-test-name="page-community">
    <v-row class="flex-column my-0" align="center">
      <v-col cols="12" class="bg py-15 text-center">
        <h1 class="text-h3 text-sm-h2 font-weight-regular mt-7" data-test-name="text-title">
          {{ $t('Community_banner_title_p1') }}
        </h1>
        <h2 class="text-h3 text-sm-h2 text-primary font-weight-bold" data-test-name="text-subtitle">
          {{ $t('Community_banner_title_p2') }}
        </h2>
        <v-row no-gutters justify="center">
          <v-col
            cols="12"
            md="10"
            lg="9"
            class="bg-white mt-5 mb-2 pa-3 py-sm-1 rounded-lg"
          >
            <v-row
              no-gutters
              align="center"
              class="mt-2 mb-n2 py-0"
              :class="$vuetify.display.xsOnly ? 'flex-column' : ''"
              justify="center"
            >
              <v-col
                :cols="$vuetify.display.smAndDown ? '12' : '5'"
                class="d-flex align-start"
                :class="$vuetify.display.xsOnly ? 'flex-column' : ''"
              >
                <v-text-field
                  v-model="searchQuery"
                  :clearable="true"
                  :label="$t('Search')"
                  class="pa-0 ma-2"
                  prepend-inner-icon="$customSearch"
                  variant="outlined"
                  color="primary"
                  base-color="primary"
                  bg-color="#fff"
                  data-test-name="input-search"
                  @keydown.enter="searchKeydownHandler"
                />
              </v-col>
              <v-divider
                v-if="$vuetify.display.smAndUp"
                class="mb-4"
                vertical
              />
              <v-col :cols="$vuetify.display.smAndDown ? '12' : '5'">
                <v-select
                  prepend-inner-icon="$customPackage"
                  :model-value="selectedTags"
                  :label="$t('Trip_preferences')"
                  @update:model-value="onAddTag"
                  multiple
                  variant="outlined"
                  color="primary"
                  base-color="primary"
                  bg-color="#fff"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="tagsOptionsList"
                  class="pa-0 ma-2"
                  data-test-name="select-preferences"
                >
                  <template #chip="{ item, props }">
                    <v-chip
                      :key="props.value"
                      v-bind="props"
                      @click:close.stop="onRemoveTag(item.value)"
                      closable
                      color="primary"
                      close-icon="mdi-close"
                      class="d-flex"
                      data-test-name="chip"
                    >
                      <span class="text-capitalize">{{ item.title }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col
                :cols="$vuetify.display.smAndDown ? '12' : '2'"
                class="mb-6"
              >
                <v-btn
                  color="primary"
                  rounded
                  elevation="0"
                  data-test-id="filter-templates-btn"
                  @click="doSearchTemplates"
                  prepend-icon="mdi-magnify"
                  data-test-name="button-do-search"
                >
                  {{ $t('Search_shared_plans') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="px-4 px-sm-0" cols="12" md="10" lg="9">
        <div class="mt-4 d-flex justify-end">
          <v-select
            prepend-inner-icon="$customShuffle"
            variant="outlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            hide-details
            class="sort-select mr-4"
            data-test-name="select-sorting-order"
            :menu-props="{ bottom: true, offsetY: true }"
            :items="sortOptionsList"
            :model-value="sorting"
            @update:model-value="onSorting"
          />
        </div>
        <div v-if="isDataLoaded && !isTemplatesFound" class="my-15 text-center">
          <div class="text-h6 text-sm-h3 text-primary font-weight-medium mb-4" data-test-name="text-title-no-results">
            {{ $t('No_results_found') }}
          </div>
          <div class="text-body-2" data-test-name="text-message"> {{ $t('Change_search_criteria') }} </div>
        </div>
        <div v-else-if="isDataLoaded && isTemplatesFound">
          <v-row no-gutters class="mt-4">
            <v-col
              v-for="card in templates"
              :key="card.templateID"
              cols="12"
              sm="6"
              md="4"
            >
              <shared-plan-card
                :card="card"
                @select="openItineraryTemplatePage"
                @delete="onDeleteTemplate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <extended-pagination
                class="my-2"
                :isStartButtonDisabled="currentPage === 1"
                :isEndButtonDisabled="currentPage === pagesCount"
                @startButtonClicked="onChangePage(1)"
                @endButtonClicked="onChangePage(pagesCount)"
              >
                <v-pagination
                  :model-value="currentPage"
                  :length="pagesCount"
                  :total-visible="$vuetify.display.xsOnly ? 3 : 5"
                  rounded
                  next-icon="mdi-chevron-right"
                  prev-icon="mdi-chevron-left"
                  data-test-name="pagination"
                  @update:model-value="onChangePage"
                />
              </extended-pagination>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/** known issue is that autocomplete box gets cleared onblur event
 * -- no quick remedies helped to address this issue
 * */

import SharedPlanCard from '@/components/SharedPlanCard'
import { mapActions, mapGetters } from 'vuex'
import ExtendedPagination from '@/components/ExtendedPagination.vue'
import { pageScroll } from '@/mixins/PageScrollMixin'
import { RouteNames } from '@/helpers/routerUtils'
import { getTemplatesWithLikesData } from '@/helpers/templates'

export default {
  name: 'CommunityPage',
  components: { SharedPlanCard, ExtendedPagination },
  mixins: [pageScroll],
  data() {
    return {
      templates: [],
      selectedTags: [],
      searchQuery: null,
      oldSearchValue: null,
      sorting: 'newest',
      offset: 0,
      pageSize: 12,
      currentPage: 1,
      pagesCount: 0,
      canRequestIfFiltering: false,
      isLoading: false,
      isDataLoaded: false,
      tags: [],
      sortOptions: [],
    }
  },
  watch: {
    searchQuery: function (newVal, oldVal) {
      newVal = newVal?.trim() || ''
      oldVal = oldVal?.trim() || ''
      const loadAllTemplates = this.oldSearchValue && newVal.length === 0 && oldVal.length > 0 && newVal !== oldVal
      if (loadAllTemplates) {
        this.doSearchTemplates()
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'USER',
    }),
    tagsOptionsList() {
      return this.tags.map((item) => ({ title: this.$t(item), value: item }))
    },
    sortOptionsList() {
      return this.sortOptions.map((item) => ({
        title: this.$t(item).charAt(0).toUpperCase() + this.$t(item).slice(1),
        value: item,
      }))
    },
    isTemplatesFound() {
      return this.templates?.length > 0
    },
  },
  methods: {
    ...mapActions({
      getTags: 'GET_TAGS',
      getSortOptions: 'GET_SORT_OPTIONS',
      getTemplates: 'GET_TEMPLATES',
      showLoader: 'LOADING_DIALOG',
      searchDestinations: 'SEARCH_TEMPLATES_DESTINATIONS',
    }),
    openItineraryTemplatePage(id) {
      this.$router.push({
        name: RouteNames.TEMPLATE_ITINERARY,
        params: { id },
      })
    },
    searchKeydownHandler() {
      this.doSearchTemplates()
    },
    onAddTag(tags) {
      this.selectedTags = tags
    },
    onRemoveTag(tag) {
      this.selectedTags = this.selectedTags.filter((item) => {
        return item !== tag
      })
      this.loadTemplates()
    },
    doSearchTemplates() {
      this.offset = 0
      this.currentPage = 1
      this.loadTemplates()
    },
    onSorting(val) {
      this.sorting = val
      this.offset = 0
      this.currentPage = 1
      this.loadTemplates()
    },
    onChangePage(page) {
      if (this.currentPage !== page) {
        this.offset = (page - 1) * this.pageSize
        this.currentPage = page
        this.loadTemplates()
      }
    },
    async loadTemplates() {
      if (this.isLoading) return

      this.isLoading = true
      await this.showLoader({
        visible: true,
      })
      const payload = {
        size: this.pageSize,
        from: this.offset,
        sort: this.sorting,
        destination: this.searchQuery,
        tags: this.selectedTags,
      }
      try {
        const data = await this.getTemplates(payload)
        this.templates = await getTemplatesWithLikesData(
          data?.itineraryTemplates,
          this.user?.id
        )
        this.pagesCount = Math.ceil(data.hits / data.size)
        this.scrollToTheTop()
        this.oldSearchValue = this.searchQuery
      } finally {
        await this.showLoader(null)
        this.isLoading = false
      }
    },
    onDeleteTemplate(id) {
      if (this.currentPage === this.pagesCount) {
        this.templates = this.templates.filter((el) => el.templateID !== id)
      } else {
        this.loadTemplates()
      }
    },
  },
  async created() {
    await this.showLoader({
      visible: true,
    })
    this.tags = await this.getTags()
    this.sortOptions = await this.getSortOptions()
    await this.loadTemplates()
    this.isDataLoaded = true
    await this.showLoader(null)
  },
}
</script>

<style lang="scss" scoped>
.bg {
  background: url('../../public/assets/images/community-bg.jpg') no-repeat 80%;
  background-size: cover;
  height: 100%;
}

.sort-select {
  max-width: 300px;
  .v-input__prepend-inner {
    .v-icon {
      width: 24px;
    }
  }
}
</style>
