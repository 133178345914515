<template>
  <alternative-card
    :imageSrc="imageSrc"
    :userRating="userRating"
    @click="selectItem"
  >
    <template #title>
      {{ title }}
    </template>
    <template #body>
      <div v-if="stars && stars > 0">
        <v-rating
          :model-value="stars"
          color="yellow"
          density="compact"
          half-increments
          readonly
          class="mb-4"
          size="small"
          empty-icon=""
        />
      </div>
      <div>{{ description }}</div>
    </template>
    <template #actions>
      <v-row>
        <v-col class="d-flex justify-start pb-0 text-caption">
          {{distance}}{{distanceUnit}}
        </v-col>
        <v-col class="d-flex justify-end pb-0 text-caption align-center font-weight-bold">
          <div
            class="mr-4 text-no-wrap"
            :class="`text-${priceColor}`"
          >
            {{priceDeltaFormatted}}
          </div>
          <colored-price
            :totalPrice="totalPrice"
          />
        </v-col>
      </v-row>
    </template>
  </alternative-card>
</template>

<script>
import AlternativeCard from './AlternativeCard'
import ColoredPrice from './ColoredPrice'
import { EventTypes, getItemTypeByType, formatPrice, calcPriceDelta } from '@/helpers'

export default {
  name: 'AlternativeLodgingItem',
  components: {
    AlternativeCard,
    ColoredPrice,
  },
  emits: ['select'],
  props: {
    item: {
      type: Object,
    },
    selectedItem: {
      type: Object,
    },
  },
  computed: {
    itemDetails() {
      return this.item?.lodging
    },
    imageSrc() {
      return this.itemDetails.roData?.thumbnailURL || getItemTypeByType(EventTypes.LODGING).defaultImageUrl
    },
    title() {
      return this.itemDetails?.title  //TODO change when BE updates
    },
    stars() {
      return this.itemDetails?.roData?.stars
    },
    userRating() {
      return this.itemDetails?.roData?.userRating
    },
    distance() {
      return this.itemDetails?.roData?.distanceFromCenter?.distance
    },
    distanceUnit() {
      return this.itemDetails?.roData?.distanceFromCenter?.unit
    },
    totalPrice() {
      return  this.itemDetails?.totalPrice
    },
    isCheaper() {
      return this.priceDelta < 0
    },
    selectedPrice() { //TODO remap
      return this.selectedItem?.lodging?.totalPrice
    },
    priceDelta() { //TODO remap
      return calcPriceDelta(this.totalPrice?.amount, this.selectedPrice?.amount)
    },
    priceDeltaFormatted() {
      if (this.priceDelta === 0) return ''
      return (this.isCheaper ? '':'+') + formatPrice({ amount: this.priceDelta, currency: this.totalPrice?.currency })
    },
    priceColor() {
      if (this.priceDelta === 0) return 'transparent'
      return this.isCheaper ? 'green' : 'error'
    },
    description() {
      return this.itemDetails?.roData?.description
    }
  },
  methods: {
    selectItem() {
      this.$emit('select',  this.item)
    },
  }
}
</script>
