<template>
  <v-row no-gutters data-test-name="content-alternative-posts">
    <v-col
      cols="12"
      class="d-flex justify-center font-weight-bold text-h5 text-sm-h3 pt-4 pt-md-10 text-textPrimaryColor"
      data-test-name="text-title"
    >
      {{ $t('More') }}
    </v-col>
    <v-col cols="12" sm="6" md="4" class="pa-0" v-for="post in alternativePosts" :key="post.id">
      <blog-card
        :post="post"
        labelsDisabled
        @select="() => onSelectPost(post.id)"
      />
    </v-col>
  </v-row>
</template>

<script>
import BlogCard from '@/components/blog/BlogCard.vue'
import { RouteNames } from '@/helpers/routerUtils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AlternativePosts',
  components: {
    BlogCard,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    alternativesCount: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    ...mapGetters({
      allBlogPosts: 'ALL_BLOG_POSTS',
    }),
    alternativePosts() {
      return (this.allBlogPosts[0]?.items || []).filter(post => this.post.id !== post.id).sort(() => Math.random() - 0.5).slice(0, this.alternativesCount)
    }
  },
  methods: {
    ...mapActions({
      loadBlogPosts: 'LOAD_BLOG_POSTS'
    }),
    onSelectPost(id) {
      this.$router.push({ name: RouteNames.BLOG_POST, params: { id: id } })
    },
  },
  watch: {
    post: {
      handler() {
        this.loadBlogPosts({labels: this.post?.labels && this.post?.labels[0] || ''})
      },
      immediate: true,
    }
  }
}
</script>
