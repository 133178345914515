<template>
  <v-card width="100%" class="mx-0" flat data-test-name="page-user-profile">
    <v-card-title
      class="pa-0 pl-0 pl-md-3 py-6 py-md-4 text-h5 text-md-h4 font-weight-bold"
      data-test-name="text-title"
    >
      {{ $t('Profile') }}
    </v-card-title>
    <v-form v-model="isUserDetailsValid">
      <v-row no-gutters>
        <v-col cols="12" sm="6" class="pa-0 pa-sm-3 pb-sm-0">
          <v-text-field
            v-model="user.firstName"
            data-test-name="input-first-name"
            class="mb-2"
            variant="outlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            prepend-inner-icon="$customUser"
            :label="$t(userProfileFields.firstName.label)"
            :rules="validateFirstName"
          />
          <v-text-field
            v-model="user.lastName"
            class="mb-2"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            prepend-inner-icon="$customUser"
            data-test-name="input-last-name"
            :label="$t(userProfileFields.lastName.label)"
            :rules="validateLastName"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0 pa-sm-3 pb-sm-0">
          <v-text-field
            v-model="user.phone"
            class="mb-2 phone-number"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            type="tel"
            prepend-inner-icon="$customPhone"
            data-test-name="input-phone"
            :placeholder="$t('Enter_Phone')"
            :label="$t(userProfileFields.phone.label)"
            :rules="validatePhone"
          />
          <v-text-field
            v-model="user.email"
            class="mb-0 mb-sm-2"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            disabled
            prepend-inner-icon="$customEmail"
            data-test-name="input-email"
            :label="$t(userProfileFields.email.label)"
            :error-messages="!user.email ? ['email error'] : []"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="mb-4 mb-sm-9"></v-divider>
    <v-row
      v-if="isUserHasPassword"
      no-gutters
    >
      <div
        class="mx-sm-3 mb-5 mb-sm-7 text-body-1 font-weight-medium"
        data-test-name="text-subtitle"
      >
        {{ $t('Change_Password') }}
      </div>
      <v-col cols="12">
        <v-form
          lazy-validation
        >
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="pa-0 px-sm-3">
              <v-text-field
                :model-value="currentPassword.value"
                @update:modelValue="value => handlePasswordChange('currentPassword', value)"
                :label="$t(currentPassword.label)"
                :hint="$t(currentPassword.hint)"
                :type="currentPasswordInputType"
                :append-icon="currentPasswordAppendedIcon"
                :error-messages="currentPasswordErrors"
                class="current-password mb-2"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                required
                @click:append="currentPassword.isValueVisible = !currentPassword.isValueVisible"
                @blur="validateCurrentPassword"
                prepend-inner-icon="$customLock"
                data-test-name="input-password-current"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-0 px-sm-3">
              <div class="mt-0 mb-8 mt-sm-3 mb-sm-0 text-center text-sm-left">
                <a style="cursor: pointer;" class="reset-link" @click="goToResetPasswordPage" data-test-name="link-to-reset-password-page">
                  {{ $t("I_dont_know_password") }}
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="pa-0 px-sm-3">
              <v-text-field
                :model-value="newPassword.value"
                @update:modelValue="value => handlePasswordChange('newPassword', value)"
                :label="$t(newPassword.label)"
                :hint="$t(newPassword.hint)"
                :type="newPasswordInputType"
                :append-icon="newPasswordAppendedIcon"
                :error-messages="newPasswordErrors"
                class="new-password mb-2"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                required
                @click:append="newPassword.isValueVisible = !newPassword.isValueVisible"
                @blur="validateNewPassword"
                prepend-inner-icon="$customLock"
                data-test-name="input-password-new"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-0 px-sm-3">
              <v-text-field
                :model-value="repeatNewPassword.value"
                @update:modelValue="value => handlePasswordChange('repeatNewPassword', value)"
                :label="$t(repeatNewPassword.label)"
                :hint="$t(repeatNewPassword.hint)"
                :type="repeatNewPasswordInputType"
                :append-icon="repeatNewPasswordAppendedIcon"
                :error-messages="repeatNewPasswordErrors"
                class="repeat-new-password"
                required
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                @click:append="repeatNewPassword.isValueVisible = !repeatNewPassword.isValueVisible"
                @blur="validateRepeatNewPassword"
                prepend-inner-icon="$customLock"
                data-test-name="input-password-new-repeat"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex justify-space-between mt-2 mt-sm-0">
      <v-col cols="12" sm="8" lg="6" class="pa-0 px-sm-3 mb-6 mb-sm-0">
        <v-btn
          class="py-3 px-10 text-truncate"
          color="primary"
          :width="$vuetify.display.smAndDown? '100%': ''"
          variant="outlined"
          rounded
          size="large"
          data-test-name="button-delete-personal-data"
        >
          <router-link
            :to="{name: this.PERSONAL_DATA_DELETION}"
            class="text-decoration-none text-primary"
            data-test-name="link-to-delete-personal-data-page"
          >
            {{ $t('Erase_all_my_data') }}
          </router-link>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" lg="6" class="pa-0 px-sm-3 d-flex justify-end">
        <v-btn
          class="submit py-3 px-10 ma-0"
          color="primary"
          :disabled="!isSubmitButtonEnabled"
          :width="$vuetify.display.smAndDown? '100%': ''"
          variant="flat"
          rounded
          size="large"
          data-test-name="button-save"
          @click="saveChanges"
        >
          {{ $t('Save_Changes') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import cloneDeep from 'lodash.clonedeep'
import checkEquality from  'fast-deep-equal/es6'
import {
  updateUserPassword,
  signInUserWithEmailAndPassword,
  getUpdatedAuthState,
} from '@/helpers/firebase/firebase'
import { firebaseErrors } from '@/helpers/firebase/firebaseErrors'
import userProfileFields, { currentPassword, newPassword, repeatNewPassword } from '@/helpers/userProfileFields'
import { checkFilled, checkCorrect, checkMatch, checkMismatch, checkHasEmptySpace } from '@/helpers/inputValidators'

export default {
  name: 'UserProfile',
  data() {
    return {
      currentPassword: {},
      newPassword: {},
      repeatNewPassword: {},
      PERSONAL_DATA_DELETION: '',
      firebaseUser: null,
      user: {},
      isUserDetailsValid: false,
      userProfileFields,
      validateFirstName: [
        v => this.required(v, this.userProfileFields.firstName),
        v => this.lengthInRange(v, [2, 50], this.userProfileFields.firstName),
        v => this.isPatternCorrect(v, this.userProfileFields.firstName)
      ],
      validateLastName: [
        v => this.required(v, this.userProfileFields.lastName),
        v => this.lengthInRange(v, [2, 50], this.userProfileFields.lastName),
        v => this.isPatternCorrect(v, this.userProfileFields.lastName)
      ],
      validatePhone: [
        v => this.lengthInRange(v, [8, 20], this.userProfileFields.phone),
        v => !v || this.isPatternCorrect(v, this.userProfileFields.phone)
      ],
    }
  },
  created() {
    this.currentPassword = cloneDeep(currentPassword)
    this.newPassword = cloneDeep(newPassword)
    this.repeatNewPassword = cloneDeep(repeatNewPassword)
    this.PERSONAL_DATA_DELETION = RouteNames.PERSONAL_DATA_DELETION
  },
  computed: {
    ...mapGetters({
      view: 'VIEW',
      stateUser: 'USER'
    }),
    isSubmitButtonEnabled(){
      return (
        this.currentPassword.value &&
        this.newPassword.value &&
        this.repeatNewPassword.value &&
        !this.currentPassword.errors.length &&
        !this.repeatNewPassword.errors.length) ||
        this.wasDataChanged && this.isUserDetailsValid !== false
    },
    isPasswordFormChanged() {
      return this.currentPassword.value || this.newPassword.value || this.repeatNewPassword.value
    },
    currentPasswordAppendedIcon() {
      return this.currentPassword.isValueVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    newPasswordAppendedIcon() {
      return this.newPassword.isValueVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    repeatNewPasswordAppendedIcon() {
      return this.repeatNewPassword.isValueVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    currentPasswordInputType() {
      return this.currentPassword.isValueVisible ? 'text' : 'password'
    },
    newPasswordInputType() {
      return this.newPassword.isValueVisible ? 'text' : 'password'
    },
    repeatNewPasswordInputType() {
      return this.repeatNewPassword.isValueVisible ? 'text' : 'password'
    },
    currentPasswordErrors() {
      return this.currentPassword.blurred ? this.currentPassword.errors.map(message => this.$t(message)) : []
    },
    newPasswordErrors() {
      return this.newPassword.blurred ? this.newPassword.errors.map(message => this.$t(message)) : []
    },
    repeatNewPasswordErrors() {
      return this.repeatNewPassword.blurred ? this.repeatNewPassword.errors.map(message => this.$t(message)) : []
    },
    wasDataChanged() {
      return !checkEquality(this.stateUser, this.user)
    },
    isUserHasPassword() {
      return !!this.firebaseUser?.providerData.some(item => item.providerId === 'password')
    },
  },
  mounted() {
    getUpdatedAuthState(this.onGetAuthStateCallback)
  },
  methods: {
    ...mapActions({
      pushAppNotification: 'PUSH_APP_NOTIFICATION',
      updateUser: 'UPDATE_USER',
    }),
    handlePasswordChange(passwordKey, value) {
      this[passwordKey].value = value
    },
    goToResetPasswordPage() {
      this.$router.push({ name: RouteNames.RESET_PASSWORD })
    },
    required(value, field) {
      return !!value || this.$t(field.errorEmpty)
    },
    lengthInRange(value, [min, max], field) {
      return (value.length >= min && value.length <= max) || this.$t(field?.errorLength ? field.errorLength :'Input_length_error', [min, max])
    },
    isPatternCorrect(value, field) {
      return field.pattern.test(value) || this.$t(field.errorIncorrect)
    },
    validatePasswordField(value) {
      const errors = []
      errors.push(checkHasEmptySpace(value))
      errors.push(checkFilled(value))
      errors.push(checkCorrect(value))
      return errors
    },
    validateCurrentPassword() {
      this.currentPassword.blurred = true
      const errors = this.validatePasswordField(this.currentPassword)
      this.currentPassword.errors = errors.filter(error => !!error)
    },
    validateNewPassword() {
      this.newPassword.blurred = true
      const errors = this.validatePasswordField(this.newPassword)
      errors.push(checkMismatch(this.currentPassword, this.newPassword))
      this.newPassword.errors = errors.filter(error => !!error)
    },
    validateRepeatNewPassword() {
      this.repeatNewPassword.blurred = true
      const errors = this.validatePasswordField(this.repeatNewPassword)
      errors.push(checkMatch(this.newPassword, this.repeatNewPassword))
      this.repeatNewPassword.errors = errors.filter(error => !!error)
    },
    onGetAuthStateCallback(user) {
      if(user) {
        this.firebaseUser = user
      }
    },
    saveChanges() {
      if(this.isUserHasPassword && this.isPasswordFormChanged) {
        this.changeUserPassword()
      }
      if(this.wasDataChanged) {
        this.updateUser(this.user)
      }
    },

    async changeUserPassword() {
      const email = this.user.email
      const currentPassword = this.currentPassword.value
      const newPassword = this.newPassword.value

      try {
        await signInUserWithEmailAndPassword(email, currentPassword)
        await updateUserPassword(newPassword)
        this.pushAppNotification({
          name: 'new_password_saved',
          content: 'New_Password_Saved',
          color: 'primary',
          timeout: 10000,
        })
      } catch(error) {
        if (firebaseErrors[error.code]) {
          this.currentPassword.errors.push(firebaseErrors[error.code])
        } else {
          this.repeatNewPassword.errors.push('Could_Not_Change_Password')
        }
      } finally {
        this.currentPassword.value = ''
        this.newPassword.value = ''
        this.repeatNewPassword.value = ''
      }
    },
  },
  watch: {
    stateUser: {
      handler(newVal) {
        this.user = cloneDeep(newVal) || {}
      },
      immediate: true,
    },
  }
}
</script>
