<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-btn
        :rounded="isRounded"
        :disabled="disabled"
        :variant="hover && outlined && 'outlined'"
        @click="$emit('click', $event)"
        :color="hover ? secondaryColor : color"
        :width="width"
        :height="height"
      >
        <div class="font-weight-medium text-uppercase">{{ text }}</div>
      </v-btn>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'ButtonPrimary',
  emits: ['click'],
  props: {
    text: String,
    outlined: Boolean,
    isRounded: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: Boolean,
    width: {
      type: Number,
      required: false,
      default: 306
    },
    height: {
      type: Number,
      required: false,
      default: 44
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    secondaryColor: {
      type: String,
      required: false,
      default: 'primary darken-1'
    }
  },
}
</script>

<style >
.v-ripple__container {
  display:none !important;
}
</style>
