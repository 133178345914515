<template>
  <v-expand-transition>
    <v-sheet
      class="disable-vertical-scroll"
      elevation="0"
      max-width="100%"
      data-test-name="content-alternative-selector-tool"
      min-height="340"
    >
      <component
        v-if="isTransportationEvent"
        :is="'Search' + eventType"
        :item="record"
        @updateSearchPayload="updateSearchPayload"
        @validateSearchPayload="validateSearchPayload"
      />

      <v-row dense class="pa-0 mt-4 mb-8 mx-0 mx-sm-6 align-end d-flex flex-wrap">
        <v-col :cols="$vuetify.display.xs ? 9 : 8" sm="10" class="px-0 py-2 py-sm-0">
          <v-text-field
            v-model="keywords"
            :label="$t('Optional_keywords')"
            hide-details
            class="py-0 my-0"
            variant="underlined"
            color="#4E77C6"
            base-color="primary"
            data-test-name="input-keywords"
          />
        </v-col>
        <v-col cols="2" v-if="!$vuetify.display.xs" class="pa-0 pt-3 d-none d-sm-flex">
          <v-btn
            :disabled="isSearchDisabled && !isSearchPayloadValid"
            :loading="isLoaderVisible"
            color="primary"
            rounded
            class="ml-auto"
            width="160"
            height="36"
            prepend-icon="mdi-magnify"
            data-test-name="button-search-flights"
            @click="getAlternatives"
          >
            {{ $t('Search') }}
          </v-btn>
        </v-col>
        <v-col cols="2" v-if="$vuetify.display.xs" class="ml-auto">
          <v-btn
            color="primary"
            icon="mdi-magnify"
            :loading="isLoaderVisible"
            @click="getAlternatives"
            variant="flat"
          />
        </v-col>
      </v-row >
      <v-row
        v-if="isAlternativesLoaded && isAlternativesVisible"
        class="my-0 mx-0 mx-sm-6 pb-5 alternatives-control justify-space-between"
      >
        <v-col cols="5" class="font-weight-500 text-h6">{{ $t('Found') }}: {{ sortedData?.length }}</v-col>
        <v-col cols="7" sm="6" md="4" v-show="!!sortingOptions.length" class="pa-0 ml-auto">
          <sorting-options
            v-model="selectedSortingType"
            :sorting-options="sortingOptions"
            @order-has-changed="(value) => descendingOrder = value"
          />
        </v-col>
      </v-row>

      <section v-if="!isLoaderVisible" class="d-flex justify-center">
        <v-card flat rounded="0" width="100%">
          <v-slide-group
            :model-value="selectedEventIndex"
            :show-arrows="isAlternativesVisible ? 'always' : false"
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
            class="mx-n5 mx-sm-0 alternative-slide-group"
            center-active
            data-test-name="slider-search-flights-results"
            @update:model-value="selectedEventIndex = $event"
          >
            <v-slide-group-item
              v-for="(item, index) in sortedData"
              :key="index"
              v-slot="{ toggle }"
              @group:selected="selectEvent(item)"
            >
              <component
                :item="item"
                :selectedItem="record"
                :is="eventType"
                @click="toggle"
              />
            </v-slide-group-item>
          </v-slide-group>
          <div v-if="!isAlternativesVisible && isAlternativesLoaded">
            <v-card-subtitle class="text-center" data-test-name="text-message-no-results">
              {{ $t('No_results_message4') }}
            </v-card-subtitle>
            <v-card-actions class="d-flex justify-center mb-4">
              <v-btn
                rounded
                variant="outlined"
                color="primary"
                data-test-name="button-confirm"
                @click="hideAlternatives"
                elevation="0"
              >
                {{ $t('Ok') }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </section>
      <section v-else-if="$vuetify.display.xs && isLoaderVisible" class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          style="height: 100px"
          color="primary"
          indeterminate
          data-test-name="loading-indicator"
        />
      </section>
    </v-sheet>
  </v-expand-transition>
</template>

<script>
import AlternativeFlightItem from './AlternativeFlightItem'
import AlternativeLodgingItem from './AlternativeLodgingItem'
import AlternativeRestaurantItem from './AlternativeRestaurantItem'
import AlternativeAttractionItem from './AlternativeAttractionItem'
import AlternativeShowItem from './AlternativeShowItem'
import AlternativeTourItem from './AlternativeTourItem'
import AlternativeInsuranceItem from './AlternativeInsuranceItem.vue'
import ButtonSort from '@/components/buttons/ButtonSort'
import itineraryService from '@/helpers/itineraryService'
import flight from '@/helpers/itineraryServices/flight'
import FlightSearchForm from './FlightSearchForm'
import lodging from '@/helpers/itineraryServices/lodging'
import restaurants from '@/helpers/itineraryServices/restaurants'
import attractions from '@/helpers/itineraryServices/attractions'
import shows from '@/helpers/itineraryServices/shows'
import tours from '@/helpers/itineraryServices/tours'
import insurance from '@/helpers/itineraryServices/insurance'
import { mapGetters } from 'vuex'
import Event from '@/components/calendar/EventDialog'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { EventTypes, getItemTypeByType, CATEGORY_TRANSPORTATION } from '@/helpers/itemTypes'
import SortingOptions from '@/components/itinerary-edit/module/SortingOptions'

const sort_by = (field, reverse, primer) => {

  const key = primer ?
    function (x) {
      if (typeof x === 'object') {
        return primer(itineraryService.getValueByPath(x, field))
      } else
        return primer(x[field])
    } :
    function (x) {
      if (typeof x === 'object') {
        return itineraryService.getValueByPath(x, field)
      } else
        return x[field]
    }

  reverse = !reverse ? 1 : -1

  return function (a, b) {
    return a = key(a), b = key(b), reverse * ((a > b) - (b > a))
  }
}

export default {
  name: 'AlternativeSelectorTool',
  components: {
    ButtonSort,
    [EventTypes.ATTRACTION]:       AlternativeAttractionItem,
    [EventTypes.FLIGHT]:           AlternativeFlightItem,
    [EventTypes.INSURANCE]:        AlternativeInsuranceItem,
    [EventTypes.LODGING]:          AlternativeLodgingItem,
    [EventTypes.RESTAURANT]:       AlternativeRestaurantItem,
    [EventTypes.SHOW]:             AlternativeShowItem,
    [EventTypes.TOUR]:             AlternativeTourItem,
    Event,
    SortingOptions,
    ['Search'+EventTypes.FLIGHT]:  FlightSearchForm,
  },
  emits: ['select', 'updateDataSource'],
  data() {
    return {
      keywords: '',
      isLoaderVisible: false,
      descendingOrder: false,
      selectedSortingType: null,
      selectedEventIndex: null,
      selectedCard: null,
      sortingOptions: [],
      searchPayload: '',
      isSearchPayloadValid: false,
    }
  },
  props: {
    record: Object,
    isSearchDisabled: {
      type: Boolean,
      default: false,
    },
    dataSource: Array,
  },
  created() {
    this.sortingOptions = itineraryService.itemSortingOptions(this.record)
    if (this.sortingOptions.length) {
      this.selectedSortingType = this.sortingOptions[0].id || null
    }
    if (this.dataSource && this.dataSource.length) {
      this.selectedCard = this.dataSource.find((item) => item[this.eventType].title === this.record[this.eventType].title)
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      currentItinerary: 'CURRENT_ITINERARY',
    }),
    isTransportationEvent() {
      return getItemTypeByType(this.eventType)?.category === CATEGORY_TRANSPORTATION
    },
    isAlternativesVisible() {
      return this.dataSource?.length
    },
    isAlternativesLoaded() {
      return this.dataSource
    },
    color() {
      return itineraryService.itemColor(this.record)
    },
    selectedSortingOption() {
      return this.sortingOptions.find(el => el.id === this.selectedSortingType)
    },
    eventType() {
      return itineraryService.getEventType(this.record)
    },
    sortedData() {
      if (!Array.isArray(this.dataSource)) {
        return []
      }
      return this.dataSource.slice().sort(sort_by(this.selectedSortingOption?.id || '', this.descendingOrder, this.selectedSortingOption?.sortPrimer || ''))
    },
  },
  methods: {
    updateSearchPayload(payload) {
      this.searchPayload = payload
    },
    validateSearchPayload(value) {
      this.isSearchPayloadValid = value
    },
    getCommonPayload(placeID) {
      const { startDateTime, endDateTime } = this.record
      return {
        location: {
          placeID,
        },
        startDateTime,
        endDateTime,
        size: Number(process.env.VUE_APP_ALTERNATIVES_SIZE),
        userID: this.user.id,
        keywords: this.keywords,
        travelers: this.getTravellers(),
      }
    },
    keyPress(event, callback) {
      switch (event.key) {
      case 'Enter':
        callback()
        break
      }
    },
    selectEvent(value) {
      this.selectedCard = value
      this.$emit('select', value)
    },
    getTravellers() {
      return {
        adults: Number(this.currentItinerary.header.travelers.adults) || 0,
        children: Number(this.currentItinerary.header.travelers.children) || 0
      }
    },
    async getAlternatives() {
      this.isLoaderVisible = true
      let result, payload
      switch (this.eventType) {
      case EventTypes.FLIGHT: {
        payload = {
          size: Number(process.env.VUE_APP_ALTERNATIVES_SIZE),
          userID: this.user.id,
          keywords: this.keywords,
          travelers: this.searchPayload.travelers,
          fromPlaceID: this.searchPayload.fromPlaceID,
          toPlaceID:   this.searchPayload.toPlaceID,
          departureDate: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(this.searchPayload.departureDate), false),
        }
        result = await flight.search(payload)
        break
      }
      case EventTypes.LODGING: {
        const commonPayload = this.getCommonPayload(this.record?.location?.placeID)
        payload = {
          ...commonPayload,
          checkInDateTime: this.record.startDateTime,
          checkOutDateTime: this.record.endDateTime,
        }
        result = await lodging.search(payload)
        break
      }
      case EventTypes.RESTAURANT: {
        payload = this.getCommonPayload(this.record?.location?.placeID)
        result = await restaurants.search({
          ...payload,
          startDateTime: this.record.startDateTime,
          endDateTime: this.record.endDateTime
        })
        break
      }
      case EventTypes.ATTRACTION: {
        payload = this.getCommonPayload(this.record?.location?.placeID)
        result = await attractions.search({
          ...payload,
          startDateTime: this.record.startDateTime,
          endDateTime: this.record.endDateTime
        })
        break
      }
      case EventTypes.SHOW: {
        payload = this.getCommonPayload(this.record?.location?.placeID)
        result = await shows.search({
          ...payload,
          startDateTime: this.record.startDateTime,
          endDateTime: this.record.endDateTime
        })
        break
      }
      case EventTypes.TOUR: {
        payload = this.getCommonPayload(this.record?.location?.placeID)
        result = await tours.search({
          ...payload,
          startDateTime: this.record.startDateTime,
          endDateTime: this.record.endDateTime
        })
        break
      }
      case EventTypes.INSURANCE: {
        payload = {
          size: Number(process.env.VUE_APP_ALTERNATIVES_SIZE),
          userID: this.user.id,
          keywords: this.keywords,
        }
        result = await insurance.search(payload)
        break
      }
      }

      this.$emit('updateDataSource', result)
      this.isLoaderVisible = false
    },
    hideAlternatives() {
      this.$emit('updateDataSource', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.alternative-selector-tool {

  &--mobile {
    height: auto;
  }

  &__link {
    cursor: pointer;
    transition: opacity .15s ease-in;

    &:hover {
      opacity: .8;
    }
  }
}

.alternatives-control {
  justify-content: space-between;
}
@media (max-width: 320px) {
  .alternatives-control{
    justify-content: center;
  }
}
.view-mode-button {
  cursor: pointer;
}

.selected-card {
  &:hover {
    border: 1px solid var(--v-primary-base) !important;
  }
  &--active {
    border: 1px solid var(--v-primary-base);
  }
}


</style>
