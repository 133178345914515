<template>
  <question-wrapper
    :step="step"
    :footerText="$t('Restaurant_Caption')"
  >
    <template v-slot:body>
      <v-col class="pa-0">
        <restaurant-selector
          :preferredRestaurantRating="restaurantRating"
          @update="updateRestaurantRating"
        />
      </v-col>
    </template>
  </question-wrapper>
</template>

<script>
import QuestionWrapper from '../QuestionWrapper.vue'
import { mapActions, mapState } from 'vuex'
import RestaurantSelector from '@/components/selector/RestaurantSelector'
import { defaultPreferences } from '@/store/modules/user'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'RestaurantQuestion',
  components: {
    QuestionWrapper,
    RestaurantSelector
  },
  props: {
    step: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      restaurantRating: null,
    }
  },
  created() {
    this.restaurantRating = this.user.preferences?.preferredRestaurantRating || 0
  },
  computed:{
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updateRestaurantRating(rating) {
      this.restaurantRating = rating
    },
    updateProfile() {
      if(this.restaurantRating && this.restaurantRating !== this.user.preferences?.preferredRestaurantRating){
        const preferences = cloneDeep(this.user.preferences || defaultPreferences)
        preferences.preferredRestaurantRating = this.restaurantRating
        this.updateUser({ preferences })
      }
    }
  },
  beforeUnmount() {
    this.updateProfile()
  }
}
</script>
