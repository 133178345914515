import TimeLine from '@/components/itinerary-edit/Timeline.vue'
import EventSegment from '@/components/itinerary-edit/EventSegment.vue'
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import itineraryService from '@/helpers/itineraryService'
import { convertAmountToServerFormat, getDatesAndTimeAsISOString, getItemTypeByType } from '@/helpers'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import cloneDeep from 'lodash.clonedeep'
import checkEquality from 'fast-deep-equal/es6'
import transportation from '@/helpers/itineraryServices/transportation'

export const transportationFormsMixin = {
  mixins: [eventFormMainStructure],
  emits: ['updateTitle', 'cancel', 'save', 'deleteEvent'],
  components: {
    TimeLine,
    EventSegment,
  },
  data() {
    return {
      departureLocation: null,
      arrivalLocation: null,
      selectedEvent: null,
      isShowMore: false,
      segments: [],
      segmentValidations: [],
      eventItem: null,
    }
  },
  computed: {
    isSegmentsView() {
      return (this.isShowMore || this.segments.length === 1) && this.isDetailsMode
    },
    isBaseView() {
      return this.segments.length > 1 && !this.isShowMore && !this.isSearchMode
    },
    isDetailsMode() {
      return this.tab === 0
    },
    isSearchMode() {
      return this.tab === 1
    },
    durations() {
      return itineraryService.calcSegmentsDuration(this.newRecord[this.eventType].segments)
    },
    isDisabledAddSegment() {
      return this.segments.length > 3 || !this.isAllSegmentsValid
    },
    isAllSegmentsValid() {
      return this.segmentValidations.every(isValid => isValid)
    },
    isLocationValid() {
      return !!this.departureLocation?.placeID && !!this.arrivalLocation?.placeID
    },
    isEventValid() {
      return this.isAllSegmentsValid
    },
    isLocationsDifferent() {
      return this.departureLocation && this.arrivalLocation && !checkEquality(this.arrivalLocation, this.departureLocation)
    },
    newRecord() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      const { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let eventData
      if (this.selectedEvent) {
        eventData = this.selectedEvent[this.eventType]
      } else if (this.record) {
        eventData = this.record[this.eventType]
      }

      let rv = {
        startDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(startDateTime)),
        endDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(endDateTime)),
        location: this.departureLocation,
        arrivalLocation: this.arrivalLocation,
        travelers: this.travelers,
        [this.eventType]: {
          title: this.title,
          ...(this.notes && { notes: this.notes }),
          ...(this.isBooked && { isBooked: this.isBooked }),
          ...(convertAmountToServerFormat(this.totalPrice?.amount) && {
            totalPrice: {
              currency: this.totalPrice?.currency,
              amount: convertAmountToServerFormat(this.totalPrice?.amount),
            },
          }),
          segments: this.segments,
          //roData is never updated by the user -- just pass it on to the BE when present
          ...(eventData?.roData && { roData: eventData.roData }),
        },
      }

      return rv
    },
    previousSegmentArrivalDateTime() {
      return (index) => this.segments.at(index - 1)?.arrival?.at
    },
  },
  async created() {
    this.eventItem = getItemTypeByType(this.eventType)
    this.imageURL = this.eventItem.defaultImageUrl
    //first segment added right away
    if (this.createNew && this.segments.length === 0) {
      await this.addSegment()
      this.syncSegmentsToEvent()
      this.$nextTick(() => {
        this.updateInitialRecord()
      })
    }
  },
  methods: {
    async addSegment() {
      const segment = await this.createTransportSegmentStruct()
      this.segments.push(segment)
      this.isShowMore = true
      this.segmentValidations.push(false)
    },
    syncSegmentsToEvent() {
      this.departureLocation = this.segments[0]?.departure?.location
      this.startDate = this.$moment.parseZone(this.segments[0]?.departure?.at).format('YYYY-MM-DD')
      this.startTime = this.$moment.parseZone(this.segments[0]?.departure?.at).format('HH:mm')

      this.arrivalLocation = this.segments[this.segments.length - 1]?.arrival?.location
      this.endDate = this.$moment.parseZone(this.segments[this.segments.length - 1]?.arrival?.at).format('YYYY-MM-DD')
      this.endTime = this.$moment.parseZone(this.segments[this.segments.length - 1]?.arrival?.at).format('HH:mm')

      this.$emit('updateTitle', this.departureLocation.placeID + ' - ' + this.arrivalLocation.placeID)
    },
    async mapDataToFormFields(eventData) {
      eventData = cloneDeep(eventData)
      const { totalPrice, segments, fullName } = (await transportation.parseEventData(eventData)) || {}

      this.isBooked = Boolean(eventData?.isBooked)
      this.notes = eventData?.notes || this.notes //preserve notes
      this.totalPrice.amount = totalPrice?.amount / 100
      this.totalPrice.currency = eventData?.totalPrice?.currency || this.userCurrency || 'USD'
      this.segments = segments || []
      this.departureLocation = this.segments[0]?.departure?.location || {}
      this.arrivalLocation = this.segments[this.segments.length - 1]?.arrival?.location || {}

      this.$emit('updateTitle', fullName)

      //set the proper dates/times
      const numOfSegments = this.segments.length
      if (!this.segmentValidations.length) {
        this.segmentValidations = new Array(numOfSegments).fill(true)
      }

      this.startDate = this.$moment.parseZone(this.segments[0]?.departure.at).format('YYYY-MM-DD')
      this.startTime = this.$moment.parseZone(this.segments[0]?.departure.at).format('HH:mm')
      this.endDate = this.$moment.parseZone(this.segments[numOfSegments - 1]?.arrival.at).format('YYYY-MM-DD')
      this.endTime = this.$moment.parseZone(this.segments[numOfSegments - 1]?.arrival.at).format('HH:mm')
    },
    updateSegment(segment, index) {
      this.segments.splice(index, 1, segment)
      this.syncSegmentsToEvent()
    },
    updateSegmentValidation(value, index) {
      this.segmentValidations.splice(index, 1, value)
    },
    updateSegmentDepartureLocation(index, value) {
      const departure = { ...this.segments[index].departure, location: value }
      const segment = { ...this.segments[index], departure }
      this.updateSegment(segment, index)
    },
    updateSegmentArrivalLocation(index, value) {
      const arrival = { ...this.segments[index].arrival, location: value }
      const segment = { ...this.segments[index], arrival}
      this.updateSegment(segment, index)
    },
    updateArrivalLocation(arrivalLocation) {
      this.updateAlternativeVisibility(arrivalLocation, this.arrivalLocation)
      this.arrivalLocation = arrivalLocation
    },
    updateDepartureLocation(departureLocation) {
      this.updateAlternativeVisibility(departureLocation, this.departureLocation)
      this.departureLocation = departureLocation
    },
    async onSelectEvent(event) {
      if (event) {
        this.selectedEvent = event
        await this.mapDataToFormFields(event[this.eventType])
        this.detailsTabCachedRecord  = null
        this.tab = 0
      }
    },
    deleteSegment(index) {
      this.segments = this.segments.filter((item, i) => i !== index)
      this.segmentValidations = this.segmentValidations.filter((item, i) => i !== index)
      this.syncSegmentsToEvent()
    },
    async createTransportSegmentStruct() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      let { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)
      let departureLocation = this.departureLocation
      let arrivalLocation = this.arrivalLocation

      const defaultDuration = this.eventItem.defaultDuration
      endDateTime = this.$moment.parseZone(startDateTime).add(defaultDuration, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ')

      const segmentsLength = this.segments.length
      let carrier

      if (segmentsLength) {
        const lastSegment = this.segments[segmentsLength - 1]
        carrier = lastSegment.carrier?.carrier
        departureLocation = lastSegment.arrival.location
        arrivalLocation = null
        endDateTime = null

        if (lastSegment.arrival.at) {
          startDateTime = this.$moment.parseZone(lastSegment.arrival.at).add(this.eventItem.segmentGapMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ')
        } else {
          startDateTime = null
        }
      }

      let rv = await transportation.createSegmentStruct(
        startDateTime,
        departureLocation,
        endDateTime,
        arrivalLocation,
        carrier
      )

      return rv
    },
  },
  watch: {
    isDialogVisible: {
      async handler(value) {
        if(!value) {
          this.tab = 0
        } else if (this.record) {
          await this.mapDataToFormFields(this.record[this.eventType])
          this.$nextTick(() => {
            this.updateInitialRecord()
          })
        }
      },
      immediate: true
    },
  }
}
