<template>
  <v-chip color="white"
          label
          @click="$emit('click', $event)">
    <v-icon color="primary">{{icon}}</v-icon>
    <label color="secondary" class="pr-1" style="cursor: pointer">{{text}}</label>
    <v-icon color="primary" v-if="isChecked">check_box</v-icon>
    <v-icon color="secondary" v-else>check_box_outline_blank</v-icon>
  </v-chip>
</template>

<script>
export default {
  name: 'ButtonCheckbox',
  emits: ['click'],
  props: {
    icon: String,
    text: String,
    isChecked: Boolean,
  }
}
</script>

<style scoped>

</style>
