import { mapGetters } from 'vuex'
import { getItemTypeByType } from '@/helpers/itemTypes'

export const rangeHour = {
  data() {
    return {
      startDate: '',
      startTime: '',
      endTime: '',
      endDate: '',
    }
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
    }),
  },
  methods:{
    normalizeDateTime(shouldEndTimeBeUpdated = true, endTimeDuration = null) {
      if(this.startDate && this.startTime && this.endTime && this.endDate) {
        const startDateTime = this.$moment(`${this.startDate} ${this.startTime}`, `YYYY-MM-DD ${this.timeFormat}`)
        const endDateTime = this.$moment(`${this.endDate} ${this.endTime}`, `YYYY-MM-DD ${this.timeFormat}`)

        if(!endDateTime.isValid() && !startDateTime.isValid()) return

        if (shouldEndTimeBeUpdated && !startDateTime.isBefore(endDateTime)) {
          const endDate = startDateTime.add(endTimeDuration ?? getItemTypeByType(this.eventType).defaultDuration, 'minute')
          this.endDate = endDate.format('YYYY-MM-DD')
          this.endTime = endDate.format('HH:mm')
        }
      }
    },
  },
  watch: {
    endDate(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.normalizeDateTime(false)
      }
    },
    startDate(newVal, oldVal) {
      if(oldVal !== newVal) {
        this.normalizeDateTime()
      }
    },
    startTime(newVal, oldVal) {
      if(oldVal !== newVal) {
        const diff = this.$moment(this.endTime, 'HH:mm').diff(this.$moment(oldVal, 'HH:mm'), 'minute')
        this.normalizeDateTime(true, diff)
      }
    },
    endTime(newVal, oldVal) {
      if(oldVal !== newVal) {
        this.normalizeDateTime(false)
      }
    },
  }
}
