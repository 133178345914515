<template>
  <svg width="2em" height="2em" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7790_7708)">
      <path d="M7.66797 6.99951V2.66634C7.66797 2.31272 7.80844 1.97358 8.05849 1.72353C8.30854 1.47348 8.64768 1.33301 9.0013 1.33301H13.668L18.3346 5.99967V13.333C18.3346 13.6866 18.1942 14.0258 17.9441 14.2758C17.6941 14.5259 17.3549 14.6663 17.0013 14.6663H14.502" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.668 1.33301V5.99967H18.3346" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g clip-path="url(#clip1_7790_7708)">
      <path d="M1 12.5C1 12.5 3.72727 7 8.5 7C13.2727 7 16 12.5 16 12.5C16 12.5 13.2727 18 8.5 18C3.72727 18 1 12.5 1 12.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.5 15C9.88071 15 11 13.8807 11 12.5C11 11.1193 9.88071 10 8.5 10C7.11929 10 6 11.1193 6 12.5C6 13.8807 7.11929 15 8.5 15Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>
