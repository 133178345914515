import { store } from '../store'
import moment from 'moment'

export function getDatesAndTimeAsISOString(startDate, startTime, endDate, endTime) {
  let startDay = new moment.parseZone()
  let endDay = new moment.parseZone()
  if (startDate && startTime) {
    startDay = new moment.parseZone(startDate + 'T' + startTime, 'YYYY-MM-DDT' + store.getters.TIME_FORMAT)
  } else if (startDate && !startTime) {
    startDay = new moment.parseZone(startDate, 'YYYY-MM-DD')
  }
  if (endDate && endTime) {
    endDay = new moment.parseZone(endDate + 'T' + endTime, 'YYYY-MM-DDT' + store.getters.TIME_FORMAT)
  } else if (endDate && !endTime) {
    endDay = new moment.parseZone(endDate, 'YYYY-MM-DD')
  }
  return {
    startDateTime: startDay.format('YYYY-MM-DDTHH:mm:ssZ'),
    endDateTime: endDay.format('YYYY-MM-DDTHH:mm:ssZ')
  }
}

export const eventRatings = [
  {value: 1, icon: 'mdi-star'},
  {value: 2, icon: 'mdi-star'},
  {value: 3, icon: 'mdi-star'},
  {value: 4, icon: 'mdi-star'},
  {value: 5, icon: 'mdi-star'}
]

export const flightClasses = [
  {text: 'Economy_Class', value: 'Economy_Class'},
  {text: 'Premium_Economy_Class', value: 'Premium_Economy_Class'},
  {text: 'Business_Class', value: 'Business_Class'},
  {text: 'First_Class', value: 'First_Class'}
]

export const priceLevels = [
  {value: 1, icon: '$customDollar'},
  {value: 2, icon: '$customDollar'},
  {value: 3, icon: '$customDollar'},
  {value: 4, icon: '$customDollar'},
  {value: 5, icon: '$customDollar'}
]

export const userRoles = [
  { title: 'Viewer_permission',    value: 'VIEWER',    selectable: true },
  { title: 'Commenter_permission', value: 'COMMENTER', selectable: true },
  { title: 'Editor_permission',    value: 'EDITOR',    selectable: true },
  { title: 'Owner_permission',     value: 'OWNER',     selectable: false }
]

export const socialNetworks = [
  {
    iconName: '$customInstagram',
    title: 'Instagram',
    link: 'https://www.instagram.com/trava.ai/'
  },
  {
    iconName: '$customFacebook',
    title: 'Facebook',
    link: 'https://www.facebook.com/IntelligentTravelSystems'
  },
  {
    iconName: '$customTwitter',
    title: 'Twitter',
    link: 'https://twitter.com/TravaContact'
  },
  {
    iconName: '$customLinkedin',
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/intelligent-travel-systems/about/'
  },
  {
    iconName: '$customYoutube',
    title: 'Youtube',
    link: 'https://www.youtube.com/channel/UCm9m6SD_VAwoauPnCQxO1Gw'
  },
  {
    iconName: '$customTiktok',
    title: 'TikTok',
    link: 'https://www.tiktok.com/@travadude'
  },
  {
    iconName: '$customPinterest',
    title: 'Pinterest',
    link: 'https://www.pinterest.com/IntellegentTravelSystems/?invite_code=98a032dff9f04c59ae062a44f1d10071&sender=932597172745120366'
  }
]
