<template>
  <question-wrapper
    :step="step"
    :footerText="$t('Airlines_Caption')"
  >
    <template v-slot:body>
      <v-col class="pa-0">
        <airlines-selector
          :airlinePreferences="airlines"
          @update="updateAirlinePreferences"
        />
      </v-col>
    </template>
  </question-wrapper>
</template>

<script>
import QuestionWrapper from '../QuestionWrapper.vue'
import { mapActions, mapState } from 'vuex'
import AirlinesSelector from '@/components/selector/AirlinesSelector'
import checkEquality from  'fast-deep-equal/es6'
import { defaultPreferences } from '@/store/modules/user'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'AirlineQuestion',
  components: {
    QuestionWrapper,
    AirlinesSelector,
  },
  props: {
    step: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    airlines: [],
  }),
  created() {
    this.airlines = cloneDeep(this.user.preferences?.airlineMembership) || []
  },
  computed:{
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'UPDATE_USER',
    }),
    updateAirlinePreferences(airlines) {
      this.airlines = airlines
    },
    updateProfile() {
      const isAirlinesChanged = !checkEquality(this.airlines, this.user.preferences?.airlineMembership)
      if(isAirlinesChanged) {
        const preferences = cloneDeep(this.user.preferences || defaultPreferences)
        preferences.airlineMembership = this.airlines
        this.updateUser({ preferences })
      }
    }
  },
  beforeUnmount() {
    this.updateProfile()
  }
}
</script>
