<template>
  <v-card class="my-4 my-md-16 bg-transparent" variant="flat" data-test-name="card-feature">
    <v-row
      class="pb-2 pb-sm-16 justify-space-between fill-height"
      :class="{'flex-row-reverse': getId % 2 === 0}"
      no-gutters
    >
      <v-col class="col-12 col-sm-6 col-md-6 pt-md-10 fill-height">
        <v-card-title
          data-test-name="text-title"
          class="pl-6 pt-8 pb-6 px-sm-0 px-md-0 py-md-9 font-weight-medium text-md-h3 text-h5 text-primary"
        >
          {{ getTitle }}
        </v-card-title>
        <v-card-text
          data-test-name="text-description"
          class="pl-6 px-sm-0 pa-md-0 text-textPrimaryColor font-weight-regular text-sm-h6 heightTxt text-body-2"
        >
          {{ getDescription }}
        </v-card-text>
        <v-img
          v-if="$vuetify.display.xs"
          :src="getMobileImageSrc"
          data-test-name="image"
          class="mb-4"
          width="100%"
          height="210"
        >
        </v-img>
        <v-row class="justify-center justify-sm-start" no-gutters>
          <button-primary
            class="mt-5 mt-md-12"
            :text="getButtonTitle"
            data-test-name="button"
            @click="redirectToRoute"
          />
        </v-row>
      </v-col>
      <v-col
        v-if="!$vuetify.display.xs"
        class="col-5 d-flex align-center"
      >
        <v-img cover :src="getImageSrc" data-test-name="image"/>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'

export default {
  name: 'OptionsItem',
  components: { ButtonPrimary },
  props: {
    optionItem: {
      type: Object
    },
  },
  computed: {
    getTitle() {
      return this.$t(this.optionItem.title)
    },
    getDescription() {
      return this.$t(this.optionItem.description)
    },
    getId() {
      return this.optionItem.id
    },
    getButtonTitle() {
      return this.$t(this.optionItem.buttonTitle)
    },
    getImageSrc() {
      return this.optionItem.image.src.rawUrl
    },
    getMobileImageSrc() {
      return this.optionItem.mobileImage.src.rawUrl
    }
  },
  methods: {
    redirectToRoute() {
      this.$router.push({ name: this.optionItem.route })
    }
  }
}
</script>

<style lang="scss" scoped>
  .heightTxt {
    line-height: 140%;
  }
</style>
