<template>
    <v-row justify="space-around">
      <v-col cols="12">
        <v-card
          :loading="loading"
          class="mx-auto my-12"
          width="100%"
          max-width="580"
          height="100%"
          max-height="400">

          <v-progress-linear
            height="3"
            indeterminate></v-progress-linear>

          <div v-if="!error">
            <v-card-title>Preparing your itinerary...</v-card-title>
          </div>
          <div v-if="error">
          <v-card-title class="text-red">Oops...</v-card-title>
          <v-card-text class="text-subtitle-1">
            Sorry... Seems that something went wrong on our side.
            You can still create your itinerary by clicking below.
          </v-card-text>
          <v-card-actions>
            <v-btn variant="outlined" rounded color="primary" class="mx-auto" @click="goToGenerateItinerary">Create Itinerary Here</v-btn>
          </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import commonAPIs from '../helpers/itineraryServices/commonAPIs'
import uiUtils from '../helpers/uiUtils'
import {initUser} from '../store/modules/user'
import itineraryName from '../helpers/itineraryServices/itineraryName'
import { RouteNames } from '@/helpers/routerUtils'
import { EventTypes } from '@/helpers/itemTypes'
import { mapActions, mapGetters } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'ExternalCreateItinerary',
  data() {
    return {
      loading: true,
      error: false,
      tripRequest: '',
      route: useRoute(),
    }
  },
  mounted() {
    this.loadTripRequest()
  },
  computed: {
    ...mapGetters({
      isNewUser: 'IS_NEW',
      getUser: 'USER',
      getTripRequest: 'TRIP_REQUEST',
    }),
  },
  methods: {
    ...mapActions({
      loadTripRequest: 'LOAD_TRIP_REQUEST',
      setUser: 'SET_USER',
      updateTripRequest: 'UPDATE_TRIP_REQUEST',
      generateItinerary: 'GENERATE_ITINERARY',
    }),
    goToGenerateItinerary() {
      this.$router.push({name: RouteNames.GENERATE_ITINERARY})
    },
    async loadTripRequest() {
      let userIdParam = this.route.params.userID
      let tripRequestIdParam = this.route.params.tripRequestID

      this.loadTripRequest({
        tripRequestID: tripRequestIdParam,
        userID: userIdParam
      }).then(async (response) => {
        if (response && response.status === 200) {
          this.tripRequest = this.getTripRequest
          await this.assignUser()
          await this.createItinerary()
        } else {
          this.error = true
          this.loading = false
          throw new Error('Cannot load trip request')
        }
      })
    },
    /**
      as discussed with the business, the logic will be as such:
        if current user is different from the one in trip request
          - change the ownership to the current user
        if there is no current user in the browser session
          - take user id from the trip request
    */
    async assignUser() {
      if (this.isNewUser) {
        //take user id from trip request when there
        //is no current user in the browser session
        let user = {...initUser, id: this.tripRequest.userID }
        await this.setUser(user)
        return
      }

      let currentUserId = this.getUser?.id
      if (currentUserId !== this.tripRequest.userID) {
        const tripRequest = JSON.parse(JSON.stringify(this.getTripRequest))
        tripRequest.userID = currentUserId

        await this.updateTripRequest(tripRequest)
      }
    },

    async createItinerary() {
      uiUtils.loadingDialog(EventTypes.FLIGHT)

      const itinerary = await this.generateItinerary({
        userID:         this.getUser?.id,
        tripRequestID:  this.tripRequest.tripRequestID,
        itineraryName:  await itineraryName.compileItineraryName(this.tripRequest.routes)
      })
      uiUtils.loadingDialog()

      if(itinerary){
        this.$router.push({
          name: RouteNames.ITINERARY,
          params: {id: itinerary.header.itineraryID}
        })

        //prefill newly created itinerary
        commonAPIs.autoFillItinerary({
          options: this.tripRequest.options,
          routes: this.tripRequest.routes,
        })
      }
    }
  }
}
</script>

