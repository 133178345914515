<template>
  <v-row class="mb-4">
    <v-col cols="6" sm="3" class="py-0 py-sm-3">
      <v-text-field
        name="departureStation"
        v-model="fromPlace"
        :label="$t('Place_from')"
        :readonly="isReadOnly"
        :min="0"
        class="pa-0 ma-0"
        bg-color="#fff"
        base-color="primary"
        color="primary"
        variant="outlined"
        hide-details="auto"
        @update:model-value="onValueChanged"
        :rules="[rules.validateAddress]"
      ></v-text-field>
    </v-col>
    <v-col cols="6" sm="3" class="py-0 py-sm-3">
      <v-text-field
        v-model="fromAddress"
        :label="$t('Address')"
        :readonly="isReadOnly"
        :min="0"
        class="pa-0 ma-0"
        bg-color="#fff"
        base-color="primary"
        color="primary"
        variant="outlined"
        hide-details="auto"
        @update:model-value="onValueChanged"
        :rules="[rules.validateAddress]"
      >
        <template v-slot:prepend-inner>
          <v-icon size="large" class="pr-2">$customHouse</v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="6" sm="3">
      <v-text-field
        name="arrivalStation"
        v-model="toPlace"
        :label="$t('Place_to')"
        :readonly="isReadOnly"
        :min="0"
        class="pa-0 ma-0"
        bg-color="#fff"
        base-color="primary"
        color="primary"
        variant="outlined"
        hide-details="auto"
        @update:model-value="onValueChanged"
        :rules="[rules.validateAddress]"
      ></v-text-field>
    </v-col>
    <v-col cols="6" sm="3">
      <v-text-field
        v-model="toAddress"
        :label="$t('Address')"
        :readonly="isReadOnly"
        :min="0"
        class="pa-0 ma-0"
        bg-color="#fff"
        base-color="primary"
        color="primary"
        variant="outlined"
        hide-details="auto"
        @update:model-value="onValueChanged"
        :rules="[rules.validateAddress]"
      >
        <template v-slot:prepend-inner>
          <v-icon class="pr-2">$customHouse</v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {defineComponent} from 'vue'
import {eventFormMainStructure} from '@/mixins/EventFormMixin'

export default defineComponent({
  name: 'PlaceAddressesRow',
  mixins: [eventFormMainStructure],
  emits: [
    'update:model-value',
  ],
  props: {
    value: {
      fromPlace: String,
      fromAddress: String,
      toPlace: String,
      toAddress: String,
    },
  },
  data() {
    return {
      fromPlace: this.value.fromPlace,
      fromAddress: this.value.fromAddress,
      toPlace: this.value.toPlace,
      toAddress: this.value.toAddress,

    }
  },
  methods: {
    onValueChanged() {
      this.$emit('update:model-value', {
        fromPlace: this.fromPlace,
        fromAddress: this.fromAddress,
        toPlace: this.toPlace,
        toAddress: this.toAddress,
      })
    }
  },
})
</script>
