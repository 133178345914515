<template>
  <v-card elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 pt-4 tabs-container">
      <div class="text-primary font-weight-medium text-uppercase my-4 ml-4">
        {{ $t('Tab_Details') }}
      </div>
    </v-row>
    <v-card-text class="event-content">
      <v-row class="mx-0 mt-10 pt-1 pt-sm-4 my-2">
        <v-col cols="12" class="mt-2 mb-2 mt-sm-0 mb-0 pb-0 px-0">
          <v-text-field
            :readonly="isReadOnly"
            :model-value="title"
            @update:model-value="updateTitle($event)"
            :label="$t('Title')"
            :class="{ 'no-pointer-events': isReadOnly }"
            variant="outlined"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            hide-details
            maxLength="200"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" sm="6" class="mb-0 pb-0 px-0 pr-sm-2">
          <place-input
            :value="location?.placeID"
            :label="$t('Location') + '*'"
            :show-suggestions="true"
            :readonly="isReadOnly"
            min-width="320"
            name="placeInputLocation"
            variant="outlined"
            @updateLocation="updateLocation"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="!$vuetify.display.xs" class="px-0 pl-sm-2 mb-0">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" sm="6" class="px-0 pr-sm-2 mb-0">
          <v-text-field
            name="departureStation"
            v-model="fromPlace"
            :label="$t('Place_from')"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="px-0 pl-sm-2 mb-0">
          <v-text-field
            v-model="fromAddress"
            :label="$t('Address')"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          >
            <template v-slot:prepend-inner>
              <v-icon size="large" class="pr-2">$customHouse</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pl-0 pt-0 ml-n2">
          <v-checkbox
            v-model="isRoundTrip"
            :readonly="isReadOnly"
            true-icon="mdi-checkbox-intermediate"
            hide-details
            :label="$t('Round_trip_title')"
            class="text-body-2"
            color="primary"
          />
        </v-col>
      </v-row>

      <v-row v-if="!isRoundTrip" class="ma-0">
        <v-col cols="12" sm="6" class="px-0 pr-sm-2 mb-0">
          <v-text-field
            name="arrivalStation"
            v-model="toPlace"
            :label="$t('Place_to')"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="px-0 pl-sm-2 mb-0">
          <v-text-field
            v-model="toAddress"
            :label="$t('Address')"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          >
            <template v-slot:prepend-inner>
              <v-icon class="pr-2">$customHouse</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="6" sm="3" class="px-0 pr-sm-2 mb-0">
          <date-picker
            :model-value="startDate"
            :readonly="isReadOnly"
            variant="outlined"
            :label="`${$t('Start_date')}*`"
            @update:model-value="updateStartDate"
            min-width="320"
            name="StartDate"
          />
        </v-col>
        <v-col cols="6" sm="3" class="px-0 pl-2 pl-sm-2 pr-sm-2 mb-0">
          <time-picker
            :model-value="startTime"
            :label="`${$t('Start_time')}*`"
            :readonly="isReadOnly"
            @update:model-value="updateStartTime"
          />
        </v-col>
        <v-col cols="6" sm="3" class="px-0 pr-sm-2 pl-sm-2 mb-0">
          <date-picker
            :model-value="endDate"
            :min-date="minEndDate"
            :readonly="isReadOnly"
            :label="`${$t('End_date')}*`"
            @update:model-value="updateEndDate"
            min-width="320"
            name="EndDate"
          />
        </v-col>
        <v-col cols="6" sm="3" class="px-0 pl-2 pl-sm-2 mb-0">
          <time-picker
            :model-value="endTime"
            :label="`${$t('End_time')}*`"
            :readonly="isReadOnly"
            :rules="[rules.afterStartTime]"
            @update:model-value="updateEndTime"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="6" sm="4" class="px-0 mb-0 pr-sm-2">
          <v-select
            prepend-inner-icon="$customCurrency"
            v-model="totalPrice.currency"
            @update:model-value="updateCurrency"
            :label="$t('Currency')"
            :readonly="isReadOnly"
            :items="currencyList"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="2" class="px-0 pl-2 px-sm-2 mb-0">
          <v-currency-field
            :model-value="Number(totalPrice?.amount)"
            @update="updateAmount"
            variant="outlined"
            class="pa-0 ma-0"
            color="primary"
            :label="`${$t('Total_price')}`"
            :readonly="isReadOnly"
          />
        </v-col>
        <v-col v-if="$vuetify.display.xs" cols="6" class="px-0 mt-n6">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
        <v-col cols="6" class="px-0 mt-sm-0 mt-n6">
          <event-booking
            :isBooked="isBooked"
            :readonly="isReadOnly"
            @updateBooked="isBooked = !isBooked"
            class="d-flex justify-end"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 mb-14">
        <v-col cols="12" class="px-0 px-sm-0">
          <v-textarea
            v-model="notes"
            :label="$t('Notes')"
            :auto-grow="false"
            :readonly="isReadOnly"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            rows="3"
            variant="outlined"
            no-resize
            hide-details="auto"
            :rules="[rules.validNotes]"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <event-popup-buttons
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
  </v-card>
</template>

<script>
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { convertAmountToServerFormat, convertAmountToUIFormat, getDatesAndTimeAsISOString } from '@/helpers'
import VCurrencyField from '@/components/VCurrencyField'
import { checkAddressInput} from '@/helpers/inputValidators'

export default {
  name: 'AddExploreForm',
  mixins: [eventFormMainStructure],
  components: {
    VCurrencyField,
  },
  emits: ['updateTitle', 'cancel', 'save'],
  data() {
    return {
      fromAddress: '',
      toAddress: '',
      fromPlace: '',
      toPlace: '',
      isRoundTrip: false,
    }
  },
  computed: {
    newRecord() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      const { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let toPlace = this.toPlace, toAddress = this.toAddress
      if (this.isRoundTrip) {
        toPlace = this.fromPlace
        toAddress = this.fromAddress
      }

      let rv = {
        startDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(startDateTime)),
        endDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(endDateTime)),
        location: this.location,
        travelers: this.travelers,
        [this.eventType]: {
          ...(this.title) && { title: this.title },
          ...(this.notes) && { notes: this.notes },
          ...(this.isBooked) && { isBooked: this.isBooked },
          ...(convertAmountToServerFormat(this.totalPrice?.amount))
            && { totalPrice: {
              currency: this.totalPrice?.currency,
              amount: convertAmountToServerFormat(this.totalPrice?.amount)
            },
            },
          ...(this.fromAddress) && { fromAddress: this.fromAddress },
          ...(this.fromPlace) && { fromPlace: this.fromPlace },
          ...(toAddress) && { toAddress: toAddress },
          ...(toPlace) && { toPlace: toPlace },
        }
      }
      return rv
    },
    isEventValid() {
      return checkAddressInput(this.fromAddress)
      && checkAddressInput(this.toAddress)
      && checkAddressInput(this.fromPlace)
      && checkAddressInput(this.toPlace)
    }
  },
  methods: {
    mapDataToFormFields(eventData) {
      this.$emit('updateTitle', eventData?.title)
      this.isBooked = Boolean(eventData?.isBooked)
      this.notes = eventData?.notes
      this.totalPrice.amount = convertAmountToUIFormat(eventData?.totalPrice?.amount)
      this.totalPrice.currency = eventData?.totalPrice?.currency || this.userCurrency || 'USD'

      this.fromAddress = eventData?.fromAddress
      this.toAddress = eventData?.toAddress
      this.fromPlace = eventData?.fromPlace
      this.toPlace = eventData?.toPlace

      this.isRoundTrip = (this.fromAddress === this.toAddress && this.fromPlace === this.toPlace)
    },
  },
  watch: {
    startDate(newVal, oldVal) {
      if(newVal && !oldVal && !this.endDate) {
        this.endDate = newVal
      }
    },
    location: {
      async handler() {
        await this.$refs.form?.validate()
      },
      immediate: true,
    },
    isDialogVisible: {
      handler(visible) {
        if(visible && this.record) {
          this.mapDataToFormFields(this.record[this.eventType])
        }
      },
      immediate: true
    },
  }
}
</script>
<style scoped lang="scss">
.passengers-selector {
  width: 100%;
}
</style>
