<template>
  <section class="mt-6">
    <v-row density="compact" class="mx-0 mx-sm-2 pt-1 pt-sm-4">
      <v-col cols="12" sm="4" class="py-0 px-0 px-sm-3">
        <place-input
          :value="payload.fromPlaceID"
          @update="updatePayload('fromPlaceID', $event)"
          min-width="450"
          :placeholder="$t('Origin')"
          :label="$t('Origin') + '*'"
          :isValid="isLocationsDifferent"
          name="PlaceInputFromAirport"
          content-class="PlaceInputFromAirport"
          base-color="primary"
          color="primary"
          variant="outlined"
        />
      </v-col>

      <v-col cols="12" sm="4" class="py-0 px-0 px-sm-3">
        <place-input
          :value="payload.toPlaceID"
          @update="updatePayload('toPlaceID', $event)"
          min-width="450"
          :placeholder="$t('Destination')"
          :label="$t('Destination') + '*'"
          :isValid="isLocationsDifferent"
          name="PlaceInputToAirport"
          content-class="PlaceInputToAirport"
          base-color="primary"
          color="primary"
          variant="outlined"
        />
      </v-col>

      <v-col cols="12" sm="4" class="pt-0 px-0 px-sm-3">
        <passengers-selector
          :value="payload.travelers"
          @input="updatePayload('travelers', $event)"
          class="w-100"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="totalTravelersNumber"
              :label="`${$t('Travelers')}*`"
              class="pa-0 ma-0 trigger-change-travelers activator"
              variant="outlined"
              bg-color="#fff"
              base-color="primary"
              color="primary"
              readonly
              hide-details
              prepend-inner-icon="$customTravelers"
              v-bind="props"
            />
          </template>
        </passengers-selector>
      </v-col>
    </v-row>

    <v-row class="mx-0 mx-sm-2 mt-0 pb-4" density="compact">
      <v-col cols="12" sm="6" class="px-0 px-sm-3 pb-1">
        <date-picker
          :model-value="payload.departureDate"
          @update:model-value="updatePayload('departureDate', $event)"
          :min-date="minDepartureDate"
          :label="$t('Departure_date') + '*'"
          min-width="320"
          variant="outlined"
        />
      </v-col>
      <v-col cols="12" sm="6" class="px-0 px-sm-3 pb-1">
        <time-picker
          :model-value="payload.departureTime"
          @update:model-value="updatePayload('departureTime', $event)"
          :label="$t('Departure_time') + '*'"
          min-width="320"
          variant="outlined"
        />
      </v-col>
    </v-row>

  </section>
</template>

<script>
import DatePicker from '@/components/DatePicker'
import TimePicker from '@/components/TimePicker'
import PlaceInput from '@/components/PlaceInput'
import PassengersSelector from '@/components/popups/PassengersSelector'
import { mapGetters } from 'vuex'
import transportation from '@/helpers/itineraryServices/transportation'

export default {
  name: 'FlightSearchForm',
  emits: ['updateSearchPayload', 'validateSearchPayload'],
  components: {
    DatePicker,
    TimePicker,
    PassengersSelector,
    PlaceInput,
  },
  data() {
    return {
      payload: {
        fromPlaceID: '',
        toPlaceID: '',
        travelers: { adults: 0, children: 0 },
        departureDate: '',
        departureTime: '',
      }
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  async created() {
    const flightDetails = await transportation.parseEventData(this.item?.flight)
    this.updatePayload('fromPlaceID', flightDetails?.departureLocation?.placeID)
    this.updatePayload('toPlaceID', flightDetails?.arrivalLocation?.placeID)

    const dateTime = flightDetails?.segments[0]?.departure?.at ? this.$moment(flightDetails?.segments[0]?.departure?.at) : this.$moment(new Date())
    this.updatePayload('departureDate', dateTime.format('YYYY-MM-DD'))
    this.updatePayload('departureTime', dateTime.format('HH:mm'))

    this.updatePayload('travelers', { adults: Number(this.item?.travelers?.adults || 0), children: Number(this.item?.travelers?.children || 0) })

    if (this.payload?.travelers?.adults === 0) {
      //read from the trip request when creating a new event
      this.payload.travelers = this.tripRequest?.travelers
      this.updatePayload('travelers', this.tripRequest?.travelers)
    }
  },
  computed: {
    ...mapGetters({
      tripRequest: 'TRIP_REQUEST',
    }),
    isLocationsDifferent() {
      return this.payload.fromPlaceID && this.payload.toPlaceID && this.payload.toPlaceID !== this.payload.fromPlaceID
    },
    totalTravelersNumber() {
      return (this.payload?.travelers?.adults || 0) + (this.payload?.travelers?.children || 0)
    },
    currentDate() {
      return this.$moment(Date.now()).format('YYYY-MM-DD')
    },
    minDepartureDate() {
      let date = this.$moment.parseZone(this.currentDate).format('YYYY-MM-DD HH:mm')
      return date
    },
    isValidSearchPayload() {
      return !!this.payload?.departureDate
        && !!this.payload?.fromPlaceID
        && !!this.payload?.toPlaceID
        && !!this.payload?.travelers?.adults
    },
  },
  methods: {
    updatePayload(fieldName, value) {
      this.payload[fieldName] = value

      this.$emit('updateSearchPayload', this.payload)
      this.$emit('validateSearchPayload', this.isValidSearchPayload)
    }
  },
}
</script>
