<template>
  <div @click="toggleDropdown">
    <v-autocomplete
      prepend-inner-icon="$customHotelOutline"
      :model-value="storedItemsList"
      @update:model-value="handleHotelSelection"
      :search="searchString"
      @update:search="searchHotels"
      :loading="loading"
      :items="searchResultsList"
      :label="$t('Preferred_Hotel_Chain')"
      :no-data-text="noDataText"
      multiple
      chips
      hide-details
      hide-selected
      variant="outlined"
      color="primary"
      base-color="primary"
      bg-color="#fff"
      ref="autocomplete"
      data-test-name="select-hotels"
      @click:append-inner.stop="toggleDropdown"
      @blur="$refs.autocomplete.isMenuActive = false"
    >
      <template #chip="{ item }">
        <v-chip
          :key="item.props.value"
          closable
          close-icon="mdi-close"
          data-test-name="chip"
          @click.close="removeSelection(item.value)"
        >
          <span class="text-caption" data-test-name="text">{{ item.title }}</span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'HotelsSelector',
  emits: ['update'],
  data() {
    return {
      loading: false,
      searchResults: [],
      storedItems: [],
      sessionChainCodes: [],
      searchString: '',
    }
  },
  props: {
    hotelPreferences: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    /** chain codes are returned in array like ['AB', 'CD', 'YZ']
     * hotelPreferences are stored as [{chainCode: 'AB'}, {chainCode:'XZ'}]
     * sessionChainCodes are stored as ['AB', 'CD']
    */

    storedChainCodes() {
      const rv = []
      this.sessionChainCodes.forEach(item => {
        if (typeof item === 'string') { //this check is required to make sure there is no garbage in
          rv.push(item)
        }
      })
      return rv
    },
    noDataText(){
      return this.$t('Start_Typing_To_See_Resuls')
    },
    searchResultsList() {
      return this.itemsToList(this.searchResults)
    },
    storedItemsList() {
      return this.itemsToList(this.storedItems)
    }
  },
  methods: {
    ...mapActions({
      lookupHotelByChainCodes: 'LOOKUP_HOTEL_BY_CHAIN_CODES',
    }),
    toggleDropdown(event) {
      const tagName = event.target.tagName?.toLowerCase()
      const autocomplete = this.$refs.autocomplete

      if (tagName !== 'i'){
        autocomplete.isMenuActive = true
        return
      }

      if (autocomplete.isMenuActive) {
        autocomplete.isMenuActive = false
        autocomplete.blur()
      } else {
        autocomplete.isMenuActive = true
      }
    },
    itemsToList(items) {
      const rv = []
      items.forEach(item => {
        rv.push({ title: item.chainName, value: item.chainCode })
      })
      return rv
    },
    handleHotelSelection(val) {
      this.sessionChainCodes = val
      this.searchString = ''
      this.$emit('update', this.convertPrimitivesIntoPreferences(this.sessionChainCodes))
    },
    removeSelection(val) {
      this.sessionChainCodes = this.sessionChainCodes.filter(item => item !== val)
      this.$emit('update', this.convertPrimitivesIntoPreferences(this.sessionChainCodes))
    },
    async searchHotels(query) {
      this.searchString = query
      if (this.loading || !query) return
      this.loading = true
      try {
        this.searchResults = []
        const response = await axios.get(process.env.VUE_APP_HOTELS_SEARCH_URL, {
          from: 0,
          params: {
            query:query,
            size: 35
          }
        })
        if (response.status === 200) {
          this.searchResults = response.data.hotelchains
        }
      }
      finally {
        this.loading = false
      }
    },
    async lookupHotelsByCodes() {
      this.storedItems = await this.lookupHotelByChainCodes(this.storedChainCodes)
    },
    /**
     * hotelPreferences are stored as [{chainCode: 'AB'}, {chainCode:'CD'}]
     * returned as ['AB', 'CD']
    */
    convertPreferencesIntoPrimitives(arr) {
      const rv = []
      arr.forEach(item => rv.push(item.chainCode))
      return rv
    },
    /**
     * sessionChainCodes are stored as ['AB', 'CD']
     * returned as [{chainCode: 'AB'}, {chainCode:'CD'}]
    */
    convertPrimitivesIntoPreferences(arr) {
      const rv = []
      arr.forEach(item => rv.push({ chainCode: item }))
      return rv
    }
  },
  watch: {
    sessionChainCodes: {
      handler() {
        this.lookupHotelsByCodes()
      },
      immediate: true
    },
    hotelPreferences: {
      handler() {
        this.sessionChainCodes = this.convertPreferencesIntoPrimitives(this.hotelPreferences)
      },
      immediate: true
    },
  },
}
</script>
