import { createStore } from 'vuex'
import user from './modules/user'
import tripRequest from './modules/tripRequest'
import itinerary from './modules/itinerary'
import comments from './modules/comments'
import lodging from './modules/lodging'
import blog from './modules/blog'
import app from './modules/app'
import itineraryTemplate from './modules/itineraryTemplate'
import createPersistedState from 'vuex-plugin-persistedstate'
import { handle403ErrorStatus } from './helpers/itineraryHelper'
import { EventTypes } from '@/helpers/itemTypes'

let loadingDialogTimeout

export const getters = {
  LOADING: state => state.loading,
  OVERLAY: state => state.overlay,
  IS_MOBILE: state => state.isMobile,
  IS_IPAD: state => state.isIpad,
  EVENT_FILTER: state => state.eventFilter,
  BOTTOM_SHEET: state => state.bottomSheet,
  LOADING_DIALOG: state => state.loadingDialog,
  MODAL_WINDOW_NAME: state => state.modalWidowName,
}

export const mutations = {
  LOADING: (state, payload) => {
    state.loading = payload
  },
  OVERLAY: (state, payload) => {
    state.overlay = payload
  },
  IS_MOBILE: (state, payload) => {
    state.isMobile = payload
  },
  IS_IPAD: (state, payload) => {
    state.isIpad = payload
  },
  BOTTOM_SHEET: (state, payload) => {
    state.bottomSheet = payload
  },
  LOADING_DIALOG: (state, payload) => {
    state.loadingDialog = payload
  },
  EVENT_FILTER: (state, payload) => {
    state.eventFilter[payload.type] = payload.value
  },
  MODAL_WINDOW_NAME: (state, payload) => {
    state.modalWidowName = payload
  },
  //RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
}

export const actions = {
  LOADING: async ({ commit }, loading) => {
    commit('LOADING', loading)
  },
  OVERLAY: async ({ commit }, overlay) => {
    commit('OVERLAY', overlay)
  },
  IS_MOBILE: async ({ commit }, loading) => {
    commit('IS_MOBILE', loading)
  },
  IS_IPAD: async ({ commit }, loading) => {
    commit('IS_IPAD', loading)
  },
  BOTTOM_SHEET: async ({ commit }, value) => {
    commit('BOTTOM_SHEET', value)
  },
  LOADING_DIALOG: async ({ commit }, value) => {
    if (value?.visible) {
      if (loadingDialogTimeout) {
        clearTimeout(loadingDialogTimeout)
      }
      loadingDialogTimeout = setTimeout(() => { commit('LOADING_DIALOG', value) }, 1) // delay displaying overlay
    } else {
      clearTimeout( loadingDialogTimeout )
      commit('LOADING_DIALOG', value)
    }
  },
  TOGGLE_EVENT_FILTER: async ({ commit }, payload) => {
    commit('EVENT_FILTER', payload)
  },
  MODAL_WINDOW_NAME: async ({ commit }, payload) => {
    commit('MODAL_WINDOW_NAME', payload)
  },
}

export const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    itinerary,
    comments,
    tripRequest,
    lodging,
    user,
    app,
    blog,
    itineraryTemplate
  },
  state: {
    loading: false,
    overlay: false,
    isMobile: false,
    isIpad: false,
    modalWidowName: null,
    bottomSheet: {
      visible: false,
      showProgressIndicator: true,
      message: ''},
    loadingDialog: {
      visible: false,
      type: '',
      icon: '',
      color: ''
    },
    eventFilter: {
      [EventTypes.ATTRACTION]:   true,
      [EventTypes.FERRY]:        true,
      [EventTypes.BUS]:          true,
      [EventTypes.CAR_RENTAL]:   true,
      [EventTypes.DRIVE]:        true,
      [EventTypes.CRUISE]:       true,
      [EventTypes.CUSTOM_EVENT]: true,
      [EventTypes.EXERCISE]:     true,
      [EventTypes.EXPLORE]:      true,
      [EventTypes.FLIGHT]:       true,
      [EventTypes.INSURANCE]:    true,
      [EventTypes.LODGING]:      true,
      [EventTypes.RELAX]:        true,
      [EventTypes.RESTAURANT]:   true,
      [EventTypes.SHOPPING]:     true,
      [EventTypes.SHOW]:         true,
      [EventTypes.TOUR]:         true,
      [EventTypes.TRAIN]:        true,
      [EventTypes.TRANSFER]:     true,
    },
  },
  getters,
  mutations,
  actions,
  plugins: [
    createPersistedState(),
    store =>
      store.subscribeAction({
        error: (action, state, error) => {
          handle403ErrorStatus(action, store, error)
        }
      })
  ]
})
