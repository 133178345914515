<template>
  <v-expansion-panel>
    <itinerary-header
      :itemID="itemID"
      :title="title"
      :totalPrice="totalPrice"
      :startDTM="startDTM"
      :endDTM="endDTM"
      :duration="duration.total"
      :icon-color="itemColor(record)"
      :icon="itemIcon(record)"
      :eventType="getEventType(record)"
      :overnightMessage="overnightMessage(startDTM, endDTM)"
      :directionsLink="directionsLink"
      :record="record"
    />
  </v-expansion-panel>
</template>

<script>
import ItineraryHeader from '@/components/itinerary-trip-rows/ItineraryHeader'
import itineraryService from '@/helpers/itineraryService'
import { getFullName } from '@/helpers/itineraryServices/nameFormatter'

export default {
  name: 'ItineraryFlightRow',
  components: {
    ItineraryHeader,
  },
  data() {
    return {
      title: 'Flight',
    }
  },
  props: {
    record: Object,
    directionsLink: String,
  },
  computed: {
    itemDetails() {
      return this.record?.flight
    },
    itemID() {
      return this.record?.header?.itemID
    },
    segments() {
      return this.itemDetails?.segments
    },
    departure() {
      return this.segments[0]?.departure
    },
    arrival() {
      return this.segments[this.segments?.length - 1]?.arrival
    },
    duration() {
      return itineraryService.calcSegmentsDuration(this.itemDetails?.segments)
    },
    startDTM() {
      //return this.record.header.startDateTime
      return this.departure?.at
    },
    endDTM() {
      //return this.record.header.endDateTime
      return this.arrival?.at
    },
    totalPrice() {
      return this.itemDetails?.totalPrice
    }
  },
  methods: {
    async compileTitle() {
      try {
        this.title = await getFullName(this.record)
      } catch(ignore) { /**/ }
    },
    itemColor: itineraryService.itemColor,
    getEventType: itineraryService.getEventType,
    itemIcon: itineraryService.itemIcon,
    overnightMessage: itineraryService.overnightMessage
  },
  mounted() {
    this.compileTitle()
  },
  watch: {
    record: {
      handler() {
        this.compileTitle()
      },
      deep: true
    }
  }
}
</script>
