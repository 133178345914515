<template>
  <div class="page-wrapper d-flex align-center" data-test-name="page-generate-itinerary">
    <v-container class="mb-16">
      <h1
        class="text-center text-h3 text-sm-h2 font-weight-regular my-5 mt-sm-0 mb-sm-10"
        data-test-name="text-title"
      >
        {{ $t('Let_us') }}
        <p class="text-primary font-weight-bold" data-test-name="text-subtitle">
          {{ $t('Create_plan') }}
        </p>
      </h1>
      <generate-itinerary-form
        :name="route.name"
        :isAllowAirportsInSearch="false"
        @onCreate="createItinerary"
      />
    </v-container>
  </div>
</template>

<script>
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import uiUtils from '@/helpers/uiUtils'
import itineraryName from '@/helpers/itineraryServices/itineraryName'
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import GenerateItineraryForm from '@/components/GenerateItineraryForm'
import cloneDeep from 'lodash.clonedeep'
import { defaultPreferences } from '@/store/modules/user'
import { EventTypes } from '@/helpers/itemTypes'
import { useRoute } from 'vue-router'

export default {
  name: 'generate-itinearary',
  components: {
    GenerateItineraryForm,
  },
  data() {
    return {
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isUserAnonymous: 'IS_ANONYMOUS',
      isUserNew: 'IS_NEW'
    }),
  },
  methods: {
    ...mapActions({
      showNotification: 'PUSH_APP_NOTIFICATION',
      addNewUser: 'ADD_NEW_USER',
      updateUser: 'UPDATE_USER',
      createTripRequest: 'CREATE_TRIP_REQUEST',
      generateItinerary: 'GENERATE_ITINERARY',
      doResetTripRequest: 'RESET_TRIP_REQUEST',
    }),
    isEmptyUserPreferences() {
      return !this.user.preferences
    },
    async createItinerary(payload) {
      // without it spinner with flights will not be visible after login and automatically triggering itinerary creation
      uiUtils.loadingDialog()
      if (this.isUserAnonymous && this.isUserNew) {
        //need to create new user locally
        await this.addNewUser({ user: this.user })
      }
      if(!this.route.query.completePlannerAssistant && this.isEmptyUserPreferences()) {
        this.$router.push({name: RouteNames.PLANNER_ASSISTANT, query: { type: RouteNames.GENERATE_ITINERARY }})
        return
      }
      uiUtils.loadingDialog(EventTypes.FLIGHT)
      const tripRequestResponse = await this.createTripRequest(payload)
      if(tripRequestResponse){
        const tripRequestData = tripRequestResponse.data

        const itinerary = await this.generateItinerary({
          userID: this.user.id,
          tripRequestID: tripRequestData.tripRequestID,
          itineraryName: await itineraryName.compileItineraryName(payload.routes)
        })
        uiUtils.loadingDialog()

        if (itinerary) {
          //prefill newly created itinerary
          await commonAPIs.autoFillItinerary({
            options: payload.options,
            routes: payload.routes,
          })

          await this.$router.push({
            name: RouteNames.ITINERARY,
            params: {id: itinerary.header.itineraryID, view: this.user.settings.view}
          })

          if(!this.user.settings.homePlaceID) {
            //store departure as default
            const settings = cloneDeep({...this.user.settings})
            settings.homePlaceID = payload.routes[0].fromPlaceID
            // planner assistant should be visible for user only once
            // we should save at least empty preferences object in DB to hide planner assistant forever
            await this.updateUser({ settings, preferences: this.user.preferences || defaultPreferences })
          }

          this.doResetTripRequest()
        }
      } else {
        uiUtils.loadingDialog()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  background-image: url('../../public/assets/images/generate-for-me-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  //74px -is height of header
  min-height: calc(100vh - 74px);
  height: 100%;
}

@media (min-width: 960px) {
  .container {
    min-width: 960px;
  }
}
</style>
