import uiUtils from '@/helpers/uiUtils'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { EventTypes } from '@/helpers'
import { store } from '@/store'
import moment from 'moment'

export default {
  /**
    size: Number of results expected
    travelers: {
        adults: Number,
        children: NUmber
      }
   */

  //TODO when backend is implemented -- replace with the actual search API call
  async search({ size, travelers }) {
    let rv = []
    const { header: { startDate, endDate, countriesOfTravel } } = store.getters.CURRENT_ITINERARY
    try {
      for (let i=0; i<size; i++) {
        rv.push({
          insuringCompanyName: 'Trava Travel Insurance Co -- TBD',
          countriesOfTravel,
          startDate: commonAPIs.momentDateTimeToStruct(moment.parseZone(startDate)),
          endDate: commonAPIs.momentDateTimeToStruct(moment.parseZone(endDate)),
          travelers,
          isBooked: false,
          totalPrice: {
            amount: 0,
            currency: store.getters.USER.settings.currency
          }
        })
      }
    } catch (error) {
      rv = []
    }
    return rv
  },

  /**
    autoFill will fetch and add trip protection to the itinerary provided:
   */
  async autoFill(payload) {
    const { itineraryID, userID, ...initialData } = payload
    uiUtils.loadingDialog(EventTypes.INSURANCE)

    let tripProtection = await this.search({
      size: 1,
      ...initialData
    })

    if (tripProtection && tripProtection.length > 0) {
      await commonAPIs.addInsurance(itineraryID, userID, tripProtection[0])
    }

    uiUtils.loadingDialog()
  }
}


