<template>
  <section class="d-flex align-center" data-test-name="content-collection-sorting">
    <v-btn
      color="primary"
      variant="tonal"
      class="mr-4"
      data-test-name="button-toggle-sorting-order"
      :icon="descendingOrder ? 'mdi-sort-descending' : 'mdi-sort-ascending'"
      @click="changeOrder"
    />
    <v-select
      :model-value="value"
      :items="sortingOptions"
      :placeholder="$t('Sorting')"
      item-title="title"
      item-value="id"
      class="sorting-dropdown"
      variant="outlined"
      color="primary"
      base-color="primary"
      bg-color="#fff"
      hide-details
      data-test-name="select-sorting-parameter"
      @update:model-value="value=$event"
    />
  </section>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'SortingOptions',
  emits:['update:model-value', 'order-has-changed'],
  props: {
    modelValue: {
      type: [String, Number],
    },
    sortingOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  /**
   * Setup function of component
     - sets value property to store selected value from dropdown
     - sets descendingOrder to store sort order state
     - defines changeOrder function to toggle descendingOrder boolean
   **/
  setup(props, {emit}) {
    const descendingOrder = ref(false)
    const value = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:model-value', value)
      }
    })

    const changeOrder = () => {
      descendingOrder.value = !descendingOrder.value
      emit('order-has-changed', descendingOrder.value)
    }
    return {
      value,
      descendingOrder,
      changeOrder
    }
  },
}
</script>
