<template>
  <v-row no-gutters class="pt-sm-4 pt-0 px-4" data-test-name="content-contact-us-text">
    <v-col cols="12" class="pa-2 pa-md-0">
      <h3
        class="mb-6 mb-md-10 text-primary font-weight-medium text-h5 text-sm-h3"
        data-test-name="title-call-to-action"
      >
        {{ $t('Please_contact_us')}}<br>{{$t('If_you_have_questions') }}
      </h3>
      <div :class="{'textBlock': !$vuetify.display.xs}" class="font-weight-regular">
        <p>
          <a
            data-test-name="link-contact-email"
            href="mailto:contact@trava.ai"
            class="text-h6 font-weight-regular text-decoration-none text-black"
          >
            contact@trava.ai
          </a>
        </p>
        <v-divider class="mt-6 mb-0 mb-sm-10 bg-primary" />
        <div class="mt-6 pl-0">
        <p class="mb-0 mb-md-4 text-h6 font-weight-bold text-primary">{{ $t('Social') }}</p>
        <v-card-actions flat class="px-0 flex-wrap flex-sm-nowrap" data-test-name="social-networks">
          <v-btn
            v-for="social in socialNetworks"
            :key="social.iconName"
            data-test-name="button"
            class="mr-8 ml-0 mt-3 text-body-2 font-weight-regular text-decoration-none text-black text-none"
            icon
            @click="directToSocial(social.link)"
          >
            <v-icon>{{ social.iconName }}</v-icon>
          </v-btn>
        </v-card-actions>
        </div>
        <v-divider class="mt-2 mt-sm-4 bg-primary" />
        <router-link
          class="text-decoration-none"
          data-test-name="link-to-feedback-form"
          :to="{}"
          @click.prevent="handleOpeningFeedbackForm"
        >
          <div class="mt-6 d-flex align-center">
            <v-icon color="primary" class="pr-2 pt-1" data-test-name="icon">
              {{ '$customFeedback' }}
            </v-icon>
            <p class="mb-0 text-h6 font-weight-bold text-primary" data-test-name="text">
              {{ $t('Leave_Feedback') }}
            </p>
          </div>
        </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FeedbackFormMixin from '@/mixins/FeedbackFormMixin'
import { socialNetworks } from '@/helpers/commonConfigs'

export default {
  name: 'ContactUsText',
  mixins: [FeedbackFormMixin],
  created() {
    this.socialNetworks = socialNetworks
  },
  methods: {
    directToSocial(link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.textBlock {
  width: 80%;
}
</style>
