<template>
  <v-container data-test-name="card-blog">
    <common-card
      v-if="post"
      :imgUrl="previewImage"
      :title="post.title"
      :description="post.content.replace(/<a\b[^>]*>(.*?)<\/a>/g,'').replace(/<p><\/p>/g,'')"
      :tags="post.labels"
      :selectedLabel="selectedLabel"
      :tagsDisabled="labelsDisabled"
      @select="onCardClick"
      @selectTag="onSelectLabel"
    >
      <template v-slot:credentials>
        <post-credentials
          class="mb-3"
          :date="post.published"
          :userAvatarSrc="author.image.url"
          :authorCredentials="author.displayName"
        />
      </template>
    </common-card>
    <v-progress-circular
      v-else
      data-test-name="progress-circular"
      indeterminate
      persistent
      class="my-14"
      width="1"
      color="primary"
      size="100"
      style="width: 100%"
    />
  </v-container>
</template>

<script>
import PostCredentials from './PostCredentials'
import CommonCard from '@/components/CommonCard.vue'

export default {
  name: 'BlogCard',
  emits: ['select', 'selectLabel'],
  components: {
    PostCredentials,
    CommonCard
  },
  props: {
    selectedLabel: String,
    post: Object,
    labelsDisabled: Boolean,
  },
  computed: {
    previewImage() {
      return this.post?.images?.[0]?.url
    },
    author() {
      return this.post?.author || { image: '' }
    },
  },
  methods: {
    onCardClick() {
      this.$emit('select')
    },
    onSelectLabel(tag) {
      this.$emit('selectLabel', tag)
    }
  },
}
</script>
