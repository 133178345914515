import cloneDeep from 'lodash.clonedeep'
import moment from 'moment'

import datetime from '@/helpers/datetime'
import { EventTypes } from '@/helpers/itemTypes'
import itineraryService from '@/helpers/itineraryService'
import { lookupPlacesByIds } from '@/helpers/placeSearch'
import { generateUrlByPlaceID } from '@/helpers/staticImagesUtil'
import { logAppError } from '@/logger'
import { store } from '@/store'

const DEFAULT_START_TIME = { hour: 0, minute: 0, second: 0 }
const DEFAULT_END_TIME = { hour: 23, minute: 59, second: 59 }

/**
 * loads up templates and votes data to be shown on the page for a given user
 * returns array of templates:
 * [
 *  {
 *    templateID: <UID>,
 *    img: string ('https://...'),
 *    likesCount: number (12),
 *    isLiked: boolean (true/false),
 *    title: string ('London'),
 *    description: string ('some description'),
 *    tags: ['one', 'two'],
 *  }
 * ]
 */

export const searchTemplates = async (from, size, sortOrder, userId) => {
  let rv = []
  try {
    const payload = {
      from: from,
      size: size,
      sort: sortOrder,
      userId: userId
    }
    let data = await store.dispatch('GET_TEMPLATES', payload)
    data = data?.itineraryTemplates
    rv = await getTemplatesWithLikesData(data, userId)
  } catch (error) {
    logAppError(error)
  }

  return rv
}

/**
 * returns the information needed to display a template card/details
 * @param {*} templates -- array of templates
 * @param {*} userId -- userId of the current user or null for anonymous user
 */
export const getTemplatesWithLikesData = async (templates, userId) => {
  let rv = []

  try {
    const templateIDs = templates.map(item => item.templateID)
    let likes = await store.dispatch('GET_TEMPLATE_LIKES', {
      userID: userId,
      templateIDs: templateIDs,
    })
    rv = templates.map((item) => ({
      templateID: item.templateID,
      userID: item.userID,
      img: generateUrlByPlaceID(item?.placeIDLocations?.[0] ? item.placeIDLocations?.[0] : ''),
      tags: item.tags,
      description: item.templateDescription,
      name: item.templateName,
      location: item.location,
      likesCount: likes && likes[item.templateID] ? likes[item.templateID].LikesCount : 0,
      isLiked: likes && likes[item.templateID] ? likes[item.templateID].IsLikedByUser : false,
    }))
  } catch (err) {
    logAppError(err)
  }
  return rv
}

export const toggleLike = async (templateId, userId) => {
  try {
    await store.dispatch('TOGGLE_TEMPLATE_VOTE', {
      type: 'Template',
      id: templateId,
      userId: userId
    })
  } catch (err) {
    logAppError(err)
  }
}

const parseHotelEventItem = item => {
  const header = cloneDeep(item.header)
  const { startDateTime, endDateTime} = header
  const datesList = datetime.getDatesList(startDateTime, endDateTime)
  if (datesList.length === 1) {
    return [{ ...item, header: { ...header,
      todayStartDateTime: moment.parseZone(startDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      todayEndDateTime: moment.parseZone(endDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      isFirstDate: true,
      isLastDate: true,
      isIntermediateDate: false
    }}]
  }

  const result = []
  const checkIn = { ...item, header: {
    ...header,
    todayStartDateTime: moment.parseZone(startDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    todayEndDateTime: moment.parseZone(startDateTime).set(DEFAULT_END_TIME).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    isFirstDate: true,
    isLastDate: false,
    isIntermediateDate: false
  }}

  const checkOut = { ...item, header: {
    ...header,
    todayStartDateTime: moment.parseZone(endDateTime).set(DEFAULT_START_TIME).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    todayEndDateTime: moment.parseZone(endDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    isFirstDate: false,
    isLastDate: true,
    isIntermediateDate: false
  }}

  result.push(checkIn)
  result.push(checkOut)

  return result
}

const parseEventItem = item => {
  const header = cloneDeep(item.header)
  const { startDateTime, endDateTime} = header
  const datesList = datetime.getDatesList(startDateTime, endDateTime)
  const result = []
  if (datesList.length === 1) {
    result.push({ ...item, header: {
      ...header,
      todayStartDateTime: moment.parseZone(startDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      todayEndDateTime: moment.parseZone(endDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      isFirstDate: true,
      isIntermediateDate: false,
      isLastDate: true
    }})

    return result
  }

  datesList.forEach((_, i) => {
    const isFirstDate = i === 0
    const isLastDate = i === datesList.length - 1
    const isIntermediateDate = !isFirstDate && !isLastDate
    let todayStartDateTime = ''
    let todayEndDateTime = ''
    if (isFirstDate) {
      todayStartDateTime = moment.parseZone(startDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]')
      todayEndDateTime = moment.parseZone(startDateTime).set(DEFAULT_END_TIME).format('YYYY-MM-DDTHH:mm:ss[Z]')
    }

    if (isIntermediateDate) {
      todayStartDateTime = moment.parseZone(startDateTime)
        .add(i, 'days')
        .set(DEFAULT_START_TIME)
        .format('YYYY-MM-DDTHH:mm:ss[Z]')

      todayEndDateTime = moment.parseZone(startDateTime)
        .add(i, 'days')
        .set(DEFAULT_END_TIME)
        .format('YYYY-MM-DDTHH:mm:ss[Z]')
    }

    if (isLastDate) {
      todayStartDateTime = moment.parseZone(endDateTime)
        .set(DEFAULT_START_TIME)
        .format('YYYY-MM-DDTHH:mm:ss[Z]')

      todayEndDateTime = moment.parseZone(endDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]')
    }


    result.push({ ...item, header: {
      ...header, todayStartDateTime, todayEndDateTime, isFirstDate, isIntermediateDate, isLastDate
    }})
  })

  return result
}

export const parseTemplateItem = item => {
  const eventType = itineraryService.getEventType(item)
  if (eventType === EventTypes.LODGING) {
    return parseHotelEventItem(item)
  }

  return parseEventItem(item)
}

export const getItineraryTemplate = async (templateId, requestLocationDetails = true) => {
  try {
    const response = await store.dispatch('GET_TEMPLATE_ITINERARY_BY_ID', templateId)
    if (!requestLocationDetails) {
      return response
    }

    const idsList = new Set()
    response.items.forEach(({ header: { locations } }) => {
      locations.forEach(({ placeID }) => {
        idsList.add(placeID)
      })
    })

    const locationsResponse = await lookupPlacesByIds(Array.from(idsList))
    response.items = response.items.map(item => {
      const locations = item.header.locations.map(location => {
        const value = locationsResponse.find(({ placeID }) => placeID === location.placeID)
        return { ...value, latLon: location.latLon }
      })

      return { ...item, header: { ...item.header, locations }}
    })

    return response
  } catch(error) {
    logAppError(error)
  }
}
