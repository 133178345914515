import { RouteNames } from '@/helpers/routerUtils'
import { mapGetters } from 'vuex'
import { openAppzi } from '../helpers/appzi'

export const OPEN_FEEDBACK_ON_LOGIN = 'openFeedbackOnLogin'
export default {
  computed: {
    ...mapGetters({
      isAnonymous: 'IS_ANONYMOUS',
      user: 'USER'
    })
  },
  methods: {
    showFeedbackForm() {
      openAppzi(this.user?.email)
    },
    handleOpeningFeedbackForm() {
      if(this.isAnonymous) {
        this.goToSignInPage()
      } else {
        this.showFeedbackForm()
      }
    },
    goToSignInPage() {
      localStorage.setItem(OPEN_FEEDBACK_ON_LOGIN, 'true')
      this.$router.push({name: RouteNames.SIGN_IN})
    }
  }
}