<template>
    <div data-test-name="menu-share">
      <v-btn
        v-if="isAnonymous"
        class="text-subtitle-1 d-flex align-center justify-center share-menu__btn"
        data-test-id="share-menu__btn"
        @click="redirectToSignIn"
        icon="$customShare"
        data-test-name="button-open-sign-in-page"
        color="textSecondaryColor"
      >
        <template #prepend>
          <v-icon color="textSecondaryColor" />
        </template>
        {{ title }}
      </v-btn>
      <v-btn v-else
        class="ma-2 text-textPrimaryColor"
        icon="$customShareColorIcon"
        data-test-id="share-menu__btn"
        id="share-itinerary-activator"
        data-test-name="button-view-on-map"
        :title="$t('Share')"
        size="x-small"
      ></v-btn>
      <share-itinerary
        v-if="isMenuToSetupSharingVisible"
        @close="closeMenuToSetupSharing"
      />
      <share-with-community
        v-if="isShareWithCommunityPopupVisible"
        @close="closeShareWithCommunityPopup"
        @not-convertible="handleNotConvertible"
      />

    <v-menu
      v-if="!isAnonymous"
      activator="#share-itinerary-activator"
      data-test-name="menu-share"
    >
      <v-card class="share-menu-dialog" width="260">
        <v-card-title>
          <v-row no-gutters class="align-center">
            <v-col class="text-h6" data-test-name="text-title">
              {{ $t('Share_Itinerary') }}
            </v-col>
            <v-col cols="auto d-flex justify-end" class="mx-auto">
              <v-btn
                variant="plain"
                class="pr-0 pl-8"
                data-test-name="button-share"
              >
                <v-icon size="24" icon="mdi-close" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-list nav data-test-name="list-navigation">
            <v-divider></v-divider>
            <v-list-item
              v-if="isOwner"
              @click="openMenuToSetupSharing()"
              prepend-icon="mdi-account-multiple-plus"
              data-test-name="list-item-open-share-menu"
            >
              <v-list-item-title
                class="text-subtitle-2 mx-0 px-0 text-left"
              >
                {{ $t('Share_Dialog') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="openShareWithCommunityPopup"
              prepend-icon="$customShareWithPeople"
              data-test-name="list-item-open-share-with-community-menu"
            >
              <v-list-item-title
                class="text-subtitle-2 text-left"
              >
                {{ $t('Share_Dialog_Community') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              class="copyLink"
              @click="copyLink"
              :data-clipboard-text="linkToShareValue"
              prepend-icon="mdi-link-variant"
              data-test-name="list-item-copy-link"
            >
              <v-list-item-title
                class="text-subtitle-2 text-left"
              >
                {{ $t('Copy_Link') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
  </v-menu>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'
import ShareItinerary from './share-itinerary/ShareItinerary.vue'
import ShareWithCommunity from './ShareWithCommunity.vue'
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import { useRoute } from 'vue-router'
import { logAppError } from '@/logger'

export default {
  name: 'ShareMenu',
  components: {
    ShareItinerary,
    ShareWithCommunity
  },
  props: {
    itineraryID: String,
    title: String,
  },
  data() {
    return {
      titleValue: 'Sharing my Trava Itinerary with you',
      descriptionValue: 'Please take a look at the itinerary I just created',
      linkToShareValue: window.location.origin
        + process.env.VUE_APP_UI_SHARED_ITINERARY_URI
        + '/' + this.itineraryID
        + '/' + process.env.VUE_APP_UI_DEFAULT_ITINERARY_VIEW,
      clipboardObj: '',
      isMenuToSetupSharingVisible: false,
      isShareWithCommunityPopupVisible: false,
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters({
      isOwner: 'IS_OWNER',
      isAnonymous: 'IS_ANONYMOUS'
    }),
    mailToLink() {
      const subject = this.titleValue
      const body = this.descriptionValue + '%0D%0A' + this.linkToShareValue
      return 'mailto:?subject=' + subject + '&body=' + body
    }
  },
  mounted() {
    this.clipboardObj = new ClipboardJS('.copyLink')
  },
  beforeUnmount() {
    this.clipboardObj.destroy()
  },

  methods: {
    ...mapActions({
      pushAppNotification: 'PUSH_APP_NOTIFICATION',
      getItineraryRoles: 'GET_ITINERARY_ROLES',
    }),
    redirectToSignIn(){
      this.$router.push({
        name: RouteNames.SIGN_IN,
        query: { redirect: this.route?.path }
      })
    },
    openShareWithCommunityPopup(){
      this.isShareWithCommunityPopupVisible = true
      this.close()
    },
    copyLink() {
      //the actual work is done by clipboardJS
      this.showPushAppNotification({
        name: 'copyLink',
        content: 'Itinerary_link_copied',
        color: 'primary',
      })
      this.close()
    },
    showPushAppNotification(notification){
      this.pushAppNotification(notification)
    },
    shareOnFB() {
      const sharing = this.$refs.sharing
      sharing.openSharer('facebook', sharing.createSharingUrl('facebook'))
    },
    close() {
      this.$refs?.dw && this.$refs.dw.dialogClose()
    },
    async openMenuToSetupSharing() {
      try {
        await this.getItineraryRoles()
        this.isMenuToSetupSharingVisible = true
      } catch(error) {
        logAppError(error)
      }
    },
    closeMenuToSetupSharing() {
      this.isMenuToSetupSharingVisible = false
    },
    closeShareWithCommunityPopup() {
      this.isShareWithCommunityPopupVisible = false
    },
    handleNotConvertible() {
      const notification = {
        name: 'cannotShareItinerary',
        content: 'Itinerary_cannot_be_shared',
        color: 'error',
      }

      this.pushAppNotification(notification)
      this.closeShareWithCommunityPopup()
    }
  }
}
</script>

<style lang="scss" scoped>
  .share-menu {
    &__btn {
      cursor: pointer;
      transition: opacity .15s ease-in;

      &:hover {
        opacity: .75;
      }
    }
  }
  .v-btn--variant-elevated {
    box-shadow: none;
  }
</style>
