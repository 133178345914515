<template>
  <v-expansion-panel>
    <itinerary-header
      :record="record"
      :itemID="itemID"
      :startDTM="startDate"
      :endDTM="endDate"
      :title="title"
      :totalPrice="totalPrice"
      :duration="duration"
      :icon-color="color"
      :icon="icon"
      :isTemplateView="isTemplateView"
      eventType="insurance"
    />
  </v-expansion-panel>
</template>

<script>
import ItineraryHeader from '@/components/itinerary-trip-rows/ItineraryHeader'
import { getItemTypeByType } from '@/helpers/itemTypes'
import { getISOFormatDateFromObject } from '@/helpers'

export default {
  name: 'ItineraryInsuranceRow',
  components: {
    ItineraryHeader,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    isTemplateView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return 'Trip protection'
    },
    totalPrice() {
      return this.record?.totalPrice
    },
    duration() {
      return 'Entire trip'
    },
    directionsLink() {
      return this.record?.tosLink || ''
    },
    color() {
      return getItemTypeByType('insurance').color
    },
    icon() {
      return getItemTypeByType('insurance').icon
    },
    itemID() {
      return this.record?.itinIdParam || this.record?.id
    },
    startDate() {
      const { startDate } = this.record
      return getISOFormatDateFromObject(startDate)
    },
    endDate() {
      const { endDate } = this.record
      return getISOFormatDateFromObject(endDate)
    },
  },
}
</script>

