<template>
  <svg width="2em" height="2em" viewBox="2 2 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect fill="none"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4481 8.40368L13.1693 5.42312C15.0401 5.75347 16.6423 6.86259 17.629 8.40368H13.6955H13.6951H11.4481ZM8.60942 10.6776L9.71235 8.76769C9.72373 8.74507 9.73638 8.72318 9.7502 8.70214L11.6988 5.32778C9.77034 5.41337 8.05451 6.31675 6.88966 7.69952L8.60942 10.6776ZM8.06203 12.3715L9.16146 14.2754H5.71871C5.46141 13.5653 5.32111 12.799 5.32111 12C5.32111 10.8903 5.59173 9.84383 6.0706 8.92295L8.01428 12.2888C8.02813 12.3174 8.04406 12.3451 8.06203 12.3715ZM13.3119 14.2754H10.687L9.3722 11.9985L10.6852 9.72479H13.3145L14.6272 11.9978L13.3119 14.2754ZM12.549 15.5965H6.37116C7.35737 17.1368 8.9585 18.2456 10.8282 18.5765L12.549 15.5965ZM11.1521 19.9556C11.4307 19.985 11.7136 20 12 20C14.6147 20 16.9366 18.7456 18.3963 16.8057C18.4274 16.7717 18.4548 16.7347 18.4778 16.6956C19.4353 15.3769 20 13.7544 20 12C20 7.89913 16.9145 4.51893 12.9382 4.05444C12.9092 4.04922 12.88 4.046 12.8509 4.04471C12.5713 4.01516 12.2874 4 12 4C9.38038 4 7.05484 5.25911 5.59556 7.20515C5.57162 7.23249 5.55018 7.26158 5.53131 7.29205C4.56827 8.61299 4 10.2401 4 12C4 13.1102 4.22616 14.1677 4.63492 15.1286C4.64988 15.1778 4.6704 15.2245 4.69576 15.268C5.82244 17.7824 8.2093 19.6091 11.0528 19.9445C11.0858 19.9508 11.119 19.9544 11.1521 19.9556ZM12.2984 18.6724L15.3899 13.3187L17.1112 16.2995C15.9458 17.6836 14.2284 18.5875 12.2984 18.6724ZM18.2814 9.72479H14.8401L17.9301 15.0758C18.4085 14.1552 18.6789 13.1092 18.6789 12C18.6789 11.2011 18.5386 10.4349 18.2814 9.72479Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CustomActivities'
}
</script>