import { createWebHistory, createRouter } from 'vue-router'
import Chatbot from './views/Chatbot'
import GenerateItinerary from './views/GenerateItinerary.vue'
import PlannerAssistant from './views/PlannerAssistant.vue'
import WelcomePage from './views/WelcomePage.vue'
import ExternalCreateItinerary from './views/ExternalCreateItinerary.vue'
import Itinerary from './views/Itinerary.vue'
import Itineraries from './views/Itineraries.vue'
import SignIn from './views/SignIn.vue'
import SignUp from './views/SignUp.vue'
import ResetPassword from './views/ResetPassword.vue'
import PageNotFound from './views/PageNotFound.vue'
import CommunityPage from './views/Community.vue'
import GenerateCustomItinerary from './views/GenerateCustomItinerary.vue'
import Privacy from './views/Privacy.vue'
import TermsOfService from './views/TermsOfService.vue'
import AboutUs from './views/AboutUs.vue'
import ContactUs from './views/ContactUs.vue'
import TemplateItinerary from './views/TemplateItinerary.vue'
//import VueMeta from 'vue-meta'
import { loadLanguageAsync } from './helpers/i18n'
import { isFeedbackFormOpen, closeAppzi } from './helpers/appzi'
import DeletionPersonalData from './views/DeletionPersonalData'
import UserProfileNavigation from './views/UserProfileNavigation.vue'
import UserProfile from './components/profile/UserProfile.vue'
import UserSettings from '../src/components/profile/UserSettings.vue'
import UserPreferences from './components/profile/UserPreferences.vue'
import Blog from './views/Blog'
import BlogPost from './views/BlogPost'
import { RouteNames } from './helpers/routerUtils'
import { store } from './store'


/*const app = createApp()
app.use(VueMeta, {
  refreshOnceOnNavigation: true
})*/

export const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  scrollBehavior(to, from) {
    if (to.path !== from.path) {
      return { top: 0, left: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: RouteNames.HOME
    },
    {
      path: process.env.VUE_APP_UI_HOME_URI,
      name: RouteNames.HOME,
      component: WelcomePage
    },
    {
      path: process.env.VUE_APP_UI_CHATBOT_URI,
      name: RouteNames.CHAT_BOT,
      component: Chatbot
    },
    {
      path: process.env.VUE_APP_UI_GENERATE_ITINERARY_URI,
      name: RouteNames.GENERATE_ITINERARY,
      meta: {
        nav: {
          title: 'nav_plan_your_trip',
        }
      },
      component: GenerateItinerary
    },
    {
      path: process.env.VUE_APP_UI_EXT_CREATE_ITINERARY,
      name: RouteNames.EXT_CREATE_ITINERARY,
      component: ExternalCreateItinerary
    },
    {
      path: process.env.VUE_APP_UI_ITINERARIES_URI + '/:page',
      name: RouteNames.ITINERARIES,
      component: Itineraries,
      //to prevent page reloading
      //alias: [process.env.VUE_APP_UI_ITINERARIES_URI + '/1'],
      meta: {
        nav: {
          title: 'nav_itineraries',
          parent: RouteNames.GENERATE_ITINERARY,
        },
        requiresRegistration: true
      }
    },
    {
      path: process.env.VUE_APP_UI_ITINERARY_URI + '/:id' + '/:view',
      name: RouteNames.ITINERARY,
      component: Itinerary,
      meta: {
        nav: {
          title: 'nav_itinerary',
          parent: RouteNames.ITINERARIES,
        }
      }
    },
    {
      path: process.env.VUE_APP_UI_TEMPLATE_ITINERARY_URI + '/:id',
      name: RouteNames.TEMPLATE_ITINERARY,
      component: TemplateItinerary,
      meta: {
        nav: {
          title: 'template_itinerary',
        }
      }
    },
    {
      path: process.env.VUE_APP_UI_SHARED_ITINERARIES_URI + '/:page',
      name: RouteNames.SHARED_ITINERARIES,
      component: Itineraries,
      //to prevent page reloading
      //alias: [process.env.VUE_APP_UI_SHARED_ITINERARIES_URI + '/1'],
      meta: {
        nav: {
          title: 'nav_shared_itineraries',
          parent: RouteNames.GENERATE_ITINERARY,
        },
        requiresRegistration: true
      }
    },
    {
      path: process.env.VUE_APP_UI_SHARED_ITINERARY_URI + '/:id' + '/:view',
      name: RouteNames.SHARED_ITINERARY,
      component: Itinerary,
      meta: {
        nav: {
          title: 'nav_shared_itinerary',
          parent: RouteNames.SHARED_ITINERARIES,
        }
      }
    },
    {
      path: process.env.VUE_APP_UI_USER_URI,
      name: RouteNames.USER,
      component: UserProfileNavigation,
      redirect: process.env.VUE_APP_UI_USER_PROFILE_URI,
      meta: {
        nav: {
          title: 'nav_profile_settings',
          parent: RouteNames.GENERATE_ITINERARY,
        },
        requiresRegistration: true
      },
      children: [
        {
          path: process.env.VUE_APP_UI_USER_PROFILE_URI,
          component: UserProfile,
          name: RouteNames.USER_PROFILE,
          meta: {
            requiresRegistration: true
          }
        },
        {
          path: process.env.VUE_APP_UI_USER_SETTINGS_URI,
          component: UserSettings,
          name: RouteNames.USER_SETTINGS,
          meta: {
            requiresRegistration: true
          }
        },
        {
          path: process.env.VUE_APP_UI_USER_PREFERENCES_URI,
          component: UserPreferences,
          name: RouteNames.USER_PREFERENCES,
          meta: {
            requiresRegistration: true
          }
        }
      ]
    },
    {
      path: process.env.VUE_APP_UI_PLANNER_ASSISTANT,
      name: RouteNames.PLANNER_ASSISTANT,
      meta: {
        nav: {
          title: 'nav_planner_assistant',
          parent: RouteNames.GENERATE_ITINERARY,
        }
      },
      component: PlannerAssistant
    },
    {
      path: process.env.VUE_APP_UI_SIGN_IN_URI,
      name: RouteNames.SIGN_IN,
      meta: {
        nav: {
          title: 'Sign_in',
          parent: RouteNames.GENERATE_ITINERARY,
        },
        requiresNotAuthorized: true
      },
      component: SignIn
    },
    {
      path: process.env.VUE_APP_UI_SIGN_UP_URI,
      name: RouteNames.SIGN_UP,
      meta: {
        nav: {
          title: 'Sign_up',
          parent: RouteNames.GENERATE_ITINERARY,
        },
        requiresNotAuthorized: true
      },
      component: SignUp
    },
    {
      path: process.env.VUE_APP_UI_RESET_PASSWORD_URI,
      name: RouteNames.RESET_PASSWORD,
      component: ResetPassword
    },
    {
      path: process.env.VUE_APP_UI_COMMUNITY_URI,
      name: RouteNames.COMMUNITY,
      component: CommunityPage,
    },
    {
      path: process.env.VUE_APP_UI_CUSTOM_URI,
      name: RouteNames.CUSTOM,
      component: GenerateCustomItinerary
    },
    {
      path: process.env.VUE_APP_UI_PRIVACY_URI,
      name: RouteNames.PRIVACY,
      component: Privacy
    },
    {
      path: process.env.VUE_APP_UI_TERMS_OF_SERVICE_URI,
      name: RouteNames.TERMS_OF_SERVICE,
      component: TermsOfService
    },
    {
      path: process.env.VUE_APP_UI_PERSONAL_DATA_DELETION_URI,
      name: RouteNames.PERSONAL_DATA_DELETION,
      component: DeletionPersonalData,
      meta: {
        requiresRegistration: true
      }
    },
    {
      path: process.env.VUE_APP_UI_ABOUT_US_URI,
      name: RouteNames.ABOUT_US,
      component: AboutUs
    },
    {
      path: process.env.VUE_APP_UI_CONTACT_US_URI,
      name: RouteNames.CONTACT_US,
      component: ContactUs,
      meta: {
        requiresRegistration: true
      }
    },
    {
      path: process.env.VUE_APP_UI_BLOG_PAGE_URI,
      name: RouteNames.BLOG,
      component: Blog,
    },
    {
      path: process.env.VUE_APP_UI_BLOG_POST_PAGE_URI + '/:id',
      name: RouteNames.BLOG_POST,
      component: BlogPost,
    },
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound
    },
    {
      path: '/:pathMatch(.*)',
      component: PageNotFound
    },
  ]
})

/*const originalPush = router.prototype.push
router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
*/

router.beforeEach(async (to, from, next) => {
  store.dispatch('LOADING', true)

  //vuex-persist restoring data ... needs to be async
  await store.restored

  store.dispatch('LOADING', false)

  if (to.matched.some(record => (record.meta.requiresRegistration && store.getters.IS_ANONYMOUS) ||
    (record.meta.requiresNotAuthorized && !store.getters.IS_ANONYMOUS))) {
    next({name: RouteNames.GENERATE_ITINERARY})
  }

  if (isFeedbackFormOpen && from.name !== RouteNames.SIGN_IN)
    closeAppzi()

  if (to.name === RouteNames.CONTACT_US && store.getters.IS_ANONYMOUS) {
    next({name: RouteNames.SIGN_IN})
  }

  next()
})

router.afterEach((to, from) => {
  store.dispatch('SET_SHARED_FLAG', to.name === RouteNames.SHARED_ITINERARY)
  if (store.getters.USER?.settings?.language)
    loadLanguageAsync(store.getters.USER?.settings?.language?.replace(/-\S+/g, ''))

  if(from.name === RouteNames.SIGN_IN &&
    to.name === RouteNames.HOME &&
    to.query.redirect === RouteNames.CONTACT_US) {
    router.push({name: RouteNames.CONTACT_US})
  }
})

export default { router }
