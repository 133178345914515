<template>
  <v-card
    :rounded="$vuetify.display.xsOnly && '0'"
    :elevation="0"
    :class="['mx-auto overflow-visible', {'input-wrapper' : $vuetify.display.xl}]"
    data-test-name="card-generate-itinerary-form"
    color="transparent"
    max-width="1322"
  >
    <div class="px-2 rounded-0 input-header rounded-t-xl">
      <v-row class="px-0 px-sm-4 py-4 my-0">
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="d-flex py-2 py-sm-0 pl-3 pl-md-0 ma-0 justify-start justify-sm-center input-header__column"
        >
          <v-btn
            @click="toggleRoundTripMode"
            color="transparent"
            class="pa-1 pl-2 pr-6 mr-3 input-header__field text-body-2"
            variant="flat"
            data-test-name="button-toggle-trip-mode"
          >
            <v-icon color="primary" class="mr-2" size="16" icon="$customRenew" data-test-name="icon"/>
            <label class="text-secondary pr-1" style="cursor: pointer" data-test-name="text-label">
              {{ $t('Round_trip') }}
            </label>
            <v-switch
              v-model="isRoundTrip"
              class="pa-0 pl-2 ma-0 round-trip-toggle text-primary"
              inset
              density="compact"
              hide-details
              color="primary"
              :disabled="isMultipleDestinations"
              data-test-name="input-toggle-trip-mode"
            />
          </v-btn>
        </v-col>
        <v-divider v-show="$vuetify.display.mdAndUp" vertical></v-divider>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="py-2 py-sm-0 input-header__column"
        >
          <passengers-selector
            :value="travelers"
            :name="`${name}-generate-itinerary`"
            @input="updateTravelers"
            class="d-inline-flex align-center pa-0 input-header__field"
            icon="$customUsers"
            variant="text"
          />
        </v-col>
        <v-divider v-show="$vuetify.display.mdAndUp" vertical></v-divider>
        <v-col
          v-if="isTransportationSelectorVisible"
          cols="12"
          sm="6"
          md="3"
          class="py-2 py-sm-0 input-header__column"
        >
          <options-selector-radio
            :label="selectedTransportationEventName"
            :value="transportationOptions"
            :options="transportationTypes"
            :name="`${name}-generate-itinerary-transportation`"
            :disabled="isTransportationSelectorDisabled"
            :icon="selectedTransportationEventIcon"
            @input="updateOptions"
            class="input-header__field"
            data-test-name="select-transportation-event"
          />
        </v-col>
        <v-divider v-show="$vuetify.display.mdAndUp" vertical></v-divider>
        <v-col
          v-if="isActivitiesSelectorVisible"
          cols="12"
          sm="6"
          md="3"
          class="py-2 py-sm-0 input-header__column"
        >
          <options-selector
            :label="$t('Activities')"
            :value="activityOptions"
            :options="activityTypes"
            :name="`${name}-generate-itinerare-activities`"
            @input="updateOptions"
            class="input-header__field"
            icon="$customActivities"
            data-test-name="select-activities"
          />
        </v-col>
      </v-row>
    </div>
    <div class="px-2 py-0 input-content rounded-b-xl">
      isAllowAirportsInSearch:{{ isAllowAirportsInSearch }}
      <trip-request-route
        v-for="(route, index) in routes"
        :value="route"
        :key="index"
        :routeIndex="index"
        :routesCount="routes.length"
        :routesLimit="routesLimit"
        :isLastRoute="index === routes.length - 1"
        :isRoundTrip="isRoundTrip"
        :isCreateButtonDisabled="!canCreateItinerary"
        :createButtonLabel="createButtonLabel"
        :isAllowMultipleRoutes="isAllowMultipleRoutes"
        :isAllowAirportsInSearch="isAllowAirportsInSearch"
        :min-date="routes[index].minDate"
        @input="updateRoute($event, index)"
        @onCreate="createItinerary"
        @onAdd="addRoute"
        @onDelete="removeRoute(index)"
      />
    </div>
  </v-card>
</template>

<script>
import OptionsSelectorRadio from '@/components/popups/OptionsSelectorRadio'
import TripRequestRoute from '@/components/TripRequestRoute'
import PassengersSelector from '@/components/popups/PassengersSelector'
import OptionsSelector from '@/components/popups/OptionsSelector'
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import {
  itemTypes,
  CATEGORY_TRANSPORTATION,
  CATEGORY_ACTIVITY,
  getItemTypeByType
} from '@/helpers'
import { useRoute } from 'vue-router'
import { RouteNames } from '@/helpers/routerUtils'

export default {
  name: 'GenerateItineraryForm',
  emits: ['onCreate'],
  components: {
    TripRequestRoute,
    PassengersSelector,
    OptionsSelector,
    OptionsSelectorRadio,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    createButtonLabel: String,
    isTransportationSelectorVisible: {
      type: Boolean,
      default: true,
    },
    isTransportationSelectorDisabled: {
      type: Boolean,
      default: true,
    },
    isActivitiesSelectorVisible: {
      type: Boolean,
      default: true,
    },
    isAllowMultipleRoutes: {
      type: Boolean,
      default: true,
    },
    isAllowAirportsInSearch: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      errorMessages: [],
      isRoutesDateValid: false,
      route: useRoute(),
      isRoundTrip: true,
      transportationTypes: [],
      activityTypes: [],
    }
  },
  beforeMount() {
    if (this.routes.length === 0) {
      this.addRoute()
    }

    if(this.route.query.completePlannerAssistant && this.isRoutesFilledIn && this.isRoutesNotEqual) {
      this.createItinerary()
    }
  },
  created() {
    this.transportationTypes = itemTypes.filter((type) => type.category === CATEGORY_TRANSPORTATION)
    this.activityTypes = itemTypes.filter((type) => type.category === CATEGORY_ACTIVITY && type.addAutomatically)
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      tripRequestState: 'TRIP_REQUEST',
    }),
    routesLimit() {
      return this.isAllowMultipleRoutes ? 10 : 1
    },
    tripRequest() {
      const cloneTripRequest = cloneDeep(this.tripRequestState)
      const clonedRoutes = cloneTripRequest.routes
      const transformedRoutes = []
      const today = new Date()
      for (let i = 0; i < clonedRoutes.length; i++) {
        let route = clonedRoutes[i]
        if(!('date' in route) && ('departureDate' in route)) {
          const date = this.$moment({
            year:   route.departureDate.year,
            month:  route.departureDate.month - 1,
            day:    route.departureDate.day
          }).format('YYYY-MM-DD')

          const dateBack = cloneTripRequest.isRoundTrip && clonedRoutes.length === 2
            ? this.$moment({
              year:   clonedRoutes[1].departureDate.year,
              month:  clonedRoutes[1].departureDate.month - 1,
              day:    clonedRoutes[1].departureDate.day
            }).format('YYYY-MM-DD')
            : ''

          transformedRoutes.push({
            fromPlaceID: route.fromPlaceID,
            toPlaceID:   route.toPlaceID,
            date: cloneTripRequest.isRoundTrip? [date, dateBack]: date,
            minDate:  clonedRoutes.indexOf(route) === 0 ? today.toISOString().substring(0, 10) : transformedRoutes[transformedRoutes.length - 1].date
          })
        } else {
          route.minDate = clonedRoutes.indexOf(route) === 0 ? today.toISOString().substring(0, 10) : clonedRoutes[i - 1].date
          // Adjust the route dates when the 'isRoundTrip' flag is toggled.
          if(!cloneTripRequest.isRoundTrip && Array.isArray(route.date)) {
            route.date = route.date[0]? route.date[0] : undefined
          } else if (cloneTripRequest.isRoundTrip && !Array.isArray(route.date)){
            if (route.date) {
              const newEndDate = this.$moment(route.date, 'YYYY-MM-DD').add(14, 'days').format('YYYY-MM-DD')
              route.date = [route.date, newEndDate]
            } else {
              route.date = []
            }
          }
          transformedRoutes.push(route)
        }
        if (clonedRoutes.length === 2 && cloneTripRequest.isRoundTrip)
          break
      }
      cloneTripRequest.routes = transformedRoutes
      if(!cloneTripRequest.travelers.children) {
        cloneTripRequest.travelers.children = 0
      }
      if(!cloneTripRequest.travelers.adults) {
        cloneTripRequest.travelers.adults = 0
      }
      return cloneTripRequest
    },
    travelers() {
      return this.tripRequest.travelers
    },
    options() {
      return this.tripRequest.options
    },
    transportationOptions() {
      const result = {}
      itemTypes.forEach(event => {
        if(event.category === CATEGORY_TRANSPORTATION) {
          result[event.type] = this.options[event.type]
        }
      })
      return result
    },
    selectedTransportationEvent() {
      const selectedTransportationType = Object.keys(this.transportationOptions).find(key => this.transportationOptions[key])
      return getItemTypeByType(selectedTransportationType)
    },
    selectedTransportationEventIcon() {
      return this.selectedTransportationEvent?.icon || '$customAirplane'
    },
    selectedTransportationEventName() {
      return this.selectedTransportationEvent?.label || this.$t('Transportation')
    },
    activityOptions() {
      const result = {}
      itemTypes.forEach(event => {
        if(event.category === CATEGORY_ACTIVITY) {
          result[event.type] = this.options[event.type]
        }
      })
      return result
    },
    routes() {
      return this.tripRequest.routes
    },
    isMultipleDestinations() {
      return this.routes.length > 1
    },
    isRoutesFilledIn() {
      return this.routes.every(route => route.fromPlaceID && route.toPlaceID)
    },
    isRoutesNotEqual() {
      return this.routes.every(route => (!route.fromPlaceID || !route.toPlaceID) || route.fromPlaceID !== route.toPlaceID)
    },
    canCreateItinerary() {
      return this.isRoutesFilledIn && this.isRoutesNotEqual && this.isRoutesDateValid
    },
  },
  methods: {
    ...mapActions({
      setTripRequest: 'SET_TRIP_REQUEST',
      doResetTripRequest: 'RESET_TRIP_REQUEST',
      pushAppNotification: 'PUSH_APP_NOTIFICATION',
    }),
    toggleRoundTripMode() {
      this.isRoundTrip = !this.isRoundTrip
      this.updateTripRequest({
        isRoundTrip: this.isRoundTrip
      })
    },
    updateTravelers(newVal){
      this.updateTripRequest({
        travelers: newVal
      })
    },
    updateOptions(newVal){
      this.updateTripRequest({
        options: { ...this.options, ...newVal }
      })
    },
    updateRoute(newVal, index) {
      const updatedRoutes = cloneDeep(this.tripRequest.routes)
      updatedRoutes[index] = newVal
      this.updateTripRequest({
        routes: updatedRoutes
      })
    },
    addRoute() {
      const lastIndex = this.routes.length - 1
      let date, lastPlaceID
      const isRoutesExists = lastIndex >= 0
      if (isRoutesExists) {
        const prevDate = this.routes[lastIndex].date
        date = this.$moment(prevDate).add(3, 'days')
        lastPlaceID = this.routes[lastIndex].toPlaceID
      } else {
        date = this.$moment().add(14, 'days')
        lastPlaceID = this.user?.settings?.homePlaceID
      }

      const newRoute = {
        fromPlaceID: lastPlaceID,
        toPlaceID: '',
        date: this.isRoundTrip? [] : this.$moment(date).format('YYYY-MM-DD')
      }
      const updatedRoutes = cloneDeep(this.tripRequest.routes)
      updatedRoutes.push(newRoute)
      this.updateTripRequest({
        routes: updatedRoutes,
      })
    },
    removeRoute(index) {
      const updatedRoutes = cloneDeep(this.tripRequest.routes)
      updatedRoutes.splice(index, 1)
      this.updateTripRequest({
        routes: updatedRoutes,
      })
    },
    updateTripRequest(updatedParams) {
      this.setTripRequest({ ...this.tripRequest, ...updatedParams })
    },
    getTripRequestPayload() {
      const payload = {
        userID: this.user.id,
        isRoundTrip: this.isRoundTrip,
        travelers: {
          adults:   Number(this.travelers.adults),
          children: Number(this.travelers.children)
        },
        options: {...this.options},
        routes: [],
      }


      this.routes.forEach(route => {
        const dateTo = this.isRoundTrip? route.date[0] : route.date
        payload.routes.push({
          fromPlaceID: route.fromPlaceID,
          toPlaceID: route.toPlaceID,
          departureDate: {
            day:    Number(this.$moment(dateTo).format('DD')),
            month:  Number(this.$moment(dateTo).format('MM')),
            year:   Number(this.$moment(dateTo).format('YYYY'))
          }
        })

        if (this.isRoundTrip) {
          const newDate = new Date(route.date[0])
          newDate.setDate(newDate.getDate() + 35)
          payload.routes.push({
            fromPlaceID: route.toPlaceID,
            toPlaceID:   route.fromPlaceID,
            departureDate: this.name === RouteNames.GENERATE_ITINERARY && !this.validateDateRange() ? {
              day:    newDate.getDate(),
              month:  newDate.getMonth() + 1,
              year:   newDate.getFullYear()
            } : {
              day:    Number(this.$moment(route.date[1]).format('DD')),
              month:  Number(this.$moment(route.date[1]).format('MM')),
              year:   Number(this.$moment(route.date[1]).format('YYYY'))
            }
          })
        }
      })


      return payload
    },

    getErrorMessages() {
      const errorMessages = []
      if (!this.isRoutesFilledIn) {
        errorMessages.push({
          name: 'routes_fill_error',
          content: 'Fill_Field_Error',
        })
      }
      if (!this.isRoutesNotEqual) {
        errorMessages.push({
          name: 'routes-the-same-error',
          content: 'Equal_Airport_Error'
        })
      }
      if (this.isActivitiesSelectorVisible && !this.validateMinDateRange()) {
        errorMessages.push({
          name: 'min-date-range-error',
          content: 'Min_Date_Range_Error'})
      }
      if (this.name === RouteNames.GENERATE_ITINERARY && !this.validateDateRange()) {
        errorMessages.push({
          name: 'max-date-range-error',
          content: 'Max_Date_Range_Error'
        })
      }
      return errorMessages
    },

    async createItinerary() {
      const errorMessages = this.getErrorMessages()
      if(errorMessages.length){
        errorMessages.forEach((error) => {
          this.pushAppNotification(error)
        })
      }
      const payload = this.getTripRequestPayload()
      this.$emit('onCreate', payload)
    },

    validateDates() {
      const isDatesSelectedForEachRoute = this.routes.every(route =>
        (this.isRoundTrip
          && Array.isArray(route.date)
          && route.date?.[0]
          && route.date[1])
        || (!this.isRoundTrip
          && route.date
          && typeof route.date === 'string')
      )
      this.isRoutesDateValid = isDatesSelectedForEachRoute
    },

    validateMinDateRange() {
      // trip duration must be minimum 2 days so the difference between dates must be more that 0
      return this.isRoundTrip
        ? this.$moment(this.routes[0].date[1]).diff(this.routes[0].date[0], 'days') > 0
        : this.routes.length === 1 ? true : this.$moment(this.routes.at(-1).date).diff(this.routes[0].date, 'days') > 0
    },

    validateDateRange() {
      return this.isRoundTrip
        ? this.$moment(this.routes[0].date[1]).diff(this.routes[0].date[0], 'days') <= 35
        : this.routes.length === 1 ? true : this.$moment(this.routes.at(-1).date).diff(this.routes[0].date, 'days') <= 35
    },
  },
  watch: {
    routes: {
      handler (newVal) {
        if (newVal.length === 0) {
          this.addRoute()
        }
        this.validateDates()
      },
      immediate: true,
    },
    isRoundTrip: {
      handler () {
        this.validateDates()
      },
      immediate: true,
    },
    'route.query.keepAliveTripRequest': {
      handler(newVal) {
        if(!newVal) {
          this.doResetTripRequest()
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.scroll-container {
  overflow-x: hidden;
}

.round-trip-toggle {
  width: 2rem;
}

.input-wrapper {
  margin: 0 15%;
}

.input-header,
.input-content {
  position: relative;
  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    content: '';
    background-color: white;
    border-radius: 0 0 1.5rem 1.5rem;
  }
}
.input-header {
  &:before {
    opacity: 0.6;
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .input-header__column {
    position: relative;
    flex: 1;

    .input-header__field {
      width: 100%;
      height: auto;
    }
  }
}
</style>
