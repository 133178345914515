<template>
  <alternative-card
    :imageSrc="imageSrc"
    :userRating="userRating"
    @click="selectItem"
  >
    <template #title>
      {{ title }}
    </template>
    <template #body>
      <div class="alternative-item-description">
        {{ description }}
      </div>
    </template>
  </alternative-card>
</template>

<script>
import AlternativeCard from './AlternativeCard'
import { EventTypes, getItemTypeByType } from '@/helpers'

export default {
  name: 'AlternativeAttractionItem',
  components: { AlternativeCard },
  emits: ['select'],
  props: {
    item: Object,
    selectedItem: Object,
  },
  computed: {
    itemDetails() {
      return this.item?.attraction
    },
    title() {
      return this.itemDetails?.title
    },
    imageSrc() {
      return this.itemDetails?.roData?.thumbnailURL || getItemTypeByType(EventTypes.ATTRACTION).defaultImageUrl
    },
    userRating() {
      return this.itemDetails?.roData?.userRating
    },
    description() {
      return this.itemDetails?.roData?.shortDescription
    },
    price() {
      return this.itemDetails?.totalPrice
    },
  },
  methods: {
    selectItem() {
      this.$emit('select',  this.item)
    },
  }
}
</script>
