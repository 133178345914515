<template>
  <event-card
    :index="index"
    :icon="icon"
    :icon-color="iconColor"
    :title="title"
    :title-label="titleLabel"
    :location="location"
    :time="time"
    :image-src="imageSrc"
  />
</template>

<script>
import EventCard from '@/components/itinerary-template/EventCard'
import { itemTypeLookup } from '@/helpers/itemTypes'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'ActivityEventCard',
  components: { EventCard },
  props: {
    record: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    eventType() {
      return itemTypeLookup(this.record)
    },
    eventDetails() {
      return { ...this.record[this.eventType.type], ...this.record.header }
    },
    iconColor() {
      return this.eventType.color
    },
    icon() {
      return this.eventType.icon
    },
    title() {
      return this.eventDetails?.title
    },
    titleLabel() {
      const { isFirstDate, isLastDate } = this.eventDetails
      if (isFirstDate && !isLastDate) {
        return this.$t('Event_beginning')
      }

      if (!isFirstDate && isLastDate) {
        return this.$t('Event_ending')
      }

      return ''
    },
    location() {
      const location = this.eventDetails.locations?.at(0)
      const city = location?.city
      const country = location?.country
      if (!city || !country) {
        return ''
      }

      return `${city}, ${country}`
    },
    time() {
      const { todayStartDateTime, todayEndDateTime } = this.eventDetails
      const startTime = itineraryService.formatTime(todayStartDateTime)
      const endTime = itineraryService.formatTime(todayEndDateTime)

      return `${startTime} - ${endTime}`
    },
    imageSrc() {
      return this.eventType.defaultImageUrl
    }
  }
}
</script>
