export const handle403ErrorStatus = (action, store, error) => {
  if(error.response?.status === 403) {
    switch(action.type) {
    case 'SEND_COMMENT':
    case 'LOAD_COMMENTS':
    case 'CREATE_EVENT_MANUALLY':
    case 'REPLACE_ITINERARY_ITEM':
    case 'REMOVE_ITINERARY_ITEM':
    case 'ADD_ITEM_TO_ITINERARY':
    case 'UPDATE_ITINERARY_HEADER':
    case 'REMOVE_ITINERARY':
      store.dispatch('GET_ITINERARY_ROLE')
    }
  }
}

/**
 * Test if we have valid data arrays and set empty in case we have nulls.
 * Is mutating given object and returning it.
 *
 * @param {Object} data - response from all comments API
 * @returns Object
 */
export const setItineraryComments = (data) => {
  if(data.itineraryComments) {
    data.itineraryComments.read = data.itineraryComments.read || []
    data.itineraryComments.unread = data.itineraryComments.unread || []
  } else {
    data.itineraryComments = {
      read: [],
      unread: []
    }
  }
  return data
}
/**
 * Iterate through each item comments and test if we have valid array or null.
 * Will set to empty array in case read or unread is null for each item.
 * In case of error in API response or other side effect - itemsComments is set to empty object {}.
 * Is mutating and returning given object.
 *
 * @param {Object} data - response from all comments API
 * @returns Object
 */
export const setItemsComments = (data) => {
  if(data.itemsComments) {
    for (const [itemID] of Object.entries(data.itemsComments)) {
      if (!data.itemsComments[itemID].read) {
        data.itemsComments[itemID].read = []
      }
      if (!data.itemsComments[itemID].unread) {
        data.itemsComments[itemID].unread = []
      }
    }
  } else {
    data.itemsComments = {}
  }
  return data
}
