import { getFullName } from './itineraryServices/nameFormatter'
import { itemTypeLookup, EventTypes } from './itemTypes'
import { store } from '../store'

const moment = require('moment')

export default {
  getValueByPath(obj, path) {
    path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    path = path.replace(/^\./, '')           // strip a leading dot
    var keys = path.split('.')
    for (var i = 0, n = keys.length; i < n; ++i) {
      var key = keys[i]
      if (key in obj) {
        obj = obj[key]
      } else {
        return
      }
    }
    return obj
  },

  getEventType(item) {
    return itemTypeLookup(item)?.type || ''
  },

  itemColor(item) {
    return itemTypeLookup(item)?.color || 'black'
  },

  itemIcon(item) {
    return itemTypeLookup(item)?.icon || ''
  },

  itemSortingOptions(item) {
    return itemTypeLookup(item)?.sortingOptions || []
  },

  /**
    returns structure in the format
    { amount: 123, currency: 'USD' }
  */
  itemPrice(item) {
    const price = itemTypeLookup(item)?.price
    return price ? this.getValueByPath(item, price) : ''
  },

  itemLocation(item) {
    let location = {
      lat: 0,
      lon: 0
    }
    switch(this.getEventType(item)) {
    case EventTypes.FLIGHT:
      location = ''
    }

    return location
  },

  itemDurationInSeconds(record) {
    return this.getEventType(record) === EventTypes.FLIGHT
      ? this.calcSegmentsDuration(record.flight.segments).totalSec
      : this.calcDurationInSeconds(record.header.startDateTime, record.header.endDateTime)
  },

  itemStartTime(record) {
    let rv
    switch(this.getEventType(record)) {
    case EventTypes.FLIGHT:
      rv = record.flight.segments[0].departure.at
      break
    default:
      rv = record.header.startDateTime
    }

    return rv
  },

  itemEndTime(record) {
    let rv
    switch(this.getEventType(record)) {
    case EventTypes.FLIGHT:
      rv = record.flight.segments[record.flight.segments.length - 1].arrival.at
      break
    default:
      rv = record.header.endDateTime
    }

    return rv
  },

  /**
    Formats Date according to format:
    ex formatDateTime(new Date(), "MMM ddd") => "Apr, Fri"
  */
  formatDateTime(date, format = store.getters.TIME_FORMAT) {
    return moment.parseZone(date).format(format)
  },

  /**
    returns time from the ISO string according to user preferences (locale)
    input
      isoDateTime: "2020-04-15T08:20:00-07:00"
      returns: "8:20 AM"
   */
  formatISODateTime(isoDateTime, format = store.getters.TIME_FORMAT) {
    return this.formatDateTime(isoDateTime, format)
  },

  overnightMessage(startDTM, endDTM) {
    const startDate= moment.parseZone(startDTM).format('MMM DD, ddd')
    const endDate = moment.parseZone(endDTM).format('MMM DD, ddd')
    return startDate !== endDate ? 'overnight' : ''
  },

  formatCalendarTime(time) {
    const format = store.getters.TIME_FORMAT
    return moment(time, 'HH:mm').format(format)
  },

  formatTime(time) {
    if (!time) return ''
    const format = store.getters.TIME_FORMAT
    return moment.parseZone(time).format(format)
  },

  /**
    calculates duration between two datetime instances
    returns human readable string
   */
  calcDurationInSeconds(startDateTime, endDateTime) {
    if (!startDateTime || !endDateTime) return ''
    const duration = moment(endDateTime) - moment(startDateTime)
    return (duration / 1000)
  },

  /**
    formats duration in seconds into day, hours, minutes for display purposes
    */
  formatDuration(durationSeconds) {
    let duration = moment.duration(durationSeconds, 'seconds')
    const days = Math.trunc(duration.asDays())
    const hours = Math.trunc(duration.asHours()) - days * 24
    const minutes = Math.trunc(duration.asMinutes()) - days * 24 * 60 - hours * 60
    return `${days ? days + 'd ' : ''}${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm' : ''}`
  },

  getItineraryLocationCodes(itinerary) {
    const uniquelocations = new Set()
    if(itinerary?.items){
      for (const place of itinerary.items){
        for(const location of Object.values(place.header.locations)){
          uniquelocations.add(location.iataCode)
        }
      }
    }
    return Array.from(uniquelocations)
  },

  /**
      returns object for duration as follows:
      {
        total: String, // human readable string representing total duration: 1d 16h 25m
        totalSec: Number, // number representing duration total in seconds
        segments: [String], // array of Strings representing segments duration in human readable format: ['12h 10m','3h 52m']
        segmentsSec: [Number], // array of numbers representing segments duration in seconds: [455465,654654]
        layovers: [], // array of Strings representing layovers duration in human readable format: ['12h 10m','3h 52m']
        layoversSec: Number // array of numbers representing layovers duration in seconds: [455465,654654]
      }
     */
  calcSegmentsDuration(eventSegments) {
    let rv = {}

    if (eventSegments) {
      let totalDuration = 0
      const segments = []
      const segmentsSec = []
      const layovers = []
      const layoversSec = []

      eventSegments.forEach((segment, index) => {
        const segmentArriveTime = moment(new Date(segment.arrival.at)).format('X')
        const segmentDepartTime = moment(new Date(segment.departure.at)).format('X')
        const segmentDuration = (segmentArriveTime - segmentDepartTime)

        totalDuration += segmentDuration
        segments.push(this.formatDuration(segmentDuration))
        segmentsSec.push(segmentDuration)
        if (index > 0) {
          const arriveTime = moment(new Date(eventSegments[index - 1].arrival.at)).format('X')
          const departTime = moment(new Date(segment.departure.at)).format('X')
          const layoverDuration = departTime - arriveTime
          layovers.push(this.formatDuration(layoverDuration))
          layoversSec.push(layoverDuration)
          totalDuration += layoverDuration
        }
      })

      rv = {
        total: this.formatDuration(totalDuration),
        totalSec: totalDuration,
        segments,
        segmentsSec,
        layovers,
        layoversSec
      }
    }

    return rv
  },


  convertToSeconds(duration) {
    const iso8601match = duration.toUpperCase().match(/(\d+DT)*(\d+H)*(\d+M)*(\d+S)*$/)
    const iso8601 = `${iso8601match[1] || '0DT'}${iso8601match[2] || '0H'}${iso8601match[3] || '0M'}`
    return  moment.duration('P' + iso8601, moment.ISO_8601).asSeconds()
  },

  async getCalendarEvents(records) {
    const events = []

    await this.asyncForEach(records, async (record) => {
      const start = this.formatISODateTime(this.itemStartTime(record), 'YYYY-MM-DD HH:mm')
      const end = this.formatISODateTime(this.itemEndTime(record),  'YYYY-MM-DD HH:mm')
      const duration = this.itemDurationInSeconds(record)

      if (!record.header.nightAtHotel)
        events.push({
          time: {
            start,
            end
          },
          title: await getFullName(record),
          eventType: this.getEventType(record),
          record: record,
          duration: duration,
          directionLink: record.header.directionLink,
        })
    })

    return events
  },

  async asyncForEach(array, callback) {
    for (let i = 0; i < array.length; i++) {
      await callback(array[i], i, array)
    }
  },

  async getFormatCalendarEvents(records) {
    const formatCalendarEvents = []
    const calendarEvents = await this.getCalendarEvents(records)
    calendarEvents.forEach(item => {
      let event = {
        id: item.record.header.itemID,
        title: item.title,
        time: item.time,
        eventType: item.eventType,
        colorScheme: item.eventType,
        description: item.eventType,
        isEditable: true,
        directionLink: item.directionLink,
        record: item.record,
        duration: item.duration,
      }

      if(item.eventType === EventTypes.LODGING && !item.record.header.checkOut) {
        const checkInStart = moment(event.time.start).subtract(1, 'hours').format('YYYY-MM-DD HH:mm')
        const lodgingCheckIn = {
          ...event,
          time: {
            start: checkInStart,
            end: event.time.start,
          },
          title: `${item.title} \nCheck in`,
          duration: this.calcDurationInSeconds(checkInStart, event.time.start),
        }

        const lodgingNight = {
          ...event,
          directionLink: '',
        }

        formatCalendarEvents.push(lodgingCheckIn, lodgingNight)
        return
      }

      if(event.record.header.checkOut) {
        event.title = `${item.title} \nCheck out`
      }

      formatCalendarEvents.push(event)
    })

    return formatCalendarEvents
  },
  getRatingColor(rate) {
    let color = 'grey'
    switch (true) {
    case rate >= 1 && rate < 2:
      color = 'red'
      break
    case rate >= 2 && rate < 4:
      color = 'orange'
      break
    case rate === 5:
      color = 'green'
      break
    case rate >= 4:
      color = 'amber'
      break
    }
    return color
  }
}
