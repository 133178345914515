<template>
  <alternative-card
    :imageSrc="imageSrc"
    :userRating="userRating"
    @click="selectItem"
  >
    <template v-slot:title>
      {{ title }}
    </template>
    <template v-slot:body>
      <div
        class="alternative-item-description"
        max-height="200px"
        style="overflow-y: auto;"
      >
        {{ description }}
      </div>
    </template>
    <template v-slot:actions>
      <v-row>
        <v-col class="d-flex justify-end pb-0 text-caption align-center font-weight-bold">
          <colored-price
            :totalPrice="totalPrice"
          />
        </v-col>
      </v-row>
    </template>
  </alternative-card>
</template>

<script>
import AlternativeCard from './AlternativeCard'
import ColoredPrice from './ColoredPrice'
import {EventTypes, getItemTypeByType} from '@/helpers'

export default {
  name: 'AlternativeTourItem',
  components: {
    AlternativeCard,
    ColoredPrice,
  },
  emits: ['select'],
  props: {
    item: Object,
    selectedItem: Object,
  },
  computed: {
    itemDetails() {
      return this.item?.tour
    },
    title() {
      return this.itemDetails?.title
    },
    imageSrc() {
      return this.itemDetails?.roData?.thumbnailURL || getItemTypeByType(EventTypes.TOUR).defaultImageUrl
    },
    userRating() {
      return this.itemDetails?.roData?.userRating
    },
    description() {
      return this.itemDetails?.shortDescription
    },
    totalPrice() {
      return this.itemDetails?.totalPrice
    }
  },
  methods: {
    selectItem() {
      this.$emit('select',  this.item)
    },
  }
}
</script>
