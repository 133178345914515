export const appziId = 'd4e1c7ac-fdfe-4f60-aa6c-7892f329041c'
export let isFeedbackFormOpen = false

export const initializeAppzi = function () {
  const appzi = document.createElement('script')
  appzi.setAttribute('src', 'https://app.appzi.io/bootstrap/bundle.js?token=Q3UoV')
  document.head.appendChild(appzi)
}

export const openAppzi = function (emailAddress = 'unknown-email') {
  window.appziSettings = {
    data: {
      email: emailAddress
    }
  }
  // eslint-disable-next-line
  appzi && appzi.openWidget(appziId)
  isFeedbackFormOpen = true
}

export const closeAppzi = function() {
  // eslint-disable-next-line
  appzi && appzi.dismissWidget(appziId)
  isFeedbackFormOpen = false
}