<template>
  <div class="page-wrapper d-flex align-center">
    <v-container class="mb-16" data-test-name="page-generate-custom-itinerary">
      <h1
        class="text-center text-h3 text-sm-h2 font-weight-regular my-5 mt-sm-0 mb-sm-10"
        data-test-name="text-title"
      >
        <span>{{$t('Create')}}</span>
        <p
          class="text-primary font-weight-bold"
          data-test-name="text-subtitle"
        >
          {{ $t('Plan_myself_banner_title_p2') }}
        </p>
      </h1>
      <generate-itinerary-form
        :createButtonLabel="$t('Start')"
        :isActivitiesSelectorVisible="false"
        :name="route.name"
        :isTransportationSelectorDisabled="false"
        :isAllowMultipleRoutes="false"
        :isAllowAirportsInSearch="true"
        @onCreate="createItinerary"
      />
    </v-container>
  </div>
</template>

<script>
import uiUtils from '@/helpers/uiUtils'
import itineraryName from '@/helpers/itineraryServices/itineraryName'
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import GenerateItineraryForm from '@/components/GenerateItineraryForm'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { CATEGORY_TRANSPORTATION, itemTypes } from '@/helpers'
import cloneDeep from 'lodash.clonedeep'
import { defaultPreferences } from '@/store/modules/user'
import { useRoute } from 'vue-router'

export default {
  name: 'generate-custom-itinerary',
  components: {
    GenerateItineraryForm,
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isUserAnonymous: 'IS_ANONYMOUS',
      isUserNew: 'IS_NEW',
    }),
    route() {
      return useRoute()
    },
  },
  methods: {
    ...mapActions({
      showNotification: 'PUSH_APP_NOTIFICATION',
      addNewUser: 'ADD_NEW_USER',
      updateUser: 'UPDATE_USER',
      createTripRequest: 'CREATE_TRIP_REQUEST',
      generateItinerary: 'GENERATE_ITINERARY',
      doResetTripRequest: 'RESET_TRIP_REQUEST',
    }),
    isEmptyUserPreferences() {
      return !this.user.preferences
    },
    async createItinerary(payload) {
      //TODO hack!! to avoid flight search by backend - if flight option is selected, remove it from the options, and then manually add flight
      const isFlightOption = payload?.options?.flight
      if (isFlightOption) {
        payload.options.flight = false
      }

      // without it spinner with flights will not be visible after login and automatically triggering itinerary creation
      uiUtils.loadingDialog()
      if (this.isUserAnonymous && this.isUserNew) {
        //need to create new user locally
        await this.addNewUser({ user: this.user })
      }
      if(!this.route.query.completePlannerAssistant && this.isEmptyUserPreferences()) {
        this.$router.push({name: RouteNames.PLANNER_ASSISTANT, query: { type: RouteNames.CUSTOM }})
        return
      }

      const transportationEvents = itemTypes.filter((event) => event.category === CATEGORY_TRANSPORTATION && payload.options[event.type])
      const transportationOptions = transportationEvents.reduce((prev, current) => {
        return {...prev, [current.type]: true}
      }, {})

      if(transportationEvents.length) {
        uiUtils.loadingDialog(transportationEvents[0].type)
      }
      const tripRequestResponse = await this.createTripRequest(payload)
      if(tripRequestResponse) {
        const tripRequestData = tripRequestResponse.data

        const itinerary = await this.generateItinerary({
          userID: this.user.id,
          tripRequestID: tripRequestData.tripRequestID,
          itineraryName: await itineraryName.compileItineraryName(payload.routes)
        })

        if (itinerary) {
          //TODO hack!! flight search avoidance
          if (isFlightOption) {
            transportationOptions.flight = true
          }

          //prefill newly created itinerary
          uiUtils.loadingDialog()
          await commonAPIs.addManualTransportationOptions({
            options: transportationOptions,
            routes: payload.routes
          })

          await this.$router.push({
            name: RouteNames.ITINERARY,
            params: {id: itinerary.header.itineraryID, view: this.user.settings.view}
          })

          if(!this.user.settings.homePlaceID) {
            //store departure as default
            const settings = cloneDeep({...this.user.settings})
            settings.homePlaceID = payload.routes[0].fromPlaceID
            // planner assistant should be visible for user only once
            // we should save at least empty preferences object in DB to hide planner assistant forever
            await this.updateUser({ settings, preferences: this.user.preferences || defaultPreferences })
          }

          this.doResetTripRequest()
        }
      } else {
        uiUtils.loadingDialog()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  background-image: url('../../public/assets/images/plan-myself-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  //74px -is height of header
  min-height: calc(100vh - 74px);
  height: 100%;
}

@media (min-width: 960px) {
  .container {
    min-width: 960px;
  }
}
</style>
