export class RouteNames {
  static BLOG = 'blog'
  static BLOG_POST = 'blog-post'
  static WELCOME = 'welcome'
  static CHAT_BOT = 'chatbot'
  static HOME = 'home'
  static GENERATE_ITINERARY = 'auto'
  static RESET_PASSWORD = 'reset-password'
  static SIGN_UP = 'sign-up'
  static SIGN_IN = 'sign-in'
  static ITINERARY = 'itinerary'
  static TEMPLATE_ITINERARY = 'template-itinerary'
  static ITINERARIES = 'itineraries'
  static SHARED_ITINERARIES = 'shared-itineraries'
  static SHARED_ITINERARY = 'shared-itinerary'
  static EXT_CREATE_ITINERARY = 'ext-create-itinerary'
  static SETTINGS = 'settings'
  static USER = 'user'
  static USER_PROFILE = '/user/profile'
  static USER_SETTINGS = '/user/settings'
  static USER_PREFERENCES = '/user/preferences'
  static PREFERENCES = 'preferences'
  static ABOUT_US = 'about-us'
  static CONTACT_US = 'contact-us'
  static PERSONAL_DATA_DELETION = 'personal-data-deletion'
  static TERMS_OF_SERVICE = 'terms-of-service'
  static PRIVACY = 'privacy'
  static CUSTOM = 'custom'
  static COMMUNITY = 'community'
  static CHANGE_PASSWORD = 'change-password'
  static PLANNER_ASSISTANT = 'planner_assistant'
  static ACTIVE_PROFILE_ROUTE_LINK = 0
  static ACTIVE_SETTINGS_ROUTE_LINK = 1
  static ACTIVE_PREFERENCES_ROUTE_LINK = 2
  static ITINERARY_TYPE = 'itinerary-type'
}
