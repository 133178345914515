<template>
  <span></span>
</template>

<script>
export default {
  name: 'KommunicateWidget',
  data() {
    return {
    }
  },
  mounted() {
    (function(d, m){
      var kommunicateSettings = {
        'appId' : '2830af251a13926816e3da808d8aad59a',
        'popupWidget' : true,
        'automaticChatOpenOnNavigation' : true,
        'conversationTitle' : 'Trava Assistant'
      }

      var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true
      s.src = 'https://widget.kommunicate.io/v2/kommunicate.app'
      var h = document.getElementsByTagName('head')[0]; h.appendChild(s)
      window.kommunicate = m; m._globals = kommunicateSettings
    })(document, window.kommunicate || {})
  },
  unmounted() {
  },
}
</script>

