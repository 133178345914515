import axios from 'axios'
import { store } from './store'

axios.defaults.baseURL = process.env.VUE_APP_API_SERVER

axios.interceptors.request.use(
  async config => {
    if (config?.url.startsWith(process.env.VUE_APP_USER_TOKEN_URL)) {
      //special handling for refreshing access token
      //-setting headers to Bearer refresh token
      //-method should be PUT when there is a token present, otherwise - POST (this is what originally set)
      const token = store?.getters?.USER_REFRESH_TOKEN
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
        config.method = 'PUT'
      }
    } else if (config?.url.startsWith('/api')) {
      if (store?.getters?.USER_ACCESS_TOKEN) {
        config.headers.Authorization = 'Bearer ' + store?.getters?.USER_ACCESS_TOKEN
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)


axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  //if an original request got rejexted with 403 error code -- assuming the access token has expired
  //TODO need to make sure that the error code returned by BE is correct, so we don't end up in a loop here
  const originalRequest = error.config
  if (!originalRequest._retry) {
    if (error?.response?.status === 401) {
      if (error?.config?.url.startsWith(process.env.VUE_APP_USER_TOKEN_URL)) {
        //logout
        await store.dispatch('LOGOUT')
        originalRequest._retry = true
        await store.dispatch('REFRESH_TOKEN')
      } else if (error?.config?.url.startsWith('/api')) {
        originalRequest._retry = true
        await store.dispatch('REFRESH_TOKEN')
        return axios(originalRequest)
      }
    }
  }
  return Promise.reject(error)
})
