<template>
  <div>
    <v-app-bar
      color="white"
      flat
      height="74"
      class="px-0 px-md-12 mx-auto"
      data-test-name="bar-navigation"
    >
      <header-navigation-menu
        v-if="$vuetify.display.smAndDown && isNavigationButtonsVisible"
        class="mr-1"
      />

      <div
        @click="goHome"
        data-test-name="button-go-home"
        class="logo-icon mr-auto mr-md-0 mt-1"
      >
        <logo-icon/>
      </div>

      <v-alert
        v-if="newVersion"
        data-test-name="alert-app-new-version"
        elevation="2"
        variant="elevated"
        @click="updateApp"
        class="py-2"
        type="info"
        icon="mdi-reload"
        :title="$t('Update_available')"
        max-width="260"
      />

      <div
        v-if="$vuetify.display.mdAndUp && isNavigationButtonsVisible"
        class="navigate-buttons-container hidden-sm-and-down"
        data-test-name="buttons-action"
      >
        <router-link
          :to="'/' + routeNames.GENERATE_ITINERARY"
          active-class="text-primary"
          class="navigate-button mx-4 text-body-1 font-weight-medium"
          data-test-name="button-generate-itinerary">
          {{ $t('Generate_For_Me') }}
        </router-link>
        <router-link
          :to="'/' + routeNames.CUSTOM"
          active-class="text-primary"
          class="navigate-button mx-4 text-body-1 font-weight-medium"
          data-test-name="button-plan-myself">
          {{ $t('Plan_Myself') }}
        </router-link>
        <router-link
          :class="{ 'text-primary': isCommunityTemplatePage }"
          :to="'/' + routeNames.COMMUNITY"
          active-class="text-primary"
          class="navigate-button mx-4 text-body-1 font-weight-medium"
          data-test-name="button-use-community">
          {{ $t('Use_Community') }}
        </router-link>
      </div>

      <v-btn
        v-if="isSignInButtonVisible"
        data-test-name="button-sign-in"
        v-on:click="goToSignInPage"
        color="normal"
        variant="text"
        rounded
        class="login-button"
      >
        <span class="font-weight-medium text-body-1">{{ $t('Sign_in') }}</span>
      </v-btn>

      <user-menu v-else-if="!isAnonymous" name="userMenuHeader" />
    </v-app-bar>
  </div>
</template>

<script>
import UserMenu from '@/components/popups/UserMenu'
import LogoIcon from '@/components/icons/LogoIcon'
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import { getDisplayUserName } from '@/helpers/userUtils'
import HeaderNavigationMenu from '@/components/popups/HeaderNavigationMenu.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'TheNavBar',
  components: {
    UserMenu,
    LogoIcon,
    HeaderNavigationMenu,
  },
  data() {
    return {
      newVersion: false,
      interval: '',
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isAnonymous: 'IS_ANONYMOUS',
      isMobile: 'IS_MOBILE',
      appVersion: 'APP_VERSION'
    }),
    userName() {
      return getDisplayUserName(this.user)
    },
    userAvatar() {
      return this.user.userAvatar
    },
    isSignInButtonVisible() {
      return this.isAnonymous && !this.route.meta.requiresNotAuthorized
    },
    isNavigationButtonsVisible() {
      const forbiddenRoutes = [
        RouteNames.SIGN_UP,
        RouteNames.SIGN_IN,
        RouteNames.RESET_PASSWORD,
      ]
      return !forbiddenRoutes.find(route => {
        const currentRouteName = this.route.name
        return currentRouteName === route
      })
    },
    routeNames() {
      return RouteNames
    },
    isCommunityTemplatePage() {
      return this.route.name === RouteNames.TEMPLATE_ITINERARY
    },
  },
  mounted() {
    this.initAppVersion()
    this.interval = setInterval(() => {this.checkVersion()}, 5 * 60 * 1000) //check every 5 min
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions({
      initAppVersion: 'INIT_APP_VERSION',
      checkAppVersion: 'CHECK_APP_VERSION',
    }),
    updateApp() {
      setTimeout(() => {window.location.reload()}, 10)
    },
    checkVersion() {
      if (this.newVersion) return //avoid extra calls

      this.checkAppVersion().then((version) => {
        this.newVersion = version !== this.appVersion
      })
    },
    goHome() {
      this.$router.push({name: RouteNames.HOME})
    },
    goToSignInPage() {
      this.$router.push({name: RouteNames.SIGN_IN, query: {redirect: this.route.path}})
    },
  },
}
</script>

<style lang="scss">
  .logo-icon {
    width: 135px;
    color: rgb(var(--v-theme-primary));
    cursor: pointer;
  }

  .v-toolbar__content {
    justify-content: space-between !important;
  }

  .navigate-buttons-container {
    display: flex;
    flex-direction: row;

    .navigate-button {
      height: 1.375rem;
      cursor: pointer;
      color: black;
      text-decoration: none;

      &:not(.text-primary):hover {
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }
    }
  }

  .login-button {
    text-transform: none;
  }
</style>
