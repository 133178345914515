import commonAPIs from './commonAPIs'
import uiUtils from '../uiUtils'
import { EventTypes } from '../itemTypes'
import { store } from '../../store'

export default {
  /**
    -routes is an array:
    [
      {
          "fromPlaceID": "2-KSFO",
          "toPlaceID": "2-EGLL",
          "departureDate": {
              "year": 2023,
              "month": 8,
              "day": 28
          }
      },
      {
          "fromPlaceID": "2-EGLL",
          "toPlaceID": "2-KSFO",
          "departureDate": {
              "year": 2023,
              "month": 8,
              "day": 31
          }
      }
    ]
  */
  async autoFill({ routes, itineraryID, userID, travelers}) {
    uiUtils.loadingDialog(EventTypes.FLIGHT)

    for (let i = 0; i < routes.length; i++) {
      let flightSearch = await this.search({
        fromPlaceID: routes[i].fromPlaceID,
        toPlaceID: routes[i].toPlaceID,
        departureDate: routes[i].departureDate,
        size: 1,
        userID,
        travelers,
      })

      await commonAPIs.addItems(itineraryID, userID, flightSearch)
    }

    uiUtils.loadingDialog()
  },

  /**
   * @param payload = {
      "size": 5,
      "userID": "6f268e29-5f1e-11ed-b646-0242ac120007",
      "keywords": "",
      "travelers": {
          "adults": 2,
          "children": 0
      },
      "fromPlaceID": "2-KSFO",
      "toPlaceID": "2-LFPG",
      "departureDate": {
          "year": 2023,
          "month": 8,
          "day": 28
      }
    }
   */
  async search(payload) {
    let rv = []
    try {
      rv = await store.dispatch('SEARCH_FLIGHTS', payload)
    } catch (error) {
      rv = []
    }
    return rv
  }
}


