<template>
  <div cols="12" class="d-flex align-center" data-test-name="content-post-credentials">
    <span class="text-left text-body-2 font-weight-medium mr-auto" data-test-name="date">
      {{ postDate }}
    </span>
    <v-avatar v-if="userAvatar" elevation="3" size="20" class="ml-3 mr-2" data-test-name="avatar">
      <v-img class="rounded" :src="userAvatar"></v-img>
    </v-avatar>
    <span class="text-right text-body-2 font-weight-medium text--secondary" data-test-name="credentials">
      {{ authorCredentials }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    date: String,
    userAvatarSrc: String,
    userAvatarExternalSrc: String,
    authorCredentials: String,
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
      dateFormat: 'DATE_FORMAT',
    }),
    postDate(){
      if(this.dateFormat && this.timeFormat) {
        const date =  this.$moment.parseZone(this.date).format(this.dateFormat)
        const time =  this.$moment.parseZone(this.date).format(this.timeFormat)
        return `${date} ${time}`
      } else {
        return this.$moment(this.date).format('MMM DD, YYYY hh:mm')
      }
    },
    userAvatar() {
      return this.userAvatarExternalSrc || this.userAvatarSrc
    },
  },
}
</script>
