<template>
  <v-snackbar
    class="v-snack"
    :class="{'v-snack--full': fullWidth}"
    :model-value="snackbar"
    :color="color"
    :minHeight="minHeight"
    :timeout="timeout"
    :style="{[position]: gap + 'px'}"
    :location="position"
    data-test-name="notification"
  >
    <slot name="default">{{ $t(content) }}</slot>
    <template #actions>
      <v-icon
        data-test-name="icon-close"
        @click="closeNotification"
        icon="mdi-close"
      />
    </template>
  </v-snackbar>
</template>

<script>

export default {
  name: 'NotificationSnackbar',
  emits: ['close'],
  data() {
    return {
      snackbar: this.content !== null,
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default: 'default',
    },
    position: {
      type: String,
      default: 'top'
    },
    color: {
      type: String,
      default: 'red'
    },
    gap: {
      type: Number,
      default: 50
    },
    timeout: {
      type: Number,
      default: -1
    },
    fullWidth: {
      type: Boolean,
    },
    minHeight: {
      type: Number,
      default: 98,
    },
  },
  methods: {
    closeNotification() {
      this.snackbar = false
      this.$emit('close', this.name)
    }
  }
}
</script>

<style lang="scss">
  .v-snack {
    width: 100%;

    &__link {
      cursor: pointer;
      transition: opacity .15s ease-in;

      &:hover {
        opacity: .7;
      }
    }

    &__wrapper {
      min-width: 320px;
      margin-bottom: 0;
    }

    &--full {

      .v-snack__wrapper {
        min-width: 100%;
        border-radius: 0;
      }
    }
  }
</style>
