<template>
  <div class="d-flex align-center" data-test-name="content-thumb-up">
    <v-btn
      class="px-0 mr-n4 text-primary"
      :variant="variant"
      size="small"
      width="65"
      :disabled="isReadOnly"
      @click.stop="onVote"
      :prepend-icon="isVoted ? '$customThumbUpFilled' : '$customThumbUpOutlined'"
      data-test-name="button-vote"
      rounded
    />
    <span class="text-body-2 votes-counter" data-test-name="text-votes-count">{{ votesCount }}</span>
  </div>
</template>

<script>
export default {
  name: 'ThumbUpVotes',
  emits: ['toggleLike'],
  data() {
    return {
      votesCount: null,
      isVoted: false
    }
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    isUserVoted: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'flat'
    }
  },
  methods: {
    onVote() {
      this.isVoted ? --this.votesCount : ++this.votesCount
      this.isVoted = !this.isVoted
      this.$emit('toggleLike')
    }
  },
  created() {
    this.votesCount = this.count
    this.isVoted = this.isUserVoted
  },
  watch: {
    count: {
      handler(val) {
        this.votesCount = val
      },
      immediate: true
    },
    isUserVoted: {
      handler(val) {
        this.isVoted = val
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.votes-counter {
  z-index: 2;
}
</style>
