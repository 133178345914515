<template>
  <v-hover v-slot:default="{}">
    <v-card
      :max-width="maxCardWidth"
      :width="cardWidth"
      :elevation="0"
      :class="['flex d-flex flex-column mr-4 mt-0 mb-6 pa-2 mr-sm-2 overflow-visible', classes]"
      @click="clicked"
      v-resize="resizeHandler"
    >
      <v-row no-gutters>
        <v-col v-if="imageSrc" cols="12" class="card-column">
          <slot name="image">
            <v-img
              v-if="imageSrc"
              :src="imageSrc"
              max-height="200px"
              class="card-image"
              height="140"
              cover
            />
          </slot>
        </v-col>

        <v-col
          cols="12"
          class="d-flex flex-column card-column pt-1"
          align-self="stretch"
        >
          <v-card-title
            class="pa-0 d-flex flex-column align-start text-subtitle-1 text-sm-h6 font-weight-bold text-wrap"
          >
            <slot name="title"></slot>
          </v-card-title>
          <user-rating
            :userRating="userRating"
          />

          <v-card-text class="card-body pa-0">
            <slot name="body"></slot>
          </v-card-text>

          <v-card-actions v-if="$slots.actions" class="px-0 mt-auto card-footer">
            <slot name="actions"></slot>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import UserRating from '@/components/UserRating'
import { ALT_CARD_WIDTH } from './constants'

export default {
  components: {
    UserRating,
  },
  emits: ['click'],
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    imageSrc: {
      type: String,
    },
    userRating: {
      type: Object,
    },
  },
  data() {
    return {
      cardWidth: ALT_CARD_WIDTH,
    }
  },
  computed: {
    maxCardWidth() {
      return this.$vuetify.display.smAndUp
        ? ALT_CARD_WIDTH
        : '100%'
    },
  },
  methods: {
    clicked() {
      this.$emit('click')
    },
    resizeHandler() {
      if(this.$vuetify.smAndUp) return
      const slideGroupContainer = document.querySelector('.alternative-slide-group').querySelector('.v-slide-group__container')
      this.cardWidth = slideGroupContainer.clientWidth - 16
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.card {
  &--flight {
    background: rgba(#0144C7, .05);
    .card-column {
      max-height: auto;
    }
  }
  &-body {
    display: contents;
    color: rgb(var(--v-theme-textPrimaryColor));
  }

  &-footer {
    color: rgb(var(--v-theme-secondary));
  }

  &-column {
    max-height: 300px;
  }

  &-image {
    border-radius: $card-border-radius;
  }
}
</style>
