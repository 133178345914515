<template>
  <alternative-card
    classes="card--flight"
    @click.native="selectItem"
  >
    <template #body>
      <div data-test-name="content-flight-card">
        <v-row class="d-flex justify-space-between align-center ma-0 px-4" densee>
          <v-col class="d-flex flex-grow-0 text-h6 text-sm-h5">
            {{ departAirport }}
          </v-col>
          <v-col class="d-flex flex-grow-1 align-center mx-2">
            <duration-line dashed />
          </v-col>
          <v-col class="d-flex flex-grow-0 justify-end text-h6 text-sm-h5">
            {{ arriveAirport }}
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-2 mx-4 d-flex">
          <v-col cols="4" sm="3" class="pa-0 d-flex justify-start">
            {{ departAt }}
          </v-col>
          <v-col cols="4" sm="6" class="pa-0 d-flex justify-center text-caption text-secondaryDarken">
            {{ flightDuration.total }} ({{ stops }})
          </v-col>
          <v-col cols="4" sm="3" class="pa-0 d-flex justify-end">
            {{ arriveAt }}
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col cols="6" sm="6" class="pa-0 d-flex align-center justify-start text-secondaryDarken">
            Class
          </v-col>
          <v-col cols="6" sm="6" class="pa-0 d-flex align-center justify-end">
            Economy
          </v-col>
        </v-row>
        <hr class="mt-4 mb-1 line line--white" />
        <v-row class="d-flex justify-space-between mx-2 my-0">
          <v-col class="pa-0 ma-0">
            <v-img
              :src="airlineLogo"
              height="100"
            />
          </v-col>
          <v-col class="pa-0 ml-2 d-flex flex-column justify-center align-center text-h6 text-sm-h5 font-weight-bold">
            {{ formattedPrice }}
          </v-col>
        </v-row>
      </div>
    </template>
  </alternative-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AlternativeCard from './AlternativeCard'
import DurationLine from '@/components/itinerary-edit/DurationLine'
import itineraryService from '../../helpers/itineraryService'
import { ALT_CARD_WIDTH } from './constants'
import { formatPrice, calcPriceDelta } from '@/helpers'
import transportation from '@/helpers/itineraryServices/transportation'

export default {
  name: 'AlternativeFlightItem',
  components: {
    AlternativeCard,
    DurationLine
  },
  emits: ['select'],
  data() {
    return {
      title: '',
    }
  },
  /**
    `item` the alternative item shown in the slider
    `selectedItem` should be only passed when replacing an existing (selected) item
   */
  props: {
    item: {
      type: Object,
    },
    selectedItem: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      timeFormat: 'TIME_FORMAT',
    }),
    flightDuration() {
      return itineraryService.calcSegmentsDuration(this.item?.flight?.segments)
    },
    cardWidth() {
      return this.$vuetify.display.smAndUp ? ALT_CARD_WIDTH : '100%'
    },
    formattedPrice() {
      return formatPrice(this.totalPrice)
    },
    totalPrice() {
      return this.item?.flight?.totalPrice
    },
    segments() {
      return this.item?.flight?.segments
    },
    priceDelta() {
      return calcPriceDelta(this.totalPrice?.amount, this.selectedItem?.flight?.totalPrice?.amount)
    },
    priceDeltaFormatted() {
      return formatPrice({ amount: this.priceDelta, currency: this.totalPrice?.currency })
    },
    priceColor() {
      if (this.priceDelta === 0) return 'black'
      return this.priceDelta < 0 ? 'success' : 'error'
    },
    airlineLogo() {
      let code = this.segments[0]?.carrier?.carrier
      return `/dias/images/airlines/${code}.png`
    },
    arriveAtRaw() {
      return this.segments[this.segments?.length - 1].arrival?.at
    },
    arriveAt() {
      return itineraryService.formatDateTime(this.arriveAtRaw, this.timeFormat)
    },
    arriveAirport() {
      return this.segments[this.segments?.length - 1].arrival?.location?.iataCode
    },
    departAtRaw() {
      return this.segments[0]?.departure?.at
    },
    departAt() {
      return itineraryService.formatDateTime(this.departAtRaw, this.timeFormat)
    },
    departAirport() {
      return this.segments[0]?.departure?.location?.iataCode
    },
    stops() {
      return  this.segments.length === 1 ? 'Non-stop' :  'Stops: ' + (this.segments.length - 1)
    }
  },
  methods: {
    selectItem() {
      this.$emit('select',  this.item)
    },
  },
  watch: {
    item: {
      async handler(item) {
        if(item) {
          const { fullName } = await transportation.parseEventData(item.flight)
          this.title = fullName
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
  .v-col {
    padding-right: 0;
    padding-left: 0;
  }
  .alternative-card {
    &__text {
      color: var(--v-secondary-base);
    }
  }
  .line {
    display: block;
    width: 100%;
    border: 0;
    &--white {
      border-top: 1px solid #ffffff;
    }
  }
</style>
