<template>
  <div v-if="cards.length > 2" data-test-name="content-gallery">
    <h2
      data-test-name="text-title"
      class="mt-6 mt-sm-12 mb-6 mb-sm-8 text-h4 text-md-h2 font-weight-bold text-center">
      {{ $t('Popular_Trips') }}
    </h2>
    <v-row no-gutters class="flex-nowrap">
      <v-col cols="6" md="4" v-for="card in largeCards" :key="card.templateID">
        <trip-card
          :templateID="card.templateID"
          :img="card.img"
          :location="card.location"
          :name="card.name"
          :description="card.description"
          :tags="card.tags"
          :likes="card.likesCount"
          :isLiked="card.isLiked"
          @select="proceedToTemplateItem"
          class="trip-card"
        />
      </v-col>
    </v-row>
    <v-row class="mt-4 mt-md-10" no-gutters>
      <v-col class="mt-2 mb-4 mb-md-16 text-center">
        <button-primary
          :text="$t('More_Trips')"
          @click="redirectToPopularTrips"
          data-test-name="button-open-community-page"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { RouteNames } from '@/helpers/routerUtils'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import TripCard from '@/components/TripCard'
import { mapGetters } from 'vuex'
import { searchTemplates } from '@/helpers/templates'

export default {
  name: 'PopularTrips',
  data() {
    return {
      cards: [],
    }
  },
  components: {
    ButtonPrimary,
    TripCard
  },
  async created() {
    this.cards = await searchTemplates(0, 30, 'ranked', this.user.id)
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isAnonymous: 'IS_ANONYMOUS',
    }),
    largeCards() {
      let cardsCopy = [...this.cards]
      let sortedCards = cardsCopy.sort(() => Math.random() - 0.5)
      if (this.$vuetify.display.mdAndUp) {
        return sortedCards.slice(0, 3)
      }
      return sortedCards.slice(0, 2)
    },
  },
  methods: {
    redirectToPopularTrips() {
      this.$router.push({name: RouteNames.COMMUNITY})
    },
    proceedToTemplateItem(id) {
      this.$router.push({
        name: RouteNames.TEMPLATE_ITINERARY,
        params: { id },
      })
    }
  }
}
</script>
