<template>
  <v-row no-gutters data-test-name="content-sign-in">
    <v-btn
      v-on:click="goToSignInPage"
      class="ma-auto mb-2"
      color="primary"
      elevation="0"
      rounded
      size="large"
      block
      data-test-name="button-sign-in"
    >
      <span>{{ $t('Sign_in') }}</span>
    </v-btn>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { PLANNER_ASSISTANT_STEP } from '@/views/PlannerAssistant'
import { RouteNames } from '@/helpers/routerUtils'

export default {
  name: 'LoginQuestion',
  emits: ['close'],
  methods: {
    closeIfAuthorized(){
      if(!this.isAnonymous) {
        this.$emit('close')
      }
    },
    goToSignInPage() {
      localStorage.removeItem(PLANNER_ASSISTANT_STEP)
      this.$router.push({
        name: RouteNames.SIGN_IN,
        query: {
          completePlannerAssistant: true,
          keepAliveTripRequest: true,
          redirect: RouteNames.GENERATE_ITINERARY
        }})
    }
  },
  computed:{
    ...mapGetters({
      isAnonymous: 'IS_ANONYMOUS'
    })
  },
  watch: {
    isAnonymous: {
      handler() {
        this.closeIfAuthorized()
      },
      immediate: true
    }
  }
}
</script>
