<template>
  <v-app v-resize="onResize">
    <loader></loader>
    <the-nav-bar v-if="['welcome'].indexOf(route?.name) < 0" />
    <v-divider></v-divider>
    <v-main fill-height class="mt-sm-0" style="z-index: 1000;">
      <router-view />
    </v-main>
    <the-footer-bar />
    <notification-snackbar
      v-for="(notification, index) of appNotifications"
      :key="notification.name + index"
      :name="notification.name"
      :content="notification.content"
      :isActive="notification.isActive"
      :position="notification.position"
      :color="notification.color"
      :gap="notification.gap || index * 100"
      :min-height="notification.minHeight"
      @close="closeNotificationByName($event)"
    />
  </v-app>
</template>

<script>
import Loader from '@/components/popups/LoaderSpinner.vue'
import TheNavBar from '@/components/TheNavBar.vue'
import TheFooterBar from '@/components/TheFooterBar.vue'
import NotificationSnackbar from '@/components/NotificationSnackbar'
import { mapActions, mapGetters } from 'vuex'
import { initializeAppzi } from './helpers/appzi'
import { useRoute } from 'vue-router'

export default {
  name: 'App',
  components: {
    Loader,
    TheNavBar,
    TheFooterBar,
    NotificationSnackbar
  },
  data() {
    return {
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters({
      appNotifications: 'APP_NOTIFICATIONS'
    }),
  },
  mounted() {
    initializeAppzi()
    this.detectUserAgent()
  },
  methods: {
    ...mapActions({
      setMobile: 'IS_MOBILE',
      setIpad: 'IS_IPAD',
      doCloseNotificationByName: 'CLOSE_NOTIFICATION_BY_NAME',
    }),
    onResize() {
      const isMobile = window.innerWidth <= 600
      this.setMobile(isMobile)
    },
    detectUserAgent(){
      const { platform, maxTouchPoints } = navigator
      const isIpad = ['iPad', 'MacIntel'].includes(platform) && maxTouchPoints !== 0
      this.setIpad(isIpad)
    },
    closeNotificationByName(name) {
      this.doCloseNotificationByName(name)
    }
  },
  metaInfo: {
    meta: [
      {
        key: 'og:title',
        name: 'og:title',
        content: 'Trava.ai',
      },
      {
        key: 'twitter:title',
        name: 'twitter:title',
        content: 'Trava.ai',
      },
    ]
  }
}

</script>
<style lang="scss">
//below is needed to prevent ResizeObserver loop completed with undelivered notifications overlay in chrome browser in dev mode
iframe#webpack-dev-server-client-overlay{ display:none!important }
</style>