<template>
  <div v-if="userRating?.aggregateRating > 0"
    class="d-flex text-body-2"
    data-test-name="content-rating"
  >
    <v-rating
      v-if="aggregateRating > 0"
      :model-value="aggregateRating"
      color="primary"
      density="compact"
      full-icon="mdi-star"
      empty-icon="mdi-star-outline"
      half-increments
      readonly
      size="small"
      class="event-rating pb-1 mb-1"
      data-test-name="rating"
    />
    <span v-if="aggregateRating" class="ml-1" data-test-name="text-rating"> {{ aggregateRating }}</span>
    <span v-if="votes" class="ml-1" data-test-name="text-votes">({{ votes }})</span>
  </div>
</template>

<script>
export default {
  name: 'UserRating',
  props: {
    userRating: {
      type: Object,
    }
  },
  computed: {
    aggregateRating() {
      return this.userRating?.aggregateRating?.toFixed(2)
    },
    votes() {
      return this.userRating?.votes
    }
  },
}
</script>

<style scoped>

</style>
