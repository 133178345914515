<template>
  <div @click="toggleDropdown">
    <v-autocomplete
      prepend-inner-icon="$customAirplaneOutline"
      :label="$t('Preferred_Airlines')"
      :model-value="storedItemsList"
      @update:model-value="handleAirlineSelection"
      :search="searchString"
      @update:search="searchAirlines"
      :loading="loading"
      :items="searchResultsList"
      hide-details
      multiple
      :no-data-text="noDataText"
      variant="outlined"
      color="primary"
      base-color="primary"
      bg-color="#fff"
      hide-selected
      chips
      ref="autocomplete"
      data-test-name="select-airlines"
      @click:append-inner.stop="toggleDropdown"
      @blur="$refs.autocomplete.isMenuActive = false"
    >
      <template #chip="{ item, props }">
        <v-chip
          :key="props.value"
          v-bind="props"
          @click:close.stop="removeSelection(item.value)"
          closable
          color="primary"
          close-icon="mdi-close"
          data-test-name="chip"
        >
          <span class="text-caption">{{ item.title }}</span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import airlines from '@/helpers/itineraryServices/airlines'

export default {
  name: 'AirlinesSelector',
  emits: ['update'],
  data() {
    return {
      loading: false,
      searchResults: [],
      storedItems: [],
      sessionAirlineCodes: [],
      searchString: '',
    }
  },
  props: {
    airlinePreferences: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    /** airline codes are returned in array like ['AB', 'CD', 'YZ']
     * preferences are stored as [{iataCode: 'AB'}, {iataCode:'XZ'}]
     * sessionAirlineCodes are stored as ['AB', 'CD']
    */

    storedAirlineCodes() {
      const rv = []
      this.sessionAirlineCodes.forEach(item => {
        if (typeof item === 'string') { //this check is required to make sure there is no garbage in
          rv.push(item)
        }
      })
      return rv
    },
    noDataText(){
      return this.$t('Start_Typing_To_See_Resuls')
    },
    searchResultsList() {
      return this.itemsToList(this.searchResults)
    },
    storedItemsList() {
      return this.itemsToList(this.storedItems)
    }
  },
  methods: {
    toggleDropdown(event) {
      const tagName = event.target.tagName?.toLowerCase()
      const autocomplete = this.$refs.autocomplete

      if (tagName !== 'i'){
        autocomplete.isMenuActive = true
        return
      }

      if (autocomplete.isMenuActive) {
        autocomplete.isMenuActive = false
        autocomplete.blur()
      } else {
        autocomplete.isMenuActive = true
      }
    },
    itemsToList(items) {
      const rv = []
      items.forEach(item => {
        rv.push({ title: item.name, value: item.iataCode })
      })
      return rv
    },
    handleAirlineSelection(val) {
      this.sessionAirlineCodes = val
      this.searchString = ''
      this.$emit('update', this.convertPrimitivesIntoPreferences(this.sessionAirlineCodes))
    },
    removeSelection(val) {
      this.sessionAirlineCodes = this.sessionAirlineCodes.filter(item => item !== val)
      this.$emit('update', this.convertPrimitivesIntoPreferences(this.sessionAirlineCodes))
    },
    async searchAirlines(query) {
      this.searchString = query
      if (this.loading || !query) return
      this.loading = true
      try {
        this.searchResults = []
        this.searchResults = await airlines.searchAirlines(query)
      }
      finally {
        this.loading = false
      }
    },
    async lookupAirlinesByCodes() {
      this.storedItems = await airlines.lookupAirlinesByIataCodes(this.storedAirlineCodes)
    },
    /**
     * airlines are stored as [{iataCode: 'AB'}, {iataCode:'CD'}]
     * returned as ['AB', 'CD']
    */
    convertPreferencesIntoPrimitives(arr) {
      const rv = []
      arr.forEach(item => rv.push(item.iataCode))
      return rv
    },
    /**
     * sessionAirlineCodes are stored as ['AB', 'CD']
     * returned as [{iataCode: 'AB'}, {iataCode:'CD'}]
    */
    convertPrimitivesIntoPreferences(arr) {
      const rv = []
      arr.forEach(item => rv.push({ iataCode: item }))
      return rv
    }
  },
  watch: {
    sessionAirlineCodes: {
      handler() {
        this.lookupAirlinesByCodes()
      },
      immediate: true
    },
    airlinePreferences: {
      handler() {
        this.sessionAirlineCodes = this.convertPreferencesIntoPrimitives(this.airlinePreferences)
      },
      immediate: true
    },
  },
}
</script>
