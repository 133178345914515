<template>
  <div class="d-flex">
    <div class="text-textPrimaryColor font-weight-bold" data-test-name="text-price">
      {{ formattedPrice }}
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/helpers'
export default {
  name: 'ColoredPrice',
  props: {
    totalPrice: {
      type: Object,
    },
  },
  computed: {
    formattedPrice() {
      return formatPrice( this.totalPrice )
    },
  },
}
</script>

<style scoped lang="scss">

</style>
