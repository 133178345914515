<template>
  <dialog-wrapper
    ref="dw"
    :min-width="minWidth"
    name="airline-selector-dialog"
    content-class="airline-selector-dialog"
    offset-y="5"
    data-test-name="select-airline"
    @close="onCloseDialog"
    @open="onOpenDialog"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator">
        <div
          class="d-flex flex-column"
          @click="!isReadOnly && on($event)"
        >
          <v-text-field
            :model-value="value ? airlineName : ''"
            :variant="variant"
            :label="label"
            :placeholder="placeholder"
            :class="{
              'mb-6': !airlineName,
              'activator': !isReadOnly,
            }"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            hide-details
            readonly
            data-test-name="input-airline-name"
            :prepend-inner-icon="prependInnerIcon"
            @keydown.enter="on"
          />
            <v-img
              v-if="airlineName"
              :src="getAirlineLogo(value)"
              width="250"
              height="60"
              class="ml-0 mt-1"
            />
        </div>
      </slot>
    </template>

    <template v-slot:default="{ off }">
      <v-card
        class="airline-selector-dialog"
        :max-width="450"
        :max-height="450"
      >
        <v-card-title>
          <v-icon @click="off" icon="mdi-arrow-left" />
          <v-text-field
            :model-value="searchQuery"
            ref="searchInput"
            variant="underlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            autofocus
            hide-details
            density="compact"
            data-test-name="input-search-airline"
            :placeholder="placeholder"
            :readonly="isReadOnly"
            @update:model-value="searchAirlines($event)"
            @focus="selectInputText"
            @keydown.enter="focusOnList()"
          >
            <template #prepend-inner>
              <v-icon color="primary">mdi-airplane</v-icon>
            </template>
          </v-text-field>
        </v-card-title>
        <v-card-text
          class="pa-0 overflow-y-auto"
          ref="scrollableContainer"
          v-show="searchResultsList"
        >
          <v-list
            class="pa-0 ma-0"
            >
            <v-list-item
              v-for="(item, index) in searchResultsList"
              :key="index"
              @click="selectItem(item.value, item.title)"
              @keydown.enter="selectItem(item.value, item.title)"
              ref="searchList"
              data-test-name="list-item-search-result"
            >
              <div class="d-flex">
                {{ item.title }}
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
  </dialog-wrapper>
</template>

<script>
import axios from 'axios'
import DialogWrapper from '@/components/DialogWrapper'
import airlines from '@/helpers/itineraryServices/airlines'

export default {
  name: 'AirlineInputSelector',
  components: { DialogWrapper },
  emits: ['update:model-value'],
  props: {
    //value is the airline code
    value: {
      type: String,
      default: '',
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      validator(value) {
        return [
          'flat',
          'text',
          'elevated',
          'tonal',
          'outlined',
          'plain',
        ].includes(value)
      },
      default: 'plain',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    minWidth: String,
    placeholder: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
    },
  },
  data() {
    return {
      searchResultsList: [],
      isSearchLoading: false,
      searchQuery: '',
      airlineName: '',
    }
  },
  async mounted() {
    this.onOpenDialog()
  },
  methods: {
    getAirlineLogo(code) {
      return `/dias/images/airlines/${code}.png`
    },
    async onOpenDialog() {
      await this.lookupAirlinesByCodes()
      this.searchQuery = this.airlineName
    },
    onCloseDialog() {
      this.searchResultsList = []
    },
    selectInputText() {
      this.$refs.searchInput?.$el.querySelector('input')?.select()
    },
    async selectItem(code, name) {
      if (this.isReadOnly) return false
      this.searchQuery = code
      this.airlineName = name
      this.$emit('update:model-value', code)
      this.$refs?.dw && this.$refs.dw.dialogClose()
    },
    focusOnList() {
      try {
        this.$refs?.searchList[0]?.$el?.focus()
      } catch (ignore) {
        //ignore
      }
    },
    async searchAirlines(searchQuery, results = 15) {
      if (this.isSearchLoading || !searchQuery) return
      this.isSearchLoading = true
      try {
        this.searchResultsList = []
        this.airlineName = ''
        this.searchQuery = searchQuery
        const response = await axios.get(process.env.VUE_APP_AIRLINES_SEARCH_URL, {
          params: {
            query: searchQuery,
            from: 0,
            size: results
          }
        })
        if (response.status === 200) {
          this.searchResultsList = response.data.airlines?.map(item => ({ title: item.name, value: item.iataCode }))
          if (this.searchResultsList?.length === 1) this.airlineName = this.searchResultsList[0]?.title
        }
      }
      finally {
        this.isSearchLoading = false
      }
    },
    async lookupAirlinesByCodes() {
      if (this.value) {
        const rv = await airlines.lookupAirlinesByIataCodes([this.value])
        this.airlineName = rv[0]?.name
        this.searchQuery = this.airlineName
      }
    },
  },
  watch: {
    value: {
      async handler() {
        await this.lookupAirlinesByCodes()
      },
      immediate: true
    },
  }
}
</script>
