<template>
  <div>
    <button-selector
      :text="label"
      :icon="icon"
      :isChecked="!isMenuOn"
      id="options-selector-activator"
      :variant="variant"
    />
    <v-menu
      activator="#options-selector-activator"
      :close-on-content-click="false"
      :model-value="isMenuOn"
      @update:model-value="isMenuOn = $event"
      data-test-name="menu"
    >
      <v-card
        class="px-1 bg-white activities-selector-dialog"
        width="280"
      >
          <v-row no-gutters class="ma-0 pa-0">
            <v-col cols="12"
              v-for="item in options"
              :key="item.type"
              class="pa-0 my-n1"
            >
              <v-col cols="12" class="pa-2 my-n1">
                <v-checkbox
                  :model-value="value[item.type]"
                  :label="item.label"
                  color="primary"
                  true-icon="mdi-checkbox-marked-outline"
                  false-icon="mdi-checkbox-blank-outline"
                  :disabled="!item.addAutomatically || item.category === categoryTransportation"
                  class="ma-0"
                  hide-details
                  density="compact"
                  @update:model-value="onChange($event, item.type)"
                  data-test-name="menu-item"
                >
                  <template v-slot:label>
                    <v-icon
                      :color="item.color"
                      class="mx-2"
                      size="18"
                      :icon="item.icon"
                    />
                    {{ item.label }}
                  </template>
                </v-checkbox>
              </v-col>
              <v-col v-if="item.groupDivider" cols="12" class="px-0 py-2">
                <v-divider></v-divider>
              </v-col>
            </v-col>
          </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import ButtonSelector from '@/components/buttons/ButtonSelector'
import { CATEGORY_TRANSPORTATION } from '@/helpers'

export default {
  name: 'OptionsSelector',
  components: {
    ButtonSelector
  },
  emits: ['input'],
  data() {
    return {
      isMenuOn: false,
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: 'text',
    },
  },
  created(){
    this.categoryTransportation = CATEGORY_TRANSPORTATION
  },
  methods: {
    onChange(newValue, selectedOption) {
      const optionsCopy = {...this.value}
      optionsCopy[selectedOption] = newValue
      this.$emit('input', optionsCopy)
    },
  },
}
</script>
