<template>
  <div class="blogPostContainer" data-test-name="page-blog-post">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-center mb-2 my-sm-8">
        <v-img
          :src="preview"
          data-test-name="image"
          class="d-flex justify-end align-center"
          cover
          style="max-height: 50vh;"
        >
          <v-row no-gutters class="px-2 px-sm-16" style="background-color: rgba(255, 255, 255, 0.75);">
            <v-col cols="12" class="d-flex justify-center px-2 px-sm-16">
              <article
                data-test-name="article"
                class="overflow-hidden font-weight-bold text-h5 text-sm-h3 text-center text-primary rounded-xl pa-5"
              >
                {{ post.title }}
              </article>
            </v-col>
            <v-col cols="12" class="d-flex my-sm-2">
              <post-credentials
                :date="post.published"
                :userAvatarSrc="author.image.url"
                :authorCredentials="author.displayName"
                class="mx-auto pa-2"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-chip-group>
                <v-chip
                  v-for="tag in post.labels"
                  :key="tag"
                  data-test-name="chip"
                  disabled
                  class="bg-secondary"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
    <v-row no-gutters class="blog-post-content">
      <v-col cols="12">
        <div
          data-test-name="text-content"
          class="d-flex flex-column"
          v-html="post.content"
        />
      </v-col>
    </v-row>
    <alternative-posts
      :post="post"
      class="px-sm-4"
    />
    <v-row class="my-4 mx-auto">
      <button-primary
        :text="$t('All_Articles')"
        data-test-name="button-open-blog-page"
        class="mt-4 mt-md-2"
        @click="goToBlog"
      />
    </v-row>
  </div>
</template>

<script>
import PostCredentials from '@/components/blog/PostCredentials.vue'
import AlternativePosts from '@/components/blog/AlternativePosts.vue'
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
import { mapActions, mapGetters } from 'vuex'
import { RouteNames } from '@/helpers/routerUtils'
import { useRoute } from 'vue-router'

export default {
  components: {
    AlternativePosts,
    ButtonPrimary,
    PostCredentials,
  },
  data() {
    return {
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters({
      blogPost: 'CURRENT_BLOG_POST',
    }),
    post() {
      return this.blogPost || {}
    },
    preview() {
      return this.post?.images?.[0]?.url
    },
    author() {
      return this.post.author || { image: ''}
    },
  },
  created() {
    this.loadBlogPostById(this.route.params.id)
  },
  methods: {
    ...mapActions({
      loadBlogPostById: 'LOAD_BLOG_POST_BY_ID'
    }),
    scrollToTheTop() {
      this.$nextTick(() => {
        const scrollableContainer = document.querySelector('html')
        scrollableContainer?.scrollTo(0, -scrollableContainer.scrollHeight)
      })
    },
    goToBlog() {
      this.$router.push({ name: RouteNames.BLOG })
    },
  },
  watch: {
    'route.params.id': {
      handler(to, from) {
        if (to !== from) {
          this.loadBlogPostById(to).then(() => {
            this.scrollToTheTop()
          })
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.blogPostContainer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  .v-chip--disabled {
    opacity: 1;
  }

  .blog-post-content {
    padding: 0 20%;

    // each image in content_html has this class
    img {
      border-radius: 8px;
    }

    // each image group shortcode in content_html has this class
    .blog-image-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .blog-image img {
        margin: 1rem;
      }
    }

    @media (max-width: 600px) {
      p .blog-image img {
        max-width: 100%;
        margin: 1rem 0;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .blog-post-content {
      padding: 0 1rem;
    }
  }
}

</style>
