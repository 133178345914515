<template>
  <div>
    <slot name="activator" v-bind:on="on"></slot>
    <v-dialog
      :fullscreen="fullscreen"
      :scrim="!hideOverlay"
      :model-value="openModal"
      @click:outside="clickOutside"
      @keydown.esc="clickOutside"
      :content-class="contentClass"
      :attach="contentClass ? false : $el"
      no-click-animation
    >
      <slot name="default" v-bind:off="off"></slot>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DialogWrapper',
  emits: ['open', 'resize', 'close', 'clickOutside'],
  data() {
    return {
      fullscreen: false,
      dialogEl: null,
      activatorEl: null,
      isTransformationCompleted: false,
      isOpened: false,
    }
  },
  props: {
    contentClass: String,
    alwaysFullscreen: {
      type: Boolean,
    },
    hideOverlay: {
      type: Boolean,
      default: true
    },
    menu: {
      type: Boolean,
      default: false
    },
    overlayOpacity: [Number, String],
    minWidth: String,
    maxWidth: String,
    minHeight: String,
    offsetY: String,
    name: String
  },
  created() {
    this.fullscreen = this.alwaysFullscreen || (this.menu ? false : this.isMobile)
    this.onResize()
  },
  computed: {
    ...mapGetters({
      isMobile: 'IS_MOBILE',
      activeModalName: 'MODAL_WINDOW_NAME'
    }),
    openModal() {
      return this.isOpened && this.name === this.activeModalName
    },
    parentEl() {
      return document.getElementsByClassName('v-card-text event-content')[0]
    }
  },
  mounted() {
    this.parentEl?.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.parentEl?.removeEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    this.setModalWindowName()
  },
  methods: {
    handleScroll() {
      if (!this.dialogEl) return false
      const el = this.$el.getBoundingClientRect()
      this.dialogEl.style.top = `${el.top - this.parentEl.scrollTop - 20}px`
    },
    ...mapActions({
      setOverlay: 'OVERLAY',
      setModalWindowName: 'MODAL_WINDOW_NAME',
    }),
    on(e) {
      if (this.fullscreen) {
        this.setOverlay(true)
      }
      this.setModalWindowName(this.name)
      this.activatorEl = e.target
      this.$emit('open')
      setTimeout(()=>{
        this.onResize()
      })
      this.isOpened = true
    },
    off() {
      this.dialogClose()
    },
    onResize() {
      this.activatorEl && this.transformDialog()
      this.$emit('resize', this.fullscreen)
    },
    clickOutside() {
      this.$emit('clickOutside')
      this.dialogClose()
    },
    dialogClose() {
      this.isOpened = false
      this.setOverlay(this.fullscreen && this.openModal)
      this.setModalWindowName()
      this.$emit('close')
    },
    transformDialog() {
      const margin = 28
      const el = this.$el.getBoundingClientRect()
      this.dialogEl = document.getElementsByClassName(this.contentClass)[0]
      // stop executing when resize event emitted on toggle user client
      if (!this.dialogEl) return false
      this.dialogEl.style.position = this.fullscreen ? 'static' : 'absolute'
      this.dialogEl.style.width = this.fullscreen ? null : `${el.width}px`
      this.dialogEl.style.minWidth = this.fullscreen ? null : `${this.minWidth}px`
      this.dialogEl.style.maxWidth = this.fullscreen ? null : `${this.maxWidth}px`
      const maxHeight = window.innerHeight - (el.top + margin)
      this.dialogEl.style.maxHeight = this.fullscreen ? null : `${(Number(this.minHeight) > maxHeight ? this.minHeight : maxHeight)}px`
      this.dialogEl.style.minHeight = this.fullscreen ? null : `${this.minHeight}px`
      this.dialogEl.style.top = this.fullscreen ? null : `${~~(el.top + Number(this.offsetY || 0) - margin)}px`
      // check Left Position
      if (!this.fullscreen) {
        let width = this.minWidth && el.width < this.minWidth ? this.minWidth : el.width
        width = this.maxWidth && width > this.maxWidth ? this.maxWidth : width
        width = Number(width)
        let left = el.left - margin * 2
        if ((el.left + width) > window.innerWidth) {
          //stick to the right corner
          left = window.innerWidth - width - margin * 2
        }
        if(left < 0) {
          //stick to the left corner
          const leftCandidate = (window.innerWidth - width - margin * 2) / 2
          left = leftCandidate > 0 ? 0 : leftCandidate
        }
        this.dialogEl.style.left = `${~~(left)}px`
      } else {
        this.dialogEl.style.left = `${~~(el.left - margin)}px`
      }
      // check TOP Position
      if (!this.fullscreen) {
        const underBottom = (el.top + margin * 2) + Number(this.minHeight || 0) - window.innerHeight
        if (underBottom > 0) {
          this.dialogEl.style.top = `${~~(parseInt(this.dialogEl.style.top) - underBottom)}px`
        }
      }
      this.isTransformationCompleted = true
    }
  },
  watch: {
    isMobile() {
      this.fullscreen = this.alwaysFullscreen || (this.menu ? false : this.isMobile)
      this.setOverlay(this.fullscreen && this.openModal)
      this.onResize()
      this.$emit('resize', this.fullscreen)
    }
  },
}
</script>
