<template>
  <span>{{ placeLabel }}</span>
</template>

<script>
import placeSearch from '@/helpers/placeSearch'

export default {
  name: 'AsyncPlaceLabel',
  data() {
    return {
      placeLabel: '',
      loading: false,
    }
  },
  props: {
    placeID: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      if (this.loading) return

      try {
        this.loading = true
        const location = (await placeSearch.lookupPlacesByIds([this.placeID])).at(0)
        this.placeLabel = location?.airportPlace?.iataCode || location?.city
      } catch (ignore) {
        this.placeLabel =''
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    placeLabel: {
      handler () {
        this.loadData()
      },
      immediate: true,
    },
  }
}
</script>
