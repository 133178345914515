<template>
  <div data-test-name="content-proposition">
    <v-row class="text-center pt-sm-16 pt-5 px-3" no-gutters>
      <v-col>
        <h1 class="pb-4 text-h3 text-sm-h2 font-weight-regular my-4" data-test-name="text-title">
          {{ $t('Your_Travel') }}
          <span class="text-primary font-weight-bold">{{$t('Simplified')}}</span>
        </h1>
        <h3 data-test-name="subtitle">{{$t('Choose_One')}}</h3>
        <h4 data-test-name="hint" class="font-weight-regular">{{$t('Or_If_You_New')}}</h4>
        <div class="d-flex justify-center flex-wrap mt-4 mb-10 mb-md-14">
          <button-primary
            data-test-name="button-generate-itinerary"
            :text="$t('Generate_For_Me')"
            class="ma-3"
            @click="goToGenerateItinerary"
          />
          <button-primary
            data-test-name="button-plan-myself"
            :text="$t('Plan_Myself')"
            class="ma-3"
            @click="goToCustom"
          />
          <button-primary
            data-test-name="button-use-community"
            :text="$t('Use_Community')"
            class="ma-3"
            @click="goToCommunity"
          />
        </div>
      </v-col>
    </v-row>
    <v-sheet fluid class="overflow-hidden" max-height="750" data-test-name="video-app">
      <video v-if="isVideoCanBePlayed" autoplay loop muted playsinline class="video-main" ref="video">
        <source :src="`/assets/videos/${$vuetify.display.xs ? 'mobilewebm' : 'desktopwebm'}.webm`" type="video/webm" />
        <source :src="`/assets/videos/${$vuetify.display.xs ? 'mobilemp4' : 'desktopmp4'}.mp4`" type="video/mp4" />
      </video>
      <v-img v-else src="/assets/images/home-page-video-screenshot.jpg" />
    </v-sheet>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
import { RouteNames } from '@/helpers/routerUtils'
import { ref, onMounted } from 'vue'

export default {
  name: 'WelcomePageTopComponent',
  components: {
    ButtonPrimary
  },
  setup() {
    const video = ref(null)
    const isVideoCanBePlayed = ref(true)

    onMounted(() => {
      if (video.value) {
        const playPromise = video.value.play()
        if (playPromise) {
          playPromise.then(() => {
            isVideoCanBePlayed.value = true
          })
            .catch(error => {
              if(error.name === 'NotAllowedError') {
                isVideoCanBePlayed.value = false
              }
            })
        }
      }
    })
    return {
      video,
      isVideoCanBePlayed
    }
  },
  methods: {
    goToGenerateItinerary() {
      this.$router.push({name: RouteNames.GENERATE_ITINERARY})
    },
    goToCommunity() {
      this.$router.push({name: RouteNames.COMMUNITY})
    },
    goToCustom() {
      this.$router.push({name: RouteNames.CUSTOM})
    },
  },
}
</script>

<style lang="scss" scoped>
// disable controls on video
video {
  pointer-events: none !important;
  object-fit:cover;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
// For iOS
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
.video-main {
  width: 100%;
  height: 100%;
}
</style>
