<template>
<div>
    <kommunicate-widget></kommunicate-widget>
</div>
</template>

<script>
import KommunicateWidget from '../components/Kommunicate'

export default {
  name: 'chatbotPage',
  components: { KommunicateWidget },
  data() {
    return {

    }
  },
}
</script>
