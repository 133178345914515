

export const getGoogleImageUrl = (googleImage) => {
  let rv
  if (googleImage && googleImage[0].photoReference) {
    if(googleImage[0].photoReference.startsWith('/')) {
      rv = googleImage.photoReference[0]
    } else {
      rv = process.env.VUE_APP_GOOGLE_PLACES_IMAGE_URL + googleImage[0].photoReference
    }
  }
  return rv
}
