<template>
  <div>
    <common-popup
      :title-content="$t('Share_with_people')"
      :full-screen="isMobile"
      :confirm-button-disabled="!canMakeRequest"
      :confirm-label="$t('Save')"
      :cancel-label="$t('Cancel')"
      @confirm="updateRecipients"
      @close="closeDialogWindow"
      @cancel="closeDialog"
    >
    <v-row
      class="flex-nowrap pt-1"
      justify="space-between"
      no-gutters
    >
      <v-text-field
        autofocus
        variant="outlined"
        color="primary"
        bg-color="#fff"
        base-color="primary"
        placeholder="user@gmail.com"
        class="mr-6"
        v-model="email"
        @keydown.enter="addEmailToList"
        :rules="[emailValidTest]"
        type="email"
      >
        <template v-slot:prepend-inner>
          <v-icon
            class="pr-2"
            color="primary"
            size="large"
            icon="$customCommunity"
          />
        </template>
      </v-text-field>
      <v-btn
        color="primary"
        variant="outlined"
        rounded="rounded"
        size="large"
        class="mx-auto"
        :disabled="!email || !isEmailValid || isEmailDuplicated"
        @click="addEmailToList"
      >
        <v-icon size="32" icon="mdi-plus" />
    </v-btn>
    </v-row>
    <v-divider class="mb-6 bg-primary"></v-divider>
    <v-row
      v-for="(user, index) in rolesListState"
      :key="user.email"
      no-gutters
      class="mt-4"
    >
      <v-col>
        <v-text-field
          variant="plain"
          :model-value="user.email"
          readonly
          hide-details
        />
      </v-col>
      <v-col cols="5" sm="4" class="pl-2 pl-sm-6">
        <v-select
          :model-value="user.role"
          :items="selectableRolesWithDeleteButton"
          hide-details
          variant="outlined"
          color="primary"
          base-color="primary"
          bg-color="#fff"
          :menu-props="{bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, contentClass:'share-itinerary-select'}"
        >
          <template v-slot:item="{ item }">
            <v-divider v-if="item.raw.divider"></v-divider>
            <v-list-item
              v-if="!item.raw.divider"
              @click="changeRole(item.raw.value, index)"
              :key="index"
              class="ma-0 pa-2"
              justify="space-between"
            >
              {{ item.raw.title }}
              <v-icon
                v-if="item.raw.value"
                class="px-2"
                :style="{ visibility: item.raw.value === user.role ? 'visible' : 'hidden' }"
                icon="mdi-check"
              />
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>
    </common-popup>
    <common-popup
      v-if="isConfirmationWindowVisible"
      :body-content="$t('Question_to_discard_changes')"
      isBtnsReversed
      @confirm="closeDialog"
      @close="closeConfirmationPopup"
      @discard="closeConfirmationPopup"
    />
  </div>
</template>

<script>
import CommonPopup from '@/components/popups/CommonPopup'
import { userRoles } from '@/helpers/commonConfigs'
import { email as emailField } from '@/helpers/userProfileFields'
import { mapActions, mapGetters } from 'vuex'
import checkEquality from  'fast-deep-equal/es6'
import { logAppError } from '@/logger'

export default {
  name: 'ShareItinerary',
  components: {
    CommonPopup,
  },
  emits: ['close'],
  data() {
    return {
      email: '',
      isEmailValid: false,
      rolesListState: [],
      isConfirmationWindowVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isOwner: 'IS_OWNER',
      itineraryID: 'GET_CURRENT_ITINERARY_ID',
      rolesApi: 'CURRENT_ITINERARY_ROLES',
      itineraryUserRole: 'CURRENT_ITINERARY_ROLE',
      isMobile: 'IS_MOBILE',
    }),
    rolesListApi() {
      const result = [] //[{email: this.user.email, role: this.itineraryUserRole}]
      Object.keys(this.rolesApi).forEach(email => {
        result.push({email, role: this.rolesApi[email]})
      })
      return result
    },
    rolesListSendToRecipients() {
      const rv = []
      this.rolesListState.forEach(el => {
        if (!this.rolesListApi.find(storedEl => storedEl.email === el.email)) {
          rv.push({ email: el.email, role: el.role })
        }
      })

      return rv
    },
    wasDataChanged() {
      return !checkEquality(this.rolesListApi, this.rolesListState)
    },
    selectableRoles() {
      const rv = []
      userRoles.forEach(el => {
        if (el.selectable) {
          rv.push( { title: this.$t(el.title), value: el.value } )
        }
      })

      return rv
    },
    selectableRolesWithDeleteButton() {
      return [...this.selectableRoles, ...[
        { divider: true },
        { title: this.$t('Delete'), value: false }]]
    },
    canMakeRequest() {
      return !(!this.wasDataChanged || !!this.email)
    },
    isEmailDuplicated() {
      return !!this.rolesListState.find(el => el.email?.toLowerCase() === this.email?.toLowerCase())
    },
    isOwnEmail() {
      return (this.email?.toLowerCase() === this.user?.email?.toLowerCase())
    }
  },
  watch: {
    rolesListApi:{
      handler() {
        this.rolesListState = [...this.rolesListApi]
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      showNotification: 'PUSH_APP_NOTIFICATION',
      sendItineraryToRecipients: 'SEND_ITINERARY_TO_RECIPIENTS',
      updateItineraryRoles:'UPDATE_ITINERARY_ROLES',
      showLoader: 'LOADING_DIALOG'
    }),
    async sendToRecipients(recipients) {
      const itineraryRecipients = {
        shareItinWithAddUsersEvent: {
          itineraryID: this.itineraryID,
          senderID: this.user.id,
          share: recipients.map(recipient => ({ email: recipient.email, role: recipient.role })),
          headerLink: 'https://trava.ai/home',
          sharedItineraryLink: window.location.origin
            + process.env.VUE_APP_UI_SHARED_ITINERARY_URI
            + '/' + this.itineraryID
            + '/' + process.env.VUE_APP_UI_DEFAULT_ITINERARY_VIEW
        }
      }
      return await this.sendItineraryToRecipients(itineraryRecipients)
    },
    emailValidTest(email) {
      this.isEmailValid = false
      if (email && !emailField.pattern.test(email)) {
        return this.$t(emailField.errorIncorrect)
      } else if (this.isOwnEmail) {
        return this.$t('Email_of_current_user')
      } else if (this.isEmailDuplicated) {
        return this.$t('Email_duplicated')
      } else {
        this.isEmailValid = true
        return true
      }
    },
    addEmailToList() {
      if (!!this.email && this.isEmailValid && !this.isEmailDuplicated) {
        this.rolesListState.push({email: this.email.trim().toLowerCase(), role: this.defaultRecipientRole })
        this.email = ''
      }
    },
    changeRole(value, index) {
      if(!value) {
        this.removeUserWithRole(index)
      } else {
        const roleToReplace = {...this.rolesListState[index]}
        roleToReplace.role = value
        this.rolesListState.splice(index, 1, roleToReplace)
      }
    },
    async saveRolesApi() {
      const payload = {}
      this.rolesListState.forEach(el => {
        if (el.role !== 'OWNER') {
          payload[el.email] = el.role
        }
      })
      try {
        await this.updateItineraryRoles(payload)
        if (this.rolesListState.length > 0){
          this.showNotification({
            name: 'saveRoles',
            content: 'Itinerary_shared_message',
            color: 'primary',
          })
        }
      } catch(error) {
        this.showNotification({
          name: 'saveRoles',
          content: 'Smth_went_wrong_message',
          color: 'error',
        })
        logAppError(error)
      } finally {
        this.showLoader(null)
        this.closeDialog()
      }
    },
    removeUserWithRole(index) {
      this.rolesListState.splice(index, 1)
    },
    async updateRecipients() {
      if (this.wasDataChanged && !this.email) {
        this.showLoader({
          visible: true,
          opacity: 0.9,
          overlayColor: 'white'
        })

        try {
          const recipients = this.rolesListSendToRecipients
          if (recipients.length > 0) {
            await this.sendToRecipients(recipients)
          }
        } catch (err) {
          logAppError(err)
        } finally {
          this.showLoader()
          await this.saveRolesApi()
        }

      }
    },
    closeDialogWindow() {
      if (this.wasDataChanged || !!this.email) {
        this.isConfirmationWindowVisible = true
      } else {
        this.$emit('close')
      }
    },
    closeConfirmationPopup() {
      this.isConfirmationWindowVisible = false
    },
    closeDialog() {
      this.$emit('close')
    },
  },
  created() {
    this.defaultRecipientRole = this.selectableRoles[0].value
  }
}
</script>

<style lang="scss">
.share-itinerary-select.v-menu__content {
  .v-divider {
    background-color: rgb(var(--v-theme-primary));
    width: 80%;
    margin: 0 auto;
  }
}
</style>
