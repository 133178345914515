<template>
  <v-container data-test-name="card-trip">
    <v-row>
      <v-col class="d-flex">
        <v-img
          cover
          width="100%"
          class="mb-2 rounded-lg trip-card__image"
          :aspect-ratio="14/9"
          :src="img"
          data-test-name="image"
          @click="openItineraryTemplatePage"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row v-if="location" no-gutters>
      <v-col>
        <span class="font-weight-regular" data-test-name="text-location`">{{ location }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="8" sm="10">
        <v-card-title
          class="px-0 font-weight-bold text-body-1 text-sm-h6"
          data-test-name="text-title"
        >
          {{name}}
        </v-card-title>
      </v-col>
      <v-col cols="4" sm="2" class="mt-1 mt-sm-2">
        <thumb-up-votes
          @toggleLike="onLike"
          :count="likes"
          :is-user-voted="isLiked"
          :isReadOnly="isAnonymous"
          variant="text"
        />
      </v-col>
    </v-row>
    <v-divider class="text-primary" />
    <v-row v-if="$vuetify.display.smAndUp" no-gutters class="mt-3">
      <v-col class="d-flex flex-wrap">
        <span
          v-for="(tag, index) in tags"
          :key="index"
          data-test-name="text-tag"
          class="decorator text-primary text-overline"
        >
          {{tag}}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="description" no-gutters class="mt-1 mb-3">
      <v-col>
        <span
          data-test-name="text-description"
          class="pr-3 text-body-2 font-weight-medium text-textPrimaryColor text--ellipsis text-ellipsis"
        >
          {{ description }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ThumbUpVotes from '@/components/ThumbUpVotes.vue'
import { mapGetters } from 'vuex'
import { toggleLike } from '@/helpers/templates'

export default {
  name: 'TripCard',
  emits: ['select'],
  components: {
    ThumbUpVotes,
  },
  props: {
    img: String,
    templateID: String,
    location: String,
    description: String,
    name: String,
    tags: Array,
    likes: Number,
    isLiked: Boolean,
    maxWidth: {
      type: Number,
      default: 306
    },
    maxHeight: {
      type: Number,
      default: 200
    },
    maxContainerWidth: {
      type: String,
      default: '390px'
    },
  },
  computed: {
    ...mapGetters({
      user: 'USER',
      isAnonymous: 'IS_ANONYMOUS',
    }),
  },
  methods: {
    openItineraryTemplatePage() {
      this.$emit('select', this.templateID)
    },
    async onLike() {
      await toggleLike(this.templateID, this.user?.id)
    }
  }
}
</script>

<style scoped lang="scss">

.trip-card {
  &__image {
    cursor: pointer;
    transition: opacity .15s ease-in;
    &:hover {
      opacity: .8;
    }
  }
}
.text-ellipsis {
  min-height: 45px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.decorator::after {
  content: "\2022";
  margin: 0 5px;
}

.decorator:last-child::after {
  content: none;
}
</style>
