<template>
  <div data-test-name="loading-indicator">
    <v-progress-linear
      data-test-name="progress-linear"
      v-if="progress"
      color="primary"
      indeterminate
      height="6"
      style="z-index: 5"
    />

    <v-dialog
      :scrim="loadingDialog.overlayColor"
      :model-value="loadingDialog.visible"
      persistent
      no-click-animation
      width="230"
      max-width="90%">
      <slot name="default">
        <v-sheet class="text-center" height="230">
          <v-progress-circular
            :v-show="loadingDialog.visible"
            :size="loadingDialog.size"
            data-test-name="progress-circular"
            indeterminate
            persistent
            class="my-14"
            width="1"
            color="light-blue-accent-1">
            <v-icon size="32" data-test-name="icon" :color="loadingDialog.color">
              {{ loadingDialog.icon }}
            </v-icon>
          </v-progress-circular>
          <div class="mt-n4 text-subtitle-1" data-test-name="text">{{ loadingDialog.text }}</div>
        </v-sheet>
      </slot>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoaderSpinner',
  data() {
    return {

    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters({
      stateLoadingDialog: 'LOADING_DIALOG',
      progress: 'LOADING'
    }),
    loadingDialog() {
      const initialData = {
        visible: false,
        type: '',
        icon: '',
        text: '',
        color: 'blue',
        opacity: 0.5,
        size: 100,
      }
      return {...initialData, ...(this.stateLoadingDialog || {})}
    }
  }
}
</script>
