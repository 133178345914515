<template>
  <alternative-card :imageSrc="imageSrc" @click="selectItem">
    <template v-slot:title>
      {{title}}
    </template>
    <template v-slot:body>
      <div class="alternative-item-description">
        {{coverageDetails}}
      </div>
    </template>
    <template v-slot:actions>
      <v-row>
        <v-col class="d-flex justify-end pb-0 text-caption align-center ml-auto font-weight-bold">
          <colored-price
            :totalPrice="item.totalPrice"
          />
        </v-col>
      </v-row>
    </template>
  </alternative-card>

</template>

<script>
import AlternativeCard from './AlternativeCard'
import ColoredPrice from './ColoredPrice'

export default {
  name: 'AlternativeInsuranceItem',
  components: {
    AlternativeCard,
    ColoredPrice,
  },
  emits: ['select'],
  props: {
    item: Object,
  },
  computed: {
    coverageDetails() {
      return this.item.coverageDetails || ''
    },
    title() {
      return 'Trip protection'
    },
    imageSrc() {
      return this.item.providerLogoURL || ''
    },
  },
  methods: {
    selectItem() {
      this.$emit('select', this.item)
    },
  }
}
</script>
