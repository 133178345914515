<template>
  <v-chip
    @click="$emit('click', $event)"
    class="px-1 button-wrapper"
    label
  >
    <v-icon
      v-if="icon"
      :size="iconSize"
      color="primary"
      class="mr-2"
      :icon="icon"
    />
    <label class="pr-1 text-secondary" style="cursor: pointer">{{text}}</label>
    <v-icon
      v-if="isChecked"
      color="secondary"
      icon="mdi-chevron-down"
    />
    <v-icon
      v-else
      color="secondary"
      icon="mdi-chevron-up"
    />
  </v-chip>
</template>

<script>
export default {
  name: 'ButtonSelector',
  emits: ['click'],
  props: {
    text: String,
    isChecked: Boolean,
    icon: {
      type: String,
      required: false,
    },
    iconSize: {
      type: Number,
      default: 18,
    },
  }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: 100%;
  height: 50px!important;
}
</style>
