<template>
  <v-expansion-panel>
    <itinerary-header
      :itemID="record?.header?.itemID"
      :title="title"
      :totalPrice="totalPrice"
      :startDTM="startDTM"
      :endDTM="endDTM"
      :duration="duration"
      :icon-color="itemColor(record)"
      :icon="itemIcon(record)"
      :eventType="getEventType(record)"
      :overnightMessage="overnightMessage(startDTM, endDTM)"
      :directionsLink="directionsLink"
      :record="record"
    />
  </v-expansion-panel>
</template>

<script>
import ItineraryHeader from '@/components/itinerary-trip-rows/ItineraryHeader'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'ItineraryShowRow',
  components: {
    ItineraryHeader,
  },
  props: {
    record: Object,
    directionsLink: String,
    readonly: { type:Boolean, required: true },
    totalTravelersNumber: Number,
  },
  computed: {
    itemDetails() {
      return this.record.show
    },
    title() {
      return this.itemDetails?.title
    },
    duration() {
      let duration = itineraryService.calcDurationInSeconds(
        this.startDTM,
        this.endDTM
      )
      return itineraryService.formatDuration(duration)
    },
    startDTM() {
      return this.record.header.startDateTime
    },
    endDTM() {
      return this.record.header.endDateTime
    },
    totalPrice() {
      return this.itemDetails?.totalPrice
    }
  },
  methods: {
    getEventType: itineraryService.getEventType,
    itemColor: itineraryService.itemColor,
    itemIcon: itineraryService.itemIcon,
    overnightMessage: itineraryService.overnightMessage
  }
}
</script>
