<template>
  <v-card class="card--flight overflow-visible" elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" density="compact" class="mx-0 tabs-container">
      <v-tabs v-model="tab" class="card__tabs" data-test-name="menu">
        <v-tab v-for="item in tabItems" :key="item" color="primary" data-test-name="tab">
          {{ $t(item) }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-card-text class="event-content">
      <v-expand-transition>
        <section v-if="isBaseView" class="mt-10">
          <v-row density="compact" class="mx-0 mx-sm-2 pt-1 pt-sm-4 d-flex">
            <v-col cols="12" sm="6" class="py-0 px-0 px-sm-3">
              <place-input
                :value="departureLocation?.placeID"
                @update="updateDepartureLocation"
                min-width="450"
                :placeholder="$t('Origin')"
                :label="$t('Origin') + '*'"
                :isValid="isLocationsDifferent"
                name="PlaceInputFromAirport"
                content-class="PlaceInputFromAirport"
                :readonly="!isSearchMode"
                base-color="primary"
                color="primary"
                variant="outlined"
                returnPlaceType="airport"
                data-test-name="input-place-from"
              />
            </v-col>

            <v-col cols="12" sm="6" class="py-0 px-0 px-sm-3">
              <place-input
                :value="arrivalLocation?.placeID"
                @update="updateArrivalLocation"
                min-width="450"
                :placeholder="$t('Destination')"
                :label="$t('Destination') + '*'"
                :isValid="isLocationsDifferent"
                name="PlaceInputToAirport"
                content-class="PlaceInputToAirport"
                :readonly="!isSearchMode"
                base-color="primary"
                color="primary"
                variant="outlined"
                returnPlaceType="airport"
                data-test-name="input-place-to"
              />
            </v-col>
          </v-row>

          <v-row class="mx-0 mx-sm-2 mt-0" density="compact">
            <v-col cols="6" sm="3" class="pl-0 pl-sm-3 pb-1">
              <date-picker
                :model-value="startDate"
                :label="$t('Departure_date') + '*'"
                min-width="320"
                @update:model-value="updateStartDate"
                name="StartDate"
                :readonly="!isSearchMode"
                variant="outlined"
              />
            </v-col>
            <v-col cols="6" sm="3" class="pr-0 pr-sm-3 pb-1">
              <time-picker
                :model-value="startTime"
                :label="$t('Departure_time') + '*'"
                @update:model-value="updateStartTime"
                :readonly="!isSearchMode"
                variant="outlined"
              />
            </v-col>
            <v-col cols="6" sm="3" class="pl-0 pl-sm-3 pb-6 pb-sm-1">
              <date-picker
                :model-value="endDate"
                :min-date="minEndDate"
                :label="$t('Arrival_date') + '*'"
                :rules="[rules.afterStartTime]"
                min-width="320"
                @update:model-value="updateEndDate"
                name="EndDate"
                :readonly="!isSearchMode"
                variant="outlined"
              />
            </v-col>
            <v-col cols="6" sm="3" class="pr-0 pr-sm-3 pb-6 pb-sm-1">
              <time-picker
                :model-value="endTime"
                :label="$t('Arrival_time') + '*'"
                :rules="[rules.afterStartTime]"
                @update:model-value="updateEndTime"
                :readonly="!isSearchMode"
                variant="outlined"
              />
            </v-col>
          </v-row>

        </section>
      </v-expand-transition>

      <div v-if="isSearchMode">
        <section class="d-flex flex-column mx-0 mx-sm-5 px-3 px-sm-6 mt-6 mt-sm-10">
          <alternative-selector-tool
            v-if="!isReadOnly"
            :record="newRecord"
            :isSearchDisabled="!isDatesLocationValid"
            :dataSource="dataSource"
            @updateDataSource="updateDataSource"
            @select="onSelectEvent"
            ref="alternatives"
          />
        </section>
      </div>

      <div v-if="isDetailsMode">
        <section class="d-flex flex-column mx-0 mx-sm-5 px-3 px-sm-6 mt-6 mt-sm-10 mb-7 pb-4">
          <v-btn
            v-if="segments.length > 1"
            class="align-self-center btn--showmore"
            color="primary"
            height="48"
            variant="text"
            data-test-name="button-toggle-segments-view"
            @click="isShowMore = !isShowMore"
          >
            {{ isShowMore ? $t('Hide_info') : $t('Edit_info') }}
          </v-btn>
          <div v-if="segments.length">
            <time-line :event-type="eventType" :segments="segments" :duration="durations" />
          </div>
          <v-expand-transition>
            <div v-if="isSegmentsView" data-test-name="content-segments-view">
              <div v-if="segments.length">
                <event-segment
                  v-for="(segment, index) in segments"
                  :key="`segment-${index}`"
                  :model-value="segment"
                  :event-type="eventType"
                  :index="index"
                  :segments-count="segments.length"
                  :min-date-time="previousSegmentArrivalDateTime(index)"
                  :event-duration="durations"
                  :isReadOnly="isReadOnly"
                  @delete-segment="deleteSegment"
                  @update:model-value="updateSegment($event, index)"
                  @update:departure-location="updateSegmentDepartureLocation"
                  @update:arrival-location="updateSegmentArrivalLocation"
                  @validate-segment="updateSegmentValidation"
                  @validate-all-segments="validateAllSegments"
                  ref="segmentsList"
                />
              </div>
              <div v-if="!isReadOnly" class="pb-4 d-flex justify-center">
                <v-btn
                  color="primary"
                  rounded
                  data-test-name="button-add-segment"
                  :width="160"
                  :disabled="isDisabledAddSegment"
                  @click="addSegment"
                >
                  {{ $t('Add_segment') }}
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
        </section>
        <v-row class="mx-0 mx-sm-6">
          <v-col cols="12" class="pa-0">
            <transportation-event-details
              :title="title"
              :isBooked="isBooked"
              :notes="notes"
              :totalPrice="totalPrice"
              :travelers="travelers"
              :eventType="eventType"
              :readonly="isReadOnly"
              :notesRules="[rules.validNotes]"
              @updateTravelers="travelers = $event"
              @updateBooked="isBooked = $event"
              @updatePrice="totalPrice = $event"
              @updateNotes="notes = $event"
              @updateTitle="title = $event"
            />
          </v-col>
        </v-row>

      </div>
    </v-card-text>

    <event-popup-buttons
      v-if="isDetailsMode"
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />

  </v-card>
</template>

<script>
/**
 * Flight event
 * 1. when a new event is created - assume single segment
 * 2. when an existing non-stop (single segment) event opened for edit - open segment view
 * 4. when 2+ segments event is opened - show simple version with fields readonly
 * 5. user can click “edit info” and get to edit segments information
 *
 * - in segment view all the fields are editable
 */
import AlternativeSelectorTool from '@/components/itinerary-edit/AlternativeSelectorTool'
import TransportationEventDetails from '@/components/popups/events/flight/TransportationEventDetails'
import { transportationFormsMixin } from '@/mixins/TransportationFormsMixin'


export default {
  name: 'AddFlightForm',
  emits: ['updateTitle', 'cancel', 'save'],
  components: {
    AlternativeSelectorTool,
    TransportationEventDetails,
  },
  mixins: [transportationFormsMixin],
  props: {
    isTemplateView: {
      type: Boolean,
      default: false,
    },
    day: {
      type: Number,
      required: false,
    }
  },
  methods: {
    validateAllSegments() {
      if (this.$refs.segmentsList?.length) {
        this.$refs.segmentsList.forEach(segment => {
          segment.validate()
        })
      }
    }
  },
}
</script>
