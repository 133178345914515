<template>
  <v-chip
    :color="'white'"
    @click="$emit('click', $event)"
    label
    class="pr-0"
  >
    <label class="pr-1 font-weight-black">{{text}}</label>
    <v-icon :color="isActive ? 'primary' : 'white'" v-if="isChecked && isActive">arrow_downward</v-icon>
    <v-icon :color="isActive ? 'primary' : 'white'" v-if="!isChecked && isActive">arrow_upward</v-icon>
  </v-chip>
</template>

<script>
export default {
  name: 'ButtonSort',
  emits: ['click'],
  props: {
    text: String,
    isChecked: Boolean,
    isActive: Boolean,
    isSortButton: Boolean
  }
}
</script>
