import axios from 'axios'
import { setItineraryComments, setItemsComments } from '@/store/helpers/itineraryHelper'
import { getDefaultRequestParams, getRequestURL } from '@/store/helpers/httpRequestHelper'

export const getters = {
  COMMENTS: (state) => state.comments,
}

export const mutations = {
  INIT_COMMENTS: (state, payload) => {
    setItineraryComments(payload.comments)
    setItemsComments(payload.comments)
    state.comments[payload.id] = payload.comments
  }
}

export const actions = {
  LOAD_COMMENTS: async ({ commit, rootState }) => {
    const params = getDefaultRequestParams(rootState)
    if(params.itineraryID) {
      const url = getRequestURL(process.env.VUE_APP_USER_ITINERARY_URL, params)
      axios.get(`${url}/all-comments`)
        .then(({ data }) => {
          commit('INIT_COMMENTS', { id: params.itineraryID, comments: data })
        })
        .catch(() => {
          commit('INIT_COMMENTS', { id: params.itineraryID, comments: {} })
        })
    }
  },
  SEND_COMMENT: async ({ rootState, dispatch }, payload) => {
    const params = getDefaultRequestParams(rootState)
    if(params.itineraryID) {
      let templateUrl = process.env.VUE_APP_USER_ITINERARY_URL
      if (payload.itemID) {
        templateUrl = process.env.VUE_APP_USER_ITINERARY_ITEM_URL
        params.itemID = payload.itemID
      }
      const url = getRequestURL(templateUrl, params)
      return await axios
        .post(`${url}/comments`, {
          text: payload.text,
          userID: params.userID,
        }).then( () => {
          dispatch('LOAD_COMMENTS')
        })
    }
  },
  UPDATE_COMMENT: async ({ dispatch, rootState }, payload) => {
    const params = getDefaultRequestParams(rootState)
    if(params.itineraryID && payload.text) {
      let templateUrl = process.env.VUE_APP_COMMENTS_FOR_ITINERARY_URL
      params.commentId = payload.commentId

      if (payload.itemID) {
        templateUrl = process.env.VUE_APP_COMMENTS_FOR_ITINERARY_ITEM_URL
        params.itemID = payload.itemID
      }
      const url = getRequestURL(templateUrl, params)
      return await axios.put(url, { text: payload.text }).then( () => {
        dispatch('LOAD_COMMENTS')
      })
    }
  },
  REMOVE_COMMENT: async ({ dispatch, rootState }, payload) => {
    const params = getDefaultRequestParams(rootState)
    if(params.itineraryID) {
      let templateUrl = process.env.VUE_APP_COMMENTS_FOR_ITINERARY_URL
      params.commentId = payload.commentId

      if (payload.itemID) {
        templateUrl = process.env.VUE_APP_COMMENTS_FOR_ITINERARY_ITEM_URL
        params.itemID = payload.itemID
      }
      const url = getRequestURL(templateUrl, params)
      return await axios.delete(url).then( () => {
        dispatch('LOAD_COMMENTS')
      })
    }
  },
  SEND_LAST_READ_COMMENT_ID: async ({ rootState }, payload) => {
    const params = getDefaultRequestParams(rootState)
    if(params.itineraryID) {
      let templateUrl = `${process.env.VUE_APP_USER_ITINERARY_URL}/itinerary-comments/last-read`

      if (payload.itemID) {
        templateUrl = `${process.env.VUE_APP_USER_ITINERARY_ITEM_URL}/comments/last-read`
        params.itemID = payload.itemID
      }
      const url = getRequestURL(templateUrl, params)
      return await axios.post(url, { lastViewedID: payload.commentId })
    }
  },
}

export default {
  state: {
    comments: {},
  },
  getters,
  mutations,
  actions,
}
