<template>
  <svg width="2em" height="2em" viewBox="2 1 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 11.5C20 10.4719 19.1731 9.625 18.1693 9.625H13.6876L11.3992 4.37125L11.2467 4H8.32907L8.93932 9.625H7.81403L6.76563 8.02312L6.59354 7.75H4L4.15256 8.4925L4.74389 11.5L4.15256 14.5081L4 15.25H6.59354L6.76563 14.9763L7.81342 13.375H8.93871L8.32846 19H11.246L11.3986 18.6288L13.6876 13.375H18.1693C19.1731 13.375 20 12.5281 20 11.5ZM18.7795 11.5C18.7795 11.8537 18.5147 12.125 18.1693 12.125H12.887L12.7344 12.4962L10.446 17.75H9.70212L10.3124 12.125H7.14642L6.97555 12.3987L5.92532 14H5.48717L5.94485 11.6169L5.98268 11.5L5.94485 11.3825L5.48717 9H5.92532L6.97494 10.6019L7.14581 10.875H10.3118L9.70151 5.25H10.4454L12.7338 10.5038L12.8864 10.875H18.1693C18.5147 10.875 18.7795 11.1463 18.7795 11.5Z" fill="#0144C7"/>
  </svg>
</template>

<script>
export default {
  name: 'CustomAirplane'
}
</script>