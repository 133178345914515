import { RouteNames } from '@/helpers/routerUtils'

export const redirectToQueryPath = {
  watch: {
    isAnonymous(){
      if(!this.isAnonymous){
        this.redirectToQueryPath()
      }
    }
  },
  methods:{
    async redirectToQueryPath(){
      if(this.route?.query?.redirect && this.route?.query?.page){
        await this.$router.push({path: this.route.query.redirect + `/${this.route.query.page}`})
      } else if(this.route?.query?.redirect){
        const { query: {isCreateItineraryPopupVisible} } = this.route
        await this.$router.push({path: this.route.query.redirect, query: {isCreateItineraryPopupVisible: isCreateItineraryPopupVisible}})
      } else {
        await this.$router.push({name: RouteNames.HOME})
      }
    },
  },
}
