//import moment from 'moment'
import placeSearch from '../placeSearch'

/**
  the name of the itinerary will look something like:
  - for a trip SFO-JFK => JFK on ...
  - for a trip SFO-JFK-SFO => JFK on ...
  - for a trip SFO-LON-PAR-FRA => LON-PAR-FRA on ...
  - for a trip SFO-LON-PAR-FRA-SFO => LON-PAR-FRA on ...
  */
export default {
  async compileItineraryName(routes) {
    let name = ''
    let isRoundTrip = false
    let locations = []
    routes.forEach((point) => {
      locations.push(point.toPlaceID)
    })

    if (locations.length > 1) {
      isRoundTrip = (routes[0].fromPlaceID === locations[locations.length - 1])
    }
    for (let i=0; i<(isRoundTrip?locations.length-1:locations.length);i++) {
      const lookUp = await placeSearch.lookupPlacesByIds(locations[i])
      name += (i>0?'-':'') + lookUp[0]?.city
    }

    return name
  }

}