import commonAPIs from './commonAPIs'
import uiUtils from '../uiUtils'
import { EventTypes } from '../itemTypes'
import { store } from '../../store'

export const BREAKFAST_TIME =  {startTime: {hour: 7, minute: 30}, duration: {hours:1, minutes: 0}}
export const LUNCH_TIME =  {startTime: {hour: 11, minute: 30}, duration: {hours:1, minutes: 0}}
export const DINNER_TIME = {startTime: {hour: 18, minute: 0},  duration: {hours:1, minutes: 30}}
export const EVENT_TIMES = [BREAKFAST_TIME, LUNCH_TIME, DINNER_TIME]

export default {
  /**
    payload = {
      location: {
        placeID: String
      },
      startDateTime: {year: Number, month: Number, day: Number, hour: Number, minute: Number}
      endDateTime: {year: Number, month: Number, day: Number, hour: Number, minute: Number}
      size: Number of results expected
      keywords: String
      userID: String
    }
   */
  async search(payload) {
    let rv = []
    try {
      rv = await store.dispatch('SEARCH_RESTAURANTS', payload)
    } catch (error) {
      rv = []
    }
    return rv
  },

  /**
    autoFill will fetch and add restaurants to the ititnerary provided:
      -locationsAndDates is an array:
      [
        { placeID, arrivalDateTime, departureDateTime },
        { placeID, arrivalDateTime, departureDateTime }
        ...
      ]
      -placeID: String
      -arrivalDateTime:   { year: Number, month: Number, day: Number, hour: Number, minute: Number }
      -departureDateTime: { year: Number, month: Number, day: Number, hour: Number, minute: Number }
   */
  async autoFill({ locationsAndDates, itineraryID, userID, travelers, routes}) {
    uiUtils.loadingDialog(EventTypes.RESTAURANT)

    let events = await commonAPIs.autoFillEvents(
      locationsAndDates,
      EVENT_TIMES,
      async (placeID, startDateTime, endDateTime, size) => {
        return await this.search({
          location: {
            placeID
          },
          //time doesn't matter as it will be changed at add
          startDateTime,
          endDateTime,
          size,
          userID,
          travelers,
        })
      },
      routes
    )
    //default price per person set to $50
    const amount = 5000 * (travelers?.adults + travelers?.children) || 5000
    events.forEach(event => {
      event[EventTypes.RESTAURANT].totalPrice = { amount: amount, currency: 'USD' }
    })
    if (events && events.length > 0) {
      await commonAPIs.addItems(itineraryID, userID, events)
    }

    uiUtils.loadingDialog()
  },
}
