import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  updateProfile,
  updatePassword,
  deleteUser
} from 'firebase/auth'
import { firebaseConfig } from './firebaseConfig'

const firebaseApp = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(firebaseApp)

export function signInUserWithEmailAndPassword(email, password) {
  return signInWithEmailAndPassword(firebaseAuth, email, password)
}

export function signInWithGoogle() {
  const provider = new GoogleAuthProvider()
  return signInWithPopup(firebaseAuth, provider)
}

export function signInWithFacebook() {
  const provider = new FacebookAuthProvider()
  return signInWithPopup(firebaseAuth, provider)
}

export async function userSignOut() {
  return await signOut(firebaseAuth)
}

export async function makeUserWithEmailAndPassword(email, password) {
  return await createUserWithEmailAndPassword(firebaseAuth, email, password)
}

export async function updateUserProfile(profile) {
  return await updateProfile(firebaseAuth.currentUser, profile)
}

export async function updateUserPassword(password) {
  return await updatePassword(firebaseAuth.currentUser, password)
}

export async function resetPassword(email) {
  return await sendPasswordResetEmail(firebaseAuth, email)
}

export async function sendUserEmailVerification(queryParams) {
  // We'll use these query params for automatically creating itinerary.
  // Query params won't be empty if a user came to this page from planner-assistant page and tries to create a new account
  const queryToUriString = new URLSearchParams(queryParams).toString()
  let url = window.location.origin + process.env.VUE_APP_UI_SIGN_IN_URI
  if(queryToUriString) {
    url += `?${queryToUriString}`
  }
  const actionCodeSettings = {
    url,
    handleCodeInApp: true
  }
  return await sendEmailVerification(firebaseAuth.currentUser, actionCodeSettings)
}

export function removeUser() {
  return deleteUser(firebaseAuth.currentUser)
}

export function initializeFirebase(onStatusChangedCallback) {
  onAuthStateChanged(firebaseAuth, onStatusChangedCallback)
}

export function getUpdatedAuthState(onGetAuthStateCallback) {
  return onAuthStateChanged(firebaseAuth, onGetAuthStateCallback)
}

export function sendUserPasswordResetEmail(email) {
  let url = window.location.origin + process.env.VUE_APP_UI_SIGN_IN_URI

  const queryToUriString = new URLSearchParams({email}).toString()
  url += `?${queryToUriString}`

  const actionCodeSettings = {
    url,
    handleCodeInApp: true
  }
  return sendPasswordResetEmail(firebaseAuth, email, actionCodeSettings)
}

export default {
  signInUserWithEmailAndPassword,
  signInWithGoogle,
  signInWithFacebook,
  signOut,
  makeUserWithEmailAndPassword,
  updateUserProfile,
  sendUserEmailVerification,
  removeUser,
  initializeFirebase,
  updateUserPassword,
  getUpdatedAuthState,
  sendUserPasswordResetEmail,
}
