import { createApp } from 'vue'

import App from './App.vue'
import { vuetify } from './plugins/vuetify'
import VueMoment  from 'vue-moment-v3'
import { router } from './router'
import { store } from './store'
import { i18n } from './helpers/i18n'
import { logAppError } from './logger'
import { initializeFirebase } from '@/helpers/firebase/firebase'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from 'vue-gtag'

import 'babel-polyfill'
import './axiosConfig'
import '@/scss/main.scss'

const app = createApp(App)
// Provide the store instance to the rest of the app
app.config.globalProperties.$store = store
app.use(store)

app.use(router)
app.use(i18n)
app.use(VueMoment)
app.use(vuetify)


//logger config
app.config.globalProperties.$logAppError = logAppError

app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID },
  pageTrackerScreenviewEnabled: true,
  appName: 'TravaTravel'  //TODO this can be set dynamically if needed
}, router)

app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY ,
  loaderOptions: {
    autoHideBadge: true,
    explicitRenderParameters: {
      badge: 'bottomleft'
    }
  }
})

store.dispatch('LOADING_DIALOG', {
  visible: true,
  opacity: 0.9,
  overlayColor: 'white'
})
const onStatusChangedCallback = (user) => {
  if(user?.providerId || user?.emailVerified) {
    store.dispatch('USER_LOGIN', user)
  } else {
    store.dispatch('LOADING_DIALOG', null)
  }
}
initializeFirebase(onStatusChangedCallback)

app.config.globalProperties.$getAppConfig = (key) => {
  return app.config.globalProperties.$appConfig[key] || process.env[key]
}

/*fetch('/config/appconfig.json?'+new Date().getTime(),
  {headers: {
    'Accept': 'application/json'
  }})
  .then(response => {
    if (response.status === 200)
      return response.json()
    else
      return {}
  })
  .then(config => {
    app.config.globalProperties.$appConfig = config
    createApp({
      router,
      i18n,
      store,
      vuetify,
      moment,
      render: h => h(App)
    }).mount('#app')
  })*/


app.mount('#app')