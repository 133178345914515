var URITemplate = require('urijs/src/URITemplate')

export default {
  /**
    returns a link to google maps with directions
    - destination is the only required parameter
   */
  directionsLink(destination, origin = '', waypoints = '', dir_action = '', travelmode = '') {
    const template = URITemplate(process.env.VUE_APP_GOOGLE_MAPS_DIRECTIONS)
    const url = template.expand({
      api: 1,
      origin: origin,
      destination: destination,
      waypoints: waypoints,
      dir_action: dir_action,
      travelmode: travelmode
    }).replace(/\/$/, '')

    return url
  },

  searchLink(params) {
    const template = URITemplate(process.env.VUE_APP_GOOGLE_MAPS_SEARCH)

    const { lat, lon, id } = params
    const query = [lat, lon].join(',')

    const url = template.expand({
      api: 1,
      query,
      query_place_id: id
    }).replace(/\/$/, '')

    return url
  }
}
