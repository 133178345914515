<template>
  <div data-test-name="select-passengers">
    <v-menu
      :close-on-content-click="false"
      :model-value="isMenuOn"
      @update:model-value="isMenuOn = $event"
    >
      <template v-slot:activator="{ props }">
        <slot name="activator" :variant="variant" :activatorLabel="activatorLabel" :props="props">
          <button-selector
            class="button-selector"
            :class="{ activator: !readonly }"
            :text="activatorLabel"
            :icon="icon"
            id="passenger-selector-activator"
            :isChecked="!isMenuOn"
            :variant="variant"
            v-bind="props"
          />
        </slot>
      </template>

      <v-card
        class="bg-white border passenger-selector-dialog"
        width="300"
      >
        <v-row no-gutters class="mx-6 my-2 text-subtitle-1">
          <v-col cols="6" class="d-flex align-end">
            <div class="mx-5">{{ $t('Adults') }}</div>
          </v-col>
          <v-col cols="6" class="d-flex">
            <button-iterator
              @click="decrementAdult"
              :disabled="value.adults <= 1 || readonly"
              icon="mdi-minus"
              data-test-name="button-decrement-adults"
            />
            <div class="text-h6 mx-2" data-test-name="text-adults-count">{{ value.adults }}</div>
            <button-iterator
              @click="incrementAdult"
              :disabled="maxPassengers || readonly"
              icon="mdi-plus"
              data-test-name="button-increment-adults"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mx-6 my-2">
          <v-col cols="6" class="d-flex align-end">
            <div class="mx-5">{{ $t('Children') }} <br />
              <span class="text-caption">{{ $t('Age') }} 2-12</span>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex">
            <button-iterator
              @click="decrementChild"
              :disabled="value.children < 1 || readonly"
              icon="mdi-minus"
              data-test-name="button-decrement-children"
            />
            <div class="text-h6 mx-2" data-test-name="text-adults-count">{{ value.children }}</div>
            <button-iterator
              @click="incrementChild"
              :disabled="maxPassengers || readonly"
              icon="mdi-plus"
              data-test-name="button-increment-children"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import ButtonSelector from '../buttons/ButtonSelector'
import ButtonIterator from '../buttons/ButtonIterator'

export default {
  name: 'PassengersSelector',
  components: {
    ButtonIterator,
    ButtonSelector,
  },
  emits: ['input'],
  data() {
    return {
      isMenuOn: false,
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      default: '$customUsers',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'outlined'
    },
  },
  computed: {
    childrenCount() {
      return this.value.children ? Number(this.value.children) : 0
    },
    adultsCount() {
      return this.value.adults ? Number(this.value.adults) : 0
    },
    maxPassengers() {
      return (this.childrenCount + this.adultsCount) >= 9
    },
    activatorLabel() {
      let label = ''
      if (this.adultsCount > 1){
        label = `${this.adultsCount} ${this.$t('Adults')}`
      } else if (this.adultsCount === 1){
        label = `${this.adultsCount} ${this.$t('Adult')}`
      }
      if (this.childrenCount > 1){
        label += ` - ${this.childrenCount} ${this.$t('Children')}`
      } else if (this.childrenCount === 1){
        label += ` - ${this.childrenCount} ${this.$t('Child')}`
      }
      return label
    },
  },
  methods: {
    decrementChild() {
      const childrenCount = this.value.children > 0 ? this.value.children - 1 : 0
      this.updatePassengers({children: childrenCount})
    },
    incrementChild(){
      this.updatePassengers({children: this.value.children + 1})
    },
    incrementAdult(){
      this.updatePassengers({adults: this.value.adults + 1})
    },
    decrementAdult() {
      const adultsCount = this.value.adults > 1 ? this.value.adults - 1 : 0
      this.updatePassengers({adults: adultsCount})
    },
    updatePassengers(updatedParameter) {
      this.$emit('input', {...this.value, ...updatedParameter})
    }
  }
}
</script>
