<template>
  <v-btn
    elevation="0"
    width="100%"
    height="44"
    rounded
    variant="outlined"
    color="primary"
    @click="onClick"
    class="mb-3 mb-sm-8 mt-sm-3 text-button"
  >
    <template #prepend>
      <v-icon class="px-4">{{ icon }}</v-icon>
    </template>
    <slot name="default" />
    <span
      class="px-3 text-textPrimaryColor"
      :class="{ 'caption-text' : $vuetify.display.mdAndDown }"
    >
    {{ caption }}
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'LoginServiceButton',
  emits: ['click'],
  props: {
    caption: String,
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
  .caption-text {
    font-size: 0.8rem;
  }
</style>
