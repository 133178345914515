import itineraryService from './itineraryService'
import google from './google'
import { EventTypes } from './itemTypes'

const today = itineraryService.formatDateTime(new Date(), 'D')
const NODATA = 'nodata'
let Hotel = null

export default {

  /**
   * If an event has only one address (such as hotel, restaurant, etc…) clicking on map icon would ONLY show a point on the map.
   * If an event has two addresses (such as flight, train, driving, transit, etc…)
   * then clicking the map icon for that item would show the direction between the two points.
   */
  mapPoint(index, records) {
    let locations = records.at(index)?.header?.locations
    if (locations && locations.length === 1 && locations?.at(0)?.latLon) {
      //single point
      const { lat, lon } = locations.at(0).latLon
      //id will only work for places searched via google APIs;
      //id is a nice to have in this case and will only show the title
      const ids = Object.values(records.at(index))
        .map(item => item.roData?.id)
        .filter(id => id !== undefined)
      const id = ids.at(0)

      return google.searchLink({ lat, lon, id })
    } else if (locations && locations.length === 2) {
      //point to point
      return google.directionsLink(this.getLocationLatLon(records.at(index), 1), this.getLocationLatLon(records.at(index), 0))
    }
  },

  buildDirections(index, records) {
    if (index === 0) //first point
      return this.getDirectionsLink(null, records[index])
    /*else if ((index+1) === records.length) //last point
      return this.getDirectionsLink(records[index], null)*/
    else
      return this.getDirectionsLink(records[index - 1], records[index])
  },

  buildMultipleDirections(records, isMobile, date = null ) {
    const recordsWithCoordinates = this.getPreparedCalendarRecords(records, date)
    const locations = this.getCalendarLocations(recordsWithCoordinates, date)

    // case for always adding "your location" as the start point for the current day map
    if (today === itineraryService.formatDateTime(date, 'D') && !!locations.length) {
      locations.unshift({ lat: '', lon: '' })
    }

    return this.getMultipleDirectionsLink(locations, isMobile)
  },

  buildTransferDirections(records, isMobile) {
    const transferRecords = records.filter(record => record.header.locations.length === 2)
    const locations = transferRecords.map(record => record.header.locations[0].latLon)

    // the case when user don't get back to trip origin
    const origin = transferRecords[0].header.locations[0].placeID
    const destination = transferRecords[transferRecords.length - 1].header.locations[1].placeID
    if (origin !== destination) {
      locations.push(transferRecords[transferRecords.length - 1].header.locations[1].latLon)
    }

    return this.getMultipleDirectionsLink(locations, isMobile)
  },

  /**
  Rules for buiding directions:
    - if a pair of adjacent items both do have location, show directions b/w them
    - if only one item is missing location, assume that it’s “pickup from your hotel”,
      and use that as item’s origin location and show directions
    - if both items are missing location, don’t show direction at all
      (e.g. a tour followed by a show would look weird with directions to and from your hotel)
  */
  getDirectionsLink(origin, destination) {
    const originLatLon = this.getLocation(origin, true)
    const destinationLatLon = this.getLocation(destination, false)
    if (originLatLon === NODATA || destinationLatLon === NODATA)
      return ''
    else if (originLatLon === destinationLatLon)
      return ''
    else
      return google.directionsLink(destinationLatLon, originLatLon)
  },

  getMultipleDirectionsLink(locations, isMobile) {
    // google map provides constraints for displaying limited number of waypoints for different platforms
    // desktop it's maximum 9 waypoints and for mobile maximum is 3 waypoints, origin and destination are not related to waypoints
    const desktopWaypoints = 9, mobileWaypoints = 3
    const waypoints = isMobile ? mobileWaypoints : desktopWaypoints
    const travelmode = isMobile ? 'transit' : ''
    const maxLocations = waypoints + 2

    let originLatLon = ''
    let destinationLatLon = ''
    let waypointsLatLon = ''
    let dir_action = ''

    locations = locations.splice(0, maxLocations)
    //fallback if no locations
    if (locations.length === 0) return ''
    //fallback if only one location available
    if (locations.length === 1) {
      const location = locations[0].lat + ',' + locations[0].lon
      return google.directionsLink(location, location)
    }

    for (let [i, val] of locations.entries()) {
      if (i === 0) {
        originLatLon = val.lat && val.lon ? val.lat + ',' + val.lon : ''
      }
      else if (i === locations.length - 1) {
        destinationLatLon = val.lat + ',' + val.lon
      } else {
        waypointsLatLon += val.lat + ',' + val.lon + '|'
      }
    }

    return google.directionsLink(destinationLatLon, originLatLon, waypointsLatLon, dir_action, travelmode)
  },

  /**
    returns address based on the record type
    isOrigin flag is used to identify whether the location is used as origin (this is mostly used for
    flights as they have departure/arrival airports)
  */
  getLocation(record, isOrigin = false) {
    if (record === null) return ''
    let location = ''
    switch (itineraryService.getEventType(record)) {
    case EventTypes.FLIGHT:
      location = this.getAirportLocation(record, isOrigin)
      break
    case EventTypes.LODGING:
      location = this.getLocationLatLon(record, 0, isOrigin)
      Hotel = location
      break
    default:
      location = this.getLocationLatLon(record, 0, isOrigin)
      break
    }
    return location
  },

  getAirportLocation(record, isOrigin) {
    if (isOrigin)
      return this.getLocationLatLon(record, 1)
    else
      return this.getLocationLatLon(record)
  },

  getLocationLatLon(record, index = 0, isOrigin = false) {
    if (record.header.locations[index].latLon)
      return record.header.locations[index].latLon.lat + ',' + record.header.locations[index].latLon.lon
    else if (isOrigin && Hotel !== null)
      return Hotel
    else
      return NODATA
  },

  getPreparedCalendarRecords(records, date) {
    const currentDate = itineraryService.formatDateTime(date, 'L')
    const currentTimestamp = itineraryService.formatDateTime(new Date(), 'X')

    let preparedRecords =  records.filter(el => {
      const isTodayEvent = currentDate === itineraryService.formatDateTime(el.header?.startDateTime, 'L')
        || currentDate === itineraryService.formatDateTime(el.header?.endDateTime, 'L')
      const hasCoordinates = Object.prototype.hasOwnProperty.call(el.header?.locations?.[0], 'latLon')
      return date ? isTodayEvent && hasCoordinates : hasCoordinates
    })
    // arrange today upcoming records
    if (!!date && today === itineraryService.formatDateTime(date, 'D')) {
      preparedRecords = preparedRecords.filter(el => {
        const isUpcomingEvent = currentTimestamp <= itineraryService.formatDateTime(el.header?.endDateTime, 'X')
        const showEveningHotel = Object.prototype.hasOwnProperty.call(el, EventTypes.LODGING) && today === itineraryService.formatDateTime(el.header?.startDateTime, 'D')
        return isUpcomingEvent || showEveningHotel
      })
    }
    return preparedRecords
  },

  getCalendarLocations(records) {
    return records.reduce((res, el, i, arr) => {
      //get proper transfer/flight coordinates
      if (el.header.locations.length === 2) {
        const startDate = itineraryService.formatDateTime(el.header?.startDateTime, 'D')
        const endDate = itineraryService.formatDateTime(el.header?.endDateTime, 'D')
        if (startDate === endDate) {
          res = [...res, el.header.locations[0].latLon]
          res = [...res, el.header.locations[1].latLon]
        } else {
          if (i === 0 && arr.length > 1)
            res = [...res, el.header.locations[1].latLon]
          else
            res = [...res, el.header.locations[0].latLon]
        }
      }
      //avoid hotel repeat
      else if (Object.prototype.hasOwnProperty.call(el, EventTypes.LODGING)
        && !Object.prototype.hasOwnProperty.call(el.header, 'nightAtHotel')) {
        res = [...res]
      }
      else {
        res = [...res, el.header.locations[0].latLon]
      }
      return res
    }, [])
  }
}
