<template>
  <v-col
    cols="12"
    class="pagination-container d-flex justify-center flex-row align-center"
    data-test-name="content-pagination-extended"
  >
    <v-btn
      class="custom-navigation-button"
      color="primary"
      icon
      height="32"
      width="32"
      data-test-name="button-start"
      :disabled="isStartButtonDisabled"
      @click="$emit('startButtonClicked')"
    >
      <v-icon>mdi-chevron-double-left</v-icon>
    </v-btn>
    <slot/>
    <v-btn
      v-if="isEndButtonVisible"
      data-test-name="button-end"
      class="custom-navigation-button"
      color="primary"
      icon
      height="32"
      width="32"
      :disabled="isEndButtonDisabled"
      @click="$emit('endButtonClicked')"
    >
      <v-icon>mdi-chevron-double-right</v-icon>
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: 'ExtendedPagination',
  emits: ['startButtonClicked', 'endButtonClicked'],
  props: {
    isStartButtonDisabled: {
      type: Boolean,
      default: false,
    },
    isEndButtonDisabled: {
      type: Boolean,
      default: false,
    },
    isEndButtonVisible: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  .theme--light.v-btn.v-btn--disabled .v-icon {
    color: rgb(var(--v-theme-primary)) !important;
    opacity: 0.6;
  }
  .custom-navigation-button {
    margin: 0.3rem 0.25rem;
  }
}
</style>
