<template>
  <v-container>
    <v-row class="pa-4 pa-md-16">
      <v-col cols="12" align="center">
        <h1 class="text-h3 text-sm-h2 font-weight-bold" data-test-name="text-title">{{ applyButtonCaption }}</h1>
        <v-row
          v-if="headerButton"
          justify='center'
          class="px-6 py-4 text-body-2"
          align='center'
        >
          <span data-test-name="text-description">{{ headerButton.description }}</span>
          <v-btn
            data-test-name="button"
            class="font-weight-medium"
            variant="text"
            color="primary"
            @click="$emit('clickHeaderButton', headerButton.callback)"
          >
            {{ headerButton.caption }}
          </v-btn>
        </v-row>
      </v-col>
      <v-row justify="center">
        <v-col cols="12" sm="6" xl="4" align="center" class="py-0 px-2 px-md-8">
          <slot name="content"/>
          <slot name="error">
            <v-col cols="12" align="center" class="pa-0">
              <label class="text-red-lighten-4 text-body-2" data-test-name="text-error-message">
                {{ errorMessage }}
              </label>
            </v-col>
          </slot>

          <v-btn
            width="100%"
            color="primary"
            rounded
            size="large"
            data-test-name="button-sign-in"
            :disabled="!applyButtonEnabled"
            @click="handleClickApply"
          >
            {{ applyButtonCaption }}
          </v-btn>
        </v-col>
        <v-col
          v-if="!hideAlternativeLoginButtons"
          cols="12"
          sm="6"
          xl="4"
          align="center"
          class="py-sm-0 px-2 px-md-8"
          :style="{ 'border-left': $vuetify.display.xs ? 'none': '1px solid rgb(var(--v-theme-primary))' }"
        >
          <login-service-button
            data-test-name="button-sign-in-with-google"
            :caption="$t('Sign_in_with_Google')"
            @click="google"
          >
            <template v-slot:default>
              <google-icon />
            </template>
          </login-service-button>
          <login-service-button
            data-test-name="button-sign-in-with-facebook"
            :caption="$t('Sign_in_with_Facebook')"
            @click="facebook"
          >
            <template v-slot:default>
              <facebook-icon />
            </template>
          </login-service-button>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-caption" data-test-name="text-notes">
            This site is protected by reCAPTCHA and the Google&nbsp;<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a>&nbsp; and &nbsp;
            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a>&nbsp; apply.
          </div>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import LoginServiceButton from '@/components/authorization-page/LoginServiceButton'
import {
  signInWithGoogle,
  signInWithFacebook
} from '@/helpers/firebase/firebase'
import GoogleIcon from '@/components/icons/GoogleIcon'
import FacebookIcon from '@/components/icons/FacebookSigninIcon'

export default {
  name: 'AuthorizationPageCommonWrapper',
  emits: ['clickApply', 'clickHeaderButton'],
  components: {
    LoginServiceButton,
    GoogleIcon,
    FacebookIcon,
  },
  props: {
    applyButtonCaption: String,
    applyButtonEnabled: Boolean,
    errorMessage: String,
    headerButton: Object,
    hideAlternativeLoginButtons: Boolean
  },
  methods: {
    handleClickApply() {
      this.$emit('clickApply')
    },
    google() {
      signInWithGoogle()
    },
    facebook() {
      signInWithFacebook()
    },
  },
}
</script>
