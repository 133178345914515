import axios from 'axios'
import { allLanguages } from '@/helpers/i18n'
import mitt from 'mitt'
import { userSignOut } from '@/helpers/firebase/firebase'
const URITemplate = require('urijs/src/URITemplate')
const cloneDeep = require('lodash.clonedeep')
import { OPEN_FEEDBACK_ON_LOGIN } from '@/mixins/FeedbackFormMixin'
import { logAppError } from '@/logger'

const eventBus = mitt()

const defaultLanguage = () => {
  let navLanguages = navigator.languages || [navigator.language]
  navLanguages = [...new Set(navLanguages.map(lang=>lang.replace(/-\S+/g, '')))]
  return navLanguages.find(lang => allLanguages.includes(lang)) || 'en'
}

export const defaultSettings = {
  homePlaceID: '',
  dateFormat: 'MM-DD-YYYY',
  timeFormat: 'h:mm A', //1:45 pm hour format,
  language: defaultLanguage(),
  currency: 'USD',
  view: process.env.VUE_APP_UI_DEFAULT_ITINERARY_VIEW
}

export const defaultPreferences = {
  hotelMembership: [],
  airlineMembership: [],
  preferredFlightClass: null,
  preferredHotelClass: null,
  preferredRestaurantRating: null,
}

export const initUser = {
  id: '',
  extID: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  userAvatar: '',
  settings: defaultSettings,
  preferences: null,
}

export const state ={
  user: initUser
}

export const mutations =  {
  SET_USER: (state, payload) => {
    const copyPayload = {...payload}
    if(!('phone' in copyPayload)) {
      copyPayload.phone = ''
    }
    state.user = copyPayload
  },
  SET_VIEW: (state, payload) => {
    state.user.settings.view = payload
  },
  SET_USER_TOKENS(state, payload) {
    state.accessToken = payload?.accessToken
    state.refreshToken = payload?.refreshToken
  }
}

export const getters = {
  DATE_FORMAT: state => {
    return state?.user?.settings?.dateFormat
  },
  TIME_FORMAT: state => {
    return state?.user?.settings?.timeFormat
  },
  USER: state => {
    return state?.user
  },
  USER_ACCESS_TOKEN: state => {
    return state?.accessToken
  },
  USER_REFRESH_TOKEN: state => {
    return state?.refreshToken
  },
  VIEW: state => {
    return state?.user?.settings?.view
  },
  CURRENCY: state => {
    return state?.user?.settings?.currency
  },
  IS_ANONYMOUS: state => {
    return typeof state?.user?.extID === 'undefined' || state?.user?.extID === ''
  },
  IS_NEW: state => {
    return typeof state?.user?.id === 'undefined' || state?.user?.id === ''
  },
  IS_ADMIN: state => {
    return state?.user?.role === 'ADMIN'
  }
}

export const actions = {
  VIEW: ({ commit }, payload) => {
    commit('SET_VIEW',  payload)
  },
  USER_LOGIN: async ({ getters, dispatch, commit }, payload) => {

    //login by external ID
    const template = URITemplate(process.env.VUE_APP_USER_EXTID_URL)
    const url = template.expand({
      id: payload.uid
    }).replace(/\/$/, '')
    await axios.get(url)
      .then(async (response) => {
        // see if anon user needs to be merged
        if (!getters.IS_NEW && getters.IS_ANONYMOUS) {
          await dispatch('MERGE_USER', {
            id: response.data.user.id,
            srcId: getters.USER.id
          })
          // we need to save preferences selected by ANONYMOUS user after login into real user account
          const mergedPreferences = {
            preferredHotelClass: getters.USER.preferences?.preferredHotelClass || response.data.user.preferences?.preferredHotelClass || defaultPreferences.preferredHotelClass,
            preferredFlightClass: getters.USER.preferences?.preferredFlightClass || response.data.user.preferences?.preferredFlightClass || defaultPreferences.preferredFlightClass,
            preferredRestaurantRating: getters.USER.preferences?.preferredRestaurantRating || response.data.user.preferences?.preferredRestaurantRating || defaultPreferences.preferredRestaurantRating,
            hotelMembership: getters.USER.preferences?.hotelMembership?.length ? getters.USER.preferences.hotelMembership : response.data.user.preferences?.hotelMembership || defaultPreferences.hotelMembership,
            airlineMembership: getters.USER.preferences?.airlineMembership?.length ? getters.USER.preferences.airlineMembership : response.data.user.preferences?.airlineMembership || defaultPreferences.airlineMembership,
          }
          dispatch('UPDATE_USER', {
            ...response.data.user,
            preferences: mergedPreferences
          })
        } else {
          commit('SET_USER', response.data.user)
        }
        commit('SET_USER_TOKENS', response.data)

        const isOpenFeedbackForm = localStorage.getItem(OPEN_FEEDBACK_ON_LOGIN)
        if(isOpenFeedbackForm){
          localStorage.removeItem(OPEN_FEEDBACK_ON_LOGIN)
          eventBus.emit('openFeedbackForm')
        }
      })
      .catch(async (error) => {
        if (error && error.response && error.response.status === 404) {
          // it means we don't have user with extID in our DB and should create new user or merge external user cedantials with internal
          const [fname, lname] = payload.displayName ? payload.displayName.split(' ') : [null, null]
          const cDate = new Date()
          const newUser = {...cloneDeep(initUser),
            id:           getters.IS_NEW ? '' : getters.USER.id,
            extID:        payload.uid,
            firstName:    fname,
            lastName:     lname,
            email:        payload.email,
            phone:        payload.phone,
            userAvatar:   payload.photoURL,
            timezoneOffset: cDate.getTimezoneOffset() * -1, // getTimozoneOffset returns the amount of minutes required to get UTC time from getTime()
            preferences:  getters.USER.preferences,
          }
          if (getters.IS_NEW) {
            await dispatch('ADD_NEW_USER', { user: newUser })
          } else {
            await dispatch('UPDATE_USER', newUser)
              .catch(async (error) => {
                // TODO this temporary fixes problem when user has invalid ID (backend doesn't recognize user with this ID).
                // In this specific scenario we don't have user with extId and we don't have user with internal ID.
                // For now we create new user on FE side to handle impossibility user to authorize
                if (error && error.response && error.response.status === 404 || error.response.status === 500) {
                  await dispatch('ADD_NEW_USER',{ user: {
                    ...newUser,
                    id: '',
                  }})
                }
              })
          }
        } else {
          logAppError(error)
        }
      }).
      finally(() => {
        dispatch('LOADING_DIALOG', null)
      })
  },
  SET_USER: ({ commit }, payload) => {
    commit('SET_USER', payload)
  },
  LOGOUT: async ({ commit }) => {
    await userSignOut()
    commit('SET_USER', initUser)
    commit('SET_USER_TOKENS', {})
  },
  ADD_NEW_USER: async ({ commit }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_URL)
    const url = template.expand({
      id: payload.id
    }).replace(/\/$/, '')
    const copyPayload = {...payload}
    const copyUser = {...(copyPayload.user || {})}
    copyUser.timezoneOffset = (new Date()).getTimezoneOffset() * -1
    copyPayload.user = copyUser
    return await axios.post(url, copyPayload)
      .then(({ data }) => {
        commit('SET_USER', data.user)
        commit('SET_USER_TOKENS', data)
      })
  },
  /**
    merge user used when anon user signs in;
    payload.id -- target user
    payload.srcId -- source user
   */
  MERGE_USER: async (_, payload) => {
    if (payload.id === payload.srcId) {
      return
    }
    const template = URITemplate(process.env.VUE_APP_USER_URL + '/merge/{srcId}')
    const url = template.expand({
      id:     payload.id,
      srcId:  payload.srcId
    }).replace(/\/$/, '')

    return await axios.post(url, payload)
  },
  UPDATE_USER: async ({ commit, getters }, payload) => {
    const template = URITemplate(process.env.VUE_APP_USER_URL)
    const url = template.expand({
      id: payload.id || getters.USER.id
    }).replace(/\/$/, '')
    return await axios.put(url, { user: { ...getters.USER, ...payload}})
      .then(({data}) => {
        commit('SET_USER', data.user)
        commit('SET_USER_TOKENS', data)
      })
  },
  // returns accessToken, also sets USER obj with the new one
  REFRESH_TOKEN: async ({ commit }) => {
    const template = URITemplate(process.env.VUE_APP_USER_TOKEN_URL)
    const url = template.expand().replace(/\/$/, '')
    try {
      const response = await axios.post(url)
      //response.data will contain user object
      if (response?.data) {
        commit('SET_USER_TOKENS', response.data)
      }
    } catch (err) {
      logAppError(err)
    }
  },
}

Object.freeze(initUser)

export default {
  state,
  getters,
  mutations,
  actions
}
