<template>
  <v-card elevation="0">
    <v-row class="mt-1 mt-sm-2" no-gutters>
      <v-col cols="6" v-if="$vuetify.display.xs" class="mx-0">
        <passengers-selector
          :value="travelers"
          :readonly="isReadOnly"
          @input="updateTravelers"
          name="generate-custom-event"
          class="passengers-selector"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="totalTravelersNumber"
              :label="$t('Travelers') + '*'"
              class="pa-0 ma-0 mr-2 trigger-change-travelers"
              :class="{ activator: !isReadOnly }"
              bg-color="#fff"
              base-color="primary"
              color="primary"
              variant="outlined"
              :disabled="isReadOnly"
              readonly
              hide-details
              prepend-inner-icon="$customTravelers"
              v-bind="props"
            />
          </template>
        </passengers-selector>
      </v-col>
      <v-col cols="6" sm="12">
        <event-booking
          :isBooked="isBooked"
          :readonly="isReadOnly"
          @updateBooked="updateBooked"
          class="d-flex justify-end"
        />
      </v-col>
    </v-row>

    <v-row v-if="imageURL || description || Number(stars) > 0 || Number(userRating?.aggregateRating) > 0">
      <v-col cols="12" sm="6" class="pt-4 pt-sm-0 pt-0 pb-2 pr-0 pr-sm-6">
        <v-img
          :src="thumbnailURL"
          class="rounded-lg"
          @error="handleImageError"
          data-test-id="restaurant-details-tool__image"
          :height="$vuetify.display.mdAndDown ? '220' : '280'"
          cover
        />
      </v-col>
      <v-col cols="12" sm="6" class="pt-3 pt-sm-0">
        <v-col v-if="!$vuetify.display.mdAndDown" class="pa-0">
          <v-divider class="pt-6 my-0"></v-divider>
        </v-col>
        <v-row>
          <v-col cols="12" sm="6" class="d-flex">
            <v-rating
              v-if="stars && stars > 0"
              :model-value="stars"
              empty-icon=""
              color="primary"
              density="compact"
              readonly
              size="small"
              data-test-id="lodging-details-tool__price-level"
            />

            <v-divider v-if="stars && stars > 0" class="divider mx-2" vertical />

            <user-rating
              :userRating="userRating"
              data-test-id="lodging-details-tool__user-rating"
            />
          </v-col>
        </v-row>
        <v-row v-if="description">
          <v-col cols="12" class="px-6 px-sm-4">
            <div class="font-weight-medium">{{ $t('Description')}}</div>
            <div class="lodging-details-tool__description" data-test-id="lodging-details-tool__description">
              {{ description }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="py-0 my-0 mt-6">
      <v-col cols="12" sm="6" class="pb-0 pb-sm-6 pb-md-0">
        <v-text-field
          :model-value="simpleAddress"
          :label="$t('Address')"
          :readonly="isReadOnly"
          @update:model-value="updateAddress"
          class="pa-0 ma-0"
          variant="outlined"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          data-test-id="lodging-details-tool__simpleAddress"
          hide-details="auto"
          :rules="addressRules"
        >
          <template v-slot:prepend-inner>
            <v-icon size="large" class="pr-2">$customHouse</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="4" class="mb-4 mb-md-0 pl-sm-4 pr-4 text-truncate">
        <v-select
          prepend-inner-icon="$customCurrency"
          :model-value="totalPrice?.currency"
          :label="$t('Currency')"
          :readonly="isReadOnly"
          :items="currencyList"
          class="pa-0 ma-0"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          variant="outlined"
          hide-details
          data-test-id="lodging-details-tool__currency"
          @update:model-value="updateCurrency"
        />
      </v-col>
      <v-col cols="6" sm="2" class="pb-0 pb-sm-6 pb-md-0">
        <v-currency-field
          :model-value="Number(totalPrice?.amount)"
          @update="updateAmount"
          variant="outlined"
          class="pa-0 ma-0"
          color="primary"
          :label="`${$t('Total_price')}`"
          :readonly="isReadOnly"
        />
      </v-col>
    </v-row>

    <v-row class="mt-lg-0">
      <v-col cols="12" class="pt-sm-0 mt-sm-0 mb-8">
        <v-textarea
          :model-value="notes"
          :label="$t('Notes')"
          :auto-grow="false"
          :readonly="isReadOnly"
          @update:model-value="updateNotes"
          class="pa-0 ma-0 mb-8"
          rows="3"
          bg-color="#fff"
          base-color="primary"
          color="primary"
          variant="outlined"
          no-resize
          hide-details="auto"
          :rules="notesRules"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCurrencySelectList } from '@/helpers/currency'
import EventBooking from '@/components/popups/event-booking/EventBooking'
import { getItemTypeByType, convertFromStringToNumber } from '@/helpers'
import PassengersSelector from '@/components/popups/PassengersSelector'
import VCurrencyField from '@/components/VCurrencyField'
import UserRating from '@/components/UserRating'

export default {
  name: 'LodgingDetailsTool',
  components: {
    EventBooking,
    PassengersSelector,
    VCurrencyField,
    UserRating,
  },
  emits: [
    'updateNotes',
    'updateTravelers',
    'updateBooked',
    'updateAddress',
    'updateTitle',
    'updatePrice'
  ],
  props: {
    infoList: {
      type: Map,
    },
    imageURL: {
      type: String,
    },
    stars: {
      type: Number,
      default: 0,
    },
    userRating: {
      type: Object,
    },
    isBooked: {
      type: Boolean,
    },
    simpleAddress: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    eventRating: {
      type: Number,
      default: 1,
    },
    description: {
      type: String,
      default: '',
    },
    totalPrice: {
      type: Object,
    },
    travelers: {
      type: Object,
    },
    eventType: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    isTemplateView: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: String,
    },
    addressRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    notesRules: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      currencyList: [],
    }
  },
  created() {
    this.currencyList = getCurrencySelectList(this.isMobile)
  },
  computed: {
    ...mapGetters({
      isEditor: 'IS_EDITOR',
      isMobile: 'IS_MOBILE',
    }),
    totalTravelersNumber() {
      return (this.travelers?.adults || 0) + (this.travelers?.children || 0)
    },
    thumbnailURL() {
      return this.imageURL || getItemTypeByType(this.eventType).defaultImageUrl
    },
    isReadOnly() {
      return this.readonly
    }
  },
  methods: {
    updateNotes(value) {
      this.$emit('updateNotes', value)
    },
    updateTravelers(value){
      this.$emit('updateTravelers', value)
    },
    updateBooked(value) {
      this.$emit('updateBooked', value)
    },
    updateAddress(value) {
      this.$emit('updateAddress', value)
    },
    updateCurrency(value) {
      this.$emit('updatePrice', { currency: value, amount: this.totalPrice?.amount })
    },
    updateAmount(value) {
      this.$emit('updatePrice', { currency: this.totalPrice?.currency, amount: convertFromStringToNumber(value) })
    },
    handleImageError() {
      this.$emit('handleImageError')
    },
  },
}
</script>
