<template>
  <v-card data-test-name="card-event">
    <v-card-title style="position: inherit; z-index: 100; top: 0; left: 0; margin: 0; padding: 0; width: 100%;">
      <v-row class="d-flex justify-space-between px-6 bg-blue-grey-lighten-4" no-gutters>
        <v-col
          cols="4"
          sm="3"
          md="2"
          class="pl-0 pl-sm-4 py-2"
        >
            <event-modal-title-icon
              :label="event.label"
              :icon="event.icon"
              :color="event.color"
            />
        </v-col>
        <v-col
          cols="6"
          sm="7"
          md="8"
          v-if="1 === 2"
          class="d-flex align-center px-0 px-sm-4">
          <v-text-field
            :readonly="isReadOnly"
            :model-value="title"
            @update:model-value="title = $event"
            :label="!title ? getEventLabelText : ''"
            class="font-weight-medium text-h6"
            :class="{ 'no-pointer-events': isReadOnly }"
            variant="underlined"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            hide-details
            maxLength="200"
            type="text"
            data-test-name="input-event-title"
          />
        </v-col>
        <v-col cols="auto" class="py-1">
          <v-icon @click="cancelChanges" color="primary" data-test-name="icon">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mx-0 pa-0 bg-blue-grey-lighten-5" style="flex: 1; overflow-y: auto;">
      <v-row no-gutters>
        <v-col cols="12">
          <component
            :is="event.type"
            :eventType="event.type"
            :record="record"
            :title="title"
            :readonly="isReadOnly"
            :isDialogVisible="isOpened"
            :createNew="isCreateMode"
            :defaultStartDateTime="eventStartDateTime"
            :isSubmitButtonLoading="isSubmitButtonLoading"
            @cancel="cancelChanges"
            @close="cancelChanges"
            :headerHeight="headerHeight"
            @input="addItineraryItem"
            @updateTitle="updateTitle"
            @deleteEvent="onDeleteEvent"
            @save="onClickSave"
            @setIsSearch="setIsSearch"
            ref="component"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AddFlightForm from '@/components/popups/events/flight/AddFlightForm'
import AddLodgingForm from '@/components/popups/events/lodging/AddLodgingForm'
import AddShoppingForm from '@/components/popups/events/shopping/AddShoppingForm'
import AddExerciseForm from '@/components/popups/events/exercise/AddExerciseForm'
import AddRelaxForm from '@/components/popups/events/relax/AddRelaxForm'
import AddRestaurantForm from '@/components/popups/events/restaurant/AddRestaurantForm'
import AddAttractionForm from '@/components/popups/events/attraction/AddAttractionForm'
import AddShowForm from '@/components/popups/events/show/AddShowForm'
import AddTourForm from '@/components/popups/events/tour/AddTourForm'
import AddCustomForm from '@/components/popups/events/custom/AddCustomForm'
import AddExploreForm from '@/components/popups/events/explore/AddExploreForm'
import AddInsuranceForm from '@/components/popups/events/insurance/AddInsuranceForm'
import AddCarRentalForm from '@/components/popups/events/car-rental/AddCarRentalForm'
import AddCruiseForm from '@/components/popups/events/cruise/AddCruiseForm'
import EventModalTitleIcon from '@/components/popups/events/EventModalTypePreview'
import { findEventByType, EventTypes, CATEGORY_TRANSPORTATION } from '@/helpers/itemTypes'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import { mapActions, mapGetters } from 'vuex'
import { logAppError } from '@/logger'
import AddTransportationForm from '@/components/popups/events/AddTransportationForm.vue'

export default {
  name: 'EventDialog',
  emits: ['close', 'cancel', 'checkFormState'],
  components: {
    EventModalTitleIcon,
    [EventTypes.ATTRACTION]:       AddAttractionForm,
    [EventTypes.CAR_RENTAL]:       AddCarRentalForm,
    [EventTypes.DRIVE]:            AddTransportationForm,
    [EventTypes.CRUISE]:           AddCruiseForm,
    [EventTypes.CUSTOM_EVENT]:     AddCustomForm,
    [EventTypes.EXERCISE]:         AddExerciseForm,
    [EventTypes.EXPLORE]:          AddExploreForm,
    [EventTypes.FLIGHT]:           AddFlightForm,
    [EventTypes.INSURANCE]:        AddInsuranceForm,
    [EventTypes.LODGING]:          AddLodgingForm,
    [EventTypes.TOUR]:             AddTourForm,
    [EventTypes.RELAX]:            AddRelaxForm,
    [EventTypes.RESTAURANT]:       AddRestaurantForm,
    [EventTypes.SHOPPING]:         AddShoppingForm,
    [EventTypes.SHOW]:             AddShowForm,
    [EventTypes.BUS]:              AddTransportationForm,
    [EventTypes.FERRY]:            AddTransportationForm,
    [EventTypes.TRAIN]:            AddTransportationForm,
    [EventTypes.TRANSFER]:         AddTransportationForm,
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    record: {
      type: Object,
      required: false,
    },
    isOpened: Boolean,
    isCreateMode: {
      type: Boolean,
      default: true,
    },
    eventStartDateTime: String,
  },
  data() {
    return {
      title: '',
      isSearch: false,
      isSubmitButtonLoading: false,
      headerHeight: 0,
    }
  },
  mounted() {
    if (this.$refs?.closeButtonRef && this.$refs?.titleRef) {
      this.headerHeight = this.$refs?.closeButtonRef?.$el?.clientHeight + this.$refs?.titleRef?.$el?.clientHeight
    }
  },
  computed: {
    ...mapGetters({
      currentItinerary: 'CURRENT_ITINERARY',
      user: 'USER',
      isEditor: 'IS_EDITOR',
    }),
    isLodgingEditable() {
      if (this.event.type === EventTypes.LODGING) {
        //not allow editing for nights and check out rows
        return !this.record?.header?.nightAtHotel && !this.record?.header?.checkOut
      }

      return true
    },
    isShowTitle() {
      return this.event.category !== CATEGORY_TRANSPORTATION && !this.isSearch
    },
    isReadOnly() {
      return !this.isEditor || !this.isLodgingEditable
    },
    getEventLabelText() {
      const event = findEventByType(this.event.type)
      return event ? `${this.$t(event.labelKey)}*` : ''
    },
  },
  methods: {
    ...mapActions({
      createEventManually: 'CREATE_EVENT_MANUALLY',
      showNotification: 'PUSH_APP_NOTIFICATION',
    }),
    async onClickSave(data) {
      this.isSubmitButtonLoading = true
      await this.createEventManually(data)
        .then((response) => {
          if (response.status === 200) {
            const item = response.data
            if (!this.isCreateMode) {
              commonAPIs.replaceItem(
                this.currentItinerary.header.itineraryID,
                this.user.id,
                item,
                this.record.header.itemID
              ).finally(() => {
              })
            } else {
              this.addItineraryItem({item: item})
            }
          }
          this.closeDialog()
        })
        .catch((err) => {
          logAppError(err)
        })
        .finally(() => {
          this.isSubmitButtonLoading = false
        })
      this.isItineraryCreating = false
    },
    async addItineraryItem(record) {
      if (!record.item) return
      if(record.item.header){
        await commonAPIs.addItems(
          this.currentItinerary.header.itineraryID,
          this.user.id,
          [ record.item ]
        )
          .finally(() => {
            this.closeDialog()
          })
      }
    },
    async onDeleteEvent() {
      try {
        await commonAPIs.removeItem(
          this.currentItinerary.header.itineraryID,
          this.user.id,
          this.record.header.itemID,
        )
        this.closeDialog()
      } catch (err) {
        logAppError(err)
      }
    },
    updateTitle(value) {
      this.title = value
    },
    cancelChanges() {
      const dirty = this.$refs.component.isDirty
      this.$emit('checkFormState', { dirty })
      this.$emit('cancel')
    },
    closeDialog() {
      this.$emit('close')
    },
    setIsSearch(v) {
      this.isSearch = v
    },
  }
}
</script>
<style>
.tabs-container {
  position: fixed;
  z-index: 1;
  background: #ECEFF1;
  width: 100%;
}
</style>