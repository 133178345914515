<template>
  <div data-test-name="page-terms-of-service">
    <v-col cols="12" lg="6" xl="8" class="mx-auto">
      <v-sheet class="py-4 py-sm-14 text-body-2 text-textPrimaryColor text-wrap">
        <h2
          class="pb-4 text-h4 text-sm-h3 font-weight-medium mt-4 text-center"
          data-test-name="title"
        >
          Terms of Service
        </h2>
        <p
          class="mb-6 mb-sm-10 text-center text-date"
          data-test-name="text-last-modified"
        >
          Last modified: March 18, 2022
        </p>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-welcome"
        >
          Welcome to Intelligent Travel Systems!
        </h3>
        <div data-test-name="text-welcome">
          <p>Thanks for using our travel planning service and website ("Services"). The Services are provided by Intelligent Travel Systems Inc. ("Intelligent Travel Systems", "We")</p>
          <p>We worked hard to make our Services useful and easy to use. By using our Services, you agree to these terms. These terms apply to all users who have signed up for an Intelligent Travel Systems account. Please read them carefully.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-travel-products"
        >
          Travel Products in Our Services
        </h3>
        <div data-test-name="text-travel-products">
          <p>Our Services provide information that help you plan a trip. We do not provide, own, or control any of the travel services and products that you can learn about or access through our Services, such as flights, accommodations, rental cars, itineraries, packages, or travel insurance (the "Travel Products"). The Travel Products are owned, controlled, or made available by third parties (the "Travel Providers") either directly (e.g., by an airline) or as an agent (e.g., by an online travel agency). The Travel Providers are responsible for the Travel Products. The Travel Providers’ terms and privacy policies as well as intellectual property rights apply when you use their Travel Products, so you must agree to and understand those terms. Further, the terms of the actual travel provider (airline, hotel, tour operator, etc.) apply to your travel, so you must also agree to and understand those terms. Your interaction with any Travel Provider accessed through our Services is at your own risk, we do not endorse or vouch for travel advisors or itineraries hosted on our platform, and we do not have any responsibility should anything go wrong with your booking or during your travel.</p>
          <p>Intelligent Travel Systems does not take fees to recommend Travel Products or Travel Providers over other Travel Products or Travel Providers. When Intelligent Travel Systems displays a Travel Product or Travel Provider as part of our Services, Intelligent Travel Systems makes a best effort to compare and recommend such Travel Product or Travel Provider solely based on how well it fits the user’s needs.</p>
          <p>Intelligent Travel Systems hosts content, including prices, made available by, or obtained from Travel Providers. We are in no way responsible for the accuracy, timeliness, or completeness of such content. Since we have no control over the Travel Products and do not verify the content uploaded by the Travel Providers, it is not possible for us to guarantee the prices, times, distances, and other information displayed on our Services. Prices change constantly and additional charges (e.g., payment fees, services charges, checked-in luggage fees, local taxes, and fees) may apply, so you should always check whether the price asked for a booking is the one you expected. Some Travel Products may also be sold in another currency than the one preset or chosen for the display of the search results. Any currency conversion is for information purposes only and should not be relied upon as accurate and real time; actual rates may vary and your payment provider (e.g., your credit card company) may charge conversion fees and apply another date’s currency rate.</p>
          <p>If you make a booking through our Services for Travel Products, that booking is made with the Travel Provider named on the booking page and our Services only act as a user interface. Accordingly, we have no responsibility for the booking or the Travel Product because we have no involvement in creating the description of the Travel Product, in defining the price and any fees, and in providing the Travel Products that you book. If you have any issues or disputes around your booking and/or the Travel Product, you agree to address and resolve these with the Travel Provider and not with us.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-your-content"
        >
          Your Content in Our Services
        </h3>
        <div data-test-name="text-your-content">
          <p>When you plan, book, execute, communicate, or comment on a trip using our Services, you provide us with (i.e., upload, submit, store, send, or receive) travel information and other content related to your or someone else’s planned or actual travel, such as locations, dates, transportation, activities, images, videos, files, and more (collectively the "Travel Information"). When you provide us with Travel Information using our Services, you retain ownership of any intellectual property rights that you hold in that content.</p>
          <p>You are solely responsible for Travel Information that you provide to us or others in our Services, and you represent and warrant that you are not providing Travel Information you do not have permission to share.</p>
          <p>When you provide Travel Information to or through our Services, you give Intelligent Travel Systems (and those we work with) an irrevocable, perpetual, non-exclusive, transferable, fully paid, worldwide license (with the right to freely sublicense) to use, host, store, reproduce, analyze, modify, copy, communicate, publish, display, and distribute such content. The rights you grant in this license are for the limited purpose of facilitating the planning, sharing, recommendation, booking, execution, and communication of your trips (as directed by you), of operating and improving our Services, and of developing new ones. This license is further restricted by our <router-link :to="{name: PRIVACY}">privacy policy</router-link>. This license continues even if you stop using our Services. You represent and warrant that you have the necessary rights to grant us this license for any content that you submit to our Services.</p>
          <p>You may remove your Travel Information from Intelligent Travel Systems at any time, but the license that you have granted will remain in effect. You understand that we do not control and are not responsible to review Travel Information. However, we reserve the right to review, edit, or delete any Travel Information at any time.</p>
          <p>If you become a registered user of an Intelligent Travel Systems, you are responsible for maintaining the secrecy of your passwords, login, and account information. You will be responsible for all use of our Services through your account, anyone using your password and login information (with or without your permission), and anyone whom you allow to access your Travel Information.</p>
          <p>If you have an Intelligent Travel Systems account, we may display, in our Services, your name, photo, and actions you take on Intelligent Travel Systems or on third-party services connected to your Intelligent Travel Systems account (such as when you share your itinerary in Facebook). We will respect the choices you make to limit sharing or visibility settings in your Intelligent Travel Systems account.</p>
          <p>You can find more information about how Intelligent Travel Systems uses and stores content in our <router-link :to="{name: PRIVACY}">privacy policy</router-link>.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-restrictions"
        >
          Restrictions in Using Our Services
        </h3>
        <div data-test-name="text-restrictions">
          <h4
            class="mb-2 font-weight-medium"
            data-test-name="text-subtitle-you-agree-with"
          >
            You agree not to do any of the following without prior written permission of Intelligent Travel Systems:
          </h4>
          <ol>
            <li>access the site with any manual or automated process for any purpose other than your personal use, or commercial use if you have a paid business account</li>
            <li>violate the restrictions in any robot exclusion headers on our Services or bypass or circumvent other measures employed to prevent or limit access to our Services</li>
            <li>deep-link to any portion of our Services for any purpose</li>
            <li>use any device, software, or routine that interferes or attempts to interfere with the normal operation of our Services or take any action that imposes an unreasonable load on our computer or network equipment</li>
            <li>reproduce, duplicate, copy, sell, trade, resell, or exploit our Services</li>
            <li>use any feature of our Services for any purpose that is unlawful, harmful, or otherwise objectionable or inappropriate as determined by us</li>
            <li>post or distribute any material on our Services that violates the rights of any third party or applicable law</li>
            <li class="mb-3">transmit any ad or promo materials on our Services</li>
          </ol>
          <p>We may offer our Services through both personal and business accounts. You agree not to use our Services through a personal account for any commercial purpose.</p>
          <p>We may, at our sole discretion, at any time and without advance notice or liability, suspend, terminate, or restrict your access to all or any component of our Services.</p>
          <p>In connection with your use of our Services, we may send you service announcements, administrative messages, and other information related to our Services and your use of our Services. We may also send you messages related to your Travel Information. You may opt out of some of those communications.</p>
          <p>Some of our Services are available on mobile devices. Do not use such Services in a way that distracts you and prevents you from obeying traffic or safety laws.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-privacy-and-copyright"
        >
          Privacy and Copyright Protection
        </h3>
        <div data-test-name="text-privacy-and-copyright">
          <p>Intelligent Travel Systems’ <router-link :to="{name: PRIVACY}">privacy policy</router-link> explains how we treat your data and protect your privacy when you use our Services. By using our Services, you agree that Intelligent Travel Systems can use such data in accordance with our privacy policy.</p>
          <p>We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S.</p>
          <p>We provide information to help copyright holders manage their intellectual property online. If you think somebody is violating your copyrights and want to notify us, you can <router-link :to="{name: CONTACT_US}">contact us</router-link>.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-property"
        >
          Intellectual Property
        </h3>
        <div data-test-name="text-property">
          <p>Our Services display some content that is not ours. We, along with our corporate affiliates, the Travel Providers, and other licensors own all the text, images, software, trademarks, service marks, or other material contained in our Services. If you use our Services through a personal account, you will not copy or transmit any of the material except for your personal, non-commercial use. If you use our Services through a business account, you agree to the additional commercial terms of that account. All copyright, trademark, and other proprietary rights notices presented in our Services must appear on all copies you print. Other product, service, or company designations in our Services belong to those respective third parties and may be mentioned in our Services for identification purposes only. You should contact the appropriate third party for more complete information regarding such designations and their registration status. Your use of and access to our Services does not grant you any license or right to use any of the marks included in our Services.</p>
          <p>In addition to content from affiliates and Travel Providers, we may also display content provided by other Intelligent Travel Systems users as permitted by our privacy policy. Third party-owned content and content provided by other Intelligent Travel Systems users are provided solely for your convenience to give you an integrated and helpful experience. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. Notwithstanding the aforementioned, we have no obligation to review content, and our failure to review content does not imply our consent with that content.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-modifying-and-terminating"
        >
          Modifying and Terminating Our Services
        </h3>
        <div data-test-name="text-modifying-and-terminating">
          <p>We are constantly changing and improving our Services. We may, at any time, add or remove functionalities or features, or suspend or terminate our Services altogether. We may also stop providing our Services to you or add or create new limits to our Services at any time.</p>
          <p>We believe that you own your information and that preserving your access to such data is important. If we discontinue or limit our Services, if possible, we will give you reasonable advance notice and a chance to get your information out of our Service.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-disclaimers"
        >
          Our Warranties, Disclaimers, and Liabilities
        </h3>
        <div data-test-name="text-disclaimers">
          <p>Our Services, all content and services provided in our Services, and all itineraries you create or obtain through our Services are provided on an "as is" and "as available" basis. Our content, including all tips, alerts, summaries, routes, map displays, and comparisons, is largely generated in an automated fashion. We cannot be comprehensive in the content we provide (e.g., we may not display all available Travel Providers and Products) and we may not always be accurate (e.g., in our estimates and calculations). Accordingly, we do not always provide you with the best possible information (e.g., optimal travel route or lowest available price). Intelligent Travel Systems expressly disclaims to the fullest extent permissible all warranties of any kind, whether express or implied, including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, and security and accuracy, as well as all warranties arising by usage of trade, course of dealing, or course of performance.</p>
          <p>We (together with our officers, directors, employees, representatives, shareholders, affiliates, and providers) to the extent permitted by law hereby expressly exclude any responsibility and liability for (a) any loss or damages to, or viruses that may infect, your computer equipment or other property as the result of your access to our Services, your downloading of any content from our Services, or your use of our Services or (b) any injury, death, loss, claim, act of god, accident, delay, or any direct, special, exemplary, punitive, indirect, incidental or consequential damages of any kind (including without limitation lost profits or lost savings), whether based in contract, tort, strict liability or otherwise, that arise out of or is in any way connected with: (i) any use of our Services or our content; (ii) any failure or delay (including without limitation the use of or inability to use any component of our Services for reservations or booking); or (iii) the performance or non-performance by us or any Travel Provider, even if we have been advised of the possibility of damages to such parties or any other party. Some states or countries do not allow this limitation of liability, so the limitations above may not apply or apply only partially to you.</p>
        </div>
        <h3
          class="mt-8 mb-4 text-sm-h6 text-body-1 font-weight-bold"
          data-test-name="text-subtitle-disclaimers"
        >
          About these Terms
        </h3>
        <div data-test-name="text-disclaimers">
          <p>These terms control the relationship between Intelligent Travel Systems and you. They do not create any third-party beneficiary rights.</p>
          <p>We may change the site and these terms at any time, in our sole discretion, and without notice to you. We will try to alert you to changes, but you are responsible for remaining knowledgeable about these terms. Your continued use of the site constitutes your acceptance of any changes to these terms, and any changes will supersede all previous versions of the terms. If you do not agree to these terms or any modified terms for our Services in the future, you should discontinue your use of our Services. Unless otherwise specified herein, all changes to these terms apply to all users, including those enrolled before the date the changes take effect. Further, we may terminate this agreement with you under these terms at any time by notifying you in writing (including by email) and/or, if you are a registered user, by cancelling your account and your access to your account.</p>
          <p>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify Intelligent Travel Systems and its affiliates, officers, agents, and employees from any claim, suit, or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.</p>
          <p>If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as acting in the future).</p>
          <p>If it turns out that a particular term is not enforceable, this will not affect any other terms.</p>
          <p>The laws of Delaware, U.S.A., will apply to any disputes arising out of or relating to these terms or the Services.</p>
          <p>For questions about these terms of service, please <router-link :to="{name: CONTACT_US}" data-test-name="link-to-contact-us-page">contact us</router-link>.</p>
          <p class="font-weight-medium">The Intelligent Travel Systems Team</p>
        </div>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { RouteNames } from '@/helpers/routerUtils'

export default {
  name: 'TermsOfService',
  created() {
    this.CONTACT_US = RouteNames.CONTACT_US
    this.PRIVACY = RouteNames.PRIVACY
  }
}
</script>

<style lang="scss" scoped>
  .text-wrap {
    line-height: 140%;
  }
  .text-date {
    color: rgb(var(--v-theme-secondary));
  }
  ol {
    list-style-type: none;
    counter-reset: num;
    padding: 0;
  }
  li::before {
    content: counter(num) '. ';
    counter-increment: num;
    font-weight: 500;
    padding-right: 9px;
  }
</style>
