import { logAppError } from '@/logger'
import { store } from '../store'
import axios from 'axios'

export const currencyList = [
  {key: 'AUD', value: 'Australian Dollar'},
  {key: 'BRL', value: 'Brazilian Real'},
  {key: 'GBP', value: 'British Pound Sterling'},
  {key: 'BGN', value: 'Bulgarian Lev'},
  {key: 'CAD', value: 'Canadian Dollar'},
  {key: 'CNY', value: 'Chinese Yuan'},
  {key: 'HRK', value: 'Croatian Kuna'},
  {key: 'CZK', value: 'Czech Republic Koruna'},
  {key: 'DKK', value: 'Danish Krone'},
  {key: 'EUR', value: 'Euro'},
  {key: 'HKD', value: 'Hong Kong Dollar'},
  {key: 'HUF', value: 'Hungarian Forint'},
  {key: 'ISK', value: 'Icelandic Króna'},
  {key: 'INR', value: 'Indian Rupee'},
  {key: 'IDR', value: 'Indonesian Rupiah'},
  {key: 'ILS', value: 'Israeli New Sheqel'},
  {key: 'JPY', value: 'Japanese Yen'},
  {key: 'MYR', value: 'Malaysian Ringgit'},
  {key: 'MXN', value: 'Mexican Peso'},
  {key: 'NZD', value: 'New Zealand Dollar'},
  {key: 'NOK', value: 'Norwegian Krone'},
  {key: 'PHP', value: 'Philippine Peso'},
  {key: 'PLN', value: 'Polish Zloty'},
  {key: 'RON', value: 'Romanian Leu'},
  {key: 'RUB', value: 'Russian Ruble'},
  {key: 'SGD', value: 'Singapore Dollar'},
  {key: 'ZAR', value: 'South African Rand'},
  {key: 'KRW', value: 'South Korean Won'},
  {key: 'SEK', value: 'Swedish Krona'},
  {key: 'CHF', value: 'Swiss Franc'},
  {key: 'THB', value: 'Thai Baht'},
  {key: 'TRY', value: 'Turkish Lira'},
  {key: 'USD', value: 'United States Dollar'},
]

export const getCurrencySelectList = (isMobile = false) => {
  return currencyList.map(item => ({title: item.key + (isMobile ? '' : ' ' + item.value), value: item.key}))
}

/**
Price formatter based on the user's locale.
parameters:
  amountInCents - number (ex 12356 or 123 or 123456)
  currency - currency to be used in formatting, defaults to USD (ex 'USD' or 'AUS')

TODO: Might need some tweeking to make sure it covers all use cases
*/
export const formatPrice = (totalPrice) => {
  if (!totalPrice) return ''
  let {amount, currency} = totalPrice
  if (!amount) return ''
  currency = currency || 'USD'
  return new Intl.NumberFormat(store.getters.USER.settings.language,
    {style: 'currency', currency: currency}).format(amount / 100)
}

/**
* Calculates price difference b/w new and current
*  Returns a difference
*    - positive if new price is more expensive
*    - negative if new is cheaper
*    - 0 if one of the prices is unknown, or if there is no difference
*/
export const calcPriceDelta = (newPrice, currentPrice) => {
  if (!currentPrice || !newPrice) return 0
  currentPrice = Number(currentPrice)
  newPrice = Number(newPrice)
  if (currentPrice === newPrice) return 0
  const delta = newPrice - currentPrice
  return delta
}

export const convertToUserCurrency = async (amount, from, to) => {
  let rv = 0
  let payload

  if (!!amount
    && !!from
    && !!to) {
    payload = {
      from: from,
      to: to,
      amount: amount
    }

    if (payload) {
      try {
        if (from === to) {
          rv = amount
        } else {
          let response = await axios.post(process.env.VUE_APP_CURRENCY_CONVERT, payload)
          if (response.status === 200 && response.data) {
            rv = response.data.convertedAmount
          }
        }
      } catch (err) {
        logAppError(err)
      }
    }
  }

  return rv
}

export const convertAmountToUIFormat = (amountFromServer) => {
  if (!amountFromServer) return 0
  return (Number(amountFromServer) / 100).toString()
}

export const convertFromStringToNumber = (amount) => {
  if(amount && !Number(amount)){
    return +(amount.toString().split(',').join('.'))
  }
  return amount
}

export const convertAmountToServerFormat = (amountFromServer) => Number((convertFromStringToNumber(amountFromServer) * 100).toFixed())
