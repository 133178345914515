<template>
  <v-text-field
    :model-value="value"
    :label="label"
    :readonly="isReadOnly"
    :variant="variant"
    :min-width="minWidth"
    :placeholder="placeholder"
    :prepend-inner-icon="prependInnerIcon"
    @update:model-value="$emit('update:model-value', $event)"
    color="primary"
    base-color="primary"
    bg-color="#fff"
    data-test-name="input-transportation-company"
  />
</template>
<script>
export default {
  name: 'TransportationCompanySelector',
  emits: ['update:model-value'],
  props: {
    value: {
      type: String,
      default: '',
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      validator(value) {
        return [
          'flat',
          'text',
          'elevated',
          'tonal',
          'outlined',
          'plain',
        ].includes(value)
      },
      default: 'plain',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    minWidth: String,
    placeholder: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
    },
  }
}
</script>
