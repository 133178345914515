import axios from 'axios'
import URITemplate from 'urijs/src/URITemplate'
import { logAppError } from '@/logger'

export const state = {
  sortOptions: [],
}

export const actions = {
  GET_TAGS: async () => {
    let rv = []
    try {
      const url = process.env.VUE_APP_TEMPLATES_TAGS_URL
      let response = await axios.get(url)
      rv = response.data
    } catch(err) {
      logAppError(err)
    }
    return rv
  },
  SEARCH_TEMPLATES_DESTINATIONS: async (context, payload) => {
    const template = URITemplate(process.env.VUE_APP_TEMPLATES_DESTINATIONS_SEARCH_URL)
    const url = template.expand({
      query: payload
    }).replace(/\/$/, '')
    return await axios.get(url)
  },
  GET_SORT_OPTIONS: async () => {
    let rv = []
    try {
      const url = process.env.VUE_APP_TEMPLATES_SORT_OPTIONS_URL
      let response = await axios.get(url)
      rv = response.data
    } catch(err) {
      logAppError(err)
    }
    return rv
  },
  GET_TEMPLATES: async (context, payload) => {
    try {
      const url = process.env.VUE_APP_TEMPLATES_URL
      let response = await axios.post(url, payload)
      return response?.data
    } catch (err) {
      logAppError(err)
    }
  },
  GET_TEMPLATE_ITINERARY_BY_ID: async (context, payload) => {
    try {
      const template = URITemplate(process.env.VUE_APP_TEMPLATE_URL)
      const url = template.expand({
        templateId: payload,
      }).replace(/\/$/, '')
      const response = await axios.get(url)
      return response?.data
    } catch (err) {
      logAppError(err)
    }
  },
  DELETE_TEMPLATE: async (context, payload) => {
    try {
      const template = URITemplate(process.env.VUE_APP_TEMPLATE_URL)
      const { templateId, userId } = payload
      const url = template.expand({
        templateId
      }).replace(/\/$/, ' ')
      return await axios({
        method: 'DELETE',
        url: url,
        data: {
          userID: userId
        }})
    } catch (err) {
      logAppError(err)
    }
  },
  CREATE_ITINERARY_FROM_TEMPLATE: async (context, payload) => {
    try {
      const template = URITemplate(process.env.VUE_APP_CREATE_ITINERARY_FROM_TEMPLATE)
      const { templateId, userId, startDate, fromPlaceID, travelers } = payload
      const url = template.expand({
        templateId,
        userId,
      }).replace(/\/$/, '')
      let response = await axios.post(url, {
        startDate,
        fromPlaceID,
        travelers,
      })
      return response?.data
    } catch (err) {
      logAppError(err)
    }
  },
  GET_TEMPLATE_LIKES: async (context, payload) => {
    try {
      const url = process.env.VUE_APP_TEMPLATES_LIKES
      const response = await axios.post(url, payload)
      return response?.data
    } catch (err) {
      logAppError(err)
    }
  },
  TOGGLE_TEMPLATE_VOTE: async (context, payload) => {
    try {
      const template = URITemplate(process.env.VUE_APP_USER_TEMPLATE_VOTE)
      const url = template.expand(payload).replace(/\/$/, '')
      return await axios.post(url, payload)
    } catch (err) {
      logAppError(err)
    }
  }
}

export const mutations = {
}

export const getters = {
}

export default {
  actions,
  mutations,
  state,
  getters
}
