<template>
  <alternative-card
    :imageSrc="imageSrc"
    :userRating="userRating"
    @click="selectItem"
  >
    <template #title>
      {{ title }}
    </template>
    <template #body>
        <div class="alternative-item-description" max-height="200px" style="overflow-y: auto;">
          {{ description }}
        </div>
    </template>
    <template #actions>
      <v-row>
        <v-col class="d-flex justify-start pb-0 text-caption">
          {{duration}}
        </v-col>
        <v-col class="d-flex justify-end pb-0 text-caption align-center font-weight-bold">
          <colored-price
            :totalPrice="totalPrice"
          />
        </v-col>
      </v-row>
    </template>
  </alternative-card>
</template>

<script>
import AlternativeCard from './AlternativeCard'
import ColoredPrice from './ColoredPrice'
import {EventTypes, getItemTypeByType} from '@/helpers'
import itineraryService from '@/helpers/itineraryService'

export default {
  name: 'AlternativeShowItem',
  components: {
    AlternativeCard,
    ColoredPrice,
  },
  emits: ['select'],
  props: {
    item: Object,
    selectedItem: Object,
  },
  computed: {
    itemDetails() {
      return this.item?.show
    },
    title() {
      return this.itemDetails?.title
    },
    imageSrc() {
      return this.itemDetails?.roData?.thumbnailURL || getItemTypeByType(EventTypes.SHOW).defaultImageUrl
    },
    userRating() {
      return this.itemDetails?.roData?.userRating
    },
    description() {
      return this.itemDetails?.shortDescription
    },
    totalPrice() {
      return this.itemDetails?.totalPrice
    },
    duration() {
      let duration = itineraryService.calcDurationInSeconds(
        this.item.header.startDateTime,
        this.item.header.endDateTime
      )
      return itineraryService.formatDuration(duration)
    },
  },
  methods: {
    selectItem() {
      this.$emit('select',  this.item)
    },
  }
}
</script>
