import axios from 'axios'
import NodeCache from 'node-cache'

const TTL = 60 * 60 * 2 // cache for 2 Hours
var placesCache

const getPlacesCacheInstance = () => {
  if (!placesCache) {
    placesCache = new NodeCache({ stdTTL: TTL, checkperiod: TTL * 0.2, useClones: false })
  }

  return placesCache
}

/**
   * returns hits from search results
   * @param query
   * @returns array of places
   */
export const searchPlaces = async (query, type) => {
  let rv
  const response = await axios.get(
    process.env.VUE_APP_PLACES_SEARCH_URL,
    {
      params: {
        query: query,
        type: type,
        from: 0,
        size: 15,
      },
    }
  )

  if (response?.status === 200 && response?.data) {
    rv = response?.data?.places
  }

  return rv
}

/**
 * returns airports in range from point
 * @param latLon - { lat: 37.77493, lon: -122.41942 }
 * @param distance - 50mi
 * @param type - 'airport' || 'city' || none
 * @returns array of places
 */
export const lookupPlacesInRange = async (latLon, type, distance = '50mi', size = 10) => {
  let rv = []

  const response = await axios.post(
    process.env.VUE_APP_LOOKUP_PLACES_IN_RANGE_URL,
    {
      latLon: latLon,
      distance: distance,
      type: type,
      size: size,
      from: 0,
    },
  )

  if (response?.status === 200 && response?.data) {
    rv = response?.data?.places
  }

  return rv
}

/**
 *
 * @param ids - an array of ids or a single id
 * @returns array of places
*/
export const lookupPlacesByIds = async (ids) => {
  let rv
  let stringOfIds = ids //in case when ids is just a single id, or already a string

  if (Array.isArray(ids) && ids.length >= 1) {
    stringOfIds = ids.join(',')
  }

  const cache = getPlacesCacheInstance()
  if (cache) {
    rv = cache.get(stringOfIds)
  }

  if (!rv) {
    const response = await axios.get(
      process.env.VUE_APP_LOOKUP_PLACES_BY_IDS_URL,
      {
        params: {
          ids: stringOfIds
        },
      }
    )

    if (response?.status === 200 && response?.data) {
      rv = response?.data
      cache.set(stringOfIds, rv)
    }
  }

  return rv
}

export default { lookupPlacesInRange, lookupPlacesByIds, searchPlaces }
