<template>
  <div class="text-center" data-test-name="content-blog">
    <h2
      data-test-name="text-title"
      class="mt-12 mt-sm-12 mb-2 mb-sm-8 text-h4 text-md-h2 font-weight-bold text-textPrimaryColor"
    >
      {{ $t('Blog') }}
    </h2>
    <v-row no-gutters>
      <v-col cols="12" md="6" v-for="post in twoBlogPosts" :key="post.id">
        <blog-card
          :post="post"
          labelsDisabled
          @select="() => onSelectPost(post.id)"
        />
      </v-col>
    </v-row>
    <button-primary
      :text="$t('All_Articles')"
      @click="goToBlogPage"
      data-test-name="button-open-blog-page"
      class="mt-2"
    />
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
import BlogCard from '@/components/blog/BlogCard.vue'
import { RouteNames } from '@/helpers/routerUtils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BlogSection',
  components: { ButtonPrimary, BlogCard },
  created() {
    this.loadBlogPosts()
  },
  computed: {
    ...mapGetters({
      allBlogPosts: 'ALL_BLOG_POSTS',
    }),
    twoBlogPosts() {
      return this.allBlogPosts[0]?.items?.slice(0, 2) || []
    }
  },
  methods: {
    ...mapActions({
      loadBlogPosts: 'LOAD_BLOG_POSTS'
    }),
    goToBlogPage() {
      this.$router.push({ name: RouteNames.BLOG })
    },
    onSelectPost(id) {
      this.$router.push({ name: RouteNames.BLOG_POST, params: { id: id } })
    },
  },
}
</script>
