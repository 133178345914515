<template>
  <v-card elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 pt-4 tabs-container">
      <div class="text-primary font-weight-medium text-uppercase my-4 ml-4">
        {{ $t('Tab_Details') }}
      </div>
    </v-row>
    <v-card-text class="event-content">
      <v-row class="mx-0 mt-10 pt-1 pt-sm-4 my-2">
        <v-col cols="12" class="mt-2 mb-2 mt-sm-0 mb-0 pb-0 px-0">
          <v-text-field
            :readonly="isReadOnly"
            :model-value="title"
            @update:model-value="updateTitle($event)"
            :label="$t('Title')"
            :class="{ 'no-pointer-events': isReadOnly }"
            variant="outlined"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            hide-details
            maxLength="200"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 px-sm-0">
        <v-col cols="12" sm="6" class="mb-0 pb-0 px-0 pr-sm-2">
          <place-input
            :value="location?.placeID"
            :label="$t('Location') + '*'"
            :show-suggestions="true"
            :readonly="isReadOnly"
            min-width="320"
            name="placeInputLocation"
            variant="outlined"
            @updateLocation="updateLocation"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="!$vuetify.display.xs" class="mb-0 pb-0 px-0 pl-sm-2">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pr-sm-2">
          <date-picker
            :model-value="startDate"
            :label="`${$t('Start_date')}*`"
            :disabled="!location"
            :readonly="isReadOnly"
            variant="outlined"
            @update:model-value="updateStartDate"
            min-width="320"
            name="StartDate"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 pl-2 pr-0 pl-sm-2 pr-sm-2">
          <time-picker
            :model-value="startTime"
            :label="`${$t('Start_time')}*`"
            :disabled="!location"
            :readonly="isReadOnly"
            @update:model-value="updateStartTime"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pl-sm-2 pr-sm-2">
          <date-picker
            :model-value="endDate"
            :min-date="minEndDate"
            :label="`${$t('End_date')}*`"
            :disabled="!location"
            :readonly="isReadOnly"
            @update:model-value="updateEndDate"
            min-width="320"
            name="EndDate"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pl-2 pl-sm-2">
          <time-picker
            :model-value="endTime"
            :label="`${$t('End_time')}*`"
            :disabled="!location"
            :readonly="isReadOnly"
            :rules="[rules.afterStartTime]"
            @update:model-value="updateEndTime"
          />
          <span v-if="!location">{{ $t('Pick_location') }}</span>
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="6" v-if="$vuetify.display.xs" class="px-0 mt-2 pr-2">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                variant="outlined"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
        <v-col cols="6" sm="12" class="pr-0">
          <event-booking
            :isBooked="isBooked"
            :readonly="isReadOnly"
            @updateBooked="isBooked = !isBooked"
            class="d-flex justify-end"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="12" sm="6" class="mb-0 pb-0 px-0 pr-sm-2">
          <v-text-field
            v-model="simpleAddress"
            :label="$t('Address')"
            :readonly="isReadOnly"
            :min="0"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details="auto"
            :rules="[rules.validateAddress]"
          >
            <template v-slot:prepend-inner>
              <v-icon size="large" class="pr-2">$customHouse</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="4" class="mb-0 pb-0 px-0 pr-2 pl-sm-2 pr-sm-2">
          <v-select
            prepend-inner-icon="$customCurrency"
            v-model="totalPrice.currency"
            @update:model-value="updateCurrency"
            :label="$t('Currency')"
            :readonly="isReadOnly"
            :items="currencyList"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="2" class="mb-0 pb-0 px-0 pl-2 pl-sm-2">
          <v-currency-field
            :model-value="Number(totalPrice?.amount)"
            @update="updateAmount"
            variant="outlined"
            class="pa-0 ma-0"
            color="primary"
            :label="`${$t('Total_price')}`"
            :readonly="isReadOnly"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 mb-14">
        <v-col cols="12" class="pt-0 px-0 px-sm-0">
          <v-textarea
            v-model="notes"
            :label="$t('Notes')"
            :auto-grow="false"
            :readonly="isReadOnly"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            rows="3"
            variant="outlined"
            no-resize
            hide-details="auto"
            :rules="[rules.validNotes]"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <event-popup-buttons
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
  </v-card>
</template>

<script>
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import { convertAmountToUIFormat } from '@/helpers'
import VCurrencyField from '@/components/VCurrencyField'

export default {
  name: 'AddExerciseForm',
  mixins: [eventFormMainStructure],
  components: {
    VCurrencyField,
  },
  emits: ['updateTitle', 'cancel', 'save'],
  methods: {
    mapDataToFormFields(eventData) {
      this.$emit('updateTitle', eventData?.title)
      this.isBooked = Boolean(eventData?.isBooked)
      this.notes = eventData?.notes
      this.totalPrice.amount = convertAmountToUIFormat(eventData?.totalPrice?.amount)
      this.totalPrice.currency = eventData?.totalPrice?.currency || this.userCurrency || 'USD'
      this.simpleAddress = eventData?.simpleAddress
    },
  },
  watch: {
    isDialogVisible: {
      handler(visible) {
        if (visible && this.record) {
          this.mapDataToFormFields(this.record[this.eventType])
        }
      },
      immediate: true
    },
  }
}
</script>
<style scoped lang="scss">
.passengers-selector {
  width: 100%;
}
</style>
