<template>
  <v-row no-gutters>
    <v-col cols="12" md="2" class="pl-16 d-flex justify-end">
      <label class="d-flex align-center fill-height">
        <v-switch
          :model-value="value"
          :readonly="readonly"
          @update:model-value="switchToggleValue"
          class="pa-0 pl-2 ma-0 pr-6"
          data-test-id="event-booking__switch"
          color="primary"
          hide-details
          data-test-name="checkbox-event-booking-status"
        />
        <span
          :class="['font-weight-medium text-body-2 event-booking__text text-no-wrap', {'cursor-pointer': !readonly }]"
          data-test-name="text-event-booking-status"
        >
          {{ isBooked ? $t('Booked') : $t('NotBooked')}}
        </span>
      </label>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'EventBooking',
  emits: ['updateBooked'],
  props: {
    isBooked: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      value: this.isBooked,
    }
  },
  methods: {
    switchToggleValue(val) {
      this.$emit('updateBooked', val)
    },
  },
  watch: {
    isBooked: {
      handler(value) {
        this.value = value
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.event-booking {

  &__text {
    width: 110px;
  }
}
</style>
